import React from 'react';

export default function InputPorcentagem({representada}) {

  const regexDecimal = /^[+-]?\d*(?:[.,]\d*)?$/;
  const [porcentagemContrato, setPorcentagemContrato] = React.useState('')

  React.useEffect(() => {
    setPorcentagemContrato(representada.porcentagemContrato);
  }, [representada])

  return (
    <input type="text"
        className="form-control form-control-comissao"
        maxLength={9}
        pattern="[+-]?\d+(?:[.,]\d+)?"
        value={porcentagemContrato || ""}
        onChange={(e) => {
          if (!regexDecimal.test(e.target.value))
            return;

          representada.porcentagemContrato = e.target.value;
          setPorcentagemContrato(representada.porcentagemContrato);

          if (onChange)
            onChange();
        }} />
  )
}