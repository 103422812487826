import React from "react";
import Switch from "react-switch";
import CurrencyInput from "react-currency-input";
import { matchSorter } from "match-sorter";
import ModalCadastro from "../_base/modal-cadastro";
import FormGroupHeader from "../_base/form-group-header";
import Errors from "../_base/errors";

import EquipeService from "../../services/equipe-service";

import lib from "../../helpers/lib";

import "./styles.scss";
import Select from "react-select";

export default function ModalCadastroEquipeUsuarioPermissoes(props) {
  const {
    showModal,
    setShowModal,
    onClose,
    opcoesQuantPedido,
    usuarioPermissoes: ref_usuarioPermissoes,
  } = props;

  const emptyUsuarioPermissoes = {
    usuario: {},
    equipe: {},
    permissoes: [],
  };

  const [usuarioPermissoes, setUsuarioPermissoes] = React.useState({
    ...emptyUsuarioPermissoes,
  });
  const [permissoes, setPermissoes] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const [usuarioPedidoQuantConfig, setUsuarioPedidoQuantConfig] =
    React.useState(0);

  React.useEffect(() => {
    getPermissoes();
  }, []);

  React.useEffect(() => {
    reset();
    setUsuarioPedidoQuantConfig(
      ref_usuarioPermissoes?.usuario?.pedidoProdutoQuantConfigId
    );
    getUsuarioPermissoes();
  }, [ref_usuarioPermissoes]);

  function reset() {
    setErrors({});
    setUsuarioPermissoes({ ...emptyUsuarioPermissoes });
  }

  async function getUsuarioPermissoes() {
    if (
      ref_usuarioPermissoes?.usuario?.usuarioId?.length &&
      ref_usuarioPermissoes?.equipe?.equipeId > 0
    ) {
      window.showLoader();

      let response = await EquipeService.getUsuarioPermissoesAsync(
        ref_usuarioPermissoes
      );
      let usuarioPermissoes = response.data.data;
      setUsuarioPermissoes(usuarioPermissoes);

      window.hideLoader();
    }
  }

  async function getPermissoes() {
    let response = await EquipeService.listPermissoesAsync();
    setPermissoes(response.data.data);
  }

  async function saveAsync() {
    window.showLoader();

    setErrors({});

    let response = await EquipeService.saveUsuarioPermissoesAsync(
      usuarioPermissoes
    );

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      // let responseEquipeConvite = response.data.data;

      // if (onClose)
      //   onClose(responseEquipeConvite);

      // setUsuarioPermissoes({ ...emptyUsuarioPermissoes })

      setShowModal(false);
      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText={`${usuarioPermissoes?.usuario?.nome || ""} - ${usuarioPermissoes?.equipe?.nome || ""
        } - PERMISSÕES`}
    >
      <div className="modal-body">
        <div className="row justify-content-left usuarioPermissoes">
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            <label className="list-item  mb-3">
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    setUsuarioPermissoes({
                      ...usuarioPermissoes,
                      permissoes: [...permissoes],
                    });
                  } else {
                    setUsuarioPermissoes({
                      ...usuarioPermissoes,
                      permissoes: [],
                    });
                  }
                }}
              />{" "}
              Selecionar todos
            </label>

            {(permissoes || []).map((permissao, index) => (
              <div key={index} className="d-block">
                <label className="list-item mb-3">
                  <Switch
                    className="me-1 d-inline-block align-middle"
                    checked={
                      (usuarioPermissoes.permissoes || []).findIndex(
                        (c) => c.name === permissao.name
                      ) !== -1
                    }
                    onChange={(checked) => {
                      let lista = usuarioPermissoes.permissoes || [];

                      if (checked) {
                        lista.push(permissao);
                        setUsuarioPermissoes({
                          ...usuarioPermissoes,
                          permissoes: [...lista],
                        });
                      } else {
                        let index = lista.findIndex(
                          (x) => x.name === permissao.name
                        );

                        if (index !== -1) {
                          lista.splice(index, 1);
                          setUsuarioPermissoes({
                            ...usuarioPermissoes,
                            permissoes: [...lista],
                          });
                        }
                      }
                    }}
                  />
                  <span className="d-inline align-middle">
                    {permissao.descricao || permissao.name}
                  </span>
                </label>
              </div>
            ))}
          </div>
          <div className="col-lg-5">
            <label className="list-item  mb-3">Permissões Específicas</label>
            <div>
              <label>Opções de Caixaria</label>
              <Select
                isClearable={true}
                options={opcoesQuantPedido}
                placeholder="Respeitar a regra da representada"
                onChange={(option) => {
                  setUsuarioPedidoQuantConfig(
                    option?.value != undefined ? option?.value : 0
                  );
                  let user = usuarioPermissoes.usuario;
                  user.pedidoProdutoQuantConfigId =
                    option?.value != undefined ? option?.value : 0;
                  setUsuarioPermissoes({
                    ...usuarioPermissoes,
                    usuario: user,
                  });
                }}
                value={opcoesQuantPedido.find(
                  (x) => x.value === usuarioPedidoQuantConfig
                )}
              />
            </div>
            <div>
              <label>Valor Mínimo por Pedido</label>
              <CurrencyInput
                className="form-control mb-3"
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
                selectAllOnFocus={true}
                value={usuarioPermissoes?.usuario?.valorMinimoPedido || 0}
                onChangeEvent={(event, maskedvalue, floatvalue) => {
                  let user = usuarioPermissoes.usuario;
                  user.valorMinimoPedido = floatvalue;
                  setUsuarioPermissoes({
                    ...usuarioPermissoes,
                    usuario: user,
                  });
                }}
              />
            </div>
            <div>
              <label>Peso Mínimo KG</label>
              <CurrencyInput
                className="form-control mb-3"
                decimalSeparator=","
                thousandSeparator="."
                prefix=""
                selectAllOnFocus={true}
                value={usuarioPermissoes?.usuario?.minimoKiloPedido || 0}
                onChangeEvent={(event, maskedvalue, floatvalue) => {
                  let user = usuarioPermissoes.usuario;
                  user.minimoKiloPedido = floatvalue;
                  setUsuarioPermissoes({
                    ...usuarioPermissoes,
                    usuario: user,
                  });
                }}
              />
            </div>
            <div className="row mb-2">
              <div className="col-lg-12">
                <label>O que fazer com os pedidos com desconto acima do máximo permitido?</label>

                <div className="ps-4">
                  <label className="form-label">
                    <input
                      type="checkbox"
                      id={"descontoSemLimite"}
                      checked={
                        usuarioPermissoes?.usuario?.descontoSemLimites || false
                      }
                      value={
                        usuarioPermissoes?.usuario?.descontoSemLimites || false
                      }
                      onChange={(e) => {
                        let user = usuarioPermissoes.usuario;
                        user.descontoSemLimites = e.target.checked;
                        setUsuarioPermissoes({
                          ...usuarioPermissoes,
                          usuario: user,
                        });
                      }}
                    />
                    Usuário consegue finalizar o pedido, porém, o pedido será enviado para aprovação do superior
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          SALVAR
        </button>
      </div>
    </ModalCadastro>
  );
}
