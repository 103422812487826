export const service_url = {
  admin_usuariolistpaged: "admin/list-usuario-paged",
  admin_loginAs: "admin/login-as",
  admin_usuarioTipoSelectList: "admin/usuario-tipo-select-list",

  loja: "Loja/",

  login_login: "login",
  login_logout: "logout",
  login_recuperarSenha: "recuperar-senha",
  login_novaSenha: "nova-senha",
  login_checkuser: "check-user",

  pedido_historicopaged: "pedido/get-historico-paged",
  pedido_avaliacao_paged: "pedido/list-pedido-avaliacao-paged",
  pedido_tipos: "pedido/get-tipos",
  pedido_resumo: "pedido/get-pedido-resumo",
  pedido_resumo_pdf: "pedido/get-pedido-resumo-pdf",
  pedido_extrato: "pedido/get-extrato-em-bytes",
  pedido_extrato_financeiro: "pedido/get-extrato-resumo-financeiro-em-bytes",
  pedido_list_produtos: "pedido/list-produtos",
  pedido_list_produtos_por_representadas:
    "pedido/list-produtos-por-representada",
  pedido_unidade_converter: "pedido/unidade-converter",
  relatorio_faturamento: "pedidofaturamento/get-extrato-faturamento",
  relatorio_faturamento_excel:
    "pedidofaturamento/get-extrato-faturamento-em-bytes",

  pedidorascunho_getpaged: "pedidorascunho/get-paged",
  pedidorascunho_inactivate: "pedidorascunho/inactivate",
  pedidorascunho_remove: "pedidorascunho/remove",

  rede_selectList: "rede/select-list",
  rede_selectList_all: "rede/select-list-all",

  representada_listpaged: "Representada/list-paged",
  representada_add: "Representada/add",
  representada_update: "Representada/update",
  representada_updateStatus: "Representada/update-status",
  representada_selectList: "Representada/select-list",
  representada_selectList_all: "Representada/select-list-all",
  representada_selectList_carteira: "Representada/select-list-carteira",
  representada_listRepresentadaLojas: "Representada/list-representada-lojas",
  representada_orderQuantConfigOptions:
    "Representada/get-config-order-quantity-options",

  meusdados_get: "MeusDados/get",
  meusdados_update: "MeusDados/update",
  meusdados_changePassword: "MeusDados/change-password",

  relatorioLojasUltimosPedidos: "Relatorio/list-lojas-ultimo-pedido",
  relatorioAnalitico: "Relatorio/get-relatorio-analitico",
  relatorioAnaliticoPdf: "Relatorio/get-relatorio-analitico-pdf-em-bytes",
  relatorioMetas: "Relatorio/get-relatorio-metas",
  relatorioMetasVolume: "Relatorio/get-relatorio-metas-volume",
  relatorioMetasBytes: "Relatorio/get-relatorio-metas-em-bytes",
  relatorioMetasVolumeBytes: "Relatorio/get-relatorio-metas-volume-em-bytes",
  excelRelatorioAnalitico: "Relatorio/get-relatorio-analitico-em-bytes",
  excelRelatorioExtratoComissao: "Relatorio/get-extrato-comissao-em-bytes",
  relatorioUltimoPedidoPdf: "Relatorio/get-lojas-ultimo-pedido-pdf-em-bytes",
  relatorioMetasPdf: "Relatorio/get-relatorio-metas-pdf-em-bytes",
  relatorioMetasVolumePdf: "Relatorio/get-relatorio-metas-volume-pdf-em-bytes",
  relatorioComissaoPdf: "Relatorio/get-relatorio-comissao-pdf-em-bytes",
};
