import React, { useState, useEffect } from "react";

import { LoggedUserContext } from "../../contexts/logged-user-context";

import PedidoService from "../../services/pedido-service";

import { Link } from "react-router-dom";

export default function AlertPedidosAvaliacao() {
  const [userData, _] = React.useContext(LoggedUserContext);
  const [countPedidosAvaliacao, setCountPedidosAvaliacao] = useState(0)

  useEffect(() => {
    if (userData.aprovaPedido)
      getCountPedidosAvaliacao();
  }, [userData]);

  async function getCountPedidosAvaliacao() {
    let response = await PedidoService.getCountPedidosAvaliacao();
    let count = response?.data?.data || 0;

    setCountPedidosAvaliacao(count);
  }

  return countPedidosAvaliacao > 0
    ? (
      <div className="alert alert-danger" role="alert">
        <Link to="/pedido/avaliacao">Você possui {countPedidosAvaliacao} pedido(s) pendente(s) de validação.</Link>
      </div>
    )

    : null
}
