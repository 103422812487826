import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faFilter, faPlus, faRecycle, faTrash } from '@fortawesome/free-solid-svg-icons';
import PageTitle from "../../components/page-title/index";
import Errors from '../../components/_base/errors';
import InputDocumento from "../../components/_base/input-documento";
import InputTelefone from "../../components/_base/input-telefone";
import ModalCompletarCadastroUsuario from "../../components/modal-completar-cadastro-usuario";

import EquipeService from "../../services/equipe-service";

import lib from "../../helpers/lib";

export default function Invitation(props) {

  const [invitation, setInvitation] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [showRefuseMessage, setShowRefuseMessage] = React.useState(false);

  const [showModalCompletarCadastro, setShowModalCompletarCadastro] = React.useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (!token?.length) {
      window.location = "/";
      return;
    }

    getInvitation(token);
  }, []);

  async function getInvitation(token) {
    window.showLoader();

    let response = await EquipeService.invitationAsync(token);
    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseInvitation = response.data.data;

      if (responseInvitation.redirectToLogin) {
        let redirectTo = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
        window.location = `/login?redirectTo=${redirectTo}`;
        return;
      }

      setInvitation(responseInvitation)
      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  function displayErrors() {
    if (lib.isNullOrEmptyObject(errors))
      return null;

    let listaErrors = [];
    for (let p in errors) {
      listaErrors = [...listaErrors, ...errors[p]]
    }

    return <div className="alert alert-danger text-start mb-0 mt-2">
      <ul className="m-0">
        {listaErrors.map((descricao, index) => (
          <li key={index}>{descricao}</li>
        ))}
      </ul>
    </div>
  }

  async function accept() {
    if (!invitation.cadastroCompleto) {
      setShowModalCompletarCadastro(true);
    } else {
      window.showLoader();

      let response = await EquipeService.acceptWithTokenAsync({ token: invitation.token });

      window.hideLoader();

      if (response.status === lib.statusCode.ok) {

        window.location = "/equipe";
        return;
      }

      setErrors(response.data.errors);
    }
  }

  async function refuse() {
    window.showLoader();

    let response = await EquipeService.refuseWithTokenAsync(invitation.token);

    window.hideLoader();

    if (response.status === lib.statusCode.ok) {

      setShowRefuseMessage(true);
      setInvitation({});
      return;
    }

    setErrors(response.data.errors);
  }

  return (
    <div className="row justify-content-center">
      <div className="col-lg-12 p-3">
        <PageTitle className="text-center" title="FAÇA PARTE DO TIME" />

        <div className="row justify-content-center">
          <div className="col-lg-6 pt-4">
            {displayErrors()}

            {showRefuseMessage && (
              <div className="alert alert-info">
                O convite foi recusado. <a href="/">Ir para Página Inicial</a>
              </div>
            )}

            {!lib.isNullOrEmptyObject(invitation) && (
              <form>

                <div className={`row mb-4`}>
                  <div className="col-lg-12 ">
                    <h4 className="text-center">Você foi convidado por {invitation?.owner} para fazer parte do time <b>{invitation?.equipe}</b>:</h4>
                  </div>
                </div>

                <div className={`row mb-4 justify-content-center`}>
                  <div className="col-lg-6 d-flex justify-content-around">
                    <button type="button" className="btn btn-lg btn-success" onClick={accept}>Aceitar</button>

                    <button type="button" className="btn btn-lg btn-danger" onClick={refuse}>Recusar</button>
                  </div>
                </div>
              </form>
            )}

            <div className="mt-5">
              <hr />
              <a href="/" ><FontAwesomeIcon icon={faHome} /> Página Inicial</a>
            </div>
          </div>



        </div>
      </div>

      <ModalCompletarCadastroUsuario showModal={showModalCompletarCadastro} setShowModal={setShowModalCompletarCadastro} invitation={invitation} />
    </div>
  );
}
