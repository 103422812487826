import React from "react";
import CurrencyInput from "react-currency-input";

export default function TabelaInputPorcentagem({
  produto,
  keyName,
  label,
  className,
}) {
  const [valor, setValor] = React.useState("");

  React.useEffect(() => {
    setValor(produto[keyName]);
  }, [produto, keyName]);

  return (
    <CurrencyInput
      className={`form-control ${className}`}
      decimalSeparator=","
      thousandSeparator="."
      prefix="% "
      selectAllOnFocus={true}
      value={valor || 0}
      onChangeEvent={(event, maskedvalue, floatvalue) => {
        produto[keyName] = floatvalue;
        setValor(produto[keyName]);
      }}
    />
  );
}
