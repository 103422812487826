import React from "react";
import ModalCadastro from "../_base/modal-cadastro";
import FormGroupHeader from "../_base/form-group-header";
import Errors from "../_base/errors";
import SelectRepresentadaCadastro from "../selects/select-representada-cadastro";
import TabelaPrecoProdutos from "./_tabela-preco-produtos";

import TabelaPrecoService from "../../services/tabela-preco-service";
import ProdutoService from "../../services/produto-service";

import lib from "../../helpers/lib";
import CurrencyInput from "react-currency-input";

export default function ModalCadastroTabelaPreco(props) {
  const { showModal, setShowModal, onClose, entity } = props;

  const formFields = React.useMemo(
    () => ({
      representadaId: { key: "representadaId", required: true },
      nome: { key: "nome", required: true },
      percentualTetoDesconto: { key: "percentualTetoDesconto", required: true },
    }),
    [entity]
  );

  const emptyTabelaPreco = React.useMemo(
    () => ({
      nome: "",
      percentualTetoDesconto: "",
    }),
    []
  );

  const [tabelaPreco, setTabelaPreco] = React.useState({ ...emptyTabelaPreco });
  const [errors, setErrors] = React.useState({});
  const [selectListRepresentadas, setSelectListRepresentadas] = React.useState(
    []
  );

  React.useEffect(() => {
    if (!showModal) return;

    setErrors({});
    setTabelaPreco(
      lib.isNullOrEmptyObject(entity) ? { ...emptyTabelaPreco } : { ...entity }
    );
  }, [showModal, entity, emptyTabelaPreco]);

  React.useEffect(() => {
    getRepresentadas();
  }, []);

  async function getRepresentadas() {
    let response = await ProdutoService.selectListRepresentadasAsync();
    let selectListRepresentadas = response.data.data;

    setSelectListRepresentadas(selectListRepresentadas);
  }

  function updateTabelaPreco(data) {
    setTabelaPreco({ ...tabelaPreco, ...data });
  }

  async function saveAsync() {
    window.showLoader();

    if (!validateForm()) {
      window.hideLoader();
      return;
    }

    setErrors({});

    let response =
      tabelaPreco.tabelaPrecoId > 0
        ? await TabelaPrecoService.updateAsync(tabelaPreco)
        : await TabelaPrecoService.addAsync(tabelaPreco);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseTabelaPreco = response.data.data;

      if (onClose) onClose(responseTabelaPreco);

      setTabelaPreco({ ...emptyTabelaPreco });

      setShowModal(false);
      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  function validateForm() {
    let errors = {};

    //required fields
    for (let prop in formFields) {
      let field = formFields[prop];
      if (
        !field.required ||
        tabelaPreco[field.key]?.length ||
        tabelaPreco[field.key].toString() != ""
      )
        continue;

      errors[field.key] = [];
    }
    //--

    if (lib.isNullOrEmptyObject(errors)) return true;

    setErrors(errors);

    return false;
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="CADASTRAR TABELA PREÇOS"
    >
      <div className="modal-body">
        <form>
          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">Representada</label>
              <SelectRepresentadaCadastro
                className={`${
                  lib.isNullOrEmptyObject(errors[formFields.representadaId.key])
                    ? ""
                    : "is-invalid"
                }`}
                placeholder="Selecione uma Representada"
                options={selectListRepresentadas}
                value={
                  selectListRepresentadas.find(
                    (x) => x.value === tabelaPreco.representadaId
                  ) || null
                }
                onChange={(selectedValue) =>
                  updateTabelaPreco({
                    representadaId: selectedValue?.value,
                    produtos: undefined,
                  })
                }
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">Nome da Tabela de Preços</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.nome.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={tabelaPreco.nome}
                onChange={(e) => updateTabelaPreco({ nome: e.target.value })}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">Teto Desconto %</label>
              <CurrencyInput
                className={`form-control ${
                  lib.isNullOrEmptyObject(
                    errors[formFields.percentualTetoDesconto.key]
                  )
                    ? ""
                    : "is-invalid"
                }`}
                decimalSeparator=","
                thousandSeparator="."
                prefix="% "
                selectAllOnFocus={true}
                value={tabelaPreco.percentualTetoDesconto || 0}
                onChangeEvent={(event, maskedvalue, floatvalue) => {
                  // produto[keyName] = floatvalue;
                  // setValor(produto[keyName]);
                  updateTabelaPreco({ percentualTetoDesconto: floatvalue });
                }}
              />
              {/* <input
                type="number"
                className={`form-control ${
                  lib.isNullOrEmptyObject(
                    errors[formFields.percTetoDesconto.key]
                  )
                    ? ""
                    : "is-invalid"
                }`}
                value={tabelaPreco.percentualTetoDesconto}
                onChange={(e) =>
                  updateTabelaPreco({ percentualTetoDesconto: e.target.value })
                }
              /> */}
            </div>
          </div>

          <TabelaPrecoProdutos tabelaPreco={tabelaPreco} errors={errors} />
        </form>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          SALVAR
        </button>
      </div>
    </ModalCadastro>
  );
}
