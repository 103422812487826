import ServiceClient from './service-client';

class ComissaoService {
  static async getModelAsync() {
    try {
      var result = await ServiceClient.Get("/comissao/get-model");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getEquipeUsuarioModelAsync({equipeId, usuarioId}) {
    try {
      var result = await ServiceClient.Get("/comissao/get-equipe-usuario-model", {equipeId, usuarioId});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveAsync (model) {
    try {
      var result = await ServiceClient.Post("/comissao/save", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveEquipeUsuarioAsync (model) {
    try {
      var result = await ServiceClient.Post("/comissao/save-equipe-usuario", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default ComissaoService;