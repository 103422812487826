import React, { useState, useEffect } from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from 'react-apexcharts';

import "./styles.scss";

export default function DashboardBarChart({source}) {

  const [barChart, setBarChart] = useState(null);

  useEffect(() => {
    let metaContratante = source;
    if (!metaContratante?.dias)
      return;

    let metas = metaContratante.dias.map(x => x.meta);
    let faturados = metaContratante.dias.map(x => x.valorFaturado);
    let faturadosAnterior = metaContratante.dias.map(x => x.valorFaturadoAnterior);
    let dias = metaContratante.dias.map(x => x.dia);

    const chartData = {
      series: [
        {
          name: 'OBJETIVO NO MÊS',
          type: 'column',
          data: metas,
        },
        {
          name: 'VENDAS NO MÊS',
          type: 'area',
          data: faturados,
        },
        {
          name: 'MÊS ANTERIOR',
          type: 'line',
          data: faturadosAnterior,
        },
      ],
      options: {
        chart: {
          type: 'line',
          stacked: false,
        },
        title: {
          text: 'Evolução Mensal', // Defina o título desejado aqui
          align: 'left', // Alinhe o título à esquerda, como você configurou
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238',
          },
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth',
          lineCap: 'round'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },
        fill: {
          opacity: [0.45, 0.55, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: dias,
        markers: {
          size: 0,
        },

        xaxis: {
          type: 'data', // Verifique se é realmente do tipo 'datetime'
        },
        yaxis: {
          title: {
            text: 'R$',
          },
          min: 0,
          labels: {
            formatter: function (metas) {
              // Arredonda o valor para o milhar mais próximo
              var roundedValue = Math.round(metas / 1000)*1000;
                      
              // Formata o valor arredondado como moeda brasileira
              return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(roundedValue);
            },
          },
        },

        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
              }
              return y;
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: '100%', // Ajusta a largura para telas pequenas
                height: 300,
              },
              legend: {
                show: false,
              },
            },
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                width: '100%', // Ajusta a largura para telas médias
                height: 400,
              },
            },
          },
          {
            breakpoint: 2992,
            options: {
              chart: {
                width: '100%', // Ajusta a largura para telas grandes
                height: 500,
              },
            },
          },
        ],
      },
    };

    setBarChart(chartData);
  }, [source]);

  if (!barChart)
    return null;

  return (
    <div className="dashboard-bar-chart">
      <div id="bar-chart">
        {barChart && (
          <ReactApexChart options={barChart.options} series={barChart.series} type="line" />
        )}
      </div>
    </div>
  );
}