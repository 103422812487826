import React from 'react';
import ModalCadastro from '../_base/modal-cadastro';

import RepresentadaService from '../../services/representada-service';
import MetaService from '../../services/meta-service';

import './styles.scss';
import MetaCadastro from '../meta-cadastro';
import MetaVolumeService from '../../services/meta-volume-service';

export default function ModalCadastroEquipeMeta(props) {
  const { showModal, setShowModal, onClose, meta } = props;

  const [representadas, setRepresentadas] = React.useState([]);

  React.useEffect(() => {
    loadRepresentadas();
  }, [meta])

  function clear() {
    setRepresentadas([]);
  }

  async function loadRepresentadas() {
    if (meta?.usuario?.usuarioId?.length && meta?.equipe?.equipeId > 0) {

      window.showLoader();
      let representadas = await RepresentadaService.selectListCarteiraAsync({ equipeId: meta.equipe.equipeId, usuarioId: meta.usuario.usuarioId });
      window.hideLoader();

      if (representadas.data && representadas.data?.data?.length) {
        setRepresentadas(representadas.data.data[0].options);
      }
    }
  }

  async function getModelAsync({ representadaId, ano, mes }) {
    return await MetaService.getEquipeUsuarioModelAsync({
      equipeId: meta?.equipe?.equipeId,
      usuarioId: meta?.usuario?.usuarioId,
      representadaId,
      ano,
      mes
    });
  }

  async function getModelVolumeAsync({ representadaId, ano, mes }) {
    return await MetaVolumeService.getEquipeUsuarioModelAsync({
      equipeId: meta?.equipe?.equipeId,
      usuarioId: meta?.usuario?.usuarioId,
      representadaId,
      ano,
      mes
    });
  }

  async function saveAsync(model) {
    return await MetaService.saveEquipeUsuarioAsync({
      equipeId: meta?.equipe?.equipeId,
      usuarioId: meta?.usuario?.usuarioId,
      ...model
    });
  }

  async function saveVolumeAsync(model) {
    return await MetaVolumeService.saveEquipeUsuarioAsync({
      equipeId: meta?.equipe?.equipeId,
      usuarioId: meta?.usuario?.usuarioId,
      ...model
    });
  }

  async function removeAsync(model) {
    return await MetaService.clearEquipeUsuarioAsync({
      equipeId: meta?.equipe?.equipeId,
      usuarioId: meta?.usuario?.usuarioId,
      ...model
    });
  }

  async function removeVolumeAsync(model) {
    return await MetaVolumeService.clearEquipeUsuarioAsync({
      equipeId: meta?.equipe?.equipeId,
      usuarioId: meta?.usuario?.usuarioId,
      ...model
    });
  }

  async function removeAllAsync(model) {
    return await MetaService.clearEquipeUsuarioAllAsync({
      equipeId: meta?.equipe?.equipeId,
      usuarioId: meta?.usuario?.usuarioId,
      ...model
    });
  }

  async function removeAllVolumeAsync(model) {
    return await MetaVolumeService.clearEquipeUsuarioAllAsync({
      equipeId: meta?.equipe?.equipeId,
      usuarioId: meta?.usuario?.usuarioId,
      ...model
    });
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText={`${meta?.usuario?.nome || ""} - ${meta?.equipe?.nome || ""} - META`}
      onClose={() => {
        clear();

        if (onClose)
          onClose();
      }}
    >

      <MetaCadastro
        representadas={representadas}

        getMetaModelAsync={getModelAsync}
        getMetaVolumeModelAsync={getModelVolumeAsync}

        saveMetaModelAsync={saveAsync}
        saveMetaVolumeModelAsync={saveVolumeAsync}

        removeMetasAsync={removeAsync}
        removeMetasVolumeAsync={removeVolumeAsync}

        modal={true}
      />

    </ModalCadastro>
  )
}