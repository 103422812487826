import ServiceClient from "./service-client";

class EquipeService {
  static async getAsync(id) {
    try {
      var result = await ServiceClient.Get("/equipe/get", { equipeId: id });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listOwnerTeamsPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        "/equipe/list-owner-teams-paged",
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listMemberTeamsPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        "/equipe/list-member-teams-paged",
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listInvitationsAsync() {
    try {
      var result = await ServiceClient.Get("/equipe/list-invitations");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addAsync(equipe) {
    try {
      var result = await ServiceClient.Post("/equipe/add", equipe);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync(equipe) {
    try {
      var result = await ServiceClient.Post("/equipe/update", equipe);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async inativateAsync(equipe) {
    try {
      equipe.ativo = false;
      var result = await ServiceClient.Post("/equipe/update", equipe);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async sendInviteAsync(model) {
    try {
      var result = await ServiceClient.Post("/equipe/send-invite", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async resendInviteAsync(model) {
    try {
      var result = await ServiceClient.Post("/equipe/resend-invite", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async invitationAsync(token) {
    try {
      var result = await ServiceClient.Get("/equipe/invitation", {
        token: token,
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async acceptWithTokenAsync(model) {
    try {
      var result = await ServiceClient.Post("/equipe/accept-with-token", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async refuseWithTokenAsync(token) {
    try {
      var result = await ServiceClient.Post("/equipe/refuse-with-token", {
        token: token,
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async removeUser(model) {
    try {
      var result = await ServiceClient.Post("/equipe/remove-user", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async acceptAsync(equipeId) {
    try {
      var result = await ServiceClient.Post(`/equipe/accept/${equipeId}`);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async refuseAsync(equipeId) {
    try {
      var result = await ServiceClient.Post(`/equipe/refuse/${equipeId}`);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async leaveAsync(equipeId) {
    try {
      var result = await ServiceClient.Post(`/equipe/leave/${equipeId}`);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCarteiraAsync() {
    try {
      var result = await ServiceClient.Get("/equipe/get-carteira");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCarteiraEquipeAsync(equipeId) {
    try {
      var result = await ServiceClient.Get("/equipe/get-carteira-equipe", {
        equipeId: equipeId,
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCarteiraEquipeUsuarioAsync({ equipe, usuario }) {
    try {
      var result = await ServiceClient.Get(
        "/equipe/get-carteira-equipe-usuario",
        { equipeId: equipe?.equipeId, usuarioId: usuario?.usuarioId }
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listClientesAsync() {
    try {
      var result = await ServiceClient.Get("/equipe/list-clientes");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listRepresentadasAsync() {
    try {
      var result = await ServiceClient.Get("/equipe/list-representadas");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveCarteiraAsync(model) {
    try {
      var result = await ServiceClient.Post("/equipe/save-carteira", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getDefaultEquipePermissoesAsync({ equipe }) {
    try {
      var result = await ServiceClient.Get("/equipe/get-equipe-permissoes", {
        equipeId: equipe?.equipeId,
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveDefaultEquipePermissoesAsync(model) {
    try {
      var result = await ServiceClient.Post(
        "/equipe/save-equipe-permissoes",
        model
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getUsuarioPermissoesAsync({ equipe, usuario }) {
    try {
      var result = await ServiceClient.Get("/equipe/get-usuario-permissoes", {
        equipeId: equipe?.equipeId,
        usuarioId: usuario?.usuarioId,
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listPermissoesAsync() {
    try {
      var result = await ServiceClient.Get("/equipe/list-permissoes");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveUsuarioPermissoesAsync(model) {
    try {
      var result = await ServiceClient.Post(
        "/equipe/save-usuario-permissoes",
        model
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getUsuarioResponsavelAsync(model) {
    try {
      var result = await ServiceClient.Get("/equipe/get-usuario-responsavel");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default EquipeService;
