const lib = {
  statusCode: {
    ok: 200,
    badRequest: 400,
  },

  isNullOrEmptyObject: (obj) => {
    if (obj === null || obj === undefined) return true;

    if (obj === "") return false;

    if (Array.isArray(obj)) return false;

    for (let p in obj) return false;

    return true;
  },

  formatToCurrencyText: (value) => {
    if (value) {
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }
    return undefined;
  },

  formatCep: (str) => {
    var re = /^([\d]{5})-?([\d]{3})/;

    if (re.test(str)) return str.replace(re, "$1-$2");

    return str;
  },

  getStringFromDate: (data) => {
    if (!data) return "";

    let parsedData = new Date(data);

    var dia = parsedData.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (parsedData.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = parsedData.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  },

  getStringFromDateTime: (data) => {
    if (!data) return "";

    let parsedData = new Date(data);

    return parsedData.toLocaleDateString("pt-BR", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  },

  getStringFromDateMinute: (data) => {
    if (!data) return "";

    let parsedData = new Date(data);

    return parsedData.toLocaleDateString("pt-BR", {
      hour: "numeric",
      minute: "numeric",
    });
  },

  getDate: (data) => {
    if (!data) return "";

    let parsedData = new Date(data);

    return parsedData;
  },

  getDateFromString: (str) => {
    if (!str || !str.length) return null;

    var parts = str.split("/");
    if (parts.length < 3) return null;

    return new Date(parts[2], parts[1] - 1, parts[0]);
  },

  getDateFromStringJson: (obj) => {
    try {
      if (!obj) return null;

      let str = obj.toString();
      if (!str.length) return null;

      var parsedData = new Date(str);
      if (parsedData === "Invalid Date") return null;

      if (isNaN(new Date(parsedData))) return null;

      return parsedData;
    } catch (error) {
      return null
    }
  },

  _base64ToByteArray: (base64) => {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes;
  },

  downloadFile: (data, fileName) => {
    function base64ToByteArray(base64) {
      let binary_string = window.atob(base64);
      let len = binary_string.length;
      let bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }

      return bytes;
    }

    let file = base64ToByteArray(data);
    let blob = new Blob([file], { type: "arraybuffer" });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    let fileURL = window.URL.createObjectURL(blob);
    a.href = fileURL;
    a.download = fileName;
    a.click();
  },

  getCurrentDateFilename: () => {
    let today = new Date();
    return `${today.getFullYear()}_${today.getMonth() + 1}_${today.getDate()}`;
  },

  selectListSituacao: [
    { value: "", label: "Todos" },
    { value: "A", label: "Ativos" },
    { value: "I", label: "Inativos" },
  ],

  getPrimeiroDiaMesAnterior: () => {
    const inicio = new Date();
    inicio.setHours(0, 0, 0, 0);
    inicio.setMonth(inicio.getMonth() - 1);
    inicio.setDate(1);

    return inicio;
  },

  getUltimoDiaMesAnterior: () => {
    const fim = new Date();
    fim.setHours(0, 0, 0, 0);
    fim.setDate(0);

    return fim;
  },

  selectListMeses: [
    { value: 1, label: "Janeiro" },
    { value: 2, label: "Fevereiro" },
    { value: 3, label: "Março" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Maio" },
    { value: 6, label: "Junho" },
    { value: 7, label: "Julho" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Setembro" },
    { value: 10, label: "Outubro" },
    { value: 11, label: "Novembro" },
    { value: 12, label: "Dezembro" },
  ],

  selectListAnos: Array(new Date().getFullYear() + 2 - 2022)
    .fill()
    .map((_, idx) => 2022 + idx)
    .map((ano) => ({
      value: ano,
      label: ano,
    })),

  pedidoTipos: {
    venda: { sigla: "V" },
    bonificacao: { sigla: "B" },
    amostra: { sigla: "A" },
    degustacao: { sigla: "D" },
  },
};

export default lib;
