import React from 'react';
import LojaService from '../../services/loja-service';
import GeneralService from '../../services/general-service';
import lib from '../../helpers/lib';

export const CadastroLojaContext = React.createContext({});

export const CadastroLojaProvider = props => {
  const [redes, setRedes] = React.useState([]);
  const [canais, setCanais] = React.useState([]);
  const [regionais, setRegionais] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    //redes e canais precisam ser carregadas novamente quando salvar porque pode ter sido criado alguma rede ou canal
    getRedes();
    getCanais();
    getRegionais();
    //--
    
  }, [])
  
  async function getRedes() {
    let response = await LojaService.listRedesAsync();
    setRedes(response.data.data);
  }

  async function getCanais() {
    let response = await LojaService.listCanaisAsync();
    setCanais(response.data.data);
  }

  async function getRegionais() {
    let response = await LojaService.listRegionaisAsync();
    setRegionais(response.data.data);
  }

  async function validateCnpj(loja) {
    window.showLoader();

    let response = await LojaService.validateCnpjAsync(loja);

    if (response.status === lib.statusCode.ok)
      return true;

    setErrors(response.data.errors)
    window.hideLoader();

    return false;
  }

  async function getEndereco(loja) {
    window.showLoader();

    let response = await GeneralService.getEnderecoAsync(loja.cep);

    if (response.status === lib.statusCode.ok) {
      loja.logradouro = response.data.data.logradouro ?? "";
      loja.numero = "";
      loja.complemento = "";
      loja.bairro = response.data.data.bairro ?? "";
      loja.cidade = response.data.data.cidade ?? "";
      loja.estado = response.data.data.uf ?? "";
    }
    
    window.hideLoader();
  }

  function validateForm(entity, formFields) {
    let errors = {};

    //required fields
    for (let prop in formFields) {
      let field = formFields[prop];

      if (formFields[field.key].validate) {
        if (formFields[field.key].validate())
          continue;
      } else if (
        !field.required ||
        entity[field.key]?.length ||
        entity[field.key] > 0
      )
        continue;

      errors[field.key] = [];
    }
    //--

    if (lib.isNullOrEmptyObject(errors)) return true;

    setErrors(errors);

    return false;
  }

  return (
    <CadastroLojaContext.Provider value={
      {
        errors,
        setErrors,
        validateForm,
        validateCnpj,
        redes, setRedes, getRedes,
        canais, setCanais, getCanais,
        regionais, setRegionais, getRegionais,
        getEndereco,
      }
      }>
      {props.children}
    </CadastroLojaContext.Provider>
  );
};