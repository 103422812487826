import React from "react";
import FormGroupHeader from "../_base/form-group-header";
import InputCodigo from "./_input-codigo";
import SelectTabelaPreco from "./_select-tabela-precos";

import RepresentadaService from "../../services/representada-service";

import lib from "../../helpers/lib";
import InputPorcentagem from "./_input-porcentagem";

export default function Representadas({ loja }) {
  const [representadas, setRepresentadas] = React.useState([]);

  React.useEffect(() => {
    if (loja.representadas) return;

    async function getListRepresentadaLojas() {
      window.showLoader();

      let response = await RepresentadaService.listRepresentadaLojasAsync(
        loja?.lojaId
      );

      if (response.status === lib.statusCode.ok) {
        loja.representadas = response.data.data || [];
        setRepresentadas(loja.representadas);
      }

      window.hideLoader();
    }

    getListRepresentadaLojas();
  }, [loja]);

  if (!representadas?.length) return null;

  return (
    <>
      <FormGroupHeader text="REPRESENTADAS" />
      <div className="row mb-2">
        <div className="col-lg-12">
          <div className="table-responsive overflow-visible">
            <table className="table table-cadastro">
              <thead>
                <tr>
                  <th>Representada</th>
                  <th>Código da Loja na Representada</th>
                  <th>Tabela de Preço</th>
                  <th>&#37; Contrato Comercial</th>
                </tr>
              </thead>
              <tbody>
                {(representadas || []).map((representada) => (
                  <tr key={representada.representadaId}>
                    <td>{representada.nomeFantasia}</td>
                    <td>
                      <InputCodigo representada={representada} />
                    </td>
                    <td>
                      <SelectTabelaPreco representada={representada} />
                    </td>
                    <td>
                      <InputPorcentagem representada={representada} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <Errors errors={errors} keyName={formFields.contatoTelefone.key} /> */}
        </div>
      </div>
    </>
  );
}
