/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { GlobalContext } from '../../contexts/global-context';
import TableauService from '../../services/tableau-service';

const _tableau = (props) => {
  const {isMobile} = React.useContext(GlobalContext)

  const [model, setModel] = useState({});

  async function getModel() {
    let result = isMobile
     ? await TableauService.getHomeMobile()
     : await TableauService.getHome();

    setModel(result.data.data);
  }

  useEffect(() => {
    setModel({});
    getModel();
  }, [isMobile]);

  if (!model?.url?.length)
    return null;

  return (
    <div className='iframe-tableau d-flex'>
      <iframe className='d-flex' src={model.url}></iframe>
    </div>
  )
};

export default _tableau;
