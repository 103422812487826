import React from "react";
import VMasker from "vanilla-masker";

export default function InputTelefone({
  className,
  placeholder,
  value,
  onChange,
  disabled,
}) {
  return (
    <input
      type="text"
      className={className}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={(event) => {
        let input = event.target.value;
        input = VMasker.toPattern(input, "99999999999999999999");

        if (input.length > 10) {
          input = VMasker.toPattern(input, "(99) 99999-9999");
        } else {
          input = VMasker.toPattern(input, "(99) 9999-9999");
        }

        onChange(input);
      }}
    />
  );
}
