import React from "react";

import Layout from "../../components/layout/base/index";
import PageTitle from "../../components/page-title";

import RepresentadaService from "../../services/representada-service";
import MetaService from "../../services/meta-service";

import MetaCadastro from "../../components/meta-cadastro";
import MetaVolumeService from "../../services/meta-volume-service";

export default function Meta() {
  const [representadas, setRepresentadas] = React.useState([]);

  React.useEffect(() => {
    loadRepresentadas();
  }, [])

  async function loadRepresentadas() {
    let representadas = await RepresentadaService.selectListAsync();
    if (representadas.data && representadas.data?.data?.length) {
      setRepresentadas(representadas.data.data[0].options);
    }
  }

  return (
    <Layout>
      <PageTitle title="Cadastro de Meta" />

      <MetaCadastro
        representadas={representadas}

        getMetaModelAsync={MetaService.getModelAsync}
        getMetaVolumeModelAsync={MetaVolumeService.getModelAsync}

        saveMetaModelAsync={MetaService.saveAsync}
        saveMetaVolumeModelAsync={MetaVolumeService.saveAsync}

        removeMetasAsync={MetaService.clearAsync}
        removeMetasVolumeAsync={MetaVolumeService.clearAsync}
      />

    </Layout>
  )
}