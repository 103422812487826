import ServiceClient from "./service-client";
class AcordoService {
  static async listPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get("/acordo/list-paged", filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getAcordosAsync() {
    try {
      var result = await ServiceClient.Get("/acordo/list-acordo");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getFormasPagamentoAsync() {
    try {
      var result = await ServiceClient.Get("/acordo/list-forma-pagamento");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addAsync(body) {
    try {
      var result = await ServiceClient.Post("/acordo/add", body);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync(body) {
    try {
      var result = await ServiceClient.Post("/acordo/update", body);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default AcordoService;
