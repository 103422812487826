import React from "react";
import { matchSorter } from 'match-sorter';
import Accordion from "../_base/custom-accordion/_accordion";
import InputComissao from "./_input-comissao";

export default function RowRepresentada({ representada, selectedRepresentadas, selectedProdutoCategorias, selectedProdutos, selectedRedes, showValorBase, fullSize }) {
  const [representadaValor, setRepresentadaValor] = React.useState(representada.valor);
  const [categorias, setCategorias] = React.useState(representada.produtoCategorias);

  let representadaIds = [];
  let produtoCategoriaIds = [];
  let produtoIds = [];
  let redeIds = [];

  if (selectedProdutos?.length) {
    representadaIds = [].concat(...selectedProdutos.map(x => x.representadaIds));
    produtoCategoriaIds = [].concat(...selectedProdutos.map(x => x.produtoCategoriaIds));
    produtoIds = [].concat(...selectedProdutos.map(x => x.produtoIds));
  } 

  else if (selectedProdutoCategorias?.length) {
    representadaIds = [].concat(...selectedProdutoCategorias.map(x => x.representadaIds));
    produtoCategoriaIds = [].concat(...selectedProdutoCategorias.map(x => x.produtoCategoriaIds));
    produtoIds = [].concat(...selectedProdutoCategorias.map(x => x.produtoIds));
  } 
  
  else if (selectedRepresentadas?.length) {
    representadaIds = [].concat(...selectedRepresentadas.map(x => x.representadaIds));
    produtoCategoriaIds = [].concat(...selectedRepresentadas.map(x => x.produtoCategoriaIds));
    produtoIds = [].concat(...selectedRepresentadas.map(x => x.produtoIds));
  } 

  if (selectedRedes?.length) {
    redeIds = [].concat(...selectedRedes.map(x => x.redeIds));
  } 

  if (representadaIds.length > 0 && representadaIds.findIndex(representadaId => representada.representadaId === representadaId) === -1)
    return null;

  let matchCategorias = produtoCategoriaIds?.length > 0
    ? categorias.filter(c => produtoCategoriaIds.indexOf(c.produtoCategoriaId) !== -1)
    : categorias;

  if (matchCategorias.length === 0)
    return null;

  let produtos = [].concat(...categorias.map(c => c.produtos));

  let matchProdutos = produtoIds.length > 0
    ? produtos.filter(x => produtoIds.indexOf(x.produtoId) !== -1)
    : produtos;

  if (matchProdutos.length === 0)
    return null;

  return (
    <>
      <div className="row group-divider-bold pt-1">
        <div className={`col-2 p-2px pt-2`}>{representada.nomeFantasia}</div>
        <div className={`col-10 p-2px`}>
          <div className="row">
            <div className={`col-3 p-0`}>
              <div className="row">
                {
                  showValorBase && representada.valorBase?.length > 0
                    ? (
                      <>
                        <div className="col-7 p-2px">
                          <InputComissao entity={representada} onChange={() => setRepresentadaValor(representada.valor)} />
                        </div>
                        <div className="col-5 p-2px">
                          <span className="form-control text-center text-muted" title="Valor Base">{representada.valorBase}%</span>
                        </div>
                      </>
                    )

                    : (
                      <div className="col-12 p-2px">
                        <InputComissao entity={representada} onChange={() => setRepresentadaValor(representada.valor)} />
                      </div>
                    )
                }

              </div>

            </div>
          </div>

          <Accordion className="group-divider" show={selectedProdutoCategorias?.length > 0 || selectedProdutos?.length > 0} header={"Categorias"}>
            {
              matchCategorias.map((categoria, index) => {
                let matchCategoriaProdutos = produtoIds.length > 0
                  ? categoria.produtos.filter(p => produtoIds.indexOf(p.produtoId) !== -1)
                  : categoria.produtos;

                if (matchCategoriaProdutos.length === 0)
                  return null;

                let categoriaValorBase = categoria.valorBase || representada.valorBase;
                return (
                  <div key={index} className="row group-divider">
                    <div className={`col-3 p-2px pt-2`}>{categoria.nome}</div>
                    <div className={`col-9 p-2px`}>
                      <div className="row">
                        <div className={`col-4 p-0`}>
                          <div className="row">
                            {
                              showValorBase && categoriaValorBase?.length > 0
                                ? (
                                  <>
                                    <div className="col-7 p-2px">
                                      <InputComissao entity={categoria} inheritedValue={representadaValor} onChange={() => setCategorias([...representada.produtoCategorias])} />
                                    </div>
                                    <div className="col-5 p-2px">
                                      <span className="form-control text-center text-muted" title="Valor Base">{categoriaValorBase}%</span>
                                    </div>
                                  </>
                                )

                                : (
                                  <div className="col-12 p-2px">
                                    <InputComissao entity={categoria} inheritedValue={representadaValor} onChange={() => setCategorias([...representada.produtoCategorias])} />
                                  </div>
                                )
                            }
                          </div>
                        </div>
                      </div>
                      <Accordion className="group-divider" show={selectedProdutos.length > 0} header={"Produtos"}>
                        {
                          matchCategoriaProdutos.map((produto, index) => {
                              let produtoValorBase = produto.valorBase || categoriaValorBase;
                              return (
                                <div key={index} className="row group-divider">
                                  <div className={`col-4 p-2px pt-2`}>{produto.codigo} - {produto.nome}</div>
                                  <div className={`col-8 p-2px`}>
                                    <div className="row">
                                      <div className={`col-6 p-0`}>
                                        <div className="row">
                                          {
                                            showValorBase && produtoValorBase?.length > 0
                                              ? (
                                                <>
                                                  <div className="col-7 p-2px">
                                                    <InputComissao entity={produto} inheritedValue={categoria.valor || representadaValor} onChange={() => setCategorias([...representada.produtoCategorias])} />
                                                  </div>
                                                  <div className="col-5 p-2px">
                                                    <span className="form-control text-center text-muted" title="Valor Base">{produtoValorBase}%</span>
                                                  </div>
                                                </>
                                              )

                                              : (
                                                <div className="col-12 p-2px">
                                                  <InputComissao entity={produto} inheritedValue={categoria.valor || representadaValor} onChange={() => setCategorias([...representada.produtoCategorias])} />
                                                </div>
                                              )
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <Accordion className="group-divider" show={selectedRedes.length > 0} header={"Redes"}>
                                      {
                                        (redeIds.length > 0
                                          ? produto.redes.filter(r => redeIds.indexOf(r.redeId) !== -1)
                                          : produto.redes).map((rede, index) => {
                                            let redeValorBase = rede.valorBase || produtoValorBase;
                                            return (
                                              <div key={index} className="row group-divider">
                                                <div className={`col-6 p-2px pt-2`}>{rede.nome}</div>
                                                <div className={`col-6 p-2px`}>
                                                  <div className={`col-12 p-0`}>
                                                    <div className="row">
                                                      {
                                                        showValorBase && redeValorBase?.length > 0
                                                          ? (
                                                            <>
                                                              <div className="col-7 p-2px">
                                                                <InputComissao entity={rede} inheritedValue={produto.valor || categoria.valor || representadaValor} />
                                                              </div>
                                                              <div className="col-5 p-2px">
                                                                <span className="form-control text-center text-muted" title="Valor Base">{redeValorBase}%</span>
                                                              </div>
                                                            </>
                                                          )

                                                          : (
                                                            <div className="col-12 p-2px">
                                                              <InputComissao entity={rede} inheritedValue={produto.valor || categoria.valor || representadaValor} />
                                                            </div>
                                                          )
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          })
                                      }
                                    </Accordion>

                                  </div>
                                </div>
                              )
                            })
                        }
                      </Accordion>

                    </div>
                  </div>
                )
              })
            }
          </Accordion>
        </div>
      </div>
    </>
  )
}