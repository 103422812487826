/* eslint-disable */
import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Admin from "../pages/admin";
import Cliente from "../pages/cliente/index";
import Comissao from "../pages/comissao/index";
import Equipe from "../pages/equipe/index";
import Extrato from "../pages/extrato";
import Home from "../pages/home/index";
import Invitation from "../pages/invitation/index";
import Login from "../pages/login/index";
import NovaSenha from "../pages/login/nova-senha";
import Meta from "../pages/meta";
import MeusDados from "../pages/meus-dados";
import Pedido from "../pages/pedido/index";
import PedidoAvaliacao from "../pages/pedido-avaliacao";
import PedidoRevisao from "../pages/pedido/revisao";
import PedidoFinalizado from "../pages/pedido/finalizado";
import PedidoHistorico from "../pages/pedido-historico";
import PedidoRascunhoLista from "../pages/pedido-rascunho";
import PedidoFaturamento from "../pages/pedido-faturamento";
import PedidoFaturamentoComissao from "../pages/pedido-faturamento-comissao";
import PedidoFaturamentoComissaoHistorico from "../pages/pedido-faturamento-comissao-historico";
import Produto from "../pages/produto/index";
import Representada from "../pages/representada/index";
import TabelaPreco from "../pages/tabela-preco/index";
import Chamado from "../pages/chamados";
import Relatorio from "../pages/relatorio";
import Acordo from "../pages/acordo";
import TesteHome from "../pages/teste-home";
import PoliticaPrivacidade from "../pages/politica-privacidade/index";
import TesteHomeIframe from "../pages/teste-home-iframe";
import PedidoFaturamentoHistorico from "../pages/pedido-faturamento-historico";
import MercadoPago from "../pages/mercado-pago";
import Dahsboard from "../pages/dashboard";
import TabelaRede from "../pages/dashboard/components/tabela-rede";
import FaturamentoRelatorio from "../pages/pedido-faturamento-relatorio";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/dashboard" element={<Dahsboard />} />
        <Route exact path="/mercado-pago" element={<MercadoPago />} />
        <Route exact path="/cliente" element={<Cliente />} />
        <Route exact path="/acordo" element={<Acordo />} />
        <Route exact path="/comissao" element={<Comissao />} />
        <Route exact path="/equipe" element={<Equipe />} />
        <Route exact path="/extrato" element={<Extrato />} />
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/politicas-privacidade"
          element={<PoliticaPrivacidade />}
        />
        <Route exact path="/novasenha" element={<NovaSenha />} />
        <Route exact path="/invitation" element={<Invitation />} />
        <Route exact path="/meta" element={<Meta />} />
        <Route exact path="/meusdados" element={<MeusDados />} />
        <Route exact path="/chamados" element={<Chamado />} />
        <Route exact path="/pedido/:id" element={<Pedido />} />
        <Route exact path="/pedido/revisao/:id" element={<PedidoRevisao />} />
        <Route
          exact
          path="/pedido/finalizado/:id"
          element={<PedidoFinalizado />}
        />
        <Route exact path="/pedido/historico" element={<PedidoHistorico />} />
        <Route
          exact
          path="/pedido/faturamento-relatorio"
          element={<FaturamentoRelatorio />}
        />
        <Route exact path="/pedido/avaliacao" element={<PedidoAvaliacao />} />
        <Route
          exact
          path="/pedido/rascunhos"
          element={<PedidoRascunhoLista />}
        />
        <Route
          exact
          path="/pedido/faturamento"
          element={<PedidoFaturamento />}
        />
        <Route
          exact
          path="/pedido-faturamento/comissao"
          element={<PedidoFaturamentoComissao />}
        />

        <Route
          exact
          path="/pedido-faturamento/comissao/historico"
          element={<PedidoFaturamentoComissaoHistorico />}
        />

        <Route
          exact
          path="/pedido-faturamento/historico"
          element={<PedidoFaturamentoHistorico />}
        />
        <Route exact path="/representada" element={<Representada />} />
        <Route exact path="/produto" element={<Produto />} />
        <Route exact path="/tabela-preco" element={<TabelaPreco />} />
        <Route exact path="/relatorio" element={<Relatorio />} />
        <Route exact path="/teste-home" element={<TesteHome />} />
        <Route exact path="/teste-home-iframe" element={<TesteHomeIframe />} />
        <Route exact path="/Tabela" element={<TabelaRede />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
