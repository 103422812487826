import React from "react";
import AppRoutes from './routes/routes';
import Loader from './components/_base/loader/index';
import { LoggedUserProvider } from './contexts/logged-user-context';
import { QueryClient, QueryClientProvider } from "react-query";
import { GlobalProvider } from "./contexts/global-context";

const queryClient = new QueryClient();

function App(props) {
  document.documentElement.lang = "pt-BR";

  return (
    <GlobalProvider>
      <QueryClientProvider client={queryClient}>
        <LoggedUserProvider>
          <Loader />
          <AppRoutes />
        </LoggedUserProvider>
      </QueryClientProvider>
    </GlobalProvider>
  );
}

export default App;