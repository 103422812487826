import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useQuery, useQueryClient } from "react-query";
import lib from "../../helpers/lib";

import TablePagination from "../../components/table-pagination";
import PageTitle from "../../components/page-title/index";
import Layout from "../../components/layout/base/index";
import ModalCancelarPedido from "../../components/modal-cancelar-pedido";
import ModalResumoPedido from "../../components/modal-resumo-pedido";
import DatePickerCustom from "../../components/_base/DatePicker";
import { LoggedUserContext } from "../../contexts/logged-user-context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faFileLines,
  faFilePdf,
  faFileExcel,
  faCancel,
} from "@fortawesome/free-solid-svg-icons";

import PedidoService from "../../services/pedido-service";
import RepresentadaService from "../../services/representada-service";
import RedeService from "../../services/rede-service";
import PedidoStatusService from "../../services/pedido-status-service";

import "./styles.scss";
import AlertPedidosAvaliacao from "../../components/alert-pedidos-avaliacao";

import Dropdown from "react-bootstrap/Dropdown";
import SelectMulti from "../../components/_base/multi-select";

export default function PedidoHistorico() {
  const [userData, _] = React.useContext(LoggedUserContext);

  const queryClient = useQueryClient();

  const [filter, setFilter] = useState({});

  const { data: pageData } = useQuery(
    
    ["pedidos", filter],
    () => getHistoricoPedido(filter),
    { refetchOnWindowFocus: false }
  );

  const [showModalResumo, setShowModalResumo] = useState(false);
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [selected, setSelected] = useState(null);

  const [codigo, setCodigo] = useState("");
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();
  const [representadas, setRepresentadas] = useState([]);
  const [representadaSelecionada, setRepresentadaSelecionada] = useState("");
  const [vendedores, setVendedores] = useState([]);
  const [vendedorSelecionado, setVendedorSelecionado] = useState("");
  const [redes, setRedes] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState("");
  const [lojas, setLojas] = useState([]);
  const [lojaSelecionada, setLojaSelecionada] = useState("");
  const [produtos, setProdutos] = useState([]);
  const [produtosSelecionado, setProdutosSelecionado] = useState([]);
  const [statusSelectList, setStatusSelectList] = useState([]);
  const [status, setStatus] = useState([]);
  const [pedidoTipos, setPedidoTipos] = useState([]);
  const [pedidoTipoSelecionado, setPedidoTipoSelecionado] = useState([]);

  // ------------------------------------------------------------------------------------------
  // Efeitos
  useEffect(() => {
    (async () => {
      window.showLoader();
      await getSelectListRepresentada();
      await getSelectListRede();
      await getSelectListLoja();
      await getSelectListStatus();
      await getSelectListVendedor();
      await getPedidoTipos();
      window.hideLoader();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await queryClient.invalidateQueries(["pedidos"]);
    })();
  }, [filter]);

  // ------------------------------------------------------------------------------------------
  // Funcoes
  const getHistoricoPedido = async (filter) => {
    window.showLoader();
    console.log(filter);
    let response = await PedidoService.GetHistoricoPagedAsync(filter);

    window.hideLoader();
    return response?.data?.data;
  };

  const getPedidoTipos = async () => {
    const response = await PedidoService.GetPedidoTiposAsync();

    setPedidoTipos(response?.data?.data);
  };

  async function getSelectListRepresentada() {
    let response = await RepresentadaService.selectListAsync();
    if (response.data) setRepresentadas(response.data.data); //([...[{}], ...representadas.data.data]);

    if (response.data.data[0].options.length === 1) {
      const representada = response.data.data[0].options[0];

      setRepresentadaSelecionada(representada.value);
      getSelectListProdutos(representada.value);

      setFilter({
        ...filter,
        representadaId: representada.value,
      });
    }
  }

  async function getSelectListStatus() {
    let resultStatus = await PedidoStatusService.getSelectListSiglaAsync();
    if (resultStatus?.data?.data[0]?.options) {
      setStatusSelectList(resultStatus.data.data[0].options);
    } //([...[{}], ...representadas.data.data]);
  }

  async function getSelectListRede() {
    let redes = await RedeService.getSelectList();
    if (redes.data) setRedes(redes.data.data); //([...[{}], ...redes.data.data]);
  }

  async function getSelectListLoja() {
    let lojas = await PedidoService.getSelectListLoja();
    if (lojas.data) setLojas(lojas.data.data); //([...[{}], ...lojas.data.data]);
  }

  async function getSelectListVendedor() {
    let vendedores = await PedidoService.getSelectListVendedor();
    if (vendedores.data) setVendedores(vendedores.data.data); //([...[{}], ...representadas.data.data]);
  }

  async function getSelectListProdutos(representadaId) {
    if (!representadaId && representadaId == null) return;

    window.showLoader();
    var ids = [representadaId];
    let produtosResponse =
      await PedidoService.listProdutosPorRepresentadasAsync(ids);

    window.hideLoader();
    let selectList = [];

    if (produtosResponse.data && produtosResponse.data.data.length > 0) {
      selectList = produtosResponse.data.data.map((x) => ({
        label: x.nome.toString(),
        value: x.produtoId.toString(),
      }));
    }
    setProdutos(selectList);
  }

  async function downloadExtratoExcel() {
    window.showLoader();
    const filter = {
      codigo: codigo,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      representadaId: representadaSelecionada.value,
      representanteId: vendedorSelecionado,
      lojaId: lojaSelecionada,
      listProdutoId: produtosSelecionado?.map((x) => x.value) ?? [],
      redeId: redeSelecionada,
      tipo: pedidoTipoSelecionado?.map((x) => x.value) ?? [],
      status: status?.map((x) => x.value) ?? [],
      pageNumber: 1,
    };
    let result = await PedidoService.getExtratoAsync(filter);

    if (result && result.data) {
      let today = new Date();
      let date =
        today.getFullYear() +
        "_" +
        (today.getMonth() + 1) +
        "_" +
        today.getDate();

      let fileName = `pedido_historico_${lib.getCurrentDateFilename()}.xlsx`;
      lib.downloadFile(result.data.data, fileName);

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  async function downloadExtratoFinanceiroExcel() {
    window.showLoader();
    const filter = {
      codigo: codigo,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      representadaId: representadaSelecionada.value,
      representanteId: vendedorSelecionado,
      lojaId: lojaSelecionada,
      listProdutoId: produtosSelecionado?.map((x) => x.value) ?? [],
      redeId: redeSelecionada,
      tipo: pedidoTipoSelecionado?.map((x) => x.value) ?? [],
      status: status?.map((x) => x.value) ?? [],
      pageNumber: 1,
    };
    let result = await PedidoService.getExtratoFinanceiroAsync(filter);

    if (result && result.data) {
      let today = new Date();
      let date =
        today.getFullYear() +
        "_" +
        (today.getMonth() + 1) +
        "_" +
        today.getDate();

      let fileName = `extrato_financeiro_${lib.getCurrentDateFilename()}.xlsx`;
      lib.downloadFile(result.data.data, fileName);

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  // ------------------------------------------------------------------------------------------
  // Renderizacao
  return (
    <Layout>
      <PageTitle title="Histórico de Pedidos" />

      <AlertPedidosAvaliacao />

      {/* Filter ------------------------------------------------------------------------------ */}
      <div className="row ">
        {/* Codigo  --------------------------------------------------------------------------- */}
        <div className="col-xl-3 mb-3">
          <label>Pedido</label>
          <input
            type="text"
            className="form-control"
            value={codigo}
            onChange={(ev) => {
              setCodigo(ev.target.value);
            }}
          />
        </div>

        {/* Vendedor ---------------------------------------------------------------------- */}
        <div className="col-xl-3 mb-3">
          <label>Vendedor</label>
          <Select
            isClearable
            clearValue={() => {
              setVendedorSelecionado({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={vendedores}
            value={vendedores?.find((x) => x.value === vendedorSelecionado)}
            onChange={(selectedValue) => {
              setVendedorSelecionado(selectedValue?.value);
            }}
          />
        </div>

        {/* Tipo ---------------------------------------------------------------------- */}
        <div className="col-xl-3 mb-3" disabled>
          <label>Tipo</label>
          <SelectMulti
            isClearable
            clearValue={() => {
              setPedidoTipoSelecionado([]);
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={pedidoTipos}
            value={pedidoTipoSelecionado}
            onChange={(options) => {
              setPedidoTipoSelecionado(options);
            }}
          />
        </div>

        {/* Representada ---------------------------------------------------------------------- */}
        <div className="col-xl-3 mb-3" disabled>
          <label>Representada</label>
          <Select
            isClearable
            clearValue={() => {
              setRepresentadaSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={representadas}
            value={representadas?.find(
              (x) => x.value === representadaSelecionada
            )}
            onChange={(selectedValue) => {
              //setRedeSelecionada(selectedValue?.nome ?? {});
              setRepresentadaSelecionada(selectedValue?.value);
              getSelectListProdutos(selectedValue?.value);
            }}
          />
        </div>
      </div>

      <div className="row ">
        {/* Produtos ------------------------------------------------------------------------------ */}
        <div className="col-xl-6 mb-6" disabled>
          <label>Produtos Por Representada</label>
          &nbsp;&nbsp;
          <span style={{ fontSize: "8px" }}>
            *Selecione uma representada para carregar os produtos
          </span>
          <SelectMulti
            isClearable
            clearValue={() => {
              setProdutosSelecionado([]);
            }}
            placeholder="Produtos da Loja"
            styles={customControlStyles}
            options={produtos}
            value={produtosSelecionado}
            onChange={(options) => {
              setProdutosSelecionado(options);
            }}
          />
        </div>

        {/* Rede ------------------------------------------------------------------------------ */}
        <div className="col-xl-3 mb-3" disabled>
          <label>Rede</label>
          <Select
            isClearable
            clearValue={() => {
              setRedeSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={redes}
            value={redes?.find((x) => x.value === redeSelecionada)}
            onChange={(selectedValue) =>
              setRedeSelecionada(selectedValue?.value)
            }
          />
        </div>

        {/* Loja ------------------------------------------------------------------------------ */}
        <div className="col-xl-3 mb-3">
          <label>Loja</label>
          <Select
            isClearable
            clearValue={() => {
              setLojaSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={lojas}
            value={lojas?.find((x) => x.value === lojaSelecionada)}
            onChange={(selectedValue) => {
              setLojaSelecionada(selectedValue?.value);
            }}
          />
        </div>
      </div>

      {/* Filter date-------------------------------------------------------------------------- */}
      <div className="row ">
        <div className="col-xl-3 mb-3">
          <label>Data Inicial </label>
          <DatePickerCustom
            name="dataInicial"
            selected={dataInicial}
            onChange={(value) => {
              setDataInicial(value);
            }}
          />
        </div>
        <div className="col-xl-3 mb-3">
          <label>Data Final</label>
          <DatePickerCustom
            name="dataFinal"
            selected={dataFinal}
            onChange={(value) => {
              setDataFinal(value);
            }}
          />
        </div>

        <div className="col-xl-3 mb-3" disabled>
          <label>Status</label>
          <SelectMulti
            isClearable
            clearValue={() => {
              setStatus([]);
            }}
            placeholder="Selecione..."
            options={statusSelectList}
            value={status}
            onChange={(options) => {
              setStatus(options);
            }}
          />
        </div>

        <div className="col-xl-3 mb-3 d-flex filter-bar2">
          {/* Botão Filtrar --------------------------------------------------------------------- */}
          <button
            type="button"
            className="btn btn-secondary btn-sm btn-action me-2"
            onClick={() => {
              setFilter({
                ...filter,
                codigo: codigo,
                dataInicio: dataInicial,
                dataFim: dataFinal,
                representadaId: representadaSelecionada.value,
                representanteId: vendedorSelecionado,
                lojaId: lojaSelecionada,
                listProdutoId: produtosSelecionado?.map((x) => x.value) ?? [],
                redeId: redeSelecionada,
                tipo: pedidoTipoSelecionado?.map((x) => x.value) ?? [],
                status: status?.map((x) => x.value) ?? [],
                pageNumber: 1,
              });
            }}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>

          {/* Botão Exportar -------------------------------------------------------------------- */}
          <Dropdown style={{ width: "155px" }}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              Exportar
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href="#/extrato-completo"
                onClick={() => {
                  downloadExtratoExcel();
                }}
              >
                Exportar Pedido e Faturamento
              </Dropdown.Item>
              <Dropdown.Item
                href="#/extrato-financeiro"
                onClick={() => {
                  downloadExtratoFinanceiroExcel();
                }}
              >
                Exportar Faturamento
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* Filter date-------------------------------------------------------------------------- */}
      <div
        className="row d-flex flex-row-reverse"
        style={{ marginRight: "30px" }}
      ></div>

      {/* Lista Representadas ----------------------------------------------------------------- */}
      <div className="table-responsive">
        <table className="table table-cadastro">
          <thead>
            <tr>
              <th>Pedido</th>
              <th>Vendedor</th>
              <th>Rede</th>
              <th>Loja</th>
              <th>Representada</th>
              <th>Data</th>
              <th>Status</th>
              <th>Tipo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {(pageData?.data || []).map((item, index) => (
              <tr key={index}>
                <td>
                  {item.codigoPedidoLoja
                    ? item.codigoPedidoLoja
                    : item.codigoPedidoRepresentada}
                </td>
                <td>{item.vendedor}</td>
                <td>{item.redeNome}</td>
                <td>{item.lojaNomeFantasia ?? item.lojaRazaoSocial}</td>
                <td>{item.representadaNomeFantasia}</td>
                <td>{lib.getStringFromDateMinute(item.data)}</td>
                <td>{item.status}</td>
                <td>{item.pedidoTipo}</td>
                <td className="action-btns">
                  {/* Botao Resumo -------------------------------------------------------------- */}
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    title="Resumo"
                    onClick={() => {
                      setSelected(item);
                      setShowModalResumo(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faFileLines} />
                  </button>

                  {/* Botao Pdf ----------------------------------------------------------------- */}
                  <a href={item.urlArquivoPedido} target="_blank">
                    <button
                      type="button"
                      disabled={item.urlArquivoPedido ? false : true}
                      className="btn btn-secondary btn-sm"
                      title="Baixar PDF"
                      onClick={() => {}}
                    >
                      <FontAwesomeIcon icon={faFilePdf} />
                    </button>
                  </a>

                  {/* Botao Cancelar ----------------------------------------------------------------- */}
                  {userData?.cancelaPedido &&
                    (item.status == "Não Faturado" ||
                      item.status == "Em Avaliação") && (
                      <button
                        type="button"
                        //disabled={item.urlArquivoPedido ? false : true}
                        className="btn btn-danger btn-sm"
                        title="Cancelar pedido"
                        onClick={() => {
                          setSelected(item);
                          setShowModalCancelar(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faCancel} />
                      </button>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Paginacao --------------------------------------------------------------------------- */}
      <TablePagination
        pageData={pageData}
        onChangePage={(pageNumber) => {
          setFilter({ ...filter, pageNumber: pageNumber });
        }}
      />

      {/* Modal Resumo Pedido ----------------------------------------------------------------- */}
      <ModalResumoPedido
        model={selected}
        showModal={showModalResumo}
        setShowModal={setShowModalResumo}
      />

      {/* Modal Cancelar Pedido ----------------------------------------------------------------- */}
      <ModalCancelarPedido
        model={selected}
        showModal={showModalCancelar}
        setShowModal={setShowModalCancelar}
        onClose={getHistoricoPedido}
      />
    </Layout>
  );
}
