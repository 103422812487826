import React from "react";
import './styles.scss';

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="footer text-end">
      <span>&copy; {year} - Pratico Vendas</span>
    </footer>
  );
}
