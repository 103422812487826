import React, { useRef } from "react";
import Select from "react-select";
import CurrencyInput, { propTypes } from "react-currency-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSave } from '@fortawesome/free-solid-svg-icons';

import Accordion from "../../components/_base/custom-accordion/_accordion";
import SelectMulti from "../../components/_base/multi-select";
import MultiSelectVirtual from "../_base/multi-select-virtual";
import FormGroupHeader from "../../components/_base/form-group-header";

import MetaValor from "./meta-valor";
import MetaVolume from "./meta-volume";

import "./styles.scss";

import lib from "../../helpers/lib";

export default function MetaCadastro({
  getMetaModelAsync,
  getMetaVolumeModelAsync,

  saveMetaModelAsync,
  saveMetaVolumeModelAsync,

  removeMetasAsync,
  removeMetasVolumeAsync,

  representadas,
  modal
}) {

  const data = new Date();

  const refMetaValor = useRef();
  const refMetaVolume = useRef();

  const [representadaId, setRepresentadaId] = React.useState("");
  const [ano, setAno] = React.useState(data.getFullYear());
  const [mes, setMes] = React.useState(data.getMonth() + 1);

  async function saveAsync() {
    refMetaValor.current.saveAsync();
    refMetaVolume.current.saveAsync();
  }

  async function removeAsync() {
    refMetaValor.current.removeAsync();
    refMetaVolume.current.removeAsync();
  }

  return (
    <>

      <div className={modal ? "modal-body" : ""}>
        <div className="meta-container">

          <label>Representada</label>
          <Select
            className="select-form-control mb-3"
            isClearable={true}
            placeholder="Selecione uma representada"
            clearValue={() => { setRepresentadaId(null) }}
            options={representadas}
            value={representadas?.find((x) => x.value === representadaId)}
            onChange={(value) => { setRepresentadaId(value?.value); }}
          />

          {
            !representadaId?.length
              ? null
              : (
                <>
                  <div className="row mb-3">
                    <div className="col-lg-2">
                      <label>Mês</label>
                      <Select
                        className="select-form-control"
                        value={lib.selectListMeses?.find((item) => item.value === mes)}
                        options={lib.selectListMeses}
                        isClearable={false}
                        onChange={(item) => { setMes(item.value); }}
                      />
                    </div>

                    <div className="col-lg-2">
                      <label>Ano</label>
                      <Select
                        className="select-form-control"
                        value={lib.selectListAnos?.find((item) => item.value === ano)}
                        options={lib.selectListAnos}
                        isClearable={false}
                        onChange={(item) => { setAno(item.value); }}
                      />
                    </div>

                    <div className="col-lg-2 d-flex align-items-end mt-3">
                      <button type="button" className="btn btn-danger" onClick={() => { removeAsync() }}>Excluir Metas</button>
                    </div>
                  </div>

                  <ul className="nav nav-tabs nav-fill" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#meta-valor" type="button" role="tab">Meta por Valor</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" data-bs-toggle="tab" data-bs-target="#meta-volume" type="button" role="tab">Meta por Volume</button>
                    </li>
                  </ul>

                  <div className="tab-content p-2 bg-lightgray">
                    <div className="tab-pane fade show active bg-white p-1" id="meta-valor" role="tabpanel" >
                      {
                        <MetaValor ref={refMetaValor}
                          representadaId={representadaId}
                          ano={ano}
                          mes={mes}
                          getMetaModelAsync={getMetaModelAsync}
                          saveMetaModelAsync={saveMetaModelAsync}
                          removeMetasAsync={removeMetasAsync}
                        />
                      }
                    </div>

                    <div className="tab-pane fade bg-white p-1" id="meta-volume" role="tabpanel" >
                      {
                        <MetaVolume ref={refMetaVolume}
                          representadaId={representadaId}
                          ano={ano}
                          mes={mes}
                          getMetaModelAsync={getMetaVolumeModelAsync}
                          saveMetaModelAsync={saveMetaVolumeModelAsync}
                          removeMetasAsync={removeMetasVolumeAsync}
                        />
                      }
                    </div>
                  </div>

                  {
                    !modal
                      ? (
                        <>
                          <hr />

                          <button type="button" className="btn btn-primary" onClick={saveAsync}>
                            <FontAwesomeIcon icon={faSave} className="me-2" />Salvar
                          </button>
                        </>
                      )
                      : null
                  }
                </>
              )
          }

        </div>
      </div>

      {
        modal
          ? (
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={saveAsync}>SALVAR</button>
            </div>
          )
          : null
      }

    </>
  )
}