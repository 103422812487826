import React, { useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import lib from "../../helpers/lib";

import TablePagination from "../../components/table-pagination";
import PageTitle from "../../components/page-title/index";
import Layout from "../../components/layout/base/index";
import DatePickerCustom from "../../components/_base/DatePicker";
import FormGroupHeader from "../../components/_base/form-group-header";
import Errors from "../../components/_base/errors";

import NumberFormat from "react-number-format";
import InputImagePreview from "../../components/_base/input-image-preview";
import InputCnpj from "../../components/_base/input-cnpj";
import InputTelefone from "../../components/_base/input-telefone";
import MeusDadosService from "../../services/meus-dados-service";
import InputDocumento from "../../components/_base/input-documento";
import ModalMessage from "../../components/modal-message";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFilter,
  faPlus,
  faRecycle,
  faTrash,
  faFileLines,
  faFilePdf,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";

import RedeService from "../../services/rede-service";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

export default function MeusDados() {
  const [errors, setErrors] = React.useState({});
  const [meusDados, setMeusDados] = React.useState({
    documento: "",
    nome: "",
    telefone: "",
    email: "",
    emailOld: "",
    file: null,
    urlFoto: "",
  });
  const [passwdModel, setPasswdModel] = React.useState({
    usuarioId: "",
    senhaAtual: "",
    novaSenha: "",
    confirmacaoSenha: "",
  });

  const [showModal, setShowModal] = React.useState(false);

  // ------------------------------------------------------------------------------------------
  // Efeitos
  useEffect(() => {
    (async () => {
      window.showLoader();
      await getMeusDados();
      window.hideLoader();
    })();
  }, []);

  // ------------------------------------------------------------------------------------------
  // Funcoes
  async function getMeusDados() {
    let response = await MeusDadosService.getAsync();
    if (response?.data) {
      setMeusDados({
        documento: response.data.data.documento,
        nome: response.data.data.nome,
        telefone: response.data.data.telefone,
        email: response.data.data.email,
        emailOld: response.data.data.email,
        urlFoto: response.data.data.urlFoto,
      });
    }
  }

  function updateMeusDados(data) {
    setMeusDados({ ...meusDados, ...data });
  }

  function updatePasswdModel(data) {
    setPasswdModel({ ...passwdModel, ...data });
  }

  async function saveAsync() {
    window.showLoader();

    setErrors({});

    var response = await MeusDadosService.updateAsync(meusDados);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseMeusDados = response?.data?.data;

      if (responseMeusDados != null) {
        setMeusDados({ ...responseMeusDados });
        // Mostra o modal com aviso de sucesso
        setShowModal(true);
      }

      return;
    }

    setErrors(response?.data?.errors);
    window.hideLoader();
  }

  async function changePasswordAsync() {
    window.showLoader();

    setErrors({});

    var response = await MeusDadosService.changePasswordAsync(passwdModel);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responsePassword = response?.data?.data;

      if (responsePassword != null) {
        // Limpa o model
        setPasswdModel({
          usuarioId: "",
          senhaAtual: "",
          novaSenha: "",
          confirmacaoSenha: "",
        });

        if (
          response?.data?.errors &&
          response?.data?.errors?.passwd?.length > 0
        ) {
          setErrors(response?.data?.errors);
          window.hideLoader();
        } else {
          // Mostra o modal com aviso de sucesso
          setShowModal(true);
        }
      }

      window.hideLoader();
      return;
    }
  }

  return (
    <Layout>
      <PageTitle title="Meus Dados"></PageTitle>

      <div className="form-meus-dados">
        <form>
          <FormGroupHeader text="DADOS PESSOAIS" />
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-2">
              {/* Foto ---------------------------------------------------------- */}
              <label className="form-label">Sua Foto</label>
              <InputImagePreview
                selectedFile={meusDados.file}
                setSelectedFile={(file) => {
                  updateMeusDados({ file: file });
                }}
                urlImage={meusDados.urlFoto}
                text="Clique para adicionar uma Foto"
              />

              {/* Botao Salvar Foto --------------------------------------------- */}
              {/* <button type="button" className="btn btn-primary" onClick={() => { }}>SALVAR FOTO</button> */}
            </div>
            <div className="col-md-6 col-lg-8 mb-2">
              {/* Documento ----------------------------------------------------- */}
              <label className="form-label">CNPJ ou CPF</label>
              <InputDocumento
                className="form-control"
                value={meusDados.documento}
                onChange={(input) => {
                  updateMeusDados({ documento: input });
                }}
              />
              <Errors errors={errors} />

              {/* Nome ---------------------------------------------------------- */}
              <label className="form-label">Nome</label>
              <input
                type={"text"}
                className="form-control"
                value={meusDados.nome}
                onChange={(e) => {
                  updateMeusDados({ nome: e.target.value });
                }}
              />
              <Errors errors={errors} />

              {/* Telefone ----------------------------------------------------- */}
              <label className="form-label">Telefone</label>
              <InputTelefone
                className="form-control"
                value={meusDados.telefone}
                onChange={(value) => {
                  updateMeusDados({ telefone: value });
                }}
              />
              <Errors errors={errors} />

              {/* Email ----------------------------------------------------- */}
              <label className="form-label">Email</label>
              <input
                type={"text"}
                className="form-control"
                value={meusDados.email}
                onChange={(e) => {
                  updateMeusDados({ email: e.target.value });
                }}
              />
              <Errors errors={errors} keyName="Email" />
            </div>
            <div className="col-12">
              {/* Botos Salvar Dados -------------------------------------------- */}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  saveAsync();
                }}
              >
                SALVAR ALTERAÇÕES
              </button>
            </div>
          </div>
        </form>

        <form>
          <FormGroupHeader text="ALTERAR A SENHA" />
          <div className="row">
            <div className="col-lg-12 mb-2">
              {/* Senha Atual --------------------------------------------------- */}
              <label className="form-label">Senha Original</label>
              <input
                type={"password"}
                className="form-control"
                value={passwdModel.senhaAtual}
                onChange={(e) => {
                  updatePasswdModel({ senhaAtual: e.target.value });
                }}
              />
              {/* <Errors errors={errors} /> */}

              {/* Nova Senha ---------------------------------------------------- */}
              <label className="form-label">Nova Senha</label>
              <input
                type={"password"}
                className="form-control"
                value={passwdModel.novaSenha}
                onChange={(e) => {
                  updatePasswdModel({ novaSenha: e.target.value });
                }}
              />
              {/* <Errors errors={errors} /> */}

              {/* Repetir Nova Senha -------------------------------------------- */}
              <label className="form-label">Repita a Nova Senha</label>
              <input
                type={"password"}
                className="form-control"
                value={passwdModel.confirmacaoSenha}
                onChange={(e) => {
                  updatePasswdModel({ confirmacaoSenha: e.target.value });
                }}
              />
              <Errors errors={errors} keyName="passwd" />

              {/* Botao salvar senha -------------------------------------------- */}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  changePasswordAsync();
                }}
              >
                SALVAR ALTERAÇÕES
              </button>
            </div>
          </div>
        </form>
      </div>

      <ModalMessage
        title="Atenção!"
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={() => {}}
      >
        <p>Seus dados foram salvos com sucesso.</p>
      </ModalMessage>
    </Layout>
  );
}
