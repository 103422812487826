import React from 'react';
import Select from "react-select";

export default function SelectTabelaPreco({representada}) {

  const [selectListTabelaPrecos, setSelectListTabelaPrecos] = React.useState([])
  const [tabelaPrecoId, setTabelaPrecoId] = React.useState(null)

  React.useEffect(() => {
    let selectListTabelaPrecos = (representada || []).tabelaPrecos.map((tabelaPreco) => ({
      label: tabelaPreco.nome,
      value: tabelaPreco.tabelaPrecoId,
    }));

    setSelectListTabelaPrecos(selectListTabelaPrecos)
    setTabelaPrecoId(representada.tabelaPrecoId);
  }, [representada])

  return (
     <Select
      styles={customControlStyles}
      placeholder="Selecione"
      classNamePrefix="select-cadastro"
      //className={`${lib.isNullOrEmptyObject(errors[formFields.tabelaPrecoId.key]) ? "" : "is-invalid" }`}
      options={selectListTabelaPrecos}
      value={
        (selectListTabelaPrecos || []).length > 0 
          ? selectListTabelaPrecos.find((x) => parseInt(x.value) === tabelaPrecoId) || null
          : null
      }
      onChange={(selectedValue) => {
        representada.tabelaPrecoId = parseInt(selectedValue.value);
        setTabelaPrecoId(representada.tabelaPrecoId)
      }}
    //placeholder={"Selecione uma Tabela de Preços"}
    />
  )
}