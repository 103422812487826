import React, { useState, useEffect } from "react";
import Select from "react-select";
import NumberFormat from "react-number-format";
import CurrencyInput from "../_base/react-currecy-input-custom/react-currency-input.cjs";
import ModalCadastro from "../_base/modal-cadastro";
import lib from "../../helpers/lib";
import "./styles.scss";
export default function ModalPedidoEditaProduto(props) {
  const {
    showModal,
    setShowModal,
    produto,
    addProdutoMobile,
    unidades,
    onClose,
    calcularPorcentagemDesconto,
    calcularPorcentagem,
    percentualDescontoTotal,
  } = props;

  const [newproduto, setNewProduto] = useState({});

  function onCloseModal() {
    onClose();
  }

  useEffect(() => {
    setNewProduto(produto);
  }, [produto]);

  function onChangeProdutoAdicionado(name, value) {
    let prod = { ...newproduto, [name]: value };

    if (prod.quantidade && prod.valor) {
      const desconto = calcularPorcentagemDesconto(
        prod.valorReferencia,
        prod.valor
      );
      let descontoValor = 0.0;
      if (desconto > 0) {
        descontoValor = prod.valorReferencia - prod.valor;
      }
      let valorTotalDescontoAdicional = 0.0;
      let valorDescontoAdicional = 0.0;
      if (percentualDescontoTotal > 0) {
        valorDescontoAdicional = calcularPorcentagem(
          percentualDescontoTotal,
          prod.valor
        );
        valorTotalDescontoAdicional = valorDescontoAdicional * prod.quantidade;
      }

      prod = {
        ...prod,
        total: prod.quantidade * prod.valor,
        referenciaTotal: prod.quantidade * prod.valorReferencia,
        porcentagemDesconto: desconto,
        valorDesconto: descontoValor,
        valorDescontoAdicional: valorDescontoAdicional,
        totalDescontoAdicional: valorTotalDescontoAdicional,
      };
    } else {
      prod = {
        ...prod,
        total: 0.0,
        referenciaTotal: 0.0,
        porcentagemDesconto: 0.0,
        valorDesconto: 0.0,
      };
    }

    setNewProduto(prod);
  }

  return (
    <ModalCadastro
      showModal={showModal}
      onClose={onCloseModal}
      setShowModal={setShowModal}
      headerText={`${newproduto?.nome || ""}`}
      size={"lg"}
    >
      {newproduto && (
        <div className="modal-body modal-pedido-edita-produto">
          <div className="row align-items-center">
            <div className="col-sm-2 imagem-produto">
              <img src={newproduto.urlImagem}></img>
            </div>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-sm-2">
                  <label>Codigo:</label>
                </div>
                <div className="col-sm-9">
                  {newproduto.codigoNaRepresentada}
                </div>
              </div>

              <div className="row linha-espaco">
                <div className="col-sm-2">
                  <label>Produto:</label>
                </div>
                <div className="col-sm-9">{newproduto.nome}</div>
              </div>
            </div>
          </div>

          <div className="row linha-espaco">
            <div className="col-12">
              <label>Unid. Medida</label>
              <Select
                styles={customControlStyles}
                options={newproduto.unidades}
                isClearable={false}
                placeholder=""
                onChange={(option) => {
                  newproduto.unidadeMedidaId =
                    option == null ? null : parseInt(option.value);
                  newproduto.unidadeMedidaNome =
                    option == null ? null : option.label;

                  setNewProduto({ ...newproduto });
                }}
                value={
                  unidades.length > 0 &&
                  unidades[0].options &&
                  unidades[0].options.filter((u) => {
                    return parseInt(u.value) === newproduto.unidadeMedidaId;
                  })[0]
                }
              />
            </div>
          </div>
          <div className="row linha-espaco">
            <div className="col-12">
              <label>Qtd.</label>
              <NumberFormat
                id="input-quantidade"
                className="form-control"
                displayType={"input"}
                thousandSeparator={false}
                decimalSeparator={","}
                onValueChange={(values, sourceInfo) => {
                  if (values.floatValue && values.floatValue < 0)
                    values.floatValue = 0;
                  onChangeProdutoAdicionado("quantidade", values.floatValue);
                }}
                value={newproduto.quantidade}
              />
              {/* <CurrencyInput
                className="form-control"
                decimalSeparator=","
                thousandSeparator="."
                prefix=""
                selectAllOnFocus={true}
                value={produto.quantidade}
                onChangeEvent={(event, maskedvalue, floatvalue) =>
                  onChangeProdutoAdicionado(
                    produto.produtoId,
                    representadaId,
                    "quantidade",
                    floatvalue
                  )
                }
              /> */}
            </div>
          </div>
          <div className="row linha-espaco">
            <div className="col-12">
              <label>
                Valor{" "}
                {newproduto.unidadeMedidaNome
                  ? "por " + newproduto.unidadeMedidaNome
                  : ""}
              </label>
              <CurrencyInput
                id="input-valor"
                className="form-control"
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
                selectAllOnFocus={true}
                value={newproduto.valor}
                onChangeEvent={(event, maskedvalue, floatvalue) => {
                  if (floatvalue && floatvalue < 0) floatvalue = 0;
                  onChangeProdutoAdicionado("valor", floatvalue);
                }}
              />
              {/* <CurrencyInput1
                className="form-control"
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
                selectAllOnFocus={true}
                value={produto.valor}
                onChangeEvent={(event, maskedvalue, floatvalue) =>
                  onChangeProdutoAdicionado(
                    produto.produtoId,
                    representadaId,
                    "valor",
                    floatvalue
                  )
                }
              /> */}
              {(newproduto?.precoMaximo > 0 || newproduto?.precoMinimo > 0) && (
                <label>
                  Tabela:{" "}
                  {newproduto?.precoMinimo && newproduto?.precoMinimo > 0
                    ? lib.formatToCurrencyText(newproduto.precoMinimo)
                    : "R$ 0"}
                  {newproduto?.precoMaximo && newproduto?.precoMaximo > 0
                    ? " - " + lib.formatToCurrencyText(newproduto.precoMaximo)
                    : ""}
                </label>
              )}
            </div>
          </div>
          <div className="row linha-espaco">
            <div className="col-12">
              <label>Total</label>
              <NumberFormat
                className="form-control"
                value={newproduto.total}
                displayType={"input"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"R$ "}
                decimalScale={2}
                fixedDecimalScale={true}
                readOnly={true}
              />
              {newproduto?.porcentagemDesconto > 0 && (
                <label>Desconto: {newproduto?.porcentagemDesconto}%</label>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="modal-footer">
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => addProdutoMobile(newproduto)}
            >
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </ModalCadastro>
  );
}
