import React, { useRef, useState, forwardRef, useImperativeHandle } from "react";
import Select from "react-select";
import CurrencyInput, { propTypes } from "react-currency-input";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSave } from '@fortawesome/free-solid-svg-icons';

import Accordion from "../_base/custom-accordion/_accordion";
import SelectMulti from "../_base/multi-select";
import MultiSelectVirtual from "../_base/multi-select-virtual";
import FormGroupHeader from "../_base/form-group-header";
import InputVolume from "./_input-volume";

import "./styles.scss";

import lib from "../../helpers/lib";

const MetaValor = forwardRef(({
  representadaId,
  ano,
  mes,

  getMetaModelAsync,
  saveMetaModelAsync,
  removeMetasAsync
}, ref) => {

  const [model, setModel] = React.useState({});

  const [selectListRedes, setSelectListRedes] = React.useState([]);
  const [selectedRedes, setSelectedRedes] = React.useState([]);
  const [selectListLojas, setSelectListLojas] = React.useState([]);
  const [selectedLojas, setSelectedLojas] = React.useState([]);

  const [selectListCategorias, setSelectListCategorias] = React.useState([]);
  const [selectedCategorias, setSelectedCategorias] = React.useState([]);
  const [selectListProdutos, setSelectListProdutos] = React.useState([]);
  const [selectedProdutos, setSelectedProdutos] = React.useState([]);

  const [errors, setErrors] = React.useState();
  const [success, setSuccess] = React.useState(false);

  const unidadeVolume = "KG";

  React.useEffect(() => {
    clear();
    getModel();
  }, [representadaId, ano, mes]);

  useImperativeHandle(ref, () => ({
    async saveAsync() {
      clear();

      window.showLoader();
      let response = await saveMetaModelAsync(model);
      window.hideLoader();

      setModel(response.data.data);

      if (response.status === lib.statusCode.ok) {
        setSuccess(true);
      }
      else {
        setErrors(response.data.errors)
      }
    },

    async removeAsync() {
      clear();

      window.showLoader();
      let response = await removeMetasAsync(model);

      if (response.status === lib.statusCode.ok) {
        setSuccess(true);
        await getModel();
      }
      else {
        setErrors(response.data.errors)
      }

      window.hideLoader();
    }
  }));

  function updateModel(m) {
    setModel(m);
  }

  async function getModel() {
    updateModel({});

    if (!representadaId)
      return;

    window.showLoader();
    let result = await getMetaModelAsync({ representadaId, ano, mes });
    window.hideLoader();

    if (result.data?.data) {
      let model = result.data.data;

      updateModel(model);
      setLists(model);
    }
  }

  function setLists(model) {
    if (!model.representadaId?.length)
      return;

    let selectListRedes = (model.redes || []).map(r => ({
      label: r.nome,
      value: r.redeId,
      lojaIds: r.lojas.map(l => l.lojaId)
    })).sort((a, b) => ('' + a.label).localeCompare(b.label))

    setSelectListRedes(selectListRedes);

    let selectListLojas = [].concat(
      ...(model.redes || []).map(r =>
        r.lojas.map(l => ({
          label: l.nomeFantasia,
          value: l.lojaId,
          redeId: r.redeId,
        }))
      )
    ).sort((a, b) => ('' + a.label).localeCompare(b.label));

    setSelectListLojas(selectListLojas);

    let selectListCategorias = (model.produtoCategorias || []).map(c => ({
      label: c.nome,
      value: c.produtoCategoriaId,
      produtoIds: c.produtos.map(p => p.produtoId)
    })).sort((a, b) => ('' + a.label).localeCompare(b.label))

    setSelectListCategorias(selectListCategorias);

    let selectListProdutos = [].concat(
      ...(model.produtoCategorias || []).map(c =>
        c.produtos.map(p => ({
          label: p.nome,
          value: p.produtoId,
          produtoCategoriaId: c.produtoCategoriaId,
        }))
      )
    ).sort((a, b) => ('' + a.label).localeCompare(b.label));

    setSelectListProdutos(selectListProdutos);
  }

  function clear() {
    setErrors({});
    setSuccess(false);
  }

  let somaRedes = (model?.redes || []).reduce((a, b) => a + (b.metaValor || 0), 0);
  let somaLojas = (model?.redes || []).reduce((a, b) => a + (b.lojas.reduce((la, lb) => la + (lb.metaValor || 0), 0)), 0);

  let somaCategorias = (model?.produtoCategorias || []).reduce((a, b) => a + (b.metaValor || 0), 0);
  let somaProdutos = (model?.produtoCategorias || []).reduce((a, b) => a + (b.produtos.reduce((pa, pb) => pa + (pb.metaValor || 0), 0)), 0);

  let filteredSelectListLojas = !selectedRedes.length
    ? selectListLojas
    : selectListLojas.filter(l => selectedRedes.findIndex(r => r.value === l.redeId) > -1);

  let filteredSelectListProdutos = !selectedCategorias.length
    ? selectListProdutos
    : selectListProdutos.filter(p => selectedCategorias.findIndex(c => c.value === p.produtoCategoriaId) > -1);

  if (!model.representadaId?.length)
    return null;

  return (
    <>
      <div>
        {
          lib.isNullOrEmptyObject(errors)
            ? null
            : (
              <div className="alert alert-danger">
                <ul className="m-0">
                  {
                    Object.values(errors).map(messages => messages.map(message => <li>{message}</li>))
                  }
                </ul>
              </div>
            )
        }

        {
          !success
            ? null
            : (
              <div className="alert alert-success">
                Dados atualizados com sucesso.
              </div>
            )
        }
      </div>

      <label>Meta Volume</label>
      <InputVolume
        initialValue={model.metaValor || 0}
        onChange={(value) => { model.metaValor = value; }}
        unidadeVolume={unidadeVolume}
      />

      <div className="row">
        <div className="col-lg-6">
          <FormGroupHeader text={`POR CLIENTE`}>
            <label className="ms-2">(
              <span className={`${model.metaValor > 0 && somaRedes > model.metaValor ? "text-danger" : null}`}>Redes: {somaRedes} {unidadeVolume}</span>
              <span className="mx-2">|</span>
              <span className={`${model.metaValor > 0 && somaLojas > model.metaValor ? "text-danger" : null}`}>Lojas: {somaLojas} {unidadeVolume}</span>
              )
            </label>
          </FormGroupHeader>

          <div className="row m-0">
            <div className="col-lg-4 p-1">
              <label><FontAwesomeIcon icon={faFilter} /> Rede</label>
              <MultiSelectVirtual
                list={selectListRedes}
                keyName="label"
                onChange={(items) => {
                  setSelectedRedes(items);
                }}
              />

            </div>
            <div className="col-lg-4 p-1 pe-0">
              <label><FontAwesomeIcon icon={faFilter} /> Loja</label>
              <MultiSelectVirtual
                list={filteredSelectListLojas}
                keyName="label"
                onChange={(items) => {
                  setSelectedLojas(items);
                }}
              />
            </div>
          </div>

          <div class="max-h-600px overflow-y-auto">
            {
              model.redes?.map((rede, index) => {
                if (selectedRedes.length > 0 && selectedRedes.findIndex(x => x.value === rede.redeId) === -1)
                  return null;

                if (selectedLojas.length > 0 && selectedLojas.findIndex(x => x.redeId === rede.redeId) === -1)
                  return null;

                return (
                  <div key={rede.redeId} className="row m-0 mb-1 border-top-bold">
                    <div className="col-lg-4 p-1">{rede.nome}</div>
                    <div className="col-lg-8 p-1 pb-0">
                      <div className="row m-0">
                        <div className="col-lg-6 p-0">
                          <InputVolume
                            className={`${rede.invalidValor ? "is-invalid" : ""}`}
                            initialValue={rede.metaValor || 0}
                            onChange={(value) => { rede.metaValor = value; }}
                            unidadeVolume={unidadeVolume}
                          />
                        </div>
                      </div>

                      <Accordion className="border-top mt-1" header="Lojas" show={selectedLojas.length > 0}>
                        {
                          rede.lojas.map((loja) => {
                            if (selectedLojas.length > 0 && selectedLojas.findIndex(x => x.value === loja.lojaId) === -1)
                              return null;

                            return (
                              <div key={loja.lojaId} className="row m-0 border-top">
                                <div className="col-lg-6 p-1">{loja.nomeFantasia}</div>
                                <div className="col-lg-6 p-1">
                                  <InputVolume
                                    className={`${loja.invalidValor ? "is-invalid" : ""}`}
                                    initialValue={loja.metaValor || 0}
                                    onChange={(value) => { loja.metaValor = value; }}
                                    unidadeVolume={unidadeVolume}
                                  />
                                </div>
                              </div>
                            )
                          })
                        }
                      </Accordion>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div className="col-lg-6">
          <FormGroupHeader text={`POR PRODUTO`}>
            <label className="ms-2">(
              <span className={`${model.metaValor > 0 && somaCategorias > model.metaValor ? "text-danger" : null}`}>Categorias: {somaCategorias} {unidadeVolume}</span>
              <span className="mx-2">|</span>
              <span className={`${model.metaValor > 0 && somaProdutos > model.metaValor ? "text-danger" : null}`}>Produtos: {somaProdutos} {unidadeVolume}</span>
              )
            </label>
          </FormGroupHeader>

          <div className="row m-0">
            <div className="col-lg-4 p-1">
              <label><FontAwesomeIcon icon={faFilter} /> Categoria</label>
              <MultiSelectVirtual
                list={selectListCategorias}
                keyName="label"
                onChange={setSelectedCategorias}
              />

            </div>
            <div className="col-lg-4 p-1 pe-0">
              <label><FontAwesomeIcon icon={faFilter} /> Produto</label>
              <MultiSelectVirtual
                list={filteredSelectListProdutos}
                keyName="label"
                onChange={setSelectedProdutos}
              />
            </div>
          </div>

          <div class="max-h-600px overflow-y-auto">
            {
              model.produtoCategorias?.map((categoria, index) => {
                if (selectedCategorias.length > 0 && selectedCategorias.findIndex(x => x.value === categoria.produtoCategoriaId) === -1)
                  return null;

                if (selectedProdutos.length > 0 && selectedProdutos.findIndex(x => x.produtoCategoriaId === categoria.produtoCategoriaId) === -1)
                  return null;

                return (
                  <div key={categoria.produtoCategoriaId} className="row m-0 mb-1 border-top-bold">
                    <div className="col-lg-4 p-1">{categoria.nome}</div>
                    <div className="col-lg-8 p-1 pb-0">
                      <div className="row m-0">
                        <div className="col-lg-6 p-0">
                          <InputVolume
                            className={`${categoria.invalidValor ? "is-invalid" : ""}`}
                            initialValue={categoria.metaValor || 0}
                            onChange={(value) => { categoria.metaValor = value; }}
                            unidadeVolume={unidadeVolume}
                          />
                        </div>
                      </div>

                      <Accordion className="border-top mt-1" header="Produtos" show={selectedProdutos.length > 0}>
                        {
                          categoria.produtos.map((produto, index) => {
                            if (selectedProdutos.length > 0 && selectedProdutos.findIndex(x => x.value === produto.produtoId) === -1)
                              return null;

                            return (
                              <div key={produto.produtoId} className="row m-0 border-top">
                                <div className="col-lg-6 p-1">{produto.nome}</div>
                                <div className="col-lg-6 p-1">
                                  <InputVolume
                                    className={`${produto.invalidValor ? "is-invalid" : ""}`}
                                    initialValue={produto.metaValor || 0}
                                    onChange={(value) => { produto.metaValor = value; }}
                                    unidadeVolume={unidadeVolume}
                                  />
                                </div>
                              </div>
                            )
                          })
                        }
                      </Accordion>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div >

    </>
  )
});

export default MetaValor;