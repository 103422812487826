import React, { useEffect, useState } from "react";

import Layout from "../../components/layout/base/index";
import PageTitle from "../../components/page-title";
import { Tab, Tabs } from "react-bootstrap";

import HomeVendas from "./homevendas";
import EquipeVendas from "./equipevendas";
import Vendedor from "./vendedor";
import Detalhamento from "./detalhamento";
import { LoggedUserContext } from "../../contexts/logged-user-context";

import "./styles.scss";

export default function Dasboard() {
  const [userData, _] = React.useContext(LoggedUserContext);

  return (
    <div className="col-12 relatorio">
      <Tabs
        defaultActiveKey={
          userData.equipe?.equipeId > 0
            ? userData.possuiEquipes
              ? "Equipe Vendas"
              : "Vendedor"
            : "Home Vendas"
        }
        id="tabs-relatorio"
        className="mb-3"
      >
        {userData.equipe?.equipeId > 0 ? null : (
          <Tab eventKey="Home Vendas" title="Home Vendas">
            <HomeVendas />
          </Tab>
        )}

        {userData.possuiEquipes ? (
          <Tab eventKey="Equipe Vendas" title="Equipe Vendas">
            <EquipeVendas />
          </Tab>
        ) : null}

        <Tab eventKey="Vendedor" title="Vendedor">
          <Vendedor />
        </Tab>
        <Tab eventKey="Detalhamento" title="Detalhamento">
          <Detalhamento />
        </Tab>
      </Tabs>
    </div>
  );
}
