import React from "react";
import NumberFormat from "react-number-format";
import lib from "../../helpers/lib";
import FormGroupHeader from "../_base/form-group-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";

import { LoggedUserContext } from "../../contexts/logged-user-context";

import PedidoService from "../../services/pedido-service";

import "./styles.scss";

function groupBy(arr, key) {
  return arr.reduce(function (groups, x) {
    let g = groups.find((g1) => g1.key === x[key]);
    if (g) {
      g.values.push(x);
    } else {
      groups.push({
        key: x[key],
        values: [x],
      });
    }

    return groups;
  }, []);
}

export default function ResumoPedido(props) {
  const { model } = props;

  const [userData, _] = React.useContext(LoggedUserContext);

  const [resumo, setResumo] = React.useState();

  React.useEffect(() => {
    getResumo();
  }, [model]);

  async function getResumo() {
    if (model !== undefined && model !== null) {
      window.showLoader();

      let response = await PedidoService.GetResumoAsync(
        model.pedidoId,
        model.representadaId
      );
      console.log("----resumo----");
      let resumo = response?.data?.data || {};
      console.log(resumo);
      if (resumo.pedidoFaturamento) {
        let notas = groupBy(resumo.pedidoFaturamento, "notaFaturamento");

        let pedidoFaturamento = [];

        for (let nota of notas) {
          let pedidoFaturamentoProdutos = [].concat(
            ...nota.values.map((n) => n.pedidoFaturamentoProdutos)
          );

          let produtos = groupBy(pedidoFaturamentoProdutos, "produtoId");

          pedidoFaturamento.push({
            ...nota.values[0],
            valorTotalFaturado: nota.values.reduce(
              (acc, x) => acc + x.valorTotalFaturado,
              0
            ),
            pedidoFaturamentoProdutos: produtos.map((p) => ({
              ...p.values[0],
              quantidade: p.values.reduce((acc, x) => acc + x.quantidade, 0),
            })),
          });
        }
        resumo.pedidoFaturamento = pedidoFaturamento;
      }

      setResumo(resumo);
      window.hideLoader();
    }
  }

  function calcularDiferencaFaturamento(itemPedido, itemFaturamento) {
    const valorItemPedido =
      parseFloat(itemPedido?.faturamentoQuantidade ?? 0) *
      parseFloat(itemPedido?.faturamentoValorUnidade ?? 0);

    if (itemFaturamento == undefined && valorItemPedido > 0)
      return valorItemPedido * -1;

    if (itemFaturamento == undefined && valorItemPedido == 0) return 0;

    const valorItemFaturamento =
      parseFloat(itemFaturamento?.quantidade ?? 0) *
      parseFloat(itemPedido?.faturamentoValorUnidade ?? 0);

    return valorItemFaturamento == valorItemPedido
      ? 0
      : valorItemFaturamento - valorItemPedido;
  }

  function SomaTotal() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      let item = resumo.produtos[i];
      total +=
        parseFloat(item.faturamentoQuantidade) *
        parseFloat(item.faturamentoValorUnidade);
    }

    return total;
  }

  function SomaTotalProdutosSemDesconto() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      let item = resumo.produtos[i];
      if (item.valorBruto && item.valorBruto != null && item.valorBruto > 0) {
        total += parseFloat(item.vendaQuantidade) * parseFloat(item.valorBruto);
      } else {
        total +=
          parseFloat(item.faturamentoQuantidade) * parseFloat(item.faturamentoValorUnidade);
      }
    }

    return total;
  }

  function SomaTotalProdutosDescontoAdicional() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      let item = resumo.produtos[i];
      total +=
        parseFloat(item.vendaQuantidade) *
        (item.valorDescontoAdicional
          ? parseFloat(item.valorDescontoAdicional)
          : 0);
    }

    return total;
  }

  function SomaTotalDesconto() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      let item = resumo.produtos[i];
      total +=
        parseFloat(item.vendaQuantidade) *
        (item.valorDesconto ? parseFloat(item.valorDesconto) : 0);
    }

    return total;
  }

  function SomaTotalFaturado() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (let produto of resumo.produtos) {
      total += produto.valorFaturado;
    }

    return total;
  }

  function SomaTotalDiferenca() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let totalPedido = 0;
    let totalFaturado = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      totalPedido +=
        parseFloat(resumo.produtos[i]?.faturamentoQuantidade ?? 0) *
        parseFloat(resumo.produtos[i]?.faturamentoValorUnidade ?? 0);
    }

    if (resumo.pedidoFaturamento && resumo.pedidoFaturamento.length > 0) {
      for (let i = 0; i < resumo.pedidoFaturamento.length; i++) {
        for (
          let j = 0;
          j < resumo.pedidoFaturamento[i].pedidoFaturamentoProdutos.length;
          j++
        ) {
          let produto = resumo.produtos.find(
            (x) =>
              x.produtoId ===
              resumo.pedidoFaturamento[i].pedidoFaturamentoProdutos[j].produtoId
          );
          totalFaturado +=
            parseFloat(
              resumo.pedidoFaturamento[i].pedidoFaturamentoProdutos[j]
                .quantidade ?? 0
            ) * parseFloat(produto?.faturamentoValorUnidade ?? 0);
        }
      }
    }

    var result = totalFaturado - totalPedido;
    if (result > -1 && result < 1) return 0;

    return result;
  }

  function getDifferenceLetterColor(valor) {
    if (valor <= -1) return "#973446";
    return "#2a6128";
  }

  function calcularTotalComDescontos(
    valorReferencia,
    valorTotDesconto,
    valorTotDescAdicional
  ) {
    var primeiroDes = valorReferencia - valorTotDesconto;

    return primeiroDes - valorTotDescAdicional;
  }

  function calcularPorcentagemDesconto(valorReferencia, valorAtual) {
    if (valorReferencia == 0 || valorAtual == 0) return 0;
    const percentual = (100 * valorAtual) / valorReferencia;
    if (percentual > 100) return 0;
    const resultPerct = 100 - percentual;
    return Math.round(resultPerct * 100) / 100;
  }

  return (
    <div className="modal-body modal-resumo-pedido">
      <div className="row mb-2">
        <div className="col-lg-2 text-end">Pedido:</div>
        <div className="col-lg-4">
          {resumo?.codigoPedidoLoja
            ? resumo?.codigoPedidoLoja
            : resumo?.codigoPedidoRepresentada}
        </div>
        <div className="col-lg-2 text-end">Data:</div>
        <div className="col-lg-4">
          {lib.getStringFromDateMinute(resumo?.data)}
        </div>

        <div className="col-lg-2 text-end">Tipo:</div>
        <div className="col-lg-4">{resumo?.pedidoTipo}</div>

        {resumo?.dataMaximaEntrega && (
          <>
            <div className="col-lg-2 text-end">Entregar até:</div>
            <div className="col-lg-4">
              {lib.getStringFromDate(resumo.dataMaximaEntrega)}
            </div>
          </>
        )}
        {resumo?.prazoPagamento && resumo?.prazoPagamento != null && (
          <>
            <div className="col-lg-2 text-end">Prazo de Pagamento:</div>
            <div className="col-lg-4">{resumo.prazoPagamento}</div>
          </>
        )}
        <div className="col-lg-2 text-end">Vendedor:</div>
        <div className="col-lg-4">{resumo?.vendedor}</div>
        {resumo?.dataAprovacao && resumo?.dataAprovacao != null && (
          <>
            <div className="col-lg-2 text-end">Data Aprovação:</div>
            <div className="col-lg-4">
              {lib.getStringFromDate(resumo.dataAprovacao)}
            </div>
            <div className="col-lg-2 text-end">Usuário Aprovação:</div>
            <div className="col-lg-4">{resumo.nomeUsuarioAprovacao}</div>
          </>
        )}
        {resumo?.dataCancelamento && resumo?.dataCancelamento != null && (
          <>
            <div className="col-lg-2 text-end">Data Cancelamento:</div>
            <div className="col-lg-4">
              {lib.getStringFromDate(resumo.dataCancelamento)}
            </div>
            <div className="col-lg-2 text-end">Usuário Cancelamento:</div>
            <div className="col-lg-4">{resumo.nomeUsuarioCancelamento}</div>
          </>
        )}
      </div>
      <FormGroupHeader text="DADOS DA LOJA" />
      <div className="row mb-2">
        <div className="col-lg-6">
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Razão Social:</div>
            <div className="col-lg-8">{resumo?.loja?.razaoSocial}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Nome Fantasia:</div>
            <div className="col-lg-8">{resumo?.loja?.nomeFantasia}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4 text-end">CNPJ:</div>
            <div className="col-lg-8">{resumo?.loja?.cnpj}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Telefone:</div>
            <div className="col-lg-8">{resumo?.loja?.contatoTelefone}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Email:</div>
            <div className="col-lg-8">{resumo?.loja?.contatoEmail}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Condição Pagamento:</div>
            <div className="col-lg-8">
              {resumo?.loja?.condicaoPagamento != ""
                ? resumo?.loja?.condicaoPagamento
                : "-"}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Endereço:</div>
            <div className="col-lg-8">
              {resumo?.loja?.logradouro}, {resumo?.loja?.numero}{" "}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">{resumo?.loja?.bairro}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
              {resumo?.loja?.cidade} - {resumo?.loja?.estado}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">{resumo?.loja?.cep}</div>
          </div>
        </div>
      </div>

      <FormGroupHeader text="DADOS DA REPRESENTADA" />
      <div className="row mb-2">
        <div className="col-lg-9">
          {model?.representadaRazaoSocial ?? resumo?.representada?.razaoSocial}
        </div>
        <div className="col-lg-3">
          CNPJ: {model?.representada?.cnpj ?? resumo?.representada?.cnpj}
        </div>
      </div>

      {/* Lista Produtos antes do faturamento ------------------------------------------------------------ */}
      <>
        <FormGroupHeader text="PRODUTOS" />
        <div className="table-responsive">
          <table className="table table-cadastro">
            <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Quantidade</th>
                <th>Unidade</th>
                <th>Preço de Tabela</th>
                {/* <th>Total Sem Desconto</th> */}
                <th>Preço do Pedido</th>
                <th>Total do Pedido</th>
                <th>Desconto no Produto</th>
              </tr>
            </thead>
            <tbody>
              {(resumo?.produtos || []).map((item, index) => (
                <React.Fragment>
                  <tr key={index}>
                    <td>{item.produtoCodigoNaRepresentada}</td>
                    <td>{item.produtoNome}</td>
                    <td>
                      <NumberFormat
                        value={item.faturamentoQuantidade}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        fixedDecimalScale={true}
                        decimalScale={3}
                      />
                    </td>
                    <td>{item.faturamentoUnidadeSigla}</td>
                    <td style={{ textAlign: "center" }}>
                      {item.valorBruto && item.valorBruto > 0 ? (
                        <NumberFormat
                          value={item.valorBruto}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      ) : null}
                      {!item.valorBruto || item.valorBruto == 0 ? (
                        <div>-</div>
                      ) : null}
                    </td>
                    {/* <td>
                      <NumberFormat
                        value={
                          parseFloat(item.faturamentoQuantidade) *
                          parseFloat(item.valorBruto)
                        }
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td> */}
                    <td>
                      <NumberFormat
                        value={item.faturamentoValorUnidade}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={
                          parseFloat(item.faturamentoQuantidade) *
                          parseFloat(item.faturamentoValorUnidade)
                        }
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={
                          //parseFloat(item.faturamentoQuantidade) *
                          (item.valorDesconto && item.valorDesconto != null
                            ? parseFloat(item.valorDesconto)
                            : 0)
                        }
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                      &nbsp;/&nbsp;
                      <NumberFormat
                        value={
                          item.porcentagemDesconto &&
                          item.porcentagemDesconto != null
                            ? parseFloat(item.porcentagemDesconto)
                            : 0
                        }
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={""}
                        suffix=" %"
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
              <tr>
                <td colSpan={7} className="text text-end">
                  TOTAL DO PEDIDO
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.valorTotalVenda ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              {/* <tr>
                <td colSpan={8} className="text text-end">
                  DESCONTOS NOS PREÇOS DOS PRODUTOS
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.valorTotalDescontoProdutos ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr> */}
              <tr>
                <td colSpan={7} className="text text-end">
                  % DESCONTO SOBRE A TABELA
                </td>
                <td className="text">
                  <NumberFormat
                    value={
                      resumo?.valorTotalBruto != undefined &&
                      resumo?.valorTotalBruto > 0 &&
                      resumo?.valorTotalBruto > resumo?.valorTotalVenda
                        ? calcularPorcentagemDesconto(
                            resumo?.valorTotalBruto,
                            resumo?.valorTotalVenda
                          )
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix=" %"
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={7} className="text text-end">
                  TOTAL DESCONTO SOBRE A TABELA
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.valorTotalDescontoProdutos ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              {/* <tr>
                <td colSpan={8} className="text text-end">
                  % DESCONTO ADICIONAL
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.percentualDescontoAdicional ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={""}
                    suffix=" %"
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={8} className="text text-end">
                  TOTAL DESCONTO ADICIONAL
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.valorTotalDescontoAdicional ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={8} className="text text-end">
                  TOTAL PEDIDO COM DESCONTO
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.valorTotalComDescontos ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={8} className="text text-end">
                  % DESCONTO TOTAL PEDIDO
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.percentualTotalValorDesconto ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={""}
                    suffix=" %"
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </>

      {resumo?.observacao && resumo?.observacao != "" && (
        <>
          <div className="row">
            <div className="col-2">Observações Internas:</div>
            <div className="col-8">{resumo?.observacao}</div>
          </div>
        </>
      )}

      {/* Resumo faturamento ------------------------------------------------------------ */}
      {resumo?.pedidoFaturamento && resumo?.pedidoFaturamento.length > 0 && (
        <>
          <FormGroupHeader text="TOTAL FATURAMENTO" />
          <div className="table-responsive">
            <table className="table table-cadastro">
              <thead>
                <tr>
                  <th>Valor Total Pedido</th>
                  <th>Desconto</th>
                  <th>Valor Faturado</th>
                  <th>Valor Não Faturado</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <NumberFormat
                      value={SomaTotal()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={SomaTotalDesconto()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={SomaTotalFaturado()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={SomaTotalDiferenca()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <FormGroupHeader text="DADOS FATURAMENTO" />

          <div className="table-responsive">
            <table className="table table-cadastro">
              <thead>
                <tr>
                  <th>Nota</th>
                  <th>Data Faturamento</th>
                  <th>Valor Faturado</th>
                  <th>Comissão</th>
                </tr>
              </thead>
              <tbody>
                {(resumo?.pedidoFaturamento || []).map((item, index) => (
                  <tr key={index}>
                    <td>{item?.notaFaturamento}</td>
                    <td>{lib.getStringFromDateTime(item?.dataFaturamento)}</td>
                    <td>
                      <NumberFormat
                        value={item.valorTotalFaturado}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td>
                      {item.valorComissao &&
                        item.valorComissao != null &&
                        item.valorComissao >= 0 && (
                          <NumberFormat
                            value={item.valorComissao}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {/* Lista Produtos após faturamento ------------------------------------------------------------ */}
      {resumo?.pedidoFaturamento && resumo?.pedidoFaturamento.length > 0 && (
        <>
          <FormGroupHeader text="FATURAMENTO DOS PRODUTOS" />
          <div className="table-responsive">
            <table className="table table-cadastro">
              <thead>
                <tr>
                  <th>N° Nota Fiscal</th>
                  <th>Cod.</th>
                  <th>Produto</th>
                  <th>Unidade Fat.</th>
                  <th>Qtd. Pedido</th>
                  <th>Valor Unidade Fat.</th>
                  <th>Qtd. Faturado</th>
                  <th>Total Pedido</th>
                  <th>Total Fat.</th>
                  <th>Diferença</th>
                </tr>
              </thead>
              <tbody>
                {resumo.pedidoFaturamento.map((faturamento, index) =>
                  faturamento.pedidoFaturamentoProdutos.map(
                    (faturamentoProduto, index) => {
                      if (faturamentoProduto.quantidade === 0) return null;

                      let produto = resumo.produtos.find(
                        (x) => x.produtoId === faturamentoProduto.produtoId
                      );

                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{faturamento.notaFaturamento}</td>
                            <td>{produto.produtoCodigoNaRepresentada}</td>
                            <td>{produto.produtoNome}</td>
                            <td>{produto?.faturamentoUnidadeSigla}</td>
                            <td>
                              <NumberFormat
                                value={produto?.vendaQuantidade ?? 0}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={3}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={produto?.faturamentoValorUnidade ?? 0}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"R$ "}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={ produto.quantidadeFaturada ?? 0 }
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={3}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={
                                  parseFloat(produto?.faturamentoQuantidade ?? 0)
                                  *
                                  parseFloat(produto?.faturamentoValorUnidade ?? 0)
                                }
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"R$ "}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={
                                  faturamentoProduto.quantidade *
                                  produto.faturamentoValorUnidade
                                }
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"R$ "}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td>
                              <span
                                style={{
                                  color: getDifferenceLetterColor(
                                    calcularDiferencaFaturamento(
                                      produto,
                                      faturamentoProduto
                                    )
                                  ),
                                }}
                              >
                                <NumberFormat
                                  value={calcularDiferencaFaturamento(
                                    produto,
                                    faturamentoProduto
                                  )}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  prefix={"R$ "}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                  )
                )}

                {/* produtos que nao foram faturados */}
                {resumo.produtos
                  .filter((p) => p.quantidadeFaturada === 0)
                  .map((produto, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>-</td>
                        <td>{produto.produtoCodigoNaRepresentada}</td>
                        <td>{produto.produtoNome}</td>
                        <td>{produto?.faturamentoUnidadeSigla}</td>
                        <td>
                          <NumberFormat
                            value={produto?.vendaQuantidade ?? 0}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={3}
                            fixedDecimalScale={false}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={produto?.faturamentoValorUnidade ?? 0}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={
                              parseFloat(produto?.faturamentoQuantidade ?? 0) *
                              parseFloat(produto?.faturamentoValorUnidade ?? 0)
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={0}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td>
                          <span
                            style={{
                              color: getDifferenceLetterColor(
                                calcularDiferencaFaturamento(produto, undefined)
                              ),
                            }}
                          >
                            <NumberFormat
                              value={calcularDiferencaFaturamento(
                                produto,
                                undefined
                              )}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                }

                <tr>
                  <td colSpan={7} style={{ textAlign: "right" }}>
                    Total
                  </td>
                  <td>
                    <NumberFormat
                      value={SomaTotal()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={SomaTotalFaturado()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <span
                      style={{
                        color: getDifferenceLetterColor(SomaTotalDiferenca()),
                      }}
                    >
                      <NumberFormat
                        value={SomaTotalDiferenca()}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={true}
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
