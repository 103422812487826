import ServiceClient from "./service-client";
import { service_url } from "./service-constants";

class PedidoFaturamentoHistoricoService {
  static async listPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get("/pedidofaturamentohistorico/list-paged", filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default PedidoFaturamentoHistoricoService;
