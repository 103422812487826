import React from "react";
import { useQueryClient } from "react-query";
import ResumoPedido from "../resumo-pedido";
import ModalCadastro from "../_base/modal-cadastro";

import PedidoService from "../../services/pedido-service";

// import "./styles.scss";

export default function ModalResumoPedido(props) {
  const { model, showModal, setShowModal, onClose } = props;

  const queryClient = useQueryClient();

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="RESUMO DO PEDIDO"
      onClose={onClose}
    >
      <ResumoPedido model={model}></ResumoPedido>

      {props.children}
    </ModalCadastro>
  );
}
