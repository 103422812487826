import React from "react";
import LoginService from "../services/login-service";

import lib from "../helpers/lib";

export const LoggedUserContext = React.createContext([{}, () => {}]);

export const LoggedUserProvider = (props) => {
  const [userData, setUserData] = React.useState({});

  React.useEffect(() => {
    checkUser();
  }, []);

  const allowAnonymousPages = [
    "/login",
    "/invitation",
    "/novasenha",
    "/politicas-privacidade",
  ];

  async function checkUser() {
    if (allowAnonymousPages.indexOf(window.location.pathname) !== -1) return;

    window.showLoader();

    let response = await LoginService.checkUser();

    window.hideLoader();

    if (response.status === lib.statusCode.ok) {
      let userData = response.data.data;
      localStorage.setItem("equipeId", userData.equipe?.equipeId ?? 0);

      setUserData(userData);
    }
  }

  return (
    <LoggedUserContext.Provider value={[userData, setUserData]}>
      {props.children}
    </LoggedUserContext.Provider>
  );
};
