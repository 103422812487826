import ServiceClient from "./service-client";
class ChamadoService {
  static async getChamadosAsync(todos) {
    try {
      var result = await ServiceClient.Get(
        "/chamado/list-chamados" + (todos ? "?todos=true" : "")
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getMensagensAsync(chamadoId) {
    try {
      var result = await ServiceClient.Get(
        "/chamado/list-chamado-mensagens/" + chamadoId
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getTipoChamadoAsync() {
    try {
      var result = await ServiceClient.Get("/chamado/list-tipo-chamado");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addChamadoAsync(body) {
    try {
      var result = await ServiceClient.Post("/chamado/add/", body);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addMensagemAsync(body) {
    try {
      var result = await ServiceClient.FormPost("/chamado/add-mensagem/", body);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async endChamadoAsync(chamadoId) {
    try {
      var result = await ServiceClient.Post(
        "/chamado/end-chamado/" + chamadoId
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default ChamadoService;
