import React from "react";

import './styles.scss'

export default function TablePagination(props) {
  const maxPages = 5;
  const { pageData } = props;
  const { onChangePage } = props;

  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    if (!pageData) {
      setPages([])
      return;
    }

    let inicio = pageData.pageNumber - 2;

    if ((pageData.totalPages - inicio) < maxPages)
      inicio = pageData.totalPages - maxPages + 1;
      
    if (inicio <= 0)
      inicio = 1;
      
    let pages = [];
    for (let i = inicio; i <= pageData.totalPages && pages.length < maxPages; i++) {
      pages.push(i);
    }

    setPages(pages);
  }, [pageData, pageData?.pageNumber, pageData?.totalPages])

  function renderTotal() {
    if (pageData.totalRows === 0) {
      return (
        <div className="row">
          <div className="col-md-12">
            <label>
              Nenhum registro encontrado.
            </label>
          </div>
        </div>)
    } else if (pageData.totalRows > 0) {
      return (
        <div className="row">
          <div className="col-md-12">
            <label>
              Total de registros: {pageData.totalRows}
            </label>
          </div>
        </div>
      )
    }

    return null;
  }

  function renderPagination() {
    if (!pages || pages.length < 2)
      return null;

    return (
      <nav aria-label="page navigation">
        <ul className="pagination">
          <li className={`page-item ${pageData.pageNumber === 1 ? "disabled" : ""}`}>
            <button className="page-link" aria-label="Previous" onClick={() => onChangePage(pageData.pageNumber - 1)}>
              <span aria-hidden="true">&laquo;</span>
              {/* <span className="sr-only">Previous</span> */}
            </button>
          </li>
          {
            pages.map((page, index) => (
              <li
                key={page}
                className={`page-item  ${page === pageData.pageNumber ? "active" : ""} ${pageData.disabled ? "disabled" : ""}`}>
                <button className="page-link" onClick={() => page !== pageData.pageNumber && onChangePage(page)}>{page}</button>
              </li>
            ))
          }

          <li className={`page-item ${pages.indexOf(pageData.pageNumber) === (pages.length - 1) ? "disabled" : ""}`}>
            <button className="page-link" aria-label="Next" onClick={() => onChangePage(pageData.pageNumber + 1)}>
              <span aria-hidden="true">&raquo;</span>
              {/* <span className="sr-only">Next</span> */}
            </button>
          </li>
        </ul>
      </nav>
    );
  }

  if (!pageData)
    return null;

  return (
    <div>
      {renderPagination()}

      {renderTotal()}
    </div>
  );
}
