import ServiceClient from "./service-client";
import { service_url } from "./service-constants";

class RepresentadaService {
  static async listPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        service_url.representada_listpaged,
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addAsync(representada) {
    try {
      var result = await ServiceClient.FormPost(
        service_url.representada_add,
        representada
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync(representada) {
    try {
      var result = await ServiceClient.FormPost(
        service_url.representada_update,
        representada
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateStatusAsync(representada) {
    try {
      var result = await ServiceClient.Post(
        service_url.representada_updateStatus,
        { representadaId: representada.representadaId }
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async selectListAsync() {
    try {
      var result = await ServiceClient.Get(service_url.representada_selectList);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async selectListAllAsync() {
    try {
      var result = await ServiceClient.Get(
        service_url.representada_selectList_all
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async selectListCarteiraAsync({ equipeId, usuarioId }) {
    try {
      var result = await ServiceClient.Get(
        service_url.representada_selectList_carteira,
        { equipeId, usuarioId }
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listRepresentadaLojasAsync(lojaId) {
    try {
      var result = await ServiceClient.Get(
        service_url.representada_listRepresentadaLojas,
        { lojaId: lojaId }
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getOrderQuantConfigOptionsAsync() {
    try {
      var result = await ServiceClient.Get(
        service_url.representada_orderQuantConfigOptions
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default RepresentadaService;
