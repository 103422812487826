import React from "react";
import Select from "react-select";
import NumberFormat from "react-number-format"; import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import InputImageProduto from "../input-image-produto";
import SelectRepresentadaCadastro from "../selects/select-representada-cadastro";
import AutoCompleteCreatable from "../_base/autocomplete-creatable";
import ModalCadastro from "../_base/modal-cadastro";
import FormGroupHeader from "../_base/form-group-header";
import Errors from "../_base/errors";
import TabelaPrecos from "./_tabela-precos";
import UnidadeService from '../../services/unidade-service';

import { LoggedUserContext } from "../../contexts/logged-user-context";

import ProdutoService from "../../services/produto-service";

import lib from "../../helpers/lib";

import "./styles.scss";

export default function ModalCadastroProduto(props) {
  const [userData, _] = React.useContext(LoggedUserContext);

  const [categorias, setCategorias] = React.useState([]);
  const [marcas, setMarcas] = React.useState([]);
  const [selectListRepresentadas, setSelectListRepresentadas] = React.useState([]);
  const [selectListUnidades, setSelectListUnidades] = React.useState([]);
  const [unidadesNaoBasicas, setUnidadesNaoBasicas] = React.useState([]);

  const { showModal, setShowModal, onClose, entity } = props;

  const [variantes, setVariantes] = React.useState([]);

  const formFields = {
    representadaId: {
      key: "representadaId",
      required: true,
      validate: () =>
        selectListRepresentadas.findIndex(
          (item) => item.value === produto.representadaId
        ) !== -1,
    },
    codigoNaRepresentada: { key: "codigoNaRepresentada", required: false },
    nome: {
      key: "nome",
      required: true /*disabled: entity.produtoId?.length > 0*/,
    },
    marcaId: {
      key: "marcaId",
      required: true,
      validate: () =>
        marcas.findIndex((marca) => marca.marcaId === produto.marcaId) !== -1,
    },
    produtoCategoriaId: {
      key: "produtoCategoriaId",
      required: true,
      validate: () =>
        categorias.findIndex(
          (categoria) =>
            categoria.produtoCategoriaId === produto.produtoCategoriaId
        ) !== -1,
    },
    unidadeId: {
      key: "unidadeId",
      required: true,
      validate: () =>
        selectListUnidades.findIndex(
          (item) => parseInt(item.value) === produto.unidadeId
        ) !== -1,
    },
    quantidadeMultiploVenda: { key: "quantidadeMultiploVenda", required: false },
    quantidadePorCaixa: { key: "quantidadePorCaixa", required: true },
    pesoUnitarioKG: { key: "pesoUnitarioKG", required: false },
    comissao: { key: "comissao", required: false },
  };

  const emptyProduto = {
    representadaId: "",
    codigoNaRepresentada: "",
    nome: "",
    marcaId: "",
    produtoCategoriaId: "",
    unidadeId: 0,
    quantidadePorCaixa: "",
    pesoUnitarioKG: "",
    comissao: "",
  };

  const [produto, setProduto] = React.useState({ ...emptyProduto });

  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    getUnidades();
    getRepresentadas();

    //categorias e marcas precisam ser carregadas novamente quando salvar porque pode ter sido criado alguma categoria ou marca
    getCategorias();
    getMarcas();
  }, []);

  React.useEffect(() => {
    setErrors({});

    let eProduto = lib.isNullOrEmptyObject(entity) ? { ...emptyProduto } : { ...entity };

    setVariantes([]);
    setProduto(eProduto);
    (async () => {
      let response = await UnidadeService.listNaoBasica();

      let naobasicas = response.data.data;
      naobasicas.forEach(function (unidade, i) {
        unidade.produtoId = eProduto.produtoId ? eProduto.produtoId : 0;
        unidade.valor = 0;
        unidade.selecionada = false;
        if (eProduto.produtoUnidadesMedidas && eProduto.produtoUnidadesMedidas.length > 0) {

          let unidadeProduto = eProduto.produtoUnidadesMedidas.filter(function (item) {
            return item.unidadeMedidaId == unidade.unidadeMedidaId;
          });

          if (unidadeProduto.length > 0) {
            unidade.valor = unidadeProduto[0].valor;
            unidade.selecionada = true;
          }
        }
      });

      setUnidadesNaoBasicas(naobasicas);

    })();
  }, [entity]);

  async function getCategorias() {
    let response = await ProdutoService.listCategoriasAsync();
    setCategorias(response.data.data);
  }

  async function getMarcas() {
    let response = await ProdutoService.listMarcasAsync();
    setMarcas(response.data.data);
  }

  async function getUnidades() {
    let response = await ProdutoService.listUnidadesAsync();
    let unidades = response.data.data;
    let selectListUnidades = unidades.map((unidade) => ({
      label: unidade.nome,
      value: unidade.unidadeMedidaId,
    }));

    setSelectListUnidades(selectListUnidades);
  }

  async function getRepresentadas() {
    let response = await ProdutoService.selectListRepresentadasAsync();
    let selectListRepresentadas = response.data.data;

    setSelectListRepresentadas(selectListRepresentadas);
  }

  function updateProduto(data) {
    setProduto({ ...produto, ...data });
  }

  function getPossuiMultiploVenda(){
   let representada =  selectListRepresentadas.find(
      (x) => x.value === produto.representadaId
    ) || null;

    if (representada!= null)
      return representada.possuiMultiploVenda;

    return false;
  }

  async function saveAsync() {
    window.showLoader();

    if (!validateForm()) {
      window.hideLoader();
      return;
    }

    setErrors({});

    var unidadesSelecionadas = unidadesNaoBasicas.filter(function (item) {
      return item.selecionada;
    });

    produto.produtoUnidadesMedidas = unidadesSelecionadas;
    produto.variantes = variantes;

    let response =
      !produto.produtoId || produto.produtoId === 0
        ? await ProdutoService.addAsync(produto)
        : await ProdutoService.updateAsync(produto);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseProduto = response.data.data;

      if (onClose) onClose(responseProduto);

      setProduto({ ...emptyProduto });

      setShowModal(false);

      //reload categorias e marcas
      getCategorias();
      getMarcas();

      return;
    }

    setErrors(response.data.errors);

    window.hideLoader();
  }

  function validateForm() {
    let errors = {};

    //required fields
    for (let prop in formFields) {
      let field = formFields[prop];

      if (formFields[field.key].validate && formFields[field.key].validate())
        continue;
      else if (
        !field.required ||
        produto[field.key]?.length ||
        produto[field.key] > 0
      )
        continue;

      errors[field.key] = [];
    }
    //--

    if (lib.isNullOrEmptyObject(errors)) return true;

    setErrors(errors);

    return false;
  }

  // let produtoVariantes = [""];
  // for(let variante of variantes) {
  //   for (let valor of variante.valores)
  //     produtoVariantes.push(produto.nome.toUpperCase() + " " + valor);
  // }
  var produtoVariantes = [produto.nome];

  for (let variante of variantes) {
    if (!variante.valores?.length)
      continue;

    let resultado = []

    for (let valor of variante.valores) {
      for (let pv of produtoVariantes) {
        resultado.push(`${pv} - ${valor}`)
      }
    }

    produtoVariantes = resultado;
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      onClose={onClose}
      headerText="CADASTRAR NOVO PRODUTO"
    >
      <div className="modal-body modal-cadastro-produto">
        <form>
          <div className="row mb-2">
            <div className="col-lg-12"></div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-6 mb-2">
              <div className="row mb-2">
                <div className="col-lg-12">
                  <label>Representada</label>
                  <SelectRepresentadaCadastro
                    className={`${lib.isNullOrEmptyObject(
                      errors[formFields.representadaId.key]
                    )
                      ? ""
                      : "is-invalid"
                      }`}
                    placeholder="Selecione uma Representada"
                    options={selectListRepresentadas}
                    value={
                      selectListRepresentadas.find(
                        (x) => x.value === produto.representadaId
                      ) || null
                    }
                    onChange={(selectedValue) =>
                      updateProduto({ representadaId: selectedValue?.value })
                    }
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-lg-12">
                  <label>Código na Representada</label>
                  <input
                    type="text"
                    className={`form-control ${lib.isNullOrEmptyObject(
                      errors[formFields.codigoNaRepresentada.key]
                    )
                      ? ""
                      : "is-invalid"
                      }`}
                    value={produto.codigoNaRepresentada || ""}
                    onChange={(e) =>
                      updateProduto({ codigoNaRepresentada: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-lg-12">
                  <label>Nome</label>
                  <input
                    type="text"
                    className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.nome.key])
                      ? ""
                      : "is-invalid"
                      }`}
                    disabled={formFields.nome.disabled}
                    value={produto.nome || ""}
                    onChange={(e) => updateProduto({ nome: e.target.value })}
                  />

                  <Errors errors={errors} keyName={formFields.nome.key} />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-lg-12">
                  <label>Marca</label>
                  <AutoCompleteCreatable
                    className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.marcaId.key])
                      ? ""
                      : "is-invalid"
                      }`}
                    placeholder="Marca"
                    keyLabel="nome"
                    keyValue="marcaId"
                    matchSorterKeys={["nome"]}
                    onSelectedItemChange={({ selectedItem }) => {
                      updateProduto({
                        marcaId: selectedItem.marcaId,
                        marca: selectedItem.nome,
                      });

                      if (selectedItem.marcaId === 0) {
                        let novaMarca = { ...selectedItem };
                        setMarcas([
                          ...marcas.filter(
                            (x) => x.marcaId !== novaMarca.marcaId
                          ),
                          { ...novaMarca },
                        ]);
                      }
                    }}
                    selectedItem={
                      marcas.find((x) => x.marcaId === produto.marcaId) || null
                    }
                    items={marcas}
                    itemToString={(item) => (item ? item.nome : "")}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-lg-12">
                  <label>Categoria</label>
                  <AutoCompleteCreatable
                    className={`form-control ${lib.isNullOrEmptyObject(
                      errors[formFields.produtoCategoriaId.key]
                    )
                      ? ""
                      : "is-invalid"
                      }`}
                    keyLabel="nome"
                    keyValue="produtoCategoriaId"
                    matchSorterKeys={["nome"]}
                    onSelectedItemChange={({ selectedItem }) => {
                      updateProduto({
                        produtoCategoriaId: selectedItem.produtoCategoriaId,
                        produtoCategoria: selectedItem.nome,
                      });

                      if (selectedItem.produtoCategoriaId === 0) {
                        let novaCategoria = { ...selectedItem };
                        setCategorias([
                          ...categorias.filter(
                            (x) =>
                              x.produtoCategoriaId !==
                              novaCategoria.produtoCategoriaId
                          ),
                          { ...novaCategoria },
                        ]);
                      }
                    }}
                    selectedItem={
                      categorias.find(
                        (x) =>
                          x.produtoCategoriaId === produto.produtoCategoriaId
                      ) || null
                    }
                    items={categorias}
                    itemToString={(item) => (item ? item.nome : "")}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-lg-12">
                  <label>Unidade de Faturamento na Representada</label>
                  <Select
                    classNamePrefix="select-cadastro"
                    className={`${lib.isNullOrEmptyObject(errors[formFields.unidadeId.key])
                      ? ""
                      : "is-invalid"
                      }`}
                    options={selectListUnidades}
                    value={
                      selectListUnidades.find(
                        (x) => parseInt(x.value) === produto.unidadeId
                      ) || null
                    }
                    onChange={(selectedValue) =>
                      updateProduto({
                        unidadeId: parseInt(selectedValue.value),
                      })
                    }
                    placeholder="Selecione uma Unidade"
                  />
                </div>
              </div>

              {getPossuiMultiploVenda() == true ?
              
              <div className="row mb-2">
                <div className="col-lg-12">
                  <label>Multiplo de Venda</label>
                  <NumberFormat
                    className={`form-control ${lib.isNullOrEmptyObject(
                      errors[formFields.quantidadeMultiploVenda.key]
                    )
                      ? ""
                      : "is-invalid"
                      }`}
                    value={produto.quantidadeMultiploVenda ?? ""}
                    decimalSeparator={false}
                    decimalScale={0}
                    allowLeadingZeros={true}
                    onValueChange={({ floatValue }) =>
                      updateProduto({ quantidadeMultiploVenda: floatValue })
                    }
                  />
                </div>
              </div>
              
              : null}

              <div className="row mb-2">
                <div className="col-lg-12">
                  <label>Unidades por Caixa</label>
                  <NumberFormat
                    className={`form-control ${lib.isNullOrEmptyObject(
                      errors[formFields.quantidadePorCaixa.key]
                    )
                      ? ""
                      : "is-invalid"
                      }`}
                    value={produto.quantidadePorCaixa ?? ""}
                    decimalSeparator={false}
                    decimalScale={0}
                    allowLeadingZeros={true}
                    onValueChange={({ floatValue }) =>
                      updateProduto({ quantidadePorCaixa: floatValue })
                    }
                  />
                </div>
              </div>
              {/*<div className="row">
                <div className="col-lg-12">
                  <label>Peso Unitario em Kg</label>
                  <NumberFormat
                    className={`form-control ${lib.isNullOrEmptyObject(
                      errors[formFields.pesoUnitarioKG.key]
                    )
                      ? ""
                      : "is-invalid"
                      }`}
                    value={produto.pesoUnitarioKG ?? ""}
                    decimalSeparator={","}
                    suffix={" Kg"}
                    allowLeadingZeros={true}
                    onValueChange={({ floatValue }) =>
                      updateProduto({ pesoUnitarioKG: floatValue })
                    }
                  />
                  <div className="observacao-campo">
                    (Total em gramas:{" "}
                    {produto.pesoUnitarioKG ? produto.pesoUnitarioKG * 1000 : 0}
                    )
                  </div>
                </div>
                  </div>*/}
            </div>

            <div className="col-lg-6 d-flex mb-2">
              <InputImageProduto
                onChange={(file) => updateProduto({ file: file })}
                removeFile={() => updateProduto({ file: undefined })}
                file={produto.file}
                urlImagemAtual={produto.urlImagem}
              />
            </div>
          </div>

          {
            entity?.produtoId > 0
              ? null
              : (
                <>
                  <FormGroupHeader text="VARIANTES" />

                  <div>
                    <button type="button" className="btn btn-link px-0"
                      onClick={() => {
                        variantes.push({ nome: '', valores: [], novoValor: '' });
                        setVariantes([...variantes])
                      }}
                    >Adicionar variante</button>

                    {
                      variantes.map((v, index) =>
                        <React.Fragment key={index}>
                          <div className="row">
                            <div className="col-lg-3">
                              <label className="d-block">Nome</label>
                              <input type="text" className="form-control" value={v.nome} onChange={(e) => {
                                v.nome = e.target.value;
                                setVariantes([...variantes]);
                              }} />
                            </div>

                            <div className="col-lg-8">
                              <label className="d-block">Valores
                                <div className="observacao-campo d-inline-block ms-2">
                                  (Para adicionar um novo valor pressione Enter)
                                </div>
                              </label>
                              {
                                v.valores.map((val, index) =>
                                  <span className="bg-secondary text-white border border-secondary rounded p-1 px-2 me-2 mb-2 d-inline-block">
                                    {val}
                                    <button type="button" className="btn-close btn-close-white ms-2" onClick={() => {
                                      v.valores.splice(index, 1);
                                      setVariantes([...variantes])
                                    }}></button>
                                  </span>
                                )
                              }

                              <input type="text" className="form-control w-auto d-inline-block" value={v.novoValor}
                                onChange={(e) => {
                                  v.novoValor = e.target.value;
                                  setVariantes([...variantes]);
                                }}
                                onKeyDown={(e) => {
                                  if ((e.key === 'Enter' || e.key === 'Tab') && v.novoValor.length > 0) {
                                    v.valores.push(v.novoValor);
                                    v.novoValor = '';
                                    setVariantes([...variantes]);
                                  }
                                }}
                              />
                            </div>

                            <div className="col-lg-1">
                              <button type="button" className="btn btn-danger" onClick={() => {
                                variantes.splice(index, 1);
                                setVariantes([...variantes])
                              }}>
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                          </div>

                          <hr />
                        </React.Fragment>)
                    }

                    {
                      variantes?.length > 0 && variantes.findIndex(v => v.valores?.length > 0) !== -1
                        ? (
                          <div>
                            {
                              produtoVariantes.map((pv, index) => <label key={index} className="d-block">{pv.toUpperCase()}</label>)
                            }
                          </div>
                        )
                        : null
                    }


                  </div>
                </>
              )
          }

          <FormGroupHeader text="UNIDADES DE NEGOCIAÇÃO" />
          <div className="observacao-campo">
            Selecione as unidades de medida que podem ser usadas em suas negociações e preencha o valor unitário do produto para cada unidade.
            <br></br>
            Por exemplo: Vendo caixa de leite de 1 litro por unidade, posso fazer a negociação em unidades e em litros, então selecione litro e preencha 1 L no campo valor.
            <br></br>
            <br></br>
          </div>
          <div className="row mb-2">
            <div className="col-lg-12">
              <table className="table table-cadastro">
                <thead>
                  <tr>
                    <th></th>
                    <th>Unidade de Medida</th>
                    <th>Valor por Unidade</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (unidadesNaoBasicas || []).map(unidade => {
                      let errorKey = `unidadeId_${unidade.unidadeMedidaId}`;
                      let invalid = errors && !lib.isNullOrEmptyObject(errors[errorKey]);

                      return (
                        <React.Fragment key={unidade.unidadeMedidaId}>
                          <tr className="border-bottom-0">
                            <td rowSpan={2}><input
                              className="form-check-input"
                              type="checkbox"
                              id={"unidadeselecionada" + unidade.unidadeMedidaId}
                              checked={unidade.selecionada}
                              value={true}
                              onChange={(e) => {
                                unidade.selecionada = e.target.checked;
                                setUnidadesNaoBasicas([...unidadesNaoBasicas]);
                              }}
                            /></td>
                            <td rowSpan={2}>{unidade.nome}</td>
                            <td className="border-bottom-0">
                              <NumberFormat
                                className={invalid ? "form-control is-invalid" : "form-control"}
                                value={unidade.valor}
                                decimalSeparator={","}
                                suffix={" " + unidade.sigla}
                                allowLeadingZeros={true}
                                onValueChange={({ floatValue }) => {
                                  if (floatValue > 0) {
                                    unidade.selecionada = true;
                                  }
                                  else {
                                    unidade.selecionada = false;
                                  }

                                  unidade.valor = floatValue ?? 0;
                                  setUnidadesNaoBasicas([...unidadesNaoBasicas]);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            {
                              invalid
                                ? (
                                  <td colSpan={2}>
                                    <Errors errors={errors} keyName={errorKey} style={{ marginTop: "-15px" }} />
                                  </td>
                                )
                                : null
                            }
                          </tr>
                        </React.Fragment>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>

          {
            userData?.cadastraTabelaPreco &&
            <TabelaPrecos produto={produto} updateProduto={updateProduto} errors={errors} />
          }

        </form>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          SALVAR
        </button>
      </div>
    </ModalCadastro>
  );
}
