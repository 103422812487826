import React from "react";
import { useQueryClient } from "react-query";
import ModalCadastro from "../_base/modal-cadastro";

import PedidoService from "../../services/pedido-service";
import ResumoPedidoAvaliacao from "../resumo-pedido-avaliacao";

// import "./styles.scss";

export default function ModalResumoPedidoAvaliacao(props) {
  const { model, showModal, setShowModal, onClose } = props;

  const queryClient = useQueryClient();

  async function aprovar() {
    window.showLoader();
    //setErrors({});
    let response = await PedidoService.aprovarAsync(model.pedidoRepresentadaId);
    if (response.data.errors) {
      //setErrors(response.data.errors);
    } else {
      await queryClient.invalidateQueries(["pedidos"]);
      setShowModal(false);
    }
    window.hideLoader();
  }

  async function cancelar() {
    window.showLoader();
    //setErrors({});
    let response = await PedidoService.cancelarAsync(
      model.pedidoRepresentadaId
    );
    if (response.data.errors) {
      //setErrors(response.data.errors);
    } else {
      await queryClient.invalidateQueries(["pedidos"]);
      setShowModal(false);
    }
    window.hideLoader();
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="RESUMO DO PEDIDO"
      onClose={onClose}
    >
      <ResumoPedidoAvaliacao
        model={model}
        aprovar={aprovar}
        cancelar={cancelar}
      ></ResumoPedidoAvaliacao>

      {props.children}
    </ModalCadastro>
  );
}
