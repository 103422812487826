import React from "react";
import { useNavigate } from "react-router-dom";
import ModalCadastro from "../_base/modal-cadastro";
import ChamadoService from "../../services/chamado-service";
import Select from "react-select";
import lib from "../../helpers/lib";
import Errors from "../_base/errors";

export default function ModalCadastroChamado(props) {
  const { showModal, setShowModal, onClose, entity } = props;
  const [errors, setErrors] = React.useState({});
  const [formFields, setFormFields] = React.useState({
    chamadoTipoId: {
      key: "chamadoTipoId",
      required: true,
      validate: () =>
        props.chamadoTipos.findIndex(
          (item) => item.value === chamado.chamadoTipoId
        ) !== -1,
    },
    mensagem: {
      key: "mensagem",
      required: true,
      validate: () =>
        chamado.mensagem &&
        chamado.mensagem != "" &&
        chamado.mensagem.length > 5,
    },
  });
  const emptyChamado = {
    chamadoId: 0,
    chamadoTipoId: 0,
    mensagem: "",
  };
  const [chamado, setChamado] = React.useState({ ...emptyChamado });

  function updateChamado(data) {
    setChamado({ ...chamado, ...data });
  }

  async function saveAsync() {
    window.showLoader();

    if (!validateForm()) {
      window.hideLoader();
      return;
    }

    setErrors({});

    let response = await ChamadoService.addChamadoAsync(chamado);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseChamado = response.data.data;

      setFormFields({
        chamadoTipoId: {
          key: "chamadoTipoId",
          required: true,
          validate: () =>
            props.chamadoTipos.findIndex(
              (item) => item.value === chamado.chamadoTipoId
            ) !== -1,
        },
        mensagem: {
          key: "mensagem",
          required: true,
          validate: () =>
            chamado.mensagem &&
            chamado.mensagem != "" &&
            chamado.mensagem.length > 5,
        },
      });

      setChamado({ ...emptyChamado });

      //window.location.reload();
      props.onChamadoCriado();
      setShowModal(false);
      if (onClose) onClose(responseChamado);
    }

    setErrors(response.data.errors);

    window.hideLoader();
  }

  function validateForm() {
    let errors = {};

    for (let prop in formFields) {
      let field = formFields[prop];

      if (formFields[field.key].validate && formFields[field.key].validate())
        continue;
      else if (
        !field.required ||
        chamado[field.key]?.length ||
        chamado[field.key] > 0
      )
        continue;

      errors[field.key] = [];
    }

    if (lib.isNullOrEmptyObject(errors)) return true;

    setErrors(errors);

    return false;
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="CADASTRAR NOVO CHAMADO"
    >
      <div className="modal-body">
        <div className="row justify-content-center">
          <div className="col-12">
            <label>Motivo</label>
            <Select
              className={`${
                formFields == undefined ||
                lib.isNullOrEmptyObject(errors[formFields?.chamadoTipoId?.key])
                  ? ""
                  : "is-invalid"
              }`}
              options={props.chamadoTipos}
              value={
                props.chamadoTipos.find(
                  (x) => x.value == chamado.chamadoTipoId
                ) || null
              }
              onChange={(selectedValue) =>
                updateChamado({ chamadoTipoId: selectedValue?.value })
              }
              classNamePrefix="select-cadastro"
              isClearable={true}
              placeholder="Selecione um Motivo"
              noOptionsMessage={() => "Sem opções"}
            />
          </div>
          <div className="col-lg-12">Descreva o problema:</div>
          <div className="col-lg-12">
            <textarea
              rows={4}
              className={`form-control ${
                lib.isNullOrEmptyObject(errors[formFields?.mensagem?.key])
                  ? ""
                  : "is-invalid"
              }`}
              value={chamado.mensagem}
              onChange={(e) => {
                updateChamado({
                  mensagem: e.target.value,
                });
              }}
            />
            <Errors errors={errors} keyName={formFields.mensagem.key} />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          Criar
        </button>
      </div>
    </ModalCadastro>
  );
}
