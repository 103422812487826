import React from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import NumberFormat from "react-number-format";

import RelatorioService from "../../services/relatorio-service";
import RepresentadaService from "../../services/representada-service";
import RedeService from "../../services/rede-service";
import lib from "../../helpers/lib";

const pdfMake = require("pdfmake/build/pdfmake");
const pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

export default function RelatorioMetasVolume() {
  const data = new Date();

  const [redes, setRedes] = React.useState();
  const [representadas, setRepresentadas] = React.useState();
  const [filter, setFilter] = React.useState({
    representadaId: "",
    redeId: "",
    ano: data.getFullYear(),
    mes: data.getMonth() + 1,
  });

  const [relatorioModel, setRelatorioModel] = React.useState({});

  React.useEffect(() => {
    getSelectListRepresentada();
    getSelectListRede();
    getRelatorio();
  }, []);

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAllAsync();
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  async function getSelectListRede() {
    const result = await RedeService.getSelectListAllAsync();
    if (result.data) setRedes(result.data.data);
  }

  async function getRelatorio() {
    window.showLoader();
    let response = await RelatorioService.getRelatorioMetasVolumeAsync(filter);
    window.hideLoader();

    setRelatorioModel(response?.data?.data);
  }

  async function exportarExcel() {
    window.showLoader();
    let result = await RelatorioService.getRelatorioMetasVolumeEmBytesAsync(filter);

    if (result && result.data?.data) {
      let fileName = `relatorio_metas_${lib.getCurrentDateFilename()}.xlsx`;
      lib.downloadFile(result.data.data, fileName);
    } 
      
    window.hideLoader();
  }

  async function downloadExtratoPdf() {
    window.showLoader();
    
    let result = await RelatorioService.getRelatorioMetasVolumeEmPdfAsync(
      filter
    );

    if (result && result.data) {
      let fileName = `relatorio_metas_${lib.getCurrentDateFilename()}.pdf`;
      lib.downloadFile(result.data.data, fileName)

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  
  return (
    <div className="container relatorio">
      <div className="row">
        {/* Rede ---------------------------------------------------------------------- */}
        <div className="col-lg-3" disabled>
          <label>Rede</label>
          <Select
            className="select-form-control"
            isClearable
            clearValue={() => {
              setFilter({ ...filter, redeId: null });
            }}
            placeholder="Selecione..."
            options={redes}
            value={redes?.find(
              (x) => x.value === filter.redeId
            )}
            onChange={(selectedValue) => {
              setFilter({ ...filter, redeId: selectedValue?.value });
            }}
          />
        </div>

        {/* Representada ---------------------------------------------------------------------- */}
        <div className="col-lg-3" disabled>
          <label>Representada</label>
          <Select
            className="select-form-control"
            isClearable
            clearValue={() => {
              setFilter({ ...filter, representadaId: null });
            }}
            placeholder="Selecione..."
            options={representadas}
            value={representadas?.find(
              (x) => x.value === filter.representadaId
            )}
            onChange={(selectedValue) => {
              setFilter({ ...filter, representadaId: selectedValue?.value });
            }}
          />
        </div>

        <div className="col-lg-3">
          <label>Mês</label>
          <Select
            className="select-form-control"
            value={lib.selectListMeses?.find((item) => item.value === filter.mes)}
            options={lib.selectListMeses}
            isClearable={false}
            onChange={(item) => { setFilter({ ...filter, mes: item.value }); }}
          />
        </div>
        <div className="col-lg-3">
          <label>Ano</label>
          <Select
            className="select-form-control"
            value={lib.selectListAnos?.find((item) => item.value === filter.ano)}
            options={lib.selectListAnos}
            isClearable={false}
            onChange={(item) => { setFilter({ ...filter, ano: item.value }); }}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-12 text-center">
          {/* Botão Filtrar --------------------------------------------------------------------- */}
          <button
            type="button"
            style={{ marginTop: "20px", width: "300px" }}
            className="btn btn-secondary btn-sm btn-action"
            onClick={getRelatorio}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>

          <button
            type="button"
            style={{ marginTop: "20px", width: "300px", marginLeft: "20px" }}
            className="btn btn-secondary btn-sm btn-action"
            onClick={exportarExcel}
          >
            <FontAwesomeIcon icon={faFileExcel} /> Exportar Excel
          </button>

          <button
            type="button"
            style={{ marginTop: "20px", width: "300px", marginLeft: "20px" }}
            className="btn btn-secondary btn-sm btn-action"
            onClick={downloadExtratoPdf}
          >
            <FontAwesomeIcon icon={faFilePdf} /> Exportar PDF
          </button>
        </div>
      </div>

      <div id="relatorio-metas" className="mt-3">
        {
          relatorioModel?.metaRepresentadas?.length > 0
            ? (
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th colSpan={2}>Meta</th>
                      <th colSpan={2}>Enviado</th>
                      <th colSpan={2}>Faturado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(relatorioModel?.metaRepresentadas || []).map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.representada}
                        </td>
                        <td width="12%">
                          <NumberDecimal
                            value={item.metaValor}
                            
                          />
                        </td>
                        <td width="12%">
                          <NumberDecimal
                            value={item.valor}
                            
                          />
                        </td>
                        <td width="12%">
                          <NumberPorcentagem
                            value={item.porcentagemEnviado}
                            
                          />
                        </td>
                        <td width="12%">
                          <NumberDecimal
                            value={item.valorFaturado}
                            
                          />
                        </td>
                        <td width="12%">
                          <NumberPorcentagem
                            value={item.porcentagemFaturado}
                            
                          />
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td className="text-end">
                        TOTAL
                      </td>
                      <td width="12%">
                        <NumberDecimal
                          value={relatorioModel.totalMetaRepresentadas.metaValor}
                          
                        />
                      </td>
                      <td width="12%">
                        <NumberDecimal
                          value={relatorioModel.totalMetaRepresentadas.valor}
                          
                        />
                      </td>
                      <td width="12%">
                        <NumberPorcentagem
                          value={relatorioModel.totalMetaRepresentadas.porcentagemEnviado}
                          
                        />
                      </td>
                      <td width="12%">
                        <NumberDecimal
                          value={relatorioModel.totalMetaRepresentadas.valorFaturado}
                          
                        />
                      </td>
                      <td width="12%">
                        <NumberPorcentagem
                          value={relatorioModel.totalMetaRepresentadas.porcentagemFaturado}
                          
                        />
                      </td>
                    </tr>

                  </tbody>
                </Table>
              </div>
            )
            : null
        }

        {
          relatorioModel?.metaRedes?.length > 0
            ? (
              <div className="table-responsive mt-5">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th colSpan={3}>Meta</th>
                      <th colSpan={2}>Enviado</th>
                      <th colSpan={3}>Faturado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(relatorioModel?.metaRedes || []).map((item, index) => (
                      <React.Fragment key={index}>
                        <tr onClick={() => { item.showLojas = !item.showLojas; setRelatorioModel({ ...relatorioModel }) }}>
                          <td width="20%">
                            {item.rede}
                          </td>
                          <td width="20%">
                            {item.representada}
                          </td>
                          <td width="12%">
                            <NumberDecimal
                              value={item.metaValor}
                              
                            />
                          </td>
                          <td width="12%">
                            <NumberDecimal
                              value={item.valor}
                              
                            />
                          </td>
                          <td width="12%">
                            <NumberPorcentagem
                              value={item.porcentagemEnviado}
                              
                            />
                          </td>
                          <td width="12%">
                            <NumberDecimal
                              value={item.valorFaturado}
                              
                            />
                          </td>
                          <td width="10%">
                            <NumberPorcentagem
                              value={item.porcentagemFaturado}
                              
                            />
                          </td>
                          <td width="2%" className="px-0 text-center">
                            {
                              item.metaLojas?.length > 0
                                ? !item.showLojas
                                  ? <button className="btn btn-link p-0 text-decoration-none">+</button>
                                  : <button className="btn btn-link p-0 text-decoration-none">-</button>
                                : null
                            }

                          </td>
                        </tr>

                        {
                          item.showLojas
                            ? (item.metaLojas || []).map((metaLoja, index) => (
                              <tr key={index}>
                                <td colSpan={2} className="ps-4">
                                  {metaLoja.loja}
                                </td>
                                <td width="12%">
                                  <NumberDecimal
                                    value={metaLoja.metaValor}
                                    
                                  />
                                </td>
                                <td width="12%">
                                  <NumberDecimal
                                    value={metaLoja.valor}
                                    
                                  />
                                </td>
                                <td width="12%">
                                  <NumberPorcentagem
                                    value={metaLoja.porcentagemEnviado}
                                    
                                  />
                                </td>
                                <td width="12%">
                                  <NumberDecimal
                                    value={metaLoja.valorFaturado}
                                    
                                  />
                                </td>
                                <td width="10%">
                                  <NumberPorcentagem
                                    value={metaLoja.porcentagemFaturado}
                                    
                                  />
                                </td>
                                <td width="2%"></td>
                              </tr>
                            ))

                            : null
                        }
                      </React.Fragment>
                    ))}

                    <tr>
                      <td width="40%" colSpan={2} className="text-end">
                        TOTAL
                      </td>
                      <td width="12%">
                        <NumberDecimal
                          value={relatorioModel.totalMetaRedes.metaValor}
                          
                        />
                      </td>
                      <td width="12%">
                        <NumberDecimal
                          value={relatorioModel.totalMetaRedes.valor}
                          
                        />
                      </td>
                      <td width="12%">
                        <NumberPorcentagem
                          value={relatorioModel.totalMetaRedes.porcentagemEnviado}
                          
                        />
                      </td>
                      <td width="12%">
                        <NumberDecimal
                          value={relatorioModel.totalMetaRedes.valorFaturado}
                          
                        />
                      </td>
                      <td width="12%" colSpan={2}>
                        <NumberPorcentagem
                          value={relatorioModel.totalMetaRedes.porcentagemFaturado}
                          
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )
            : null
        }

        {
          relatorioModel?.metaProdutoCategorias?.length > 0
            ? (
              <div className="table-responsive mt-5">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th colSpan={3}>Meta</th>
                      <th colSpan={2}>Enviado</th>
                      <th colSpan={3}>Faturado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(relatorioModel?.metaProdutoCategorias || []).map((item, index) => (
                      <React.Fragment key={index}>
                        <tr onClick={() => { item.showProdutos = !item.showProdutos; setRelatorioModel({ ...relatorioModel }) }}>
                          <td width="20%">
                            {item.produtoCategoria}
                          </td>
                          <td width="20%">
                            {item.representada}
                          </td>
                          <td width="12%">
                            <NumberDecimal
                              value={item.metaValor}
                              
                            />
                          </td>
                          <td width="12%">
                            <NumberDecimal
                              value={item.valor}
                              
                            />
                          </td>
                          <td width="12%">
                            <NumberPorcentagem
                              value={item.porcentagemEnviado}
                              
                            />
                          </td>
                          <td width="12%">
                            <NumberDecimal
                              value={item.valorFaturado}
                              
                            />
                          </td>
                          <td width="10%">
                            <NumberPorcentagem
                              value={item.porcentagemFaturado}
                              
                            />
                          </td>
                          <td width="2%" className="px-0 text-center">
                            {
                              item.metaProdutos?.length > 0
                                ? !item.showProdutos
                                  ? <button className="btn btn-link p-0 text-decoration-none">+</button>
                                  : <button className="btn btn-link p-0 text-decoration-none">-</button>
                                : null
                            }

                          </td>
                        </tr>

                        {
                          item.showProdutos
                            ? (item.metaProdutos || []).map((metaProduto, index) => (
                              <tr key={index}>
                                <td colSpan={2} className="ps-4">
                                  {metaProduto.produto}
                                </td>
                                <td width="12%">
                                  <NumberDecimal
                                    value={metaProduto.metaValor}
                                    
                                  />
                                </td>
                                <td width="12%">
                                  <NumberDecimal
                                    value={metaProduto.valor}
                                    
                                  />
                                </td>
                                <td width="12%">
                                  <NumberPorcentagem
                                    value={metaProduto.porcentagemEnviado}
                                    
                                  />
                                </td>
                                <td width="12%">
                                  <NumberDecimal
                                    value={metaProduto.valorFaturado}
                                    
                                  />
                                </td>
                                <td width="10%">
                                  <NumberDecimal value={metaProduto.porcentagemFaturado} />
                                </td>
                                <td width="2%"></td>
                              </tr>
                            ))

                            : null
                        }
                      </React.Fragment>
                    ))}

                    <tr>
                      <td width="40%" colSpan={2} className="text-end">
                        TOTAL
                      </td>
                      <td width="12%">
                        <NumberDecimal value={relatorioModel.totalMetaProdutoCategorias.metaValor} />
                      </td>
                      <td width="12%">
                        <NumberDecimal value={relatorioModel.totalMetaProdutoCategorias.valor} />
                      </td>
                      <td width="12%">
                        <NumberPorcentagem value={relatorioModel.totalMetaProdutoCategorias.porcentagemEnviado} />
                      </td>
                      <td width="12%">
                        <NumberDecimal value={relatorioModel.totalMetaProdutoCategorias.valorFaturado} />
                      </td>
                      <td width="12%" colSpan={2}>
                        <NumberPorcentagem value={relatorioModel.totalMetaProdutoCategorias.porcentagemFaturado} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )
            : null
        }
      </div>
    </div>
  );
}


const NumberDecimal = ({ value }) => {
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={""}
      decimalSeparator={","}
      prefix={""}
      suffix=" KG"
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
}

const NumberPorcentagem = ({ value }) => {
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={""}
      decimalSeparator={","}
      prefix={""}
      suffix={"%"}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
}
