import React from 'react'

import './styles.scss'

const PageTitle = ({ className, title }) => {
	return (
		<div className={`mb-4`}>
			<div className={`page-title ${className}`}>{title}</div>
		</div>
	)
}

export default PageTitle
