import React from "react";
import { LoggedUserContext } from "../../contexts/logged-user-context";
import ModalCadastro from "../_base/modal-cadastro";
import Representante from "./_representante";
import Distribuidor from "./_distribuidor";
import { CadastroLojaProvider } from "./_context";

export default function ModalCadastroLoja({
  showModal,
  setShowModal,
  onClose,
  lojaId,
  leitura = false,
}) {
  const [userData, _] = React.useContext(LoggedUserContext);
  function closeModal(refresh) {
    setShowModal(false);

    onClose(refresh);
  }

  return (
    <CadastroLojaProvider>
      <ModalCadastro
        showModal={showModal}
        setShowModal={setShowModal}
        headerText={
          userData?.cadastraClienteDistribuidor === true && lojaId?.length > 0
            ? "LOJA"
            : "CADASTRAR NOVA LOJA"
        }
      >
        {userData?.cadastraClienteDistribuidor === true ? (
          <Distribuidor lojaId={lojaId} closeModal={closeModal} />
        ) : (
          <Representante lojaId={lojaId} closeModal={closeModal} />
        )}
      </ModalCadastro>
    </CadastroLojaProvider>
  );
}
