import React from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faFilter,
  faPlus,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../../components/page-title/index";
import ModalCadastroLoja from "../../components/modal-cadastro-loja";
import ModalConfirmacao from "../../components/modal-confirmacao";
import TablePagination from "../../components/table-pagination";
import Layout from "../../components/layout/base/index";

import LojaService from "../../services/loja-service";
import RedeService from "../../services/rede-service";

import lib from "../../helpers/lib";

import "./styles.scss";
import { LoggedUserContext } from "../../contexts/logged-user-context";

export default function Cliente() {
  const [redes, setRedes] = React.useState();
  const [redeSelecionada, setRedeSelecionada] = React.useState();
  const [loja, setLoja] = React.useState({});
  const [lojaId, setLojaId] = React.useState("");
  const [userData, _] = React.useContext(LoggedUserContext);

  const [filter, setFilter] = React.useState({
    cnpj: "",
    nomeFantasia: "",
    situacao: "A",
  });

  const [pageData, setPageData] = React.useState({});

  const [showModal, setShowModal] = React.useState(false);
  const [showModalConfirmacao, setShowModalConfirmacao] = React.useState(false);

  React.useEffect(() => {
    getSelectListRede();
    filterLojas();
  }, []);

  async function filterLojas(page) {
    window.showLoader();

    let response = await LojaService.listPagedAsync({
      ...filter,
      pageNumber: page,
    });

    setPageData(response.data.data);

    window.hideLoader();
  }

  async function getExtratoLojas() {
    window.showLoader();

    let response = await LojaService.getExtratoLojas(filter);

    if (response && response.data) {
      let fileName = `extrato_lojas_${lib.getCurrentDateFilename()}.xlsx`;
      lib.downloadFile(response.data.data, fileName);
    }

    window.hideLoader();
  }

  function add() {
    setShowModal(true);
    setLojaId("");
  }

  function update(loja) {
    setShowModal(true);
    setLojaId(loja.lojaId);
  }

  function onCloseModal(refresh) {
    setLojaId("");

    if (refresh) filterLojas();
  }

  async function changeStatus(loja) {
    setLoja(loja);
    setShowModalConfirmacao(true);
  }

  async function updateStatus(loja) {
    window.showLoader();

    let response = await LojaService.updateStatusAsync(loja);
    if (response.status === lib.statusCode.ok) await filterLojas();

    window.hideLoader();
  }

  async function getSelectListRede() {
    let redes = await RedeService.getSelectList();
    if (redes.data) setRedes(redes.data.data); //([...[{}], ...redes.data.data]);
  }

  return (
    <Layout>
      <PageTitle title="Cadastro de Loja" />

      {/* Botao Nova Loja --------------------------------------------------------------------- */}
      <div className="row mb-4">
        <div className="col-lg-10">
          <button type="button" className="btn btn-secondary" onClick={add}>
            + Nova Loja
          </button>
        </div>

        <div className="col-lg-2">
          <button type="button" className="btn btn-secondary w-100" onClick={() => getExtratoLojas()}>Exportar</button>
        </div>
      </div>

      <div className="row mb-4 filter-bar3">
        {/* CNPJ ------------------------------------------------------------------------------ */}
        <div className="col-lg-2">
          <label>CNPJ</label>
          <input
            type="text"
            className="form-control"
            value={filter.cnpj}
            onChange={(e) => setFilter({ ...filter, cnpj: e.target.value })}
          />
        </div>

        {/* Nome Fantasia ---------------------------------------------------------------------- */}
        <div className="col-lg-3">
          <label>Nome Fantasia</label>
          <input
            type="text"
            className="form-control"
            value={filter.nomeFantasia}
            onChange={(e) =>
              setFilter({ ...filter, nomeFantasia: e.target.value })
            }
          />
        </div>

        {/* Rede ------------------------------------------------------------------------------ */}
        <div className="col-lg-3">
          <label>Rede</label>
          <Select
            styles={customControlStyles}
            isClearable
            clearValue={() => {
              setRedeSelecionada({});
            }}
            placeholder="Selecione..."
            options={redes}
            value={redes?.find((x) => x.value === redeSelecionada)}
            onChange={(selectedValue) => {
              setRedeSelecionada(selectedValue?.nome ?? {});
              setFilter({ ...filter, rede: selectedValue?.nome });
            }}
          />
        </div>

        {/* Situacao -------------------------------------------------------------------------- */}
        <div className="col-lg-2">
          <label>Situação</label>
          <Select
            styles={customControlStyles}
            options={lib.selectListSituacao}
            value={lib.selectListSituacao.find(
              (x) => x.value === filter.situacao
            )}
            onChange={(selectedValue) => {
              setFilter({ ...filter, situacao: selectedValue.value });
            }}
          />
        </div>

        {/* Botao Filtrar --------------------------------------------------------------------- */}
        <div className="col-lg-2 d-flex align-items-center">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => filterLojas()}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>
        </div>
      </div>

      {pageData.data && (
        <>
          <div className="table-responsive">
            <table className="table table-cadastro">
              <thead>
                <tr>
                  <th>Nome Fantasia</th>
                  <th>CNPJ</th>
                  <th>Rede</th>
                  <th>Situação</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {(pageData?.data || []).map((loja, index) => (
                  <tr key={index}>
                    <td>{loja.nomeFantasia}</td>
                    <td>{loja.cnpj}</td>
                    <td>{loja.rede}</td>
                    <td>{loja.ativo ? "Ativo" : "Inativo"}</td>
                    <td>
                      {userData?.cadastraClienteDistribuidor == false && (
                        <>
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm m-1"
                            title="Editar"
                            onClick={() => update(loja)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>

                          <button
                            type="button"
                            className="btn btn-danger btn-sm m-1"
                            title={loja.ativo ? "Inativar" : "Reativar"}
                            onClick={() => changeStatus(loja)}
                          >
                            <FontAwesomeIcon
                              icon={loja.ativo ? faTrash : faRecycle}
                            />
                          </button>
                        </>
                      )}
                      {userData?.cadastraClienteDistribuidor === true && (
                        <>
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm m-1"
                            title="Visualizar"
                            onClick={() => update(loja)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <TablePagination
            pageData={pageData}
            onChangePage={(page) => filterLojas(page)}
          />
        </>
      )}

      <ModalCadastroLoja
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={onCloseModal}
        lojaId={lojaId}
      />

      {/* Modal Confirmacao Ativar/Inativar Loja -------------------------------- */}
      <ModalConfirmacao
        showModal={showModalConfirmacao}
        setShowModal={setShowModalConfirmacao}
        onClose={(result) => {
          if (result === "yes") {
            updateStatus(loja);
          }
        }}
      >
        <p>
          Deseja mesmo <b>{loja?.ativo ? "inativar" : "reativar"}</b> a loja "
          {loja?.nomeFantasia}"?
        </p>
      </ModalConfirmacao>
    </Layout>
  );
}
