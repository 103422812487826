import React from "react";
import Select from "react-select";
import { faTrash, faAdd } from "@fortawesome/free-solid-svg-icons";
import InputCnpj from "../_base/input-cnpj";
import InputDocumento from '../_base/input-documento'
import InputCep from "../_base/input-cep";
import InputTelefone from "../_base/input-telefone";
import InputPorcentagem from "../_base/input-porcentagem";
import AutoCompleteCreatable from "../_base/autocomplete-creatable";
import FormGroupHeader from "../_base/form-group-header";
import Errors from "../_base/errors";

import GeneralService from "../../services/general-service";
import LojaService from "../../services/loja-service";

import lib from "../../helpers/lib";

import { CadastroLojaContext } from "./_context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Distribuidor({ lojaId, closeModal }) {
  const {
    errors,
    setErrors,
    validateForm,
    validateCnpj,
    getRedes,
    redes,
    setRedes,
    getCanais,
    canais,
    setCanais,
    getRegionais,
    regionais,
    setRegionais,
    getEndereco,
  } = React.useContext(CadastroLojaContext);

  const emptyLoja = React.useMemo(
    () => ({
      codigoInterno: "",
      cnpj: "",
      razaoSocial: "",
      nomeFantasia: "",
      ie: "",
      redeId: "",
      rede: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      telefone: "",
      condicaoPagamento: "",
      optanteSimples: null,
      possuiContrato: null,
      porcentagemContrato: 0,
      contribuinteIcms: null,
      cadastroSuframa: null,
      codigoSuframa: "",
      emailNfe: "",
      contatos: [
        {
          nome: "",
          setor: "",
          telefone: "",
          email: "",
        },
      ],
      referenciasBancarias: [
        // {
        //   banco: "",
        //   contato: "",
        //   agencia: "",
        //   conta: ""
        // }
      ],
      referenciasComerciais: [
        // {
        //   razaoSocial: "",
        //   contato: "",
        //   telefone: "",
        //   email: ""
        // }
      ],
      representante: "",
      coordenador: "",
      transportadora: "",
      promotor: "",
      categoriaCliente: "",
      canalId: "",
      canal: "",
      regionalId: "",
      regional: "",
    }),
    []
  );

  const [loja, setLoja] = React.useState({ ...emptyLoja });
  const [tabelaPrecos, setTabelaPrecos] = React.useState([]);

  const formFields = React.useMemo(
    () => ({
      codigoInterno: {
        key: "codigoInterno",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      cnpj: { key: "cnpj", required: true, disabled: loja.lojaId?.length > 0 },
      razaoSocial: {
        key: "razaoSocial",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      nomeFantasia: {
        key: "nomeFantasia",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      cep: { key: "cep", required: true, disabled: loja.lojaId?.length > 0 },
      logradouro: {
        key: "logradouro",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      numero: {
        key: "numero",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      bairro: {
        key: "bairro",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      cidade: {
        key: "cidade",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      estado: {
        key: "estado",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      telefone: {
        key: "telefone",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      emailNfe: {
        key: "emailNfe",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      condicaoPagamento: {
        key: "condicaoPagamento",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      representante: {
        key: "representante",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      coordenador: {
        key: "coordenador",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      categoriaCliente: {
        key: "categoriaCliente",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      ie: { key: "ie", required: false, disabled: loja.lojaId?.length > 0 },
      complemento: {
        key: "complemento",
        required: false,
        disabled: loja.lojaId?.length > 0,
      },
      transportadora: {
        key: "transportadora",
        required: false,
        disabled: loja.lojaId?.length > 0,
      },
      promotor: {
        key: "promotor",
        required: false,
        disabled: loja.lojaId?.length > 0,
      },

      redeId: {
        key: "redeId",
        validate: () => loja.redeId === 0 || loja.redeId > 0,
        disabled: loja.lojaId?.length > 0,
      },
      canalId: {
        key: "canalId",
        validate: () => loja.canalId === 0 || loja.canalId > 0,
        disabled: loja.lojaId?.length > 0,
      },
      regionalId: {
        key: "regionalId",
        validate: () => loja.regionalId === 0 || loja.regionalId > 0,
        disabled: loja.lojaId?.length > 0,
      },

      optanteSimples: {
        key: "optanteSimples",
        disabled: loja.lojaId?.length > 0,
        validate: () =>
          loja.optanteSimples === true || loja.optanteSimples === false,
      },
      contribuinteIcms: {
        key: "contribuinteIcms",
        disabled: loja.lojaId?.length > 0,
        validate: () =>
          loja.contribuinteIcms === true || loja.contribuinteIcms === false,
      },
      cadastroSuframa: {
        key: "cadastroSuframa",
        disabled: loja.lojaId?.length > 0,
        validate: () =>
          loja.cadastroSuframa === true || loja.cadastroSuframa === false,
      },
      possuiContrato: {
        key: "possuiContrato",
        disabled: loja.lojaId?.length > 0,
        validate: () =>
          loja.possuiContrato === true || loja.possuiContrato === false,
      },

      codigoSuframa: {
        key: "codigoSuframa",
        disabled: loja.lojaId?.length > 0,
        validate: () =>
          !loja.cadastroSuframa ||
          (loja.cadastroSuframa === true && loja.codigoSuframa?.length > 0),
      },
      porcentagemContrato: {
        key: "porcentagemContrato",
        disabled: loja.lojaId?.length > 0,
        validate: () =>
          !loja.possuiContrato ||
          (loja.possuiContrato && loja.porcentagemContrato > 0),
      },

      contatos: {
        key: "contatos",
        required: false,
        disabled: loja.lojaId?.length > 0,
        validate: () => {
          if (loja.contatos?.length > 0) {
            for (let contato of loja.contatos) {
              if (
                !(contato.setor?.length > 0) ||
                !(contato.nome?.length > 0) ||
                !(contato.telefone?.length > 0) ||
                !(contato.email?.length > 0)
              ) {
                contato.invalid = true;
              } else {
                contato.invalid = false;
              }
            }

            //se nao encontrar nenhum invalido retornar true
            return loja.contatos.findIndex((c) => c.invalid) === -1;
          }

          //se nao tiver pelo menos um contato retornar false
          return false;
        },
      },

      referenciasBancarias: {
        key: "referenciasBancarias",
        disabled: loja.lojaId?.length > 0,
        required: false,
        validate: () => {
          if (loja.referenciasBancarias?.length > 0) {
            for (let referencia of loja.referenciasBancarias) {
              if (
                !(referencia.banco?.length > 0) ||
                !(referencia.contato?.length > 0) ||
                !(referencia.agencia?.length > 0) ||
                !(referencia.conta?.length > 0)
              ) {
                referencia.invalid = true;
              } else {
                referencia.invalid = false;
              }
            }

            //se nao encontrar nenhum invalido retornar true
            return loja.referenciasBancarias.findIndex((c) => c.invalid) === -1;
          }

          //se nao tiver pelo menos um referencia retornar true (nao é obrigatorio)
          return true;
        },
      },

      referenciasComerciais: {
        key: "referenciasComerciais",
        required: false,
        disabled: loja.lojaId?.length > 0,
        validate: () => {
          if (loja.referenciasComerciais?.length > 0) {
            for (let referencia of loja.referenciasComerciais) {
              if (
                !(referencia.razaoSocial?.length > 0) ||
                !(referencia.contato?.length > 0) ||
                !(referencia.telefone?.length > 0) ||
                !(referencia.email?.length > 0)
              ) {
                referencia.invalid = true;
              } else {
                referencia.invalid = false;
              }
            }

            //se nao encontrar nenhum invalido retornar true
            return (
              loja.referenciasComerciais.findIndex((c) => c.invalid) === -1
            );
          }

          //se nao tiver pelo menos um referencia retornar true (nao é obrigatorio)
          return true;
        },
      },
    }),
    [loja]
  );

  React.useEffect(() => {
    setErrors({});
    getLoja();
  }, [lojaId]);

  React.useEffect(() => {
    getTabelaPrecos();
  }, []);

  async function getLoja() {
    if (lojaId?.length > 0) {
      window.showLoader();
      let response = await LojaService.getCadastroDistribuidorAsync(lojaId);
      window.hideLoader();

      setLoja(response?.data?.data || {});
    } else {
      setLoja({ ...emptyLoja });
    }
  }

  function updateLoja(data) {
    setLoja({ ...loja, ...data });
  }

  async function saveAsync() {
    window.showLoader();

    if (!validateForm(loja, formFields)) {
      window.hideLoader();
      return;
    }

    setErrors({});

    let response = lib.isNullOrEmptyObject(loja.lojaId)
      ? await LojaService.addDistribuidorAsync(loja)
      : await LojaService.updateDistribuidorAsync(loja);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseLoja = response.data.data;

      setLoja({ ...emptyLoja });

      closeModal(true);

      //reload redes
      getRedes();

      //reload canais
      getCanais();

      //reload regionais
      getRegionais();

      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  async function getCnpjDados() {
    delete errors.cnpj;
    setErrors({ ...errors });

    let isValid = await validateCnpj(loja);
    if (!isValid) return;

    window.showLoader();

    let response = await GeneralService.getCNPJAsync(loja.cnpj);

    if (response.status === lib.statusCode.ok) {
      let data = {
        razaoSocial: response.data.data.razaoSocial ?? "",
        nomeFantasia: response.data.data.nomeFantasia ?? "",
        cep: lib.formatCep(response.data.data.cep) ?? "",
        logradouro: response.data.data.logradouro ?? "",
        numero: response.data.data.numero ?? "",
        complemento: response.data.data.complemento ?? "",
        bairro: response.data.data.bairro ?? "",
        cidade: response.data.data.cidade ?? "",
        estado: response.data.data.uf ?? "",
        optanteSimples: response.data.data.optanteSimples ?? false,
      };

      updateLoja(data);
    }

    window.hideLoader();
  }

  async function getTabelaPrecos() {
    let response = await LojaService.listTabelaPrecosDistribuidorAsync();
    let selectList = response.data.data.map((x) => ({
      label: x.nome,
      value: x.tabelaPrecoId,
    }));

    setTabelaPrecos(selectList);
  }

  return (
    <>
      <div className="modal-body">
        <form>
          <section name="Info">
            <div className="row mb-2">
              <div className="col-lg-12">
                <label className="form-label">Código Interno</label>
                <input
                  type="text"
                  disabled={formFields.codigoInterno.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(
                      errors[formFields.codigoInterno.key]
                    )
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.codigoInterno || ""}
                  onChange={
                    (e) => updateLoja({ codigoInterno: e.target.value })

                    // if (value.length === 18)
                    //   if (loja.codigoInterno !== value) {
                    //     loja.codigoInterno = value;
                    //     //getCnpjDados(loja);
                    //   }
                  }
                />

                <Errors
                  errors={errors}
                  keyName={formFields.codigoInterno.key}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12">
                <label className="form-label">CNPJ/CPF</label>
                <InputDocumento
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.cnpj.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  disabled={formFields.cnpj.disabled}
                  value={loja.cnpj || ""}
                  onChange={(value) => {
                    updateLoja({ cnpj: value });

                    if (value.length === 18)
                      if (loja.cnpj !== value) {
                        loja.cnpj = value;
                        getCnpjDados(loja);
                      }
                  }}
                />

                <Errors errors={errors} keyName={formFields.cnpj.key} />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12">
                <label className="form-label">Razão Social</label>
                <input
                  type="text"
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.razaoSocial.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  disabled={formFields.razaoSocial.disabled}
                  value={loja.razaoSocial || ""}
                  onChange={(e) => updateLoja({ razaoSocial: e.target.value })}
                />

                <Errors errors={errors} keyName={formFields.razaoSocial.key} />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-6">
                <label className="form-label">Nome Fantasia</label>
                <input
                  type="text"
                  disabled={formFields.nomeFantasia.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.nomeFantasia.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.nomeFantasia || ""}
                  onChange={(e) => updateLoja({ nomeFantasia: e.target.value })}
                />
              </div>

              <div className="col-lg-6">
                <label className="form-label">Inscrição Estadual</label>
                <input
                  type="text"
                  disabled={formFields.ie.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.ie.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.ie || ""}
                  onChange={(e) => updateLoja({ ie: e.target.value })}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-6">
                <label className="form-label">Rede</label>
                <AutoCompleteCreatable
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.redeId.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  keyLabel="nome"
                  disabled={formFields.redeId.disabled}
                  keyValue="redeId"
                  matchSorterKeys={["nome"]}
                  onSelectedItemChange={({ selectedItem }) => {
                    updateLoja({
                      redeId: selectedItem.redeId,
                      rede: selectedItem.nome,
                    });

                    if (selectedItem.redeId === 0) {
                      let novaRede = { ...selectedItem };
                      setRedes([
                        ...redes.filter((x) => x.redeId !== novaRede.redeId),
                        { ...novaRede },
                      ]);
                    }
                  }}
                  selectedItem={
                    redes.find((x) => x.redeId === loja.redeId) || null
                  }
                  items={redes}
                  itemToString={(item) => (item ? item.nome : "")}
                />
              </div>

              <div className="col-lg-6">
                <label className="form-label">Canal/Segmento</label>
                <AutoCompleteCreatable
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.canalId.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  keyLabel="nome"
                  disabled={formFields.canalId.disabled}
                  keyValue="canalId"
                  matchSorterKeys={["nome"]}
                  onSelectedItemChange={({ selectedItem }) => {
                    updateLoja({
                      canalId: selectedItem.canalId,
                      canal: selectedItem.nome,
                    });

                    if (selectedItem.canalId === 0) {
                      let novoCanal = { ...selectedItem };
                      setCanais([
                        ...canais.filter(
                          (x) => x.canalId !== novoCanal.canalId
                        ),
                        { ...novoCanal },
                      ]);
                    }
                  }}
                  selectedItem={
                    canais.find((x) => x.canalId === loja.canalId) || null
                  }
                  items={canais}
                  itemToString={(item) => (item ? item.nome : "")}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-3">
                <label className="form-label">CEP</label>
                <InputCep
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.cep.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.cep || ""}
                  disabled={formFields.cep.disabled}
                  onChange={(value) => {
                    updateLoja({ cep: value });

                    loja.cep = value;
                    if (loja.cep.length === 9)
                      (async () => {
                        await getEndereco(loja);
                        setLoja({ ...loja });
                      })();
                  }}
                />

                <Errors errors={errors} keyName={formFields.cep.key} />
              </div>

              <div className="col-lg-9">
                <label className="form-label">Logradouro</label>
                <input
                  type="text"
                  disabled={formFields.logradouro.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.logradouro.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.logradouro || ""}
                  onChange={(e) => updateLoja({ logradouro: e.target.value })}
                />
                <Errors errors={errors} keyName={formFields.logradouro.key} />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-6">
                <label className="form-label">Número</label>
                <input
                  type="text"
                  disabled={formFields.numero.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.numero.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.numero || ""}
                  onChange={(e) => updateLoja({ numero: e.target.value })}
                />
                <Errors errors={errors} keyName={formFields.numero.key} />
              </div>

              <div className="col-lg-6">
                <label className="form-label">Complemento</label>
                <input
                  type="text"
                  disabled={formFields.complemento.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.complemento.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.complemento || ""}
                  onChange={(e) => updateLoja({ complemento: e.target.value })}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12">
                <label className="form-label">Bairro</label>
                <input
                  type="text"
                  disabled={formFields.bairro.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.bairro.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.bairro || ""}
                  onChange={(e) => updateLoja({ bairro: e.target.value })}
                />
                <Errors errors={errors} keyName={formFields.bairro.key} />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-lg-4">
                <label className="form-label">Cidade</label>
                <input
                  type="text"
                  disabled={formFields.cidade.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.cidade.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.cidade || ""}
                  onChange={(e) => updateLoja({ cidade: e.target.value })}
                />
                <Errors errors={errors} keyName={formFields.cidade.key} />
              </div>

              <div className="col-lg-4">
                <label className="form-label">UF</label>
                <input
                  type="text"
                  disabled={formFields.estado.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.estado.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.estado || ""}
                  onChange={(e) => updateLoja({ estado: e.target.value })}
                />
                <Errors errors={errors} keyName={formFields.estado.key} />
              </div>

              <div className="col-lg-4">
                <label className="form-label">Telefone</label>
                <InputTelefone
                  type="text"
                  disabled={formFields.telefone.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.telefone.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.telefone || ""}
                  onChange={(value) => updateLoja({ telefone: value })}
                />
                <Errors errors={errors} keyName={formFields.telefone.key} />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12">
                <label
                  className={`form-label ${
                    lib.isNullOrEmptyObject(
                      errors[formFields.optanteSimples.key]
                    )
                      ? ""
                      : "text-danger"
                  }`}
                >
                  Optante pelo Simples
                </label>
                <div className="mb-3">
                  <label className="me-3">
                    <input
                      type="radio"
                      disabled={formFields.optanteSimples.disabled}
                      checked={loja.optanteSimples === false}
                      onChange={() => {
                        updateLoja({ optanteSimples: false });
                      }}
                    />{" "}
                    Não
                  </label>

                  <label>
                    <input
                      type="radio"
                      disabled={formFields.optanteSimples.disabled}
                      checked={loja.optanteSimples === true}
                      onChange={() => {
                        updateLoja({ optanteSimples: true });
                      }}
                    />{" "}
                    Sim
                  </label>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12">
                <label
                  className={`form-label ${
                    lib.isNullOrEmptyObject(
                      errors[formFields.contribuinteIcms.key]
                    )
                      ? ""
                      : "text-danger"
                  }`}
                >
                  Contribuinte de ICMS
                </label>
                <div className="mb-3">
                  <label className="me-3">
                    <input
                      type="radio"
                      disabled={formFields.contribuinteIcms.disabled}
                      checked={loja.contribuinteIcms === false}
                      onChange={() => {
                        updateLoja({ contribuinteIcms: false });
                      }}
                    />{" "}
                    Não
                  </label>

                  <label>
                    <input
                      type="radio"
                      disabled={formFields.contribuinteIcms.disabled}
                      checked={loja.contribuinteIcms === true}
                      onChange={() => {
                        updateLoja({ contribuinteIcms: true });
                      }}
                    />{" "}
                    Sim
                  </label>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12 d-flex">
                <div className="me-5">
                  <label
                    className={`form-label ${
                      lib.isNullOrEmptyObject(
                        errors[formFields.cadastroSuframa.key]
                      )
                        ? ""
                        : "text-danger"
                    }`}
                  >
                    Cadastro no SUFRAMA
                  </label>
                  <div className="mb-3">
                    <label className="me-3">
                      <input
                        type="radio"
                        disabled={formFields.cadastroSuframa.disabled}
                        checked={loja.cadastroSuframa === false}
                        onChange={() => {
                          updateLoja({ cadastroSuframa: false });
                        }}
                      />{" "}
                      Não
                    </label>

                    <label>
                      <input
                        type="radio"
                        disabled={formFields.cadastroSuframa.disabled}
                        checked={loja.cadastroSuframa === true}
                        onChange={() => {
                          updateLoja({ cadastroSuframa: true });
                        }}
                      />{" "}
                      Sim
                    </label>
                  </div>
                </div>

                {loja.cadastroSuframa ? (
                  <div className="flex-grow-1">
                    <label className="form-label">Código Suframa</label>
                    <input
                      type="text"
                      disabled={formFields.codigoSuframa.disabled}
                      className={`form-control ${
                        lib.isNullOrEmptyObject(
                          errors[formFields.codigoSuframa.key]
                        )
                          ? ""
                          : "is-invalid"
                      }`}
                      value={loja.codigoSuframa || ""}
                      onChange={(e) =>
                        updateLoja({ codigoSuframa: e.target.value })
                      }
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12">
                <label className="form-label">Condição de Pagamento</label>
                <input
                  type="text"
                  disabled={formFields.condicaoPagamento.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(
                      errors[formFields.condicaoPagamento.key]
                    )
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.condicaoPagamento || ""}
                  onChange={(e) =>
                    updateLoja({ condicaoPagamento: e.target.value })
                  }
                />
                <Errors
                  errors={errors}
                  keyName={formFields.condicaoPagamento.key}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12 d-flex">
                <div className="me-5">
                  <label
                    className={`form-label ${
                      lib.isNullOrEmptyObject(
                        errors[formFields.possuiContrato.key]
                      )
                        ? ""
                        : "text-danger"
                    }`}
                  >
                    Possui Contrato
                  </label>
                  <div className="mb-3">
                    <label className="me-3">
                      <input
                        type="radio"
                        disabled={formFields.possuiContrato.disabled}
                        checked={loja.possuiContrato === false}
                        onChange={() => {
                          updateLoja({ possuiContrato: false });
                        }}
                      />{" "}
                      Não
                    </label>

                    <label>
                      <input
                        type="radio"
                        disabled={formFields.possuiContrato.disabled}
                        checked={loja.possuiContrato === true}
                        onChange={() => {
                          updateLoja({ possuiContrato: true });
                        }}
                      />{" "}
                      Sim
                    </label>
                  </div>
                </div>

                {loja.possuiContrato ? (
                  <div className="flex-grow-1">
                    <label className="form-label">
                      Porcentagem do Contrato
                    </label>
                    <InputPorcentagem
                      type="text"
                      disabled={formFields.porcentagemContrato.disabled}
                      className={`form-control ${
                        lib.isNullOrEmptyObject(
                          errors[formFields.porcentagemContrato.key]
                        )
                          ? ""
                          : "is-invalid"
                      }`}
                      value={loja.porcentagemContrato || ""}
                      onChange={(value) =>
                        updateLoja({ porcentagemContrato: value })
                      }
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12">
                <label className="form-label">E-mail Nfe</label>
                <input
                  type="text"
                  disabled={formFields.emailNfe.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.emailNfe.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.emailNfe || ""}
                  onChange={(e) => updateLoja({ emailNfe: e.target.value })}
                />
                <Errors errors={errors} keyName={formFields.emailNfe.key} />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12">
                <label className="form-label">Categoria do Cliente</label>
                <input
                  type="text"
                  disabled={formFields.categoriaCliente.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(
                      errors[formFields.categoriaCliente.key]
                    )
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.categoriaCliente || ""}
                  onChange={(e) =>
                    updateLoja({ categoriaCliente: e.target.value })
                  }
                />
                <Errors
                  errors={errors}
                  keyName={formFields.categoriaCliente.key}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-12">
                <label className="form-label">Tabela de Preços</label>
                <Select
                  className="select-form-control"
                  isDisabled={loja.lojaId?.length > 0}
                  isClearable={true}
                  options={tabelaPrecos}
                  value={tabelaPrecos.find(
                    (x) => x.value === loja.tabelaPrecoId
                  )}
                  onChange={(selectedValue) => {
                    updateLoja({ tabelaPrecoId: selectedValue?.value });
                  }}
                />
              </div>
            </div>
          </section>

          <section name="Contatos">
            <FormGroupHeader className="mb-0" text="DADOS DE CONTATO" />

            {!(loja.contatos?.length > 0) ? (
              <div className={`invalid-feedback-error mb-2`}>
                Pelo menos um contato deve ser preenchido
              </div>
            ) : null}

            <Errors errors={errors} keyName={formFields.contatos.key} />

            {loja?.contatos?.map((contato, index) => (
              <React.Fragment key={index}>
                <div className="row mb-4">
                  <div className="col-lg-11">
                    <div className="row mb-2">
                      <div className="col-lg-6">
                        <label className="form-label">Nome</label>
                        <input
                          type="text"
                          disabled={formFields.contatos.disabled}
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.contatos.key]
                            ) || contato.nome?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          value={contato.nome || ""}
                          onChange={(e) => {
                            contato.nome = e.target.value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>

                      <div className="col-lg-6">
                        <label className="form-label">Setor</label>
                        <input
                          type="text"
                          disabled={formFields.contatos.disabled}
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.contatos.key]
                            ) || contato.setor?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          value={contato.setor || ""}
                          onChange={(e) => {
                            contato.setor = e.target.value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <label className="form-label">Telefone</label>
                        <InputTelefone
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.contatos.key]
                            ) || contato.telefone?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          disabled={formFields.contatos.disabled}
                          value={contato.telefone || ""}
                          onChange={(value) => {
                            contato.telefone = value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>

                      <div className="col-lg-6">
                        <label className="form-label">E-mail</label>
                        <input
                          type="text"
                          disabled={formFields.contatos.disabled}
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.contatos.key]
                            ) || contato.email?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          value={contato.email || ""}
                          onChange={(e) => {
                            contato.email = e.target.value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {loja.contatos?.length === 1 ? null : (
                    <div className="col-lg-1 d-flex pt-2">
                      <button
                        type="button"
                        className="btn btn-danger m-auto"
                        onClick={() => {
                          loja.contatos.splice(index, 1);
                          setLoja({ ...loja });
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  )}
                </div>
                {index !== loja.contatos.length - 1 ? (
                  <hr style={{ opacity: 0.5 }}></hr>
                ) : null}
              </React.Fragment>
            ))}

            <button
              type="button"
              disabled={formFields.contatos.disabled}
              className="btn btn-sm btn-secondary margin-right"
              onClick={() => {
                loja.contatos.push({
                  nome: "",
                  setor: "",
                  telefone: "",
                  email: "",
                });
                setLoja({ ...loja });
              }}
            >
              <FontAwesomeIcon icon={faAdd} /> Contato
            </button>
          </section>

          <section name="Referencia Bancaria">
            <FormGroupHeader className="mb-0" text="REFERÊNCIA BANCÁRIA" />

            <Errors
              errors={errors}
              keyName={formFields.referenciasBancarias.key}
            />

            {loja?.referenciasBancarias?.map((ref, index) => (
              <React.Fragment key={index}>
                <div className="row mb-4">
                  <div className="col-lg-11">
                    <div className="row mb-2">
                      <div className="col-lg-6">
                        <label className="form-label">Banco</label>
                        <input
                          type="text"
                          disabled={formFields.referenciasBancarias.disabled}
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.referenciasBancarias.key]
                            ) || ref.banco?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          value={ref.banco || ""}
                          onChange={(e) => {
                            ref.banco = e.target.value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>

                      <div className="col-lg-6">
                        <label className="form-label">Contato</label>
                        <input
                          type="text"
                          disabled={formFields.referenciasBancarias.disabled}
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.referenciasBancarias.key]
                            ) || ref.contato?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          value={ref.contato || ""}
                          onChange={(e) => {
                            ref.contato = e.target.value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <label className="form-label">Agência</label>
                        <input
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.referenciasBancarias.key]
                            ) || ref.agencia?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          disabled={formFields.referenciasBancarias.disabled}
                          value={ref.agencia || ""}
                          onChange={(e) => {
                            ref.agencia = e.target.value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>

                      <div className="col-lg-6">
                        <label className="form-label">Conta</label>
                        <input
                          type="text"
                          disabled={formFields.referenciasBancarias.disabled}
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.referenciasBancarias.key]
                            ) || ref.conta?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          value={ref.conta || ""}
                          onChange={(e) => {
                            ref.conta = e.target.value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 d-flex pt-2">
                    <button
                      type="button"
                      disabled={formFields.referenciasBancarias.disabled}
                      className="btn btn-danger m-auto"
                      onClick={() => {
                        loja.referenciasBancarias.splice(index, 1);
                        setLoja({ ...loja });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
                {index !== loja.referenciasBancarias.length - 1 ? (
                  <hr style={{ opacity: 0.5 }}></hr>
                ) : null}
              </React.Fragment>
            ))}
            <button
              type="button"
              disabled={formFields.referenciasBancarias.disabled}
              className="btn btn-sm btn-secondary margin-right"
              onClick={() => {
                loja.referenciasBancarias.push({
                  banco: "",
                  contato: "",
                  agencia: "",
                  conta: "",
                });
                setLoja({ ...loja });
              }}
            >
              <FontAwesomeIcon icon={faAdd} /> Referência Bancária
            </button>
          </section>

          <section name="Referencia Comercial">
            <FormGroupHeader className="mb-0" text="REFERÊNCIA COMERCIAL" />

            <Errors
              errors={errors}
              keyName={formFields.referenciasComerciais.key}
            />
            {loja?.referenciasComerciais?.map((ref, index) => (
              <React.Fragment key={index}>
                <div className="row mb-4">
                  <div className="col-lg-11">
                    <div className="row mb-2">
                      <div className="col-lg-6">
                        <label className="form-label">Razão Social</label>
                        <input
                          type="text"
                          disabled={formFields.referenciasComerciais.disabled}
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.referenciasComerciais.key]
                            ) || ref.razaoSocial?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          value={ref.razaoSocial || ""}
                          onChange={(e) => {
                            ref.razaoSocial = e.target.value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>

                      <div className="col-lg-6">
                        <label className="form-label">Contato</label>
                        <input
                          type="text"
                          disabled={formFields.referenciasComerciais.disabled}
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.referenciasComerciais.key]
                            ) || ref.contato?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          value={ref.contato || ""}
                          onChange={(e) => {
                            ref.contato = e.target.value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <label className="form-label">Telefone</label>
                        <InputTelefone
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.referenciasComerciais.key]
                            ) || ref.telefone?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          disabled={formFields.referenciasComerciais.disabled}
                          value={ref.telefone || ""}
                          onChange={(value) => {
                            ref.telefone = value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>

                      <div className="col-lg-6">
                        <label className="form-label">E-mail</label>
                        <input
                          type="text"
                          disabled={formFields.referenciasComerciais.disabled}
                          className={`form-control ${
                            lib.isNullOrEmptyObject(
                              errors[formFields.referenciasComerciais.key]
                            ) || ref.email?.length
                              ? ""
                              : "is-invalid"
                          }`}
                          value={ref.email || ""}
                          onChange={(e) => {
                            ref.email = e.target.value;
                            setLoja({ ...loja });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 d-flex pt-2">
                    <button
                      type="button"
                      disabled={formFields.referenciasComerciais.disabled}
                      className="btn btn-danger m-auto"
                      onClick={() => {
                        loja.referenciasComerciais.splice(index, 1);
                        setLoja({ ...loja });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
                {index !== loja.referenciasComerciais.length - 1 ? (
                  <hr style={{ opacity: 0.5 }}></hr>
                ) : null}
              </React.Fragment>
            ))}
            <button
              type="button"
              disabled={formFields.referenciasComerciais.disabled}
              className="btn btn-sm btn-secondary margin-right"
              onClick={() => {
                loja.referenciasComerciais.push({
                  razaoSocial: "",
                  contato: "",
                  telefone: "",
                  email: "",
                });
                setLoja({ ...loja });
              }}
            >
              <FontAwesomeIcon icon={faAdd} /> Referência Comercial
            </button>
          </section>

          <section name="Representante">
            <FormGroupHeader
              className="mb-0"
              text="DADOS DO REPRESENTANTE - ÁREA DE VENDAS"
            />

            <div className="row mb-2">
              <div className="col-lg-6">
                <label className="form-label">Representante</label>
                <input
                  type="text"
                  disabled={formFields.representante.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(
                      errors[formFields.representante.key]
                    )
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.representante || ""}
                  onChange={(e) =>
                    updateLoja({ representante: e.target.value })
                  }
                />
                <Errors
                  errors={errors}
                  keyName={formFields.representante.key}
                />
              </div>

              <div className="col-lg-6">
                <label className="form-label">Coordenador</label>
                <input
                  type="text"
                  disabled={formFields.coordenador.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.coordenador.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.coordenador || ""}
                  onChange={(e) => updateLoja({ coordenador: e.target.value })}
                />
                <Errors errors={errors} keyName={formFields.coordenador.key} />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-6">
                <label className="form-label">Transportadora</label>
                <input
                  type="text"
                  disabled={formFields.transportadora.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(
                      errors[formFields.transportadora.key]
                    )
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.transportadora || ""}
                  onChange={(e) =>
                    updateLoja({ transportadora: e.target.value })
                  }
                />
                <Errors
                  errors={errors}
                  keyName={formFields.transportadora.key}
                />
              </div>

              <div className="col-lg-6">
                <label className="form-label">Promotor</label>
                <input
                  type="text"
                  disabled={formFields.promotor.disabled}
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.promotor.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  value={loja.promotor || ""}
                  onChange={(e) => updateLoja({ promotor: e.target.value })}
                />
                <Errors errors={errors} keyName={formFields.promotor.key} />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-lg-6">
                <label className="form-label">Área</label>

                <AutoCompleteCreatable
                  className={`form-control ${
                    lib.isNullOrEmptyObject(errors[formFields.regionalId.key])
                      ? ""
                      : "is-invalid"
                  }`}
                  disabled={formFields.regionalId.disabled}
                  keyLabel="nome"
                  keyValue="regionalId"
                  matchSorterKeys={["nome"]}
                  onSelectedItemChange={({ selectedItem }) => {
                    updateLoja({
                      regionalId: selectedItem.regionalId,
                      regional: selectedItem.nome,
                    });

                    if (selectedItem.regionalId === 0) {
                      let novaRegional = { ...selectedItem };
                      setRegionais([
                        ...regionais.filter(
                          (x) => x.regionalId !== novaRegional.regionalId
                        ),
                        { ...novaRegional },
                      ]);
                    }
                  }}
                  selectedItem={
                    regionais.find((x) => x.regionalId === loja.regionalId) ||
                    null
                  }
                  items={regionais}
                  itemToString={(item) => (item ? item.nome : "")}
                />

                <Errors errors={errors} keyName={formFields.regionalId.key} />
              </div>
            </div>
          </section>
        </form>
      </div>

      <div className="modal-footer">
        {(!lojaId || lojaId?.length == 0) && (
          <button type="button" className="btn btn-primary" onClick={saveAsync}>
            SALVAR
          </button>
        )}
      </div>
    </>
  );
}
