import React from "react";
import { matchSorter } from "match-sorter";
import ModalCadastro from "../_base/modal-cadastro";
import FormGroupHeader from "../_base/form-group-header";
import Errors from "../_base/errors";

import EquipeService from "../../services/equipe-service";

import lib from "../../helpers/lib";

import "./styles.scss";

export default function ModalCarteira(props) {
  const { showModal, setShowModal, onClose, equipeId } = props;

  const emptyCarteira = {
    usuario: {},
    equipe: {},
  };

  const [carteira, setCarteira] = React.useState({ ...emptyCarteira });
  const [carteiraClientes, setCarteiraClientes] = React.useState([]);
  const [carteiraRepresentadas, setCarteiraRepresentadas] = React.useState([]);
  const [filteredClientes, setFilteredClientes] = React.useState([]);
  const [filteredRepresentadas, setFilteredRepresentadas] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    reset();

    getCarteira();
  }, [equipeId]);

  function reset() {
    setErrors({});
    setCarteira({ ...emptyCarteira });
    // setCarteiraClientes([]);
    // setCarteiraRepresentadas([]);
    setFilteredClientes(carteiraClientes);
    setFilteredRepresentadas(carteiraRepresentadas);
  }

  async function getCarteira() {
    if (equipeId > 0) {
      window.showLoader();

      let response = await EquipeService.getCarteiraEquipeAsync(equipeId);
      let carteira = response.data.data;
      setCarteira(carteira);
      setCarteiraClientes(carteira.clientes);
      setCarteiraRepresentadas(carteira.representadas);
      setFilteredClientes(carteira.clientes);
      setFilteredRepresentadas(carteira.representadas);

      window.hideLoader();
    }
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText={`${carteira?.usuario?.nome || ""} - ${
        carteira?.equipe?.nome || ""
      } - CARTEIRA`}
    >
      <div className="modal-body">
        <div className="row carteira">
          <div className="col-lg-6">
            <FormGroupHeader text="CLIENTES" />

            <input
              type="search"
              className="form-control mb-3"
              placeholder="Filtrar"
              onChange={(e) => {
                setFilteredClientes(
                  matchSorter(carteiraClientes || [], e.target.value, {
                    keys: ["nomeFantasia", "razaoSocial", "cnpj"],
                  })
                );
              }}
            />

            {(filteredClientes || []).map((cliente, index) => (
              <div key={index} className="d-block">
                {/* <label className="list-item  mb-3"></label> */}
                <div className="list-item  mb-3">
                  <span className="">
                    {cliente.nomeFantasia}{" "}
                    {cliente.inadimplente ? " - Bloqueado" : ""}
                  </span>
                  <br />
                  <span className="fantasia">
                    {cliente.razaoSocial} - {cliente.cnpj}
                  </span>
                  <br />
                  <span className="cnpj">
                    {cliente.logradouro} - {cliente.bairro} - {cliente.cidade}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            <FormGroupHeader text="REPRESENTADAS" />

            <input
              type="search"
              className="form-control mb-3"
              placeholder="Filtrar"
              onChange={(e) => {
                setFilteredRepresentadas(
                  matchSorter(carteiraRepresentadas || [], e.target.value, {
                    keys: ["nomeFantasia", "razaoSocial", "cnpj"],
                  })
                );
              }}
            />

            {(filteredRepresentadas || []).map((representada, index) => (
              <div key={index} className="d-block">
                {/* <label className="list-item mb-3">
                  {representada.nomeFantasia}
                </label> */}
                <div className="list-item  mb-3">
                  <span className="">{representada.nomeFantasia} </span>
                  <br />
                  <span className="fantasia">
                    {representada.razaoSocial} - {representada.cnpj}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowModal(false)}
        >
          Fechar
        </button>
      </div>
    </ModalCadastro>
  );
}
