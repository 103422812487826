import React, { useState, useEffect } from "react";
import ModalImage from "react-modal-image";
import Select from "react-select";
import NumberFormat from "react-number-format";
import CurrencyInput from "../../components/_base/react-currecy-input-custom/react-currency-input.cjs";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { matchSorter } from "match-sorter";

import { LoggedUserContext } from "../../contexts/logged-user-context";

import PedidoService from "../../services/pedido-service";
import UnidadeService from "../../services/unidade-service";
import PedidoRascunhoService from "../../services/pedido-rascunho-service";

import Layout from "../../components/layout/base/index";
import ModalCadastroLoja from "../../components/modal-cadastro-loja";
import ModalCadastroRepresentada from "../../components/modal-cadastro-representada";
import ModalCadastroProduto from "../../components/modal-cadastro-produto";
import PageTitle from "../../components/page-title/index";
import FormGroupHeader from "../../components/_base/form-group-header/index";
import SelectLoja from "../../components/selects/select-loja/index";
import SelectRepresentada from "../../components/selects/select-representada/index";
import DatePickerCustom from "../../components/_base/DatePicker";
import Errors from "../../components/_base/errors/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBarcode,
	faPlus,
	faCheckCircle,
	faTrash,
	faSearch,
	faStar,
} from "@fortawesome/free-solid-svg-icons";
import SemImagem from "../../assets/imagens/sem-imagem.jpg";

import lib from "../../helpers/lib";

import "./styles.scss";
import ModalConfirmacao from "../../components/modal-confirmacao";
import ModalMessage from "../../components/modal-message";
import ModalPedidoEditaProduto from "../../components/modal-pedido-edita-produto";

const Pedido = () => {
	const [userData, _] = React.useContext(LoggedUserContext);
	const { search } = useLocation();
	const params = useParams();
	const navigate = useNavigate();
	const pedidoRascunhoId = params.id || 0;
	const [lojas, setLojas] = useState([]);
	const [representadas, setRepresentadas] = useState([]);
	const [produtos, setProdutos] = useState({});
	const [textFilter, setTextFilter] = useState("");
	const [unidades, setUnidades] = useState([]);
	const [representadaId, setRepresentadaId] = useState("");
	const [model, setModel] = useState({ pedidoRepresentadas: [] });
	const [valorTotal, setValorTotal] = useState(0.0);
	const [percentualDescontoTotal, setPercentualDescontoTotal] = useState(0.0);
	const [valorReferenciaTotal, setValorReferenciaTotal] = useState(0.0);
	const [descontoAdicionalTotal, setDescontoAdicionalTotal] = useState(0.0);
	const [errors, setErrors] = useState([]);
	const [rascunhoInvalido, setRascunhoInvalido] = useState(false);
	const [pedidoEditarProduto, setPedidoEditarProduto] = useState({});
	const [rascunho, setRascunho] = useState({});
	const [produtosRascunho, setProdutosRascunho] = useState({});
	const [showModalCliente, setShowModalCliente] = useState(false);
	const [showModalRepresentada, setShowModalRepresentada] = useState(false);
	const [showModalProduto, setShowModalProduto] = useState(false);
	const [showModalEditarProduto, setShowModalEditarProduto] = useState(false);
	const [showModalConfirmacao, setShowModalConfirmacao] = React.useState(false);
	const [showModalMessage, setShowModalMessage] = React.useState(false);
	const [showModalMessageError, setShowModalMessageError] = React.useState(false);
	const [pedidoPadrao, setPedidoPadrao] = useState({
		lojaId: 0,
		showButton: false,
	});

	useEffect(() => {
		//let tipo = new URLSearchParams(search).get("t");
		
		(async () => {
			window.showLoader();
			
			await getSelectListLoja();
			await getSelectListRepresentada();

			let unidades = await UnidadeService.getSelectList();
			if (unidades.data) setUnidades(unidades.data.data);

			let rascunho = await PedidoRascunhoService.getAsync(pedidoRascunhoId);

			if (rascunho.data) {
				setRascunho(rascunho.data.data);

				if (rascunho?.data?.errors) {
					setErrors(rascunho?.data?.errors);
					setRascunhoInvalido(true);
					
					window.hideLoader();

				} else if (rascunho?.data?.data?.jsonModel) {
					let modelRascunho = JSON.parse(rascunho.data.data.jsonModel);

					let produtosRascunhoModel = [];

					if (modelRascunho.pedidoRepresentadas && modelRascunho.pedidoRepresentadas.length > 0){
						modelRascunho.pedidoRepresentadas.forEach(function (rep, i) {

							if (rep.pedidoRepresentadaProdutos && rep.pedidoRepresentadaProdutos.length > 0) {
								rep.pedidoRepresentadaProdutos.forEach(function (prod, ind) {
									prod.erro = "";

									produtosRascunhoModel.push({
										produtoId: prod.produtoId,
										valor: prod.valor,
										quantidade: prod.quantidade,
										valorDesconto: prod.valorDesconto,
										porcentagemDesconto: prod.porcentagemDesconto,
										total: prod.total,
										referenciaTotal: prod.referenciaTotal,
									});
								});
							}
							rep.pedidoRepresentadaProdutos = [];
						});

						setProdutosRascunho(produtosRascunhoModel);
					}

					setModel({
						...modelRascunho,
						produtos: [],
					});
					setPercentualDescontoTotal(modelRascunho?.percentualDescontoAdicional ?? 0);
				}
			}

			window.hideLoader();
		})();
	}, [search]);

	useEffect(() => {
		if (!model?.lojaId?.length) {
			if (!produtos?.lojaId?.length) return;

			setProdutos({
				lojaId: "",
				lista: [],
			});

			return;
		}

		if (produtos?.lojaId === model.lojaId) return;

		const getSelectListProduto = async () => {
			window.showLoader();
			let produtos = await PedidoService.listProdutosAsync(model.lojaId);
			window.hideLoader();
			const prods = produtos?.data?.data || [];

			if (prods.length > 0) {
				const destaques = prods.filter((p) => p.destaque == true) ?? [];

				if (destaques.length > 0) {
					const produtosNaoDest = prods.filter((p) => p.destaque == false) ?? [];
					if (produtosNaoDest.length > 0) {
						const ordenados = destaques.concat(produtosNaoDest);

						setProdutos({
							lojaId: model.lojaId,
							lista: ordenados,
						});
						return;
					} else {
						setProdutos({
							lojaId: model.lojaId,
							lista: destaques,
						});
						return;
					}
				}
			}

			setProdutos({
				lojaId: model.lojaId,
				lista: produtos?.data?.data || [],
			});
		};

		getSelectListProduto();
	}, [model]);

	useEffect(() => {
		resetComissao(true);
		setModel({ ...model });
	}, [produtos]);

	useEffect(() => {
		if(produtos?.lista?.length > 0 && produtosRascunho?.length > 0){
			produtosRascunho.forEach(prod => {
				let prodRascunho = produtos?.lista?.find(p => p.produtoId === prod.produtoId);

				if(prodRascunho){
					prodRascunho.quantidade = prod.quantidade ? prod.quantidade : prodRascunho?.quantidade;
					prodRascunho.valor = prod.valor ? prod.valor : prodRascunho.valor;
					prodRascunho.valorDesconto = prod.valorDesconto ?? 0;
					prodRascunho.porcentagemDesconto = prod.porcentagemDesconto ?? 0;
					prodRascunho.total = prod.total ?? 0;
					prodRascunho.referenciaTotal = prod.referenciaTotal ?? 0;

					addProduto(prodRascunho);
				}
			});

			setProdutosRascunho([]);
			calcularValorTotal();
		}
	}, [produtos, produtosRascunho]);

	async function getSelectListLoja() {
		let lojas = await PedidoService.getSelectListLoja();
		if (lojas.data) {
			let lojasResponse =
				lojas.data.data?.map((l) => ({
					...l,
					label: l.label + " " + removeCharactersFromString(l.cnpj),
				})) ?? [];
			setLojas(lojasResponse);
		}
	}

	function removeCharactersFromString(cnpj) {
		if (!cnpj) return "";

		return cnpj.replace(/\D/g, "");
	}

	async function getSelectListRepresentada() {
		let representadas = await PedidoService.getSelectListRepresentada();
		if (representadas.data) setRepresentadas(representadas.data.data);
	}

	useEffect(() => {
		calcularValorTotal();
	}, [model.pedidoRepresentadas]);

	function onChangeInput(event) {
		const { value, name } = event.target;

		setModel({
			...model,
			[name]: value,
		});
	}

	// useEffect(() => {
	//   // setModel({
	//   //   ...model,
	//   //   percentualDescontoAdicional: value,
	//   // });
	//   calcularValorTotal();
	// }, [percentualDescontoTotal]);

	function onChangeRepresentada(value) {
		setRepresentadaId(value);
	}

	function onChangeLoja(value) {
		let loja = lojas.filter((l) => { return l.value === value; })[0];

		if (loja) {
			(async () => {
				window.showLoader();

				let padrao = await PedidoRascunhoService.getPadraoLojaAsync(pedidoRascunhoId, value);

				if (padrao.data && padrao.data.data) {
					const modelPedido = JSON.parse(padrao.data.data.jsonModel);

					let produtosRascunhoModel = [];

					modelPedido.pedidoRepresentadas.forEach(rep => {
						rep.pedidoRepresentadaProdutos.forEach(prod => {
							produtosRascunhoModel.push({
								produtoId: prod.produtoId,
								valor: prod.valor,
							});
						});

						rep.pedidoRepresentadaProdutos = [];
					});

					setProdutosRascunho(produtosRascunhoModel);

					setModel({
						...modelPedido,
						produtos: [],
					});
					
					setPedidoPadrao({
						lojaId: value,
						showButton: true,
					});
				} else {
					setModel({
						...model,
						lojaId: value,
						lojaNomeFantasia: loja.fantasia,
						lojaCNPJ: loja.cnpj,
						inadimplente: loja.inadimplente,
					});
					setPedidoPadrao({
						lojaId: value,
						showButton: false,
					});
				}
				window.hideLoader();
			})();
		} else {
			setModel({
				...model,
				lojaId: "",
				lojaNomeFantasia: "",
				lojaCNPJ: "",
				percentualDescontoAdicional: 0,
				inadimplente: false,
			});
		}
	}

	function onChangeDescontoAdicional(value) {
		if (value < 0) value = 0;
		if (value > 100) value = 100;
		changeDescontoAdicionalNosProdutos(value);
		// setModel({
		//   ...model,
		//   percentualDescontoAdicional: value,
		// });
		setPercentualDescontoTotal(value);
		// precisa verficar no calculo de total como usar esse desconto adiconal do produto...
		// nao deve mais utilizar do input direto
	}

	function changeDescontoAdicionalNosProdutos(valorPorcentagemDescAdicionalParam) {
		let newPedidoRepresentadas = [...model.pedidoRepresentadas];

		for (let i = 0; i < newPedidoRepresentadas.length; i++) {
			if (
				model.pedidoRepresentadas[i].pedidoRepresentadaProdutos &&
				model.pedidoRepresentadas[i].pedidoRepresentadaProdutos.length > 0
			) {
				for (
					let j = 0;
					j < model.pedidoRepresentadas[i].pedidoRepresentadaProdutos.length;
					j++
				) {
					let prod = model.pedidoRepresentadas[i].pedidoRepresentadaProdutos[j];
					if (prod.quantidade && prod.valor && valorPorcentagemDescAdicionalParam > 0) {
						const valorDescontoAdicional = PedidoService.calcularPorcentagem(
							valorPorcentagemDescAdicionalParam,
							prod.valor
						);
						const valorTotalDescontoAdicional =
							valorDescontoAdicional * prod.quantidade;

						prod = {
							...prod,
							porcentagemDescontoAdicional: valorPorcentagemDescAdicionalParam,
							valorDescontoAdicional: valorDescontoAdicional,
							totalDescontoAdicional: valorTotalDescontoAdicional,
						};
					} else {
						prod = {
							...prod,
							porcentagemDescontoAdicional: 0.0,
							valorDescontoAdicional: valorPorcentagemDescAdicionalParam,
							totalDescontoAdicional: 0,
						};
					}
					newPedidoRepresentadas[i].pedidoRepresentadaProdutos[j] = prod;
				}
			}
		}

		setModel({
			...model,
			pedidoRepresentadas: newPedidoRepresentadas,
			percentualDescontoAdicional: valorPorcentagemDescAdicionalParam,
		});
	}

	function onChangeProdutoAdicionado(produtoId, representadaId, name, value) {
		const indexRepresentada = model.pedidoRepresentadas.findIndex((pr) => {
			return pr.representadaId === representadaId;
		});

		const indexProduto = model.pedidoRepresentadas[
			indexRepresentada
		].pedidoRepresentadaProdutos.findIndex((produto) => {
			return produto.produtoId === produtoId;
		});

		let prod =
			model.pedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[indexProduto];
		prod = { ...prod, [name]: value };

		if (prod.quantidade && prod.valor) {
			const desconto = calcularPorcentagemDesconto(prod.valorReferencia, prod.valor);
			let descontoValor = 0.0;
			if (desconto > 0) {
				descontoValor = prod.valorReferencia - prod.valor;
			}
			let valorTotalDescontoAdicional = 0.0;
			let valorDescontoAdicional = 0.0;
			if (percentualDescontoTotal > 0) {
				valorDescontoAdicional = PedidoService.calcularPorcentagem(
					percentualDescontoTotal,
					prod.valor
				);
				valorTotalDescontoAdicional = valorDescontoAdicional * prod.quantidade;
			}

			prod = {
				...prod,
				total: prod.quantidade * prod.valor,
				referenciaTotal: prod.quantidade * prod.valorReferencia,
				porcentagemDesconto: desconto,
				valorDesconto: descontoValor,
				valorDescontoAdicional: valorDescontoAdicional,
				totalDescontoAdicional: valorTotalDescontoAdicional,
			};
		} else {
			prod = {
				...prod,
				total: 0.0,
				referenciaTotal: 0.0,
				porcentagemDesconto: 0.0,
				valorDesconto: 0.0,
			};
		}

		let newPedidoRepresentadas = [...model.pedidoRepresentadas];
		newPedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[indexProduto] = prod;

		setModel({ ...model, pedidoRepresentadas: newPedidoRepresentadas });

		calcularValorTotal();
	}

	function updateProduto(
		produtoId,
		representadaId,
		valor,
		quantidade,
		valorReferencia,
		novaUnidadeMedidaId,
		precoMinimo,
		precoMaximo
	) {
		const indexRepresentada = model.pedidoRepresentadas.findIndex((pr) => {
			return pr.representadaId === representadaId;
		});

		const indexProduto = model.pedidoRepresentadas[
			indexRepresentada
		].pedidoRepresentadaProdutos.findIndex((produto) => {
			return produto.produtoId === produtoId;
		});

		let prod =
			model.pedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[indexProduto];

		let valorCondicionado = 0.0;
		let valorReferenciaCondicionado = 0.0;
		let valorMinimoCondicionado = 0.0;
		let valorMaximoCondicionado = 0.0;
		if (
			novaUnidadeMedidaId == prod.unidadeMedidaIdOriginal &&
			prod.valorReferenciaOriginal != undefined &&
			prod.valorReferenciaOriginal > 0
		) {
			valorCondicionado = prod.valorReferenciaOriginal;
			valorReferenciaCondicionado = prod.valorReferenciaOriginal;
			valorMinimoCondicionado = prod.precoMinimoOriginal;
			valorMaximoCondicionado = prod.precoMaximoOriginal;
		} else {
			valorCondicionado = valor;
			valorReferenciaCondicionado = valorReferencia;
			valorMinimoCondicionado = precoMinimo;
			valorMaximoCondicionado = precoMaximo;
		}

		prod = {
			...prod,
			valor: valorCondicionado,
			//valorBruto: valorReferenciaCondicionado,
			quantidade: quantidade,
			total: quantidade * valorCondicionado,
			valorReferencia: valorReferenciaCondicionado,
			referenciaTotal: quantidade * valorReferenciaCondicionado,
			precoMinimo: valorMinimoCondicionado,
			precoMaximo: valorMaximoCondicionado,
		};

		const desconto = calcularPorcentagemDesconto(prod.valorReferencia, prod.valor);
		let descontoValor = 0.0;
		if (desconto > 0) {
			descontoValor = prod.valorReferencia - prod.valor;
		}

		prod = {
			...prod,
			porcentagemDesconto: desconto,
			valorDesconto: descontoValor,
		};

		let newPedidoRepresentadas = [...model.pedidoRepresentadas];
		newPedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[indexProduto] = prod;

		setModel({ ...model, pedidoRepresentadas: newPedidoRepresentadas });

		calcularValorTotal();
	}

	function calcularPorcentagemDesconto(valorReferencia, valorAtual) {
		if (valorReferencia == 0 || valorAtual == 0) return 0;
		const percentual = (100 * valorAtual) / valorReferencia;
		if (percentual > 100) return 0;
		const resultPerct = 100 - percentual;
		return Math.round(resultPerct * 100) / 100;
	}

	async function onChangeUnidade(produtoId, representadaId, name, value, text) {
		onChangeProdutoAdicionado(produtoId, representadaId, name, value);

		const indexRepresentada = model.pedidoRepresentadas.findIndex((pr) => {
			return pr.representadaId === representadaId;
		});

		const indexProduto = model.pedidoRepresentadas[
			indexRepresentada
		].pedidoRepresentadaProdutos.findIndex((produto) => {
			return produto.produtoId === produtoId;
		});

		var unidadeAntiga =
			model.pedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[indexProduto]
				.unidadeMedidaNome;
		model.pedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[
			indexProduto
		].unidadeMedidaNome = text;

		if (unidadeAntiga == text) return;

		let result = await convertUnidade(
			model.pedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[indexProduto],
			unidadeAntiga,
			text
		);
		if (result && result.data && result.data.data) {
			updateProduto(
				result.data.data.produtoId,
				representadaId,
				result.data.data.valor,
				result.data.data.quantidade,
				result.data.data.valorReferencia,
				value,
				result.data.data.valorMinimo,
				result.data.data.valorMaximo
			);

			// model.pedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[
			//   indexProduto
			// ].valor = result.data.data.valor;

			// if (result.data.data.quantidade && result.data.data.quantidade > 1) {
			//   model.pedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[
			//     indexProduto
			//   ].quantidade = result.data.data.quantidade;
			//   model.pedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[
			//     indexProduto
			//   ].total = result.data.data.valor * result.data.data.quantidade;
			// }
		}
	}

	async function onCloseModalCliente(loja) {
		window.showLoader();

		await getSelectListLoja();

		if (loja) {
			setModel({
				...model,
				lojaId: loja.lojaId,
				lojaNomeFantasia: loja.fantasia,
				lojaCNPJ: loja.cnpj,
			});
		}

		window.hideLoader();
	}

	async function onCloseModalRepresentada(representada) {
		window.showLoader();

		await getSelectListRepresentada();

		if (representada) {
			setRepresentadaId(representada.representadaId);
		}

		window.hideLoader();
	}

	async function onCloseModalProduto(loja) {
		window.showLoader();

		await getSelectListProduto();

		window.hideLoader();
	}

	async function onCloseModalEditarProduto() {
		setShowModalEditarProduto(false);
		setPedidoEditarProduto({});
	}

	function checkProdutoAdicionado(produtoId, representadaId) {
		const pedidoRepresentada = model.pedidoRepresentadas.filter((pr) => {
			return pr.representadaId === representadaId;
		})[0];

		if (pedidoRepresentada) {
			return pedidoRepresentada.pedidoRepresentadaProdutos.some((prod) => {
				return prod.produtoId === produtoId;
			});
		}

		return false;
	}

	function addProduto(produto) {
		let selectListUnidades = produto.produtoUnidadesMedidas.map((unidade) => ({
			label: unidade.unidadeMedida,
			value: unidade.unidadeMedidaId,
		}));

		const tabelaPreco = (produto.tabelaPrecos || []).filter((tabela) => {
			return tabela.representada === produto.representadaId;
		});

		const precoMinimo = Number(tabelaPreco[0]?.precoMinimo);
		const precoMaximo = Number(tabelaPreco[0]?.precoMaximo);

		const newProduto = {
			produtoId: produto.produtoId,
			representadaId: produto.representadaId,
			codigoNaRepresentada: produto.codigoNaRepresentada,
			nome: produto.nome,
			urlImagem: produto.urlImagem,
			unidadeMedidaId: produto.unidadeId,
			unidadeMedidaNome: produto.unidade,
			unidadeMedidaIdOriginal: produto.unidadeId,
			quantidade: produto.quantidade ? produto.quantidade : "",
			total: produto.total ?? 0.0,
			referenciaTotal: produto.referenciaTotal ?? 0.0,
			valorBruto: precoMaximo && precoMaximo > 0 ? precoMaximo : precoMinimo,
			valorReferenciaOriginal: precoMaximo && precoMaximo > 0 ? precoMaximo : precoMinimo,
			valorReferencia: precoMaximo && precoMaximo > 0 ? precoMaximo : precoMinimo,
			valor: produto.valor ? produto.valor : (precoMaximo && precoMaximo > 0 ? precoMaximo : precoMinimo),
			porcentagemDesconto: produto.porcentagemDesconto ?? 0.0,
			valorDesconto: produto.valorDesconto ?? 0.0,
			porcentagemDescontoAdicional: percentualDescontoTotal,
			valorDescontoAdicional: 0.0,
			totalDescontoAdicional: 0.0,
			comissaoPadrao: produto.comissao,
			unidades: selectListUnidades,
			tabelaPrecoId: tabelaPreco[0]?.tabelaPrecoId ?? 0,
			precoMaximoOriginal: precoMaximo,
			precoMinimoOriginal: precoMinimo,
			precoMaximo: precoMaximo,
			precoMinimo: precoMinimo,
			erro: "",
		};

		let newPedidoRepresentadas = [...model.pedidoRepresentadas];

		const representada = representadas.filter((r) => {
			return r.value === produto.representadaId;
		})[0];

		const indexRepresentada = newPedidoRepresentadas.findIndex((pr) => {
			return pr.representadaId === produto.representadaId;
		});

		if (indexRepresentada >= 0) {
			let teste = newPedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos;

			// if (teste.findIndex((x) => x.produtoId == newProduto.produtoId) != -1)
			// 	return;
			
			newPedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos.unshift(newProduto);
		} else {
			let newRepresentada = {
				representadaId: representada.value,
				nomeFantasia: representada.fantasia,
				cnpj: representada.cnpj,
				pedidoRepresentadaProdutos: [],
			};

			newRepresentada.pedidoRepresentadaProdutos.push(newProduto);
			newPedidoRepresentadas.unshift(newRepresentada);
		}

		setModel({ ...model, pedidoRepresentadas: newPedidoRepresentadas });
	}

	function addProdutoMobile(newProduto) {
		let newPedidoRepresentadas = [...model.pedidoRepresentadas];

		const representada = representadas.filter((r) => {
			return r.value === newProduto.representadaId;
		})[0];

		const indexRepresentada = newPedidoRepresentadas.findIndex((pr) => {
			return pr.representadaId === newProduto.representadaId;
		});

		if (indexRepresentada >= 0) {
			if (
				newPedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos.findIndex(
					(x) => x.produtoId == newProduto.produtoId
				) != -1
			)
				return;
			newPedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos.unshift(
				newProduto
			);
		} else {
			let newRepresentada = {
				representadaId: representada.value,
				nomeFantasia: representada.fantasia,
				cnpj: representada.cnpj,
				pedidoRepresentadaProdutos: [],
			};

			newRepresentada.pedidoRepresentadaProdutos.push(newProduto);
			newPedidoRepresentadas.unshift(newRepresentada);
		}

		setModel({ ...model, pedidoRepresentadas: newPedidoRepresentadas });

		setPedidoEditarProduto(null);
		setShowModalEditarProduto(false);
	}

	function showModalAddProduto(produto) {
		let selectListUnidades = produto.produtoUnidadesMedidas.map((unidade) => ({
			label: unidade.unidadeMedida,
			value: unidade.unidadeMedidaId,
		}));

		const tabelaPreco = (produto.tabelaPrecos || []).filter((tabela) => {
			return tabela.representada === produto.representadaId;
		});

		const precoMinimo = Number(tabelaPreco[0]?.precoMinimo);
		const precoMaximo = Number(tabelaPreco[0]?.precoMaximo);

		const newProduto = {
			produtoId: produto.produtoId,
			representadaId: produto.representadaId,
			codigoNaRepresentada: produto.codigoNaRepresentada,
			nome: produto.nome,
			urlImagem: produto.urlImagem,
			unidadeMedidaId: produto.unidadeId,
			unidadeMedidaNome: produto.unidade,
			unidadeMedidaIdOriginal: produto.unidadeId,
			quantidade: "",
			total: 0.0,
			referenciaTotal: 0.0,
			valorBruto: precoMaximo && precoMaximo > 0 ? precoMaximo : precoMinimo,
			valorReferenciaOriginal: precoMaximo && precoMaximo > 0 ? precoMaximo : precoMinimo,
			valorReferencia: precoMaximo && precoMaximo > 0 ? precoMaximo : precoMinimo,
			valor: precoMaximo && precoMaximo > 0 ? precoMaximo : precoMinimo,
			porcentagemDesconto: 0.0,
			valorDesconto: 0.0,
			porcentagemDescontoAdicional: percentualDescontoTotal,
			valorDescontoAdicional: 0.0,
			totalDescontoAdicional: 0.0,
			comissaoPadrao: produto.comissao,
			unidades: selectListUnidades,
			tabelaPrecoId: tabelaPreco[0]?.tabelaPrecoId ?? 0,
			precoMaximoOriginal: precoMaximo,
			precoMinimoOriginal: precoMinimo,
			precoMaximo: precoMaximo,
			precoMinimo: precoMinimo,
			erro: "",
		};

		setPedidoEditarProduto(newProduto);
		setShowModalEditarProduto(true);
	}

	function removeProduto(produtoId, representadaId) {
		let newPedidoRepresentadas = [...model.pedidoRepresentadas];

		const indexRepresentada = newPedidoRepresentadas.findIndex((pr) => {
			return pr.representadaId === representadaId;
		});

		newPedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos =
			newPedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos.filter(
				(produto) => {
					return produto.produtoId !== produtoId;
				}
			);

		if (newPedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos.length === 0) {
			newPedidoRepresentadas = newPedidoRepresentadas.filter((r) => {
				return r.representadaId !== representadaId;
			});
		}

		setModel({ ...model, pedidoRepresentadas: newPedidoRepresentadas });
	}

	function calcularValorTotal() {
		let total = 0.0;
		let totalValorReferencia = 0.0;
		let totalDescontoAdicional = 0.0;
		for (let i = 0; i < model.pedidoRepresentadas.length; i++) {
			total += model.pedidoRepresentadas[i].pedidoRepresentadaProdutos.reduce(function (
				totalProduto,
				currentValueProduto
			) {
				return totalProduto + currentValueProduto.total;
			},
			0.0);
			totalValorReferencia += model.pedidoRepresentadas[i].pedidoRepresentadaProdutos.reduce(
				function (totalPrecoRefProd, currentValueProduto) {
					if (currentValueProduto.referenciaTotal > 0) {
						return totalPrecoRefProd + currentValueProduto.referenciaTotal;
					}
					return totalPrecoRefProd + currentValueProduto.total;
				},
				0.0
			);

			totalDescontoAdicional += model.pedidoRepresentadas[
				i
			].pedidoRepresentadaProdutos.reduce(function (
				totalDescAdicionalProd,
				currentValueProduto
			) {
				return totalDescAdicionalProd + currentValueProduto.totalDescontoAdicional;
			},
			0.0);
		}

		// if (totalDescontoAdicional > 0) {
		//   total = total - totalDescontoAdicional;
		// }

		setValorTotal(total);
		setValorReferenciaTotal(totalValorReferencia);
		setDescontoAdicionalTotal(totalDescontoAdicional);
	}

	function resetComissao(keepValue) {
		if (!model?.pedidoRepresentadas?.length || !produtos?.lista?.length) return;

		for (let pr of model.pedidoRepresentadas) {
			if (!pr?.pedidoRepresentadaProdutos?.length) continue;

			for (let prp of pr.pedidoRepresentadaProdutos) {
				if (!keepValue) delete prp.comissaoVariavel;

				let produto = produtos.lista.find((p) => p.produtoId === prp.produtoId);
				if (!produto) continue;

				prp.comissaoPadrao = produto.comissao;
			}
		}
	}

	async function updatePedidoRascunho(modelPedido) {
		let modelUpdate = {
			...rascunho,
			lojaId: modelPedido.lojaId,
			jsonModel: JSON.stringify(modelPedido),
		};

		await PedidoRascunhoService.updateAsync(modelUpdate);
	}

	async function continuar() {
		window.showLoader();
		model.pedidoRascunhoId = rascunho.pedidoRascunhoId;

		var result = await PedidoService.validateAsync(model);

		window.testeResult = result;

		if (result.data) {
			//setModel(result.data.data); //preciso filtrar o erro de cada produto e tirar essa substituição de model
			getProdutoErros(result.data.data);

			await updatePedidoRascunho(model);

			if (result.data.errors) {
				setErrors(result.data.errors);
				window.hideLoader();
			} else {
				navigate("/pedido/revisao/" + pedidoRascunhoId);
			}
		} else {
			window.hideLoader();
		}
	}

	function getProdutoErros(pedidoModel) {
		for (
			var indexRepre = 0;
			indexRepre < pedidoModel.pedidoRepresentadas.length;
			indexRepre++
		) {
			var pedidoRepresentada = pedidoModel.pedidoRepresentadas[indexRepre];
			var sizeProds =
				pedidoModel.pedidoRepresentadas[indexRepre].pedidoRepresentadaProdutos.length;

			for (var indexProd = 0; indexProd < sizeProds; indexProd++) {
				const produto =
					pedidoModel.pedidoRepresentadas[indexRepre].pedidoRepresentadaProdutos[
						indexProd
					];

				const indexRepresentada = model.pedidoRepresentadas.findIndex((pr) => {
					return pr.representadaId === pedidoRepresentada.representadaId;
				});

				const indexProduto = model.pedidoRepresentadas[
					indexRepresentada
				].pedidoRepresentadaProdutos.findIndex((produtoInner) => {
					return produtoInner.produtoId === produto.produtoId;
				});

				let prod =
					model.pedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[
						indexProduto
					];
				prod = {
					...prod,
					erro: produto.erro,
				};
				let newPedidoRepresentadas = [...model.pedidoRepresentadas];
				newPedidoRepresentadas[indexRepresentada].pedidoRepresentadaProdutos[indexProduto] =
					prod;
				setModel({ ...model, pedidoRepresentadas: newPedidoRepresentadas });
			}
		}
	}

	async function salvar() {
		window.showLoader();
		await updatePedidoRascunho(model);

		navigate("/");

		window.hideLoader();
	}

	async function convertUnidade(produto, unidadeAtual, unidadeNova) {
		window.showLoader();
		const filter = {
			produtoId: produto.produtoId,
			tabelaPrecoId: produto.tabelaPrecoId,
			unidadeAtual: unidadeAtual,
			unidadeNova: unidadeNova,
			valor: produto?.valor ? produto?.valor : 0.0,
			valorReferencia: produto?.valorReferencia ? produto.valorReferencia : 0.0,
			quantidade: produto?.quantidade ? produto?.quantidade : 0.0,
			precoMinimo: produto?.precoMinimo ? produto?.precoMinimo : 0.0,
			precoMaximo: produto?.precoMaximo ? produto?.precoMaximo : 0.0,
		};

		const result = await PedidoService.getUnidadeConverterAsync(filter);

		window.hideLoader();

		return result;
	}

	async function removePedidoPadrao(lojaId) {
		(async () => {
			window.showLoader();
			let padrao = await PedidoRascunhoService.removePedidoPadraoLojaAsync(lojaId);
			if (padrao.data && padrao.data.data) {
				showSucesso();
			} else {
				showErro();
			}
			window.hideLoader();
		})();
	}

	function showSucesso() {
		setShowModalMessage(true);
	}

	function showErro() {
		setShowModalMessageError(true);
	}

	function multipleSearch(
		rows, // array of data [{a: "a", b: "b"}, {a: "c", b: "d"}]
		keys, // keys to search ["a", "b"]
		filterValue // potentially multi-word search string "two words"
	) {
		if (!filterValue || !filterValue.length) {
			return rows;
		}

		const terms = filterValue.split(" ");
		if (!terms) {
			return rows;
		}

		// reduceRight will mean sorting is done by score for the _first_ entered word.
		return terms.reduceRight((results, term) => matchSorter(results, term, { keys }), rows);
	}

	let matchProdutos = (produtos.lista || []).filter(
		(produto) => !representadaId || produto.representadaId === representadaId
	);
	//matchProdutos = matchSorter(matchProdutos, textFilter, { keys: ['nome', 'codigoNaRepresentada'] });
	matchProdutos = multipleSearch(matchProdutos, ["nome", "codigoNaRepresentada"], textFilter);

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			if (matchProdutos && matchProdutos.length == 1) {
				const largura = window.innerWidth;
				if (largura && largura < 700) {
					showModalAddProduto(matchProdutos[0]);
				} else {
					addProduto(matchProdutos[0]);
				}
			}
		}
	};

	return (
		<Layout>
			<div className="pedido-page">
				<PageTitle title={`Novo Pedido - ${rascunho?.pedidoTipo || ""}`}></PageTitle>
				{/* <Errors errors={errors} keyName="" /> */}
				{!rascunhoInvalido && (
					<>
						<FormGroupHeader text="LOJA" />
						<SelectLoja
							options={lojas}
							onChange={(option) => onChangeLoja(option == null ? "" : option.value)}
							value={
								lojas &&
								lojas.filter((loja) => {
									return loja.value === model.lojaId;
								})[0]
							}></SelectLoja>

						{userData.cadastraCliente && (
							<div className="link-cadastro">
								<button
									type="button"
									className="btn btn-link"
									onClick={() => setShowModalCliente(true)}>
									+ Cadastrar nova loja
								</button>
							</div>
						)}

						{/* <div className="row">
              <a
                onClick={() => setShowModalCliente(true)}
                className="link-remover"
              >
                - Remover o Pedido Padrão
              </a>
            </div> */}
						{model.lojaId && !rascunhoInvalido && model.inadimplente && (
							<div className="alert alert-danger">
								Este cliente esta bloqueado para efetuar novos pedidos.
							</div>
						)}

						{model.lojaId && !rascunhoInvalido && !model.inadimplente && (
							<div className="row form-group mb-4">
								<div className="col-lg-2">
									<label className="col-form-label">Nº do Pedido da Loja:</label>
								</div>

								<div className="col-lg-8 col-xl-5">
									<input
										type="text"
										className="form-control"
										name="codigoPedidoLoja"
										onChange={onChangeInput}
										value={model.codigoPedidoLoja}
										placeholder="(Opcional)"
									/>
									<span className="texto-observacao">
										(Número do pedido encontrado no pedido de compra da loja)
									</span>
								</div>
							</div>
						)}

						{model.lojaId && !rascunhoInvalido && !model.inadimplente && (
							<div className="row form-group mb-4">
								<div className="col-lg-2">
									<label className="col-form-label">Data do pedido:</label>
								</div>

								<div className="col-lg-8 col-xl-5">
									<DatePickerCustom
										selected={
											lib.getDateFromStringJson(model.data) || new Date()
										}
										onChange={(value) => {
											setModel({ ...model, data: value });
										}}
									/>
								</div>
							</div>
						)}

						{model.lojaId && !rascunhoInvalido && !model.inadimplente && (
							<div className="row form-group mb-4">
								<div className="col-lg-8">
									<label>
										<input
											type="checkbox"
											checked={model?.possuiComissaoVariavel || false}
											onChange={(e) => {
												resetComissao();
												setModel({
													...model,
													possuiComissaoVariavel: e.target.checked,
												});
											}}
										/>{" "}
										Pedido com comissão variável
									</label>
								</div>

								{pedidoPadrao.showButton && (
									<div
										className="col-lg-4"
										style={{ textAlign: "right" }}>
										<a
											onClick={() => setShowModalConfirmacao(true)}
											className="link-remover">
											- Deixar de usar esse Pedido Padrão
										</a>
									</div>
								)}
							</div>
						)}
					</>
				)}

				{model.lojaId && !rascunhoInvalido && !model.inadimplente && (
					<>
						<FormGroupHeader text="PRODUTOS" />
						<div className="row produto-filtro">
							<div className="col-lg-4">
								<label>REPRESENTADA</label>
								<SelectRepresentada
									options={representadas}
									onChange={(option) =>
										onChangeRepresentada(option == null ? "" : option.value)
									}
									value={
										representadas &&
										representadas.filter((representada) => {
											return representada.value === representadaId;
										})[0]
									}></SelectRepresentada>
								{userData?.cadastraRepresentada && (
									<div className="link-cadastro">
										<button
											type="button"
											className="btn btn-link"
											onClick={() => setShowModalRepresentada(true)}>
											+ Cadastrar nova representada
										</button>
									</div>
								)}

								<label>PRODUTO</label>
								<div className="input-produto-filter input-group flex-nowrap">
									<span
										className="input-group-text"
										id="addon-wrapping">
										<FontAwesomeIcon icon={faSearch} />
									</span>
									<input
										type="text"
										className="form-control"
										placeholder="Filtre por código ou nome"
										value={textFilter}
										onChange={(e) => {
											setTextFilter(e.target.value);
										}}
										onKeyDown={handleKeyDown}
									/>
								</div>
								{userData?.cadastraProduto && (
									<div className="link-cadastro">
										<button
											type="button"
											className="btn btn-link"
											onClick={() => setShowModalProduto(true)}>
											+ Cadastrar novo produto
										</button>
									</div>
								)}

								<div className="lista-produto">
									<div
										style={{
											padding: "2px,",
											paddingLeft: "4px",
											backgroundColor: "#e6f0ff",
											border: "2px solid #3385ff",
											borderRadius: "3px",
										}}>
										<span
											style={{
												padding: "2px,",
												paddingLeft: "4px",
												color: "#4ebcec",
												fontWeight: "bold",
												textDecoration: "underline",
											}}>
											PRODUTOS DOS ÚLTIMOS PEDIDOS
										</span>
										{matchProdutos
											.filter((p) => p.destaque == true)
											.map((item, index) => (
												<div
													key={index}
													className="row h-100 align-items-center lista-produto-item">
													<div className="col-auto">
														{checkProdutoAdicionado(
															item.produtoId,
															item.representadaId
														) && (
															<FontAwesomeIcon
																icon={faCheckCircle}
																size="2x"
																className="text-success"
															/>
														)}
														{!checkProdutoAdicionado(
															item.produtoId,
															item.representadaId
														) && (
															<>
																<button
																	className="btn btn-secondary btn-sm add-produto-mobile"
																	onClick={() =>
																		showModalAddProduto(item)
																	}>
																	<FontAwesomeIcon
																		icon={faPlus}
																	/>
																</button>
																<button
																	className="btn btn-secondary btn-sm add-produto-desktop"
																	onClick={() =>
																		addProduto(item)
																	}>
																	<FontAwesomeIcon
																		icon={faPlus}
																	/>
																</button>
															</>
														)}
													</div>
													<div className="col">
														<span className="nome">{item.nome}</span>
														<span className="codigo">
															{" "}
															- Cód. {item.codigoNaRepresentada}
														</span>
													</div>
												</div>
											))}
									</div>

									{matchProdutos
										.filter((p) => p.destaque == false)
										.map((item, index) => (
											<div
												key={index}
												className="row h-100 align-items-center lista-produto-item">
												<div className="col-auto">
													{checkProdutoAdicionado(
														item.produtoId,
														item.representadaId
													) && (
														<FontAwesomeIcon
															icon={faCheckCircle}
															size="2x"
															className="text-success"
														/>
													)}
													{!checkProdutoAdicionado(
														item.produtoId,
														item.representadaId
													) && (
														<>
															<button
																className="btn btn-secondary btn-sm add-produto-mobile"
																onClick={() =>
																	showModalAddProduto(item)
																}>
																<FontAwesomeIcon icon={faPlus} />
															</button>
															<button
																className="btn btn-secondary btn-sm add-produto-desktop"
																onClick={() => addProduto(item)}>
																<FontAwesomeIcon icon={faPlus} />
															</button>
														</>
													)}
												</div>
												<div className="col">
													<span className="nome">{item.nome}</span>
													<span className="codigo">
														{" "}
														- Cód. {item.codigoNaRepresentada}
													</span>
												</div>
											</div>
										))}
								</div>
							</div>
							<div className="col-lg-8">
								<div className="titulo-adicionados">PRODUTOS DO PEDIDO</div>

								{model.pedidoRepresentadas.map((representada, i) => (
									<div key={i}>
										<label className="representada-adicionada">
											{representada.nomeFantasia} - {representada.cnpj}
										</label>

										{representada.pedidoRepresentadaProdutos.map(
											(produto, index) => {
												let urlImagem = produto.urlImagem
													? produto.urlImagem
													: SemImagem;

												return (
													<div
														key={index}
														className="row align-items-center produtos-pedido">
														{/* xs, sm, md, lg, xl, xxl */}
														<div className="col-auto d-none d-xxl-block text-center">
															<div className="imagem-produto">
																<ModalImage
																	small={urlImagem}
																	large={urlImagem}
																/>
															</div>
														</div>
														<div className="col">
															<div className="row">
																<div className="col">
																	<div className="row dados-produto">
																		<div className="col-xl-9">
																			<div className="row">
																				<div className="col-xl-2">
																					<label>
																						Codigo
																					</label>
																				</div>
																				<div className="col-xl-10">
																					{
																						produto.codigoNaRepresentada
																					}
																				</div>
																				<div className="col-xl-2">
																					<label>
																						Produto
																					</label>
																				</div>
																				<div className="col-xl-10">
																					{produto.nome}
																				</div>
																			</div>
																		</div>

																		{model?.possuiComissaoVariavel ? (
																			<div className="col-xl-3">
																				<label>
																					Comissão
																				</label>
																				<CurrencyInput
																					className="form-control"
																					decimalSeparator=","
																					thousandSeparator=""
																					prefix="% "
																					selectAllOnFocus={
																						true
																					}
																					value={
																						produto.comissaoVariavel ||
																						produto.comissaoPadrao ||
																						0
																					}
																					onChangeEvent={(
																						event,
																						maskedvalue,
																						floatvalue
																					) => {
																						if (
																							floatvalue &&
																							floatvalue <
																								0
																						)
																							floatvalue = 0;
																						onChangeProdutoAdicionado(
																							produto.produtoId,
																							representada.representadaId,
																							"comissaoVariavel",
																							floatvalue
																						);
																					}}
																				/>
																			</div>
																		) : null}
																	</div>
																</div>
																<div className="col-auto d-xxl-none">
																	<div className="imagem-produto-sm">
																		<ModalImage
																			small={urlImagem}
																			large={urlImagem}
																		/>
																	</div>
																</div>
															</div>

															<div className="row">
																<div className="col-12 col-lg-6 col-xl-3">
																	<label>Unid. Medida</label>
																	<Select
																		styles={customControlStyles}
																		options={produto.unidades}
																		isClearable={false}
																		placeholder=""
																		onChange={(option) => {
																			onChangeUnidade(
																				produto.produtoId,
																				representada.representadaId,
																				"unidadeMedidaId",
																				option == null
																					? null
																					: parseInt(
																							option.value
																					  ),
																				option == null
																					? null
																					: option.label
																			);
																		}}
																		value={
																			unidades.length > 0 &&
																			unidades[0].options &&
																			unidades[0].options.filter(
																				(u) => {
																					return (
																						parseInt(
																							u.value
																						) ===
																						produto.unidadeMedidaId
																					);
																				}
																			)[0]
																		}
																	/>
																</div>
																<div className="col-12 col-lg-6 col-xl-3">
																	<label>Qtd.</label>
																	<NumberFormat
																		className="form-control"
																		id="input-quantidade"
																		displayType={"input"}
																		thousandSeparator={false}
																		decimalSeparator={","}
																		onValueChange={(
																			values,
																			sourceInfo
																		) => {
																			if (
																				sourceInfo.source ===
																				"event"
																			) {
																				if (
																					values.floatValue &&
																					values.floatValue <
																						0
																				) {
																					values.floatValue = 0;
																				}
																				onChangeProdutoAdicionado(
																					produto.produtoId,
																					representada.representadaId,
																					"quantidade",
																					values.floatValue
																				);
																			}
																		}}
																		value={produto.quantidade}
																		defaultValue={
																			produto.quantidade
																		}
																	/>
																</div>
																<div className="col-12 col-lg-6 col-xl-3">
																	<label>
																		Valor{" "}
																		{produto.unidadeMedidaNome
																			? "por " +
																			  produto.unidadeMedidaNome
																			: ""}
																	</label>
																	<CurrencyInput
																		className="form-control"
																		decimalSeparator=","
																		thousandSeparator="."
																		prefix="R$ "
																		selectAllOnFocus={true}
																		value={produto.valor}
																		onChangeEvent={(
																			event,
																			maskedvalue,
																			floatvalue
																		) => {
																			if (
																				floatvalue &&
																				floatvalue < 0
																			) {
																				floatvalue = 0;
																			}
																			onChangeProdutoAdicionado(
																				produto.produtoId,
																				representada.representadaId,
																				"valor",
																				floatvalue
																			);
																		}}
																	/>
																	{(produto?.precoMaximo > 0 ||
																		produto?.precoMinimo >
																			0) && (
																		<label>
																			Tabela:{" "}
																			{produto?.precoMinimo &&
																			produto?.precoMinimo > 0
																				? lib.formatToCurrencyText(
																						produto.precoMinimo
																				  )
																				: "R$ 0"}
																			{produto?.precoMaximo &&
																			produto?.precoMaximo > 0
																				? " - " +
																				  lib.formatToCurrencyText(
																						produto.precoMaximo
																				  )
																				: ""}
																		</label>
																	)}
																</div>
																<div className="col-12 col-lg-6 col-xl-3">
																	<label>Total</label>
																	<NumberFormat
																		className="form-control"
																		value={produto.total}
																		displayType={"input"}
																		thousandSeparator={"."}
																		decimalSeparator={","}
																		prefix={"R$ "}
																		decimalScale={2}
																		fixedDecimalScale={true}
																		readOnly={true}
																	/>
																	{(produto?.porcentagemDesconto >
																		0 ||
																		produto?.valorDescontoAdicional >
																			0) && (
																		<label>
																			<>
																				Desconto:{" "}
																				{
																					produto?.porcentagemDesconto
																				}{" "}
																				%
																				{/* { PedidoService.somarPorcentagem(
																					produto?.porcentagemDesconto,
																					produto?.porcentagemDescontoAdicional
																					)}
																					%
																					<br />
																					{Number.isNaN(
																					produto?.totalDescontoAdicional
																					) == false &&
																					produto?.totalDescontoAdicional >
																						0 && (
																						<>
																						Total com Desc. Adicional:{" "}
																						<NumberFormat
																							value={
																							produto.total -
																							produto.totalDescontoAdicional
																							}
																							displayType={"text"}
																							thousandSeparator={"."}
																							decimalSeparator={","}
																							prefix={"R$"}
																							decimalScale={2}
																							fixedDecimalScale={true}
																						/>
																						</>
																				)} */}
																			</>
																		</label>
																	)}
																</div>
															</div>
															{produto.erro && produto.erro != "" && (
																<div className="col-12 ">
																	<div className="alert-danger erro-produto">
																		{produto.erro}
																	</div>
																</div>
															)}
														</div>
														<div
															className="col-auto remover-produto"
															onClick={() =>
																removeProduto(
																	produto.produtoId,
																	representada.representadaId
																)
															}>
															<FontAwesomeIcon icon={faTrash} />
														</div>
													</div>
												);
											}
										)}
									</div>
								))}
								{model.pedidoRepresentadas.length === 0 && (
									<div className="sem-produtos">Nenhum produto adicionado.</div>
								)}
								{model.pedidoRepresentadas.length > 0 && (
									<>
										<div className="row align-items-center valor-total">
											<div className="col text-end">
												<label>Total do Pedido: </label>
											</div>
											<div className="col-auto">
												<label className="valor-total-final">
													<NumberFormat
														value={valorTotal}
														displayType={"text"}
														thousandSeparator={"."}
														decimalSeparator={","}
														prefix={"R$"}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
												</label>
											</div>
										</div>
										{/* <div className="row align-items-center valor-total">
											<div className="col text-end">
												<label className="label-total">Total: </label>
											</div>
											<div className="col-auto">
												<label className="valor-total-final">
												<NumberFormat
													value={
													valorReferenciaTotal > 0 &&
													valorReferenciaTotal > valorTotal
														? valorReferenciaTotal
														: valorTotal
													}
													displayType={"text"}
													thousandSeparator={"."}
													decimalSeparator={","}
													prefix={"R$"}
													decimalScale={2}
													fixedDecimalScale={true}
												/>
												</label>
											</div>
										</div> */}

										<br />
										<br />
										<div className="row align-items-center valor-total">
											<div className="col text-end">
												<label className="label-total">
													% Desconto Sobre a Tabela:{" "}
												</label>
											</div>
											<div className="col-auto">
												<label className="valor-total-final">
													<NumberFormat
														value={
															valorReferenciaTotal > 0 &&
															valorReferenciaTotal > valorTotal
																? calcularPorcentagemDesconto(
																		valorReferenciaTotal,
																		valorTotal
																  )
																: 0
														}
														displayType={"text"}
														thousandSeparator={"."}
														decimalSeparator={","}
														decimalScale={2}
														suffix=" %"
														fixedDecimalScale={true}
													/>
												</label>
											</div>
										</div>
										<div className="row align-items-center valor-total">
											<div className="col text-end">
												<label>Total Desconto Sobre a Tabela: </label>
											</div>
											<div className="col-auto">
												<label className="valor-total-final">
													<NumberFormat
														value={
															valorTotal > 0
																? valorReferenciaTotal > 0 &&
																  valorReferenciaTotal > valorTotal
																	? valorReferenciaTotal -
																	  valorTotal
																	: 0
																: 0
														}
														displayType={"text"}
														thousandSeparator={"."}
														decimalSeparator={","}
														prefix={"R$"}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
												</label>
											</div>
										</div>

										{/* {valorReferenciaTotal > 0 && (
											<>
												<br />
												<br />
												<div className="row align-items-center valor-total">
												<div className="col text-end">
													<label>Desconto Adicional: </label>
												</div>
												<div className="col-auto">
													<label>
													<NumberFormat
														className="form-control valor-total-final"
														displayType={"input"}
														thousandSeparator={false}
														decimalSeparator={","}
														suffix=" %"
														onValueChange={(values, sourceInfo) =>
														onChangeDescontoAdicional(values.floatValue)
														}
														value={percentualDescontoTotal}
														fixedDecimalScale={true}
													/>
													</label>
												</div>
												</div>
												<div className="row align-items-center valor-total">
												<div className="col text-end">
													<label className="label-total">
													Valor Desconto Adicional:{" "}
													</label>
												</div>
												<div className="col-auto">
													<label className="valor-total-final">
													<NumberFormat
														value={descontoAdicionalTotal}
														displayType={"text"}
														thousandSeparator={"."}
														decimalSeparator={","}
														prefix={"R$"}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
													</label>
												</div>
												</div>
											</>
										)} */}

										{/* <br />
                    <br />
                    <div className="row align-items-center valor-total">
                      <div className="col text-end">
                        <label className="total-pedido-destaque">
                          Valor do Pedido:{" "}
                        </label>
                      </div>
                      <div className="col-auto">
                        <label className="total-pedido-destaque valor-total-final">
                          <NumberFormat
                            value={valorTotal - descontoAdicionalTotal}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row align-items-center valor-total">
                      <div className="col text-end">
                        <label>Desconto Total Pedido: </label>
                      </div>
                      <div className="col-auto">
                        <label className="valor-total-final">
                          <NumberFormat
                            value={calcularPorcentagemDesconto(
                              valorTotal,
                              valorTotal -
                                (descontoAdicionalTotal +
                                  (valorTotal > 0
                                    ? valorReferenciaTotal > 0 &&
                                      valorReferenciaTotal > valorTotal
                                      ? valorReferenciaTotal - valorTotal
                                      : 0
                                    : 0))
                            )}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={""}
                            suffix={"%"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </label>
                      </div>
                    </div> */}
										<div className="text-end">
											<Errors
												errors={errors}
												keyName=""
											/>
										</div>

										<div className="text-end">
											<button
												className="btn btn-lg btn-secondary btn-avancar"
												onClick={() => salvar()}>
												SALVAR E CONTINUAR MAIS TARDE
											</button>
											<button
												className="btn btn-lg btn-primary btn-avancar"
												onClick={() => continuar()}>
												AVANÇAR PARA RESUMO
											</button>
										</div>
									</>
								)}
							</div>
						</div>
					</>
				)}
			</div>

			<ModalCadastroLoja
				showModal={showModalCliente}
				setShowModal={setShowModalCliente}
				onClose={onCloseModalCliente}
				entity={{}}
			/>
			<ModalCadastroRepresentada
				showModal={showModalRepresentada}
				setShowModal={setShowModalRepresentada}
				onClose={onCloseModalRepresentada}
				entity={{}}
			/>
			<ModalCadastroProduto
				showModal={showModalProduto}
				setShowModal={setShowModalProduto}
				onClose={onCloseModalProduto}
				entity={{}}
			/>
			<ModalPedidoEditaProduto
				showModal={showModalEditarProduto}
				setShowModal={setShowModalEditarProduto}
				produto={pedidoEditarProduto}
				addProdutoMobile={addProdutoMobile}
				unidades={unidades}
				onClose={onCloseModalEditarProduto}
				calcularPorcentagemDesconto={calcularPorcentagemDesconto}
				calcularPorcentagem={PedidoService.calcularPorcentagem}
				percentualDescontoTotal={percentualDescontoTotal}
			/>

			{/* Modal Confirmacao Remover Pedido Padrão -------------------------------- */}
			<ModalConfirmacao
				showModal={showModalConfirmacao}
				setShowModal={setShowModalConfirmacao}
				onClose={(result) => {
					if (result === "yes") {
						removePedidoPadrao(model.lojaId);
					}
				}}>
				<p>Deseja mesmo deixar de usar esse pedido padrão?"</p>
			</ModalConfirmacao>

			<ModalMessage
				title="Sucesso"
				showModal={showModalMessage}
				setShowModal={setShowModalMessage}
				onClose={() => {}}>
				<p>O pedido deixou de ser padrão</p>
			</ModalMessage>

			<ModalMessage
				title="Erro"
				showModal={showModalMessageError}
				setShowModal={setShowModalMessageError}
				onClose={() => {}}>
				<p>Não foi possível remover o pedido de padrão</p>
			</ModalMessage>
		</Layout>
	);
};

export default Pedido;