import React from "react";
import TablePagination from "../../components/table-pagination";
import ModalConfirmacao from "../../components/modal-confirmacao";
import ModalCarteira from "../../components/modal-carteira";

import EquipeService from "../../services/equipe-service";

import lib from "../../helpers/lib";

export default function Invitations() {

  const [invitations, setInvitations] = React.useState([]);

  const [refuseData, setRefuseData] = React.useState({
    equipe: {},
    showModalRefuse: false
  })

  React.useEffect(() => {
    filterMemberTeams();
  }, []);

  async function filterMemberTeams(page) {
    window.showLoader();

    let response = await EquipeService.listInvitationsAsync({ pageNumber: page });

    setInvitations(response.data.data);

    window.hideLoader();
  }

  async function refuseConfirm(answer) {
    if (answer === 'yes') {
      window.showLoader();

      let response = await EquipeService.refuseAsync(refuseData.equipe.equipeId);

      window.hideLoader();

      if (response.status === lib.statusCode.ok) {
        window.location = "/";
        return;
      }
    }
  }

  async function accept(equipeId) {
    window.showLoader();

    let response = await EquipeService.acceptAsync(equipeId);

    window.hideLoader();

    if (response.status === lib.statusCode.ok) {
      localStorage.clear();
      window.location = "/";
      return;
    }
  }

  return (
    <>
      {invitations && (
        <div className="equipe-container">
          {(invitations || []).map((equipe, index) => (
            <table key={index} className={`table table-cadastro ${!equipe.ativa ? "equipe-inativa" : ""}`}>
              <thead>
                <tr>
                  <th>
                    <div className="row">
                      <div className="col-6">
                        {equipe.nome}
                      </div>
                      <div className="col-6 text-end">
                        <label className="texto-light px-3">Time criado por {equipe.owner}</label>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="row justify-content-center">
                      <div className="col-11 equipe-usuario-container">
                        <div className="row">

                          <div className="col-lg-3 d-flex flex-column justify-content-center equipe-usuario-descricao-container">
                            {equipe.usuario.nome?.length > 0
                              ?
                              <>
                                <label className="d-block">{equipe.usuario.nome}</label>
                                <label className="d-block texto-light">{equipe.usuario.email}</label>
                              </>

                              :
                              <label className="texto">{equipe.usuario.email}</label>
                            }

                          </div>
                          {equipe.usuario.currentUser && (
                            <div className="col-lg-9 d-flex flex-column justify-content-center">
                              <>
                                <div className="row justify-content-around">
                                  <div className="col-lg-4 px-5 py-1">
                                    <button type="button" className="btn btn-success w-100" onClick={() => accept(equipe.equipeId)}>ACEITAR</button>
                                  </div>

                                  <div className="col-lg-4 px-5 py-1">
                                    <button type="button" className="btn btn-danger w-100" onClick={() => {
                                      setRefuseData({
                                        equipe: equipe,
                                        showModalRefuse: true
                                      });
                                    }}>RECUSAR</button>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-lg-12 text-center">
                                    <hr />
                                    <label className="d-block">Aguardando aceite do convite.</label>
                                    <label className="texto-light">Convite enviado em {lib.getStringFromDateTime(equipe.usuario.dataConvite)}</label>
                                  </div>
                                </div>
                              </>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          ))}

          <ModalConfirmacao
            showModal={refuseData.showModalRefuse}
            setShowModal={() => setRefuseData({ ...refuseData, showModalRefuse: false })}
            onClose={refuseConfirm}
            headerText=" "
            btnYesText="Recusar"
            btnNoText="Cancelar"
          >
            <p>Confirme se quer recusar o convite do time {refuseData.equipe?.nome}</p>
          </ModalConfirmacao>
        </div>
      )}
    </>
  );
}
