import React, { useState, useEffect } from 'react';
import './styles.scss';

export default function TabelaRede({ source }) {
  const [tabelaRede, setTabelaRede] = useState(null);

  useEffect(() => {
    if (!source?.redes)
      return;

    setTabelaRede(source);
  }, [source]);

  if (!tabelaRede)
    return null;

  return (
    <div className="row m-0">
      <h1 className="h3 mb-2 text-gray-800">Resumo Mensal por Rede</h1>
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary"></h6>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
            <thead>
              <tr>
                <th>REDE</th>
                <th>META REDE</th>
                <th>TOTAL PEDIDO</th>
                <th>% PEDIDO</th>
                <th>PARCIAL+FATURADO</th>
                <th>% FATURADO</th>
                <th>PARCIAL</th>
                <th>RUPTURA</th>
                <th>TOTAL NÃO FATURADO</th>
              </tr>
            </thead>
            <tbody>
              {tabelaRede.redes.map((rede, index) => (
                <tr key={index}>
                  <td>{rede.rede}</td>
                  <td>{rede.meta > 0 ? rede.meta.toLocaleString('pt-br', {style: 'currency', currency: 'BRL' }) : "-"}</td>
                  <td>{rede.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                  <td>
                    {
                      rede.meta > 0
                        ? (
                          <div className="progress">
                            <div className="progress-bar" role="progressbar"
                              style={{ width: `${Math.trunc(rede.valorTotal * 100 / rede.meta)}%` }}
                              aria-valuenow={Math.trunc(rede.valorTotal * 100 / rede.meta)}
                              aria-valuemin="0"
                              aria-valuemax="100">
                            </div>
                            {Math.trunc(rede.valorTotal * 100 / rede.meta)}%
                          </div>
                        )
                        : (
                          <span>-</span>
                        )
                    }

                  </td>
                  <td>{(rede.valorFaturado + rede.valorParcial).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                  <td>
                    {
                      rede.meta > 0
                        ? (
                          <div className="progress">
                            <div className="progress-bar" role="progressbar"
                              style={{ width: `${Math.trunc((rede.valorFaturado + rede.valorParcial) * 100 / rede.meta)}%` }}
                              aria-valuenow={Math.trunc((rede.valorFaturado + rede.valorParcial) * 100 / rede.meta)}
                              aria-valuemin="0"
                              aria-valuemax="100">
                            </div>
                            {Math.trunc((rede.valorFaturado + rede.valorParcial) * 100 / rede.meta)}%
                          </div>
                        )
                        : (
                          <span>-</span>
                        )
                    }
                  </td>
                  <td>{rede.valorParcial.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                  <td>{rede.valorRuptura.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                  <td>{rede.valorNaoFaturado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                </tr>
              ))}

              <tr>
                <td>{tabelaRede.total.rede}</td>
                <td>{tabelaRede.total.meta > 0 ? tabelaRede.total.meta.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "-"}</td>
                <td>{tabelaRede.total.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>
                  {
                    tabelaRede.total.meta > 0
                      ? (
                        <div className="progress">
                          <div className="progress-bar" role="progressbar"
                            style={{ width: `${Math.trunc(tabelaRede.total.valorTotal * 100 / tabelaRede.total.meta)}%` }}
                            aria-valuenow={Math.trunc(tabelaRede.total.valorTotal * 100 / tabelaRede.total.meta)}
                            aria-valuemin="0"
                            aria-valuemax="100">
                          </div>
                          {Math.trunc(tabelaRede.total.valorTotal * 100 / tabelaRede.total.meta)}%
                        </div>
                      )
                      : (
                        <span>-</span>
                      )
                  }

                  {/* <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> */}
                </td>
                <td>{(tabelaRede.total.valorFaturado + tabelaRede.total.valorParcial).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>
                  {
                    tabelaRede.total.meta > 0
                      ? (
                        <div className="progress">
                          <div className="text-center progress-bar" role="progressbar"
                            style={{ width: `${Math.trunc((tabelaRede.total.valorFaturado + tabelaRede.total.valorParcial) * 100 / tabelaRede.total.meta)}%` }}
                            aria-valuenow={Math.trunc((tabelaRede.total.valorFaturado + tabelaRede.total.valorParcial) * 100 / tabelaRede.total.meta)}
                            aria-valuemin="0"
                            aria-valuemax="100">
                          </div>
                          {Math.trunc((tabelaRede.total.valorFaturado + tabelaRede.total.valorParcial) * 100 / tabelaRede.total.meta)}%
                        </div>
                      )
                      : (
                        <span>-</span>
                      )
                  }
                </td>
                <td>{tabelaRede.total.valorParcial.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{tabelaRede.total.valorRuptura.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                <td>{tabelaRede.total.valorNaoFaturado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
              </tr>
            </tbody>
          </table>
          {/* <div className="total">
              <strong>Total Salary:</strong> {totalSalary.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </div> */}
        </div>
      </div>
    </div>
  );
}