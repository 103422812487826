import React from 'react';

export const GlobalContext = React.createContext({});

export const GlobalProvider = props => {
  const maxWidth = 768;
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < maxWidth ? true : false);

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth < maxWidth ? true : false);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <GlobalContext.Provider value={{
      isMobile: isMobile,
    }}>
      {props.children}
    </GlobalContext.Provider>
  );
};