import React from "react";
import VMasker from "vanilla-masker";

export default function InputCep({
  className,
  placeholder,
  onChange,
  value,
  onKeyDown,
  disabled,
}) {
  return (
    <input
      type="text"
      disabled={disabled}
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={(event) => {
        let input = event.target.value;
        input = VMasker.toPattern(input, "99999999999999999999");
        input = VMasker.toPattern(input, "99999-999");
        onChange(input);
      }}
      onKeyDown={onKeyDown}
    />
  );
}
