import ServiceClient from "./service-client";
import { service_url } from "./service-constants";

class RelatorioService {
  static async getLojasSemPedidosAsync(model) {
    try {
      var result = await ServiceClient.Get(
        service_url.relatorioLojasUltimosPedidos,
        model
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatorioAnaliticoAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        service_url.relatorioAnalitico,
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getExcelRelatorioAnaliticoEmBytesAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        service_url.excelRelatorioAnalitico,
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  static async getRelatorioAnaliticoUltimoPedidoPdf(filter) {
    try {
      var result = await ServiceClient.Get(
        service_url.relatorioUltimoPedidoPdf,
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  static async getRelatorioComissaoEmPdfAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        service_url.relatorioComissaoPdf,
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatorioMetasEmPdfAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        service_url.relatorioMetasPdf,
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatorioMetasVolumeEmPdfAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        service_url.relatorioMetasVolumePdf,
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatorioAnaliticoEmPdfAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        service_url.relatorioAnaliticoPdf,
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatorioMetasAsync(filter) {
    try {
      var result = await ServiceClient.Get(service_url.relatorioMetas, filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatorioMetasVolumeAsync(filter) {
    try {
      var result = await ServiceClient.Get(service_url.relatorioMetasVolume, filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatorioMetasEmBytesAsync(filter) {
    try {
      var result = await ServiceClient.Get(service_url.relatorioMetasBytes, filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatorioMetasVolumeEmBytesAsync(filter) {
    try {
      var result = await ServiceClient.Get(service_url.relatorioMetasVolumeBytes, filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getExtratoComissaoExcelAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        service_url.excelRelatorioExtratoComissao,
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default RelatorioService;
