import ServiceClient from "./service-client";

class DashboardService {
  static async getMetaContratanteAsync(filter) {
    try {
      var result = await ServiceClient.Post("/dashboard/get-meta-contratante", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getPositivacaoAsync(filter) {
    try {
      var result = await ServiceClient.Post("/dashboard/get-positivacao", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getResumoMensalRedeAsync(filter) {
    try {
      var result = await ServiceClient.Post("/dashboard/get-resumo-mensal-rede", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getDetalhamentoAsync(filter) {
    try {
      var result = await ServiceClient.Post("/dashboard/get-detalhamento", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getMetaEquipeAsync(filter) {
    try {
      var result = await ServiceClient.Post("/dashboard/get-meta-equipe", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getPositivacaoEquipeAsync(filter) {
    try {
      var result = await ServiceClient.Post("/dashboard/get-positivacao-equipe", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getResumoMensalEquipeVendedorAsync(filter) {
    try {
      var result = await ServiceClient.Post("/dashboard/get-resumo-mensal-equipe-vendedor", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getResumoMensalEquipeRedeAsync(filter) {
    try {
      var result = await ServiceClient.Post("/dashboard/get-resumo-mensal-equipe-rede", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListRepresentada(filter) {
    try {
      var result = await ServiceClient.Get("/dashboard/select-list-representada", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListRepresentadaPorVendedores(filter) {
    try {
      var result = await ServiceClient.Get("/dashboard/select-list-representada-vendedores", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListRede(filter) {
    try {
      var result = await ServiceClient.Get("/dashboard/select-list-rede", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListRedePorVendedores(filter) {
    try {
      var result = await ServiceClient.Get("/dashboard/select-list-rede-vendedores", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListVendedor(filter) {
    try {
      var result = await ServiceClient.Get("/dashboard/select-list-vendedor", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListCategoria(filter) {
    try {
      var result = await ServiceClient.Get("/dashboard/select-list-categoria", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  
  static async getSelectListProduto(filter) {
    try {
      var result = await ServiceClient.Get("/dashboard/select-list-produto", filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default DashboardService;
