import React from "react";
import NumberFormat from "react-number-format";
import lib from "../../helpers/lib";
import FormGroupHeader from "../_base/form-group-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";

import { LoggedUserContext } from "../../contexts/logged-user-context";

import PedidoService from "../../services/pedido-service";

import "./styles.scss";
import RelatorioService from "../../services/relatorio-service";

function groupBy(arr, key) {
  return arr.reduce(function (groups, x) {
    let g = groups.find((g1) => g1.key === x[key]);
    if (g) {
      g.values.push(x);
    } else {
      groups.push({
        key: x[key],
        values: [x],
      });
    }

    return groups;
  }, []);
}

export default function ResumoPedidoAvaliacao(props) {
  const { model, aprovar, cancelar } = props;

  const [userData, _] = React.useContext(LoggedUserContext);

  const [resumo, setResumo] = React.useState();

  React.useEffect(() => {
    getResumo();
  }, [model]);

  async function getResumo() {
    if (model !== undefined && model !== null) {
      window.showLoader();

      let response = await PedidoService.GetResumoAsync(
        model.pedidoId,
        model.representadaId
      );
      // console.log("----resumo----");
      let resumo = response?.data?.data || {};
      // console.log(resumo);
      if (resumo.pedidoFaturamento) {
        let notas = groupBy(resumo.pedidoFaturamento, "notaFaturamento");

        let pedidoFaturamento = [];

        for (let nota of notas) {
          let pedidoFaturamentoProdutos = [].concat(
            ...nota.values.map((n) => n.pedidoFaturamentoProdutos)
          );

          let produtos = groupBy(pedidoFaturamentoProdutos, "produtoId");

          pedidoFaturamento.push({
            ...nota.values[0],
            valorTotalFaturado: nota.values.reduce(
              (acc, x) => acc + x.valorTotalFaturado,
              0
            ),
            pedidoFaturamentoProdutos: produtos.map((p) => ({
              ...p.values[0],
              quantidade: p.values.reduce((acc, x) => acc + x.quantidade, 0),
            })),
          });
        }
        resumo.pedidoFaturamento = pedidoFaturamento;
      }

      setResumo(resumo);
      window.hideLoader();
    }
  }

  function calcularDiferencaFaturamento(itemPedido, itemFaturamento) {
    const valorItemPedido =
      parseFloat(itemPedido?.faturamentoQuantidade ?? 0) *
      parseFloat(itemPedido?.faturamentoValorUnidade ?? 0);

    if (itemFaturamento == undefined && valorItemPedido > 0)
      return valorItemPedido * -1;

    if (itemFaturamento == undefined && valorItemPedido == 0) return 0;

    const valorItemFaturamento =
      parseFloat(itemFaturamento.quantidade ?? 0) *
      parseFloat(itemPedido?.faturamentoValorUnidade ?? 0);

    return valorItemFaturamento == valorItemPedido
      ? 0
      : valorItemFaturamento - valorItemPedido;
  }

  function SomaTotal() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      let item = resumo.produtos[i];
      total +=
        parseFloat(item.faturamentoQuantidade) *
        parseFloat(item.faturamentoValorUnidade);
    }

    return total;
  }

  function SomaTotalProdutosSemDesconto() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      let item = resumo.produtos[i];
      if (item.valorBruto > 0)
        total += parseFloat(item.vendaQuantidade) * parseFloat(item.valorBruto);
      else
        total +=
          parseFloat(item.vendaQuantidade) * parseFloat(item.vendaValorUnidade);
    }

    return total;
  }

  function SomaTotalProdutosDescontoAdicional() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      let item = resumo.produtos[i];
      total +=
        parseFloat(item.vendaQuantidade) *
        parseFloat(item.valorDescontoAdicional);
    }

    return total;
  }

  function SomaTotalDesconto() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      let item = resumo.produtos[i];
      total +=
        parseFloat(item.vendaQuantidade) *
        (item.valorDesconto ? parseFloat(item.valorDesconto) : 0);
    }

    return total;
  }

  function SomaTotalFaturado() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (let produto of resumo.produtos) {
      total += produto.valorFaturado;
    }

    return total;
  }

  function SomaTotalDiferenca() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let totalPedido = 0;
    let totalFaturado = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      totalPedido +=
        parseFloat(resumo.produtos[i]?.faturamentoQuantidade ?? 0) *
        parseFloat(resumo.produtos[i]?.faturamentoValorUnidade ?? 0);
    }

    if (resumo.pedidoFaturamento && resumo.pedidoFaturamento.length > 0) {
      for (let i = 0; i < resumo.pedidoFaturamento.length; i++) {
        for (
          let j = 0;
          j < resumo.pedidoFaturamento[i].pedidoFaturamentoProdutos.length;
          j++
        ) {
          let produto = resumo.produtos.find(
            (x) =>
              x.produtoId ===
              resumo.pedidoFaturamento[i].pedidoFaturamentoProdutos[j].produtoId
          );
          totalFaturado +=
            parseFloat(
              resumo.pedidoFaturamento[i].pedidoFaturamentoProdutos[j]
                .quantidade ?? 0
            ) * parseFloat(produto?.faturamentoValorUnidade ?? 0);
        }
      }
    }

    var result = totalFaturado - totalPedido;
    if (result > -1 && result < 1) return 0;

    return result;
  }

  function getDifferenceLetterColor(valor) {
    if (valor <= -1) return "#973446";
    return "#2a6128";
  }

  function calcularTotalComDescontos(
    valorReferencia,
    valorTotDesconto,
    valorTotDescAdicional
  ) {
    var primeiroDes = valorReferencia - valorTotDesconto;

    return primeiroDes - valorTotDescAdicional;
  }

  function calcularPorcentagemDesconto(valorReferencia, valorAtual) {
    if (valorReferencia == 0 || valorAtual == 0) return 0;
    const percentual = (100 * valorAtual) / valorReferencia;
    if (percentual > 100) return 0;
    const resultPerct = 100 - percentual;
    return Math.round(resultPerct * 100) / 100;
  }

  async function downloadPedidoAvaliacaoPdf() {
    window.showLoader();

    let result = await PedidoService.getResumoAvaliacaoEmPdfAsync(
      model.pedidoId,
      model.representadaId
    );

    if (result && result.data) {
      console.log("Arquivo");
      console.log(result);
      let fileName = `pedido_avaliacao_${
        model.pedidoId
      }_${lib.getCurrentDateFilename()}.pdf`;
      lib.downloadFile(result.data, fileName);

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  return (
    <div className="modal-body modal-resumo-pedido">
      <div className="row mb-2">
        <div className="col-lg-2 text-end">Pedido:</div>
        <div className="col-lg-4">
          {resumo?.codigoPedidoLoja
            ? resumo?.codigoPedidoLoja
            : resumo?.codigoPedidoRepresentada}
        </div>
        <div className="col-lg-2 text-end">Data:</div>
        <div className="col-lg-4">
          {lib.getStringFromDateMinute(resumo?.data)}
        </div>

        <div className="col-lg-2 text-end">Tipo:</div>
        <div className="col-lg-4">{resumo?.pedidoTipo}</div>

        {resumo?.dataMaximaEntrega && (
          <>
            <div className="col-lg-2 text-end">Entregar até:</div>
            <div className="col-lg-4">
              {lib.getStringFromDate(resumo.dataMaximaEntrega)}
            </div>
          </>
        )}
        {resumo?.prazoPagamento && resumo?.prazoPagamento != null && (
          <>
            <div className="col-lg-2 text-end">Prazo de Pagamento:</div>
            <div className="col-lg-4">{resumo.prazoPagamento}</div>
          </>
        )}
        <div className="col-lg-2 text-end">Vendedor:</div>
        <div className="col-lg-4">{resumo?.vendedor}</div>
      </div>
      <FormGroupHeader text="DADOS DA LOJA" />
      <div className="row mb-2">
        <div className="col-lg-6">
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Razão Social:</div>
            <div className="col-lg-8">{resumo?.loja?.razaoSocial}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Nome Fantasia:</div>
            <div className="col-lg-8">{resumo?.loja?.nomeFantasia}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4 text-end">CNPJ:</div>
            <div className="col-lg-8">{resumo?.loja?.cnpj}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Telefone:</div>
            <div className="col-lg-8">{resumo?.loja?.contatoTelefone}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Email:</div>
            <div className="col-lg-8">{resumo?.loja?.contatoEmail}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Condição Pagamento:</div>
            <div className="col-lg-8">
              {resumo?.loja?.condicaoPagamento != ""
                ? resumo?.loja?.condicaoPagamento
                : "-"}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row mb-2">
            <div className="col-lg-4 text-end">Endereço:</div>
            <div className="col-lg-8">
              {resumo?.loja?.logradouro}, {resumo?.loja?.numero}{" "}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">{resumo?.loja?.bairro}</div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
              {resumo?.loja?.cidade} - {resumo?.loja?.estado}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">{resumo?.loja?.cep}</div>
          </div>
        </div>
      </div>

      <FormGroupHeader text="DADOS DA REPRESENTADA" />
      <div className="row mb-2">
        <div className="col-lg-9">{model?.representadaRazaoSocial}</div>
        <div className="col-lg-3">CNPJ: {model?.representada?.cnpj}</div>
      </div>

      {/* Resumo faturamento ------------------------------------------------------------ */}
      {resumo?.pedidoFaturamento && resumo?.pedidoFaturamento.length > 0 && (
        <>
          <FormGroupHeader text="TOTAL FATURAMENTO" />
          <div className="table-responsive">
            <table className="table table-cadastro">
              <thead>
                <tr>
                  <th>Valor Total Pedido</th>
                  <th>Desconto</th>
                  <th>Valor Faturado</th>
                  <th>Valor Não Faturado</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <NumberFormat
                      value={SomaTotal()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={SomaTotalDesconto()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={SomaTotalFaturado()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={SomaTotalDiferenca()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <FormGroupHeader text="DADOS FATURAMENTO" />

          <div className="table-responsive">
            <table className="table table-cadastro">
              <thead>
                <tr>
                  <th>Nota</th>
                  <th>Data Faturamento</th>
                  <th>Valor Faturado</th>
                  <th>Comissão</th>
                </tr>
              </thead>
              <tbody>
                {(resumo?.pedidoFaturamento || []).map((item, index) => (
                  <tr key={index}>
                    <td>{item?.notaFaturamento}</td>
                    <td>{lib.getStringFromDateTime(item?.dataFaturamento)}</td>
                    <td>
                      <NumberFormat
                        value={item.valorTotalFaturado}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td>
                      {item.valorComissao &&
                        item.valorComissao != null &&
                        item.valorComissao >= 0 && (
                          <NumberFormat
                            value={item.valorComissao}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {/* Lista Produtos antes do faturamento ------------------------------------------------------------ */}
      {
        <>
          <FormGroupHeader text="PRODUTOS" />
          <div className="table-responsive">
            <table className="table table-cadastro">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Descrição</th>
                  <th>Quantidade</th>
                  <th>Unidade</th>
                  <th>Preço de Tabela</th>
                  {/* <th>Total Sem Desconto</th> */}
                  <th>Preço do Pedido</th>
                  <th>Total do Pedido</th>
                  <th>Desconto no Produto</th>
                </tr>
              </thead>
              <tbody>
                {(resumo?.produtos || []).map((item, index) =>
                  userData?.aprovaPedido &&
                  resumo?.statusSigla === "A" &&
                  item.mensagemAvaliacao?.length > 0 ? (
                    <React.Fragment key={index}>
                      <tr className="text-danger">
                        <td rowSpan={1}>{item.produtoCodigoNaRepresentada}</td>
                        <td rowSpan={1}>{item.produtoNome}</td>
                        <td>
                          <NumberFormat
                            value={item.faturamentoQuantidade}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={3}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td>{item.faturamentoUnidadeSigla}</td>
                        <td>
                          {item.valorBruto && item.valorBruto > 0 ? (
                            <NumberFormat
                              value={item.valorBruto}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          ) : null}
                          {!item.valorBruto || item.valorBruto == 0 ? (
                            <div>-</div>
                          ) : null}
                        </td>
                        {/* <td>
                          <NumberFormat
                            value={
                              parseFloat(item.faturamentoQuantidade) *
                              parseFloat(item.valorBruto)
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td> */}
                        <td>
                          <NumberFormat
                            value={item.vendaValorUnidade}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={
                              parseFloat(item.faturamentoQuantidade) *
                              parseFloat(item.vendaValorUnidade)
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={
                              //parseFloat(item.faturamentoQuantidade) *
                              (item.valorDesconto && item.valorDesconto != null
                                ? parseFloat(item.valorDesconto)
                                : 0)
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                          &nbsp;/&nbsp;
                          <NumberFormat
                            value={
                              item.porcentagemDesconto &&
                              item.porcentagemDesconto != null
                                ? parseFloat(item.porcentagemDesconto)
                                : 0
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={""}
                            suffix=" %"
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={8}
                          className="text-xs text-danger text-center"
                        >
                          {item.mensagemAvaliacao}
                        </td>
                      </tr>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <tr key={index}>
                        <td>{item.produtoCodigoNaRepresentada}</td>
                        <td>{item.produtoNome}</td>
                        <td>
                          <NumberFormat
                            value={item.faturamentoQuantidade}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            fixedDecimalScale={false}
                          />
                        </td>
                        <td>{item.faturamentoUnidadeSigla}</td>
                        <td>
                          {item.valorBruto && item.valorBruto > 0 ? (
                            <NumberFormat
                              value={item.valorBruto}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          ) : null}
                          {!item.valorBruto || item.valorBruto == 0 ? (
                            <div>-</div>
                          ) : null}
                        </td>
                        {/* <td>
                          <NumberFormat
                            value={
                              parseFloat(item.faturamentoQuantidade) *
                              parseFloat(item.valorBruto)
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td> */}
                        <td>
                          <NumberFormat
                            value={item.vendaValorUnidade}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={
                              parseFloat(item.faturamentoQuantidade) *
                              parseFloat(item.vendaValorUnidade)
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={
                              //parseFloat(item.faturamentoQuantidade) *
                              (item.valorDesconto && item.valorDesconto != null
                                ? parseFloat(item.valorDesconto)
                                : 0)
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                          &nbsp;/&nbsp;
                          <NumberFormat
                            value={
                              item.porcentagemDesconto &&
                              item.porcentagemDesconto != null
                                ? parseFloat(item.porcentagemDesconto)
                                : 0
                            }
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={""}
                            suffix=" %"
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                )}
                <tr>
                  <td colSpan={7} className="text text-end">
                    TOTAL DO PEDIDO
                  </td>
                  <td className="text">
                    <NumberFormat
                      value={resumo?.valorTotalVenda ?? 0}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
                {/* <tr>
                <td colSpan={8} className="text text-end">
                  DESCONTOS NOS PREÇOS DOS PRODUTOS
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.valorTotalDescontoProdutos ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr> */}
                <tr>
                  <td colSpan={7} className="text text-end">
                    % DESCONTO SOBRE A TABELA
                  </td>
                  <td className="text">
                    <NumberFormat
                      value={
                        resumo?.valorTotalBruto != undefined &&
                        resumo?.valorTotalBruto > 0 &&
                        resumo?.valorTotalBruto > resumo?.valorTotalVenda
                          ? calcularPorcentagemDesconto(
                              resumo?.valorTotalBruto,
                              resumo?.valorTotalVenda
                            )
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      suffix=" %"
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={7} className="text text-end">
                    TOTAL DESCONTO SOBRE A TABELA
                  </td>
                  <td className="text">
                    <NumberFormat
                      value={resumo?.valorTotalDescontoProdutos ?? 0}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
                {/* <tr>
                <td colSpan={8} className="text text-end">
                  % DESCONTO ADICIONAL
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.percentualDescontoAdicional ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={""}
                    suffix=" %"
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={8} className="text text-end">
                  TOTAL DESCONTO ADICIONAL
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.valorTotalDescontoAdicional ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={8} className="text text-end">
                  TOTAL PEDIDO COM DESCONTO
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.valorTotalComDescontos ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={8} className="text text-end">
                  % DESCONTO TOTAL PEDIDO
                </td>
                <td className="text">
                  <NumberFormat
                    value={resumo?.percentualTotalValorDesconto ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={""}
                    suffix=" %"
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr> */}
              </tbody>
            </table>
          </div>
        </>
      }

      {resumo?.observacao && (
        <>
          <FormGroupHeader text="OBSERVAÇÔES" />
          <div>{resumo.observacao}</div>
        </>
      )}

      {resumo?.statusSigla === "A" && userData?.aprovaPedido === true ? (
        <>
          <hr></hr>
          <div className="text-center mb-3">
            <h3 className="mb-3">Pedido aguardando avaliação</h3>
            <button
              type="button"
              className="btn btn-success me-5"
              onClick={aprovar}
            >
              <FontAwesomeIcon icon={faCheck} /> Aprovar
            </button>
            <button type="button" className="btn btn-danger" onClick={cancelar}>
              <FontAwesomeIcon icon={faCancel} /> Cancelar
            </button>
          </div>
          <br />
          <div className="text-center mb-3">
            <a href="#" onClick={downloadPedidoAvaliacaoPdf}>
              Baixar PDF
            </a>
          </div>
        </>
      ) : null}
    </div>
  );
}
