import React from "react";

import Layout from "../../components/layout/base/index";
import PageTitle from "../../components/page-title";
import ContentChamado from "./content-chamado";
import ListChamado from "./list-chamados";
import ModalCadastroChamado from "../../components/modal-cadastro-chamado/index";
import ChamadoService from "../../services/chamado-service";

export default function Chamado() {
  const [showModalCadastro, setShowModalCadastro] = React.useState(false);
  const [selectedChamado, setSelectedChamado] = React.useState({});
  const [somenteFechado, setSomenteFechado] = React.useState(false);
  const [chamados, setChamados] = React.useState([]);
  const [chamadoTipos, setChamadoTipos] = React.useState([]);

  function chamadoChange(chamado) {
    setSelectedChamado(chamado);
  }

  function statusChamadoChange(valor) {
    setSomenteFechado(valor);
    setSelectedChamado({});
  }

  function abriNovoClick() {
    setShowModalCadastro(true);
  }

  function onCloseModalCadastro() {
    setShowModalCadastro(false);
  }

  async function getChamados(somenteFechados) {
    window.showLoader();

    let response = await ChamadoService.getChamadosAsync(somenteFechados);

    setChamados(response.data.data);

    window.hideLoader();
  }

  async function getSelectListTipoChamado() {
    let tipos = await ChamadoService.getTipoChamadoAsync();
    if (tipos.data) setChamadoTipos(tipos.data.data); //([...[{}], ...redes.data.data]);
  }

  React.useEffect(() => {
    (async () => {
      getChamados(false);
      getSelectListTipoChamado();
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      getChamados(somenteFechado);
    })();
  }, [somenteFechado]);

  function onChamadoCriado() {
    getChamados(false);
  }

  return (
    <Layout>
      <PageTitle title="Chamados" />
      <div className="row vh-75">
        <div className="col-4">
          <ListChamado
            onChangeChamado={chamadoChange}
            somenteFechado={somenteFechado}
            chamados={chamados}
          />
        </div>
        <div className="col-8">
          <ContentChamado
            chamado={selectedChamado}
            changeStatusChamado={statusChamadoChange}
            abrirNovoClick={abriNovoClick}
          />
        </div>
      </div>
      {showModalCadastro && (
        <ModalCadastroChamado
          setShowModal={setShowModalCadastro}
          showModal={showModalCadastro}
          onClose={onCloseModalCadastro}
          onChamadoCriado={onChamadoCriado}
          chamadoTipos={chamadoTipos}
        ></ModalCadastroChamado>
      )}
    </Layout>
  );
}
