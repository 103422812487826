import React from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFilter,
  faPlus,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../../components/page-title/index";
import ModalCadastroProduto from "../../components/modal-cadastro-produto";
import ModalConfirmacao from "../../components/modal-confirmacao";
import TablePagination from "../../components/table-pagination";
import Layout from "../../components/layout/base/index";

import ProdutoService from "../../services/produto-service";
import RepresentadaService from "../../services/representada-service";

import lib from "../../helpers/lib";

import "./styles.scss";

export default function Produto() {
  const [produto, setProduto] = React.useState({});

  const [filter, setFilter] = React.useState({
    codigoNaRepresentada: "",
    nome: "",
    representadaId: "",
    marcaId: "",
    produtoCategoriaId: "",
    situacao: "A",
  });

  const [pageData, setPageData] = React.useState({});
  const [representadas, setRepresentadas] = React.useState();
  const [representadaSelecionada, setRepresentadaSelecionada] =
    React.useState();

  const [showModal, setShowModal] = React.useState(false);
  const [showModalConfirmacao, setShowModalConfirmacao] = React.useState(false);

  const [marcas, setMarcas] = React.useState([]);
  const [categorias, setCategorias] = React.useState([]);

  React.useEffect(() => {
    filterProdutos();
    getSelectListRepresentada();
    getSelectListMarca();
    getSelectListCategoria();
  }, []);

  async function filterProdutos(page) {
    window.showLoader();

    let response = await ProdutoService.listPagedAsync({
      ...filter,
      pageNumber: page,
    });

    setPageData(response.data.data);

    window.hideLoader();
  }

  async function getExtratoProdutos() {
    window.showLoader();

    let response = await ProdutoService.getExtratoProdutos(filter);

    if (response && response.data) {
      let fileName = `extrato_produtos_${lib.getCurrentDateFilename()}.xlsx`;
      lib.downloadFile(response.data.data, fileName);
    }

    window.hideLoader();
  }

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAsync();
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  async function getSelectListMarca() {
    let marcas = await ProdutoService.listMarcasAsync();
    if (marcas.data)
      setMarcas(marcas.data.data.map(x => ({
        value: x.marcaId,
        label: x.nome,
      })
      ));
  }

  async function getSelectListCategoria() {
    let categorias = await ProdutoService.listCategoriasAsync();
    if (categorias.data)
      setCategorias(categorias.data.data.map(x => ({
        value: x.produtoCategoriaId,
        label: x.nome,
      })
      ));
  }

  function add() {
    setProduto({});
    setShowModal(true);
  }

  function update(produto) {
    setProduto(produto);
    setShowModal(true);
  }

  function onCloseModal() {
    setProduto({});
    filterProdutos();
  }

  async function changeStatus(produto) {
    setProduto(produto);
    setShowModalConfirmacao(true);
  }

  async function updateStatus(produto) {
    window.showLoader();

    let response = await ProdutoService.updateStatusAsync(produto);
    if (response.status === lib.statusCode.ok) await filterProdutos();

    window.hideLoader();
  }

  return (
    <Layout>
      <PageTitle title="Cadastro de Produto" />

      <div className="row mb-4">
        <div className="col-lg-10">
          <button type="button" className="btn btn-secondary" onClick={add}>
            + Novo Produto
          </button>
        </div>

        <div className="col-lg-2 d-flex align-items-center">
          <button
            type="button"
            className="btn btn-secondary w-100"
            onClick={() => getExtratoProdutos()}
          >
            Exportar
          </button>
        </div>
      </div>

      <div className="row mb-4 filter-bar4">
        <div className="col-lg-1">
          <label>Código</label>
          <input
            type="text"
            className="form-control"
            value={filter.codigoNaRepresentada}
            onChange={(e) =>
              setFilter({ ...filter, codigoNaRepresentada: e.target.value })
            }
          />
        </div>

        <div className="col-lg-2">
          <label>Nome</label>
          <input
            type="text"
            className="form-control"
            value={filter.nome}
            onChange={(e) => setFilter({ ...filter, nome: e.target.value })}
          />
        </div>

        <div className="col-lg-2" disabled>
          <label>Representada</label>
          <Select
            isClearable
            clearValue={() => {
              setRepresentadaSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={representadas}
            value={representadas?.find(
              (x) => x.value === representadaSelecionada
            )}
            onChange={(selectedValue) => {
              setFilter({ ...filter, representadaId: selectedValue?.value });
              setRepresentadaSelecionada(selectedValue?.value);
            }}
          />
        </div>

        <div className="col-lg-2">
          <label>Marca</label>
          <Select
            styles={customControlStyles}
            isClearable
            options={marcas}
            value={marcas?.find(
              (x) => x.value === filter.marcaId
            )}
            onChange={(selectedValue) => {
              console.log(selectedValue)
              setFilter({ ...filter, marcaId: selectedValue?.value })
            }}
          />
        </div>

        <div className="col-lg-2">
          <label>Categoria</label>
          <Select
            styles={customControlStyles}
            isClearable
            options={categorias}
            value={categorias?.find(
              (x) => x.value === filter.produtoCategoriaId
            )}
            onChange={(selectedValue) =>
              setFilter({ ...filter, produtoCategoriaId: selectedValue?.value })
            }
          />
        </div>

        <div className="col-lg-1">
          <label>Situação</label>
          <Select
            styles={customControlStyles}
            options={lib.selectListSituacao}
            value={lib.selectListSituacao.find(
              (x) => x.value === filter.situacao
            )}
            onChange={(selectedValue) =>
              setFilter({ ...filter, situacao: selectedValue.value })
            }
          />
        </div>

        <div className="col-lg-2 d-flex align-items-center">
          <button
            type="button"
            className="btn btn-secondary w-100"
            onClick={() => filterProdutos()}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>
        </div>
      </div>

      {pageData.data && (
        <>
          <table className="table table-cadastro">
            <thead>
              <tr>
                <th>Código</th>
                <th>Nome</th>
                <th>Marca</th>
                <th>Categoria</th>
                <th>Representada</th>
                <th>Situação</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {(pageData?.data || []).map((produto, index) => (
                <tr key={index}>
                  <td>{produto.codigoNaRepresentada}</td>
                  <td>{produto.nome}</td>
                  <td>{produto.marca}</td>
                  <td>{produto.produtoCategoria}</td>
                  <td>{produto.representada}</td>
                  <td>{produto.ativo ? "Ativo" : "Inativo"}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm me-1"
                      title="Editar"
                      onClick={() => update(produto)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      title={produto.ativo ? "Inativar" : "Reativar"}
                      onClick={() => changeStatus(produto)}
                    >
                      <FontAwesomeIcon
                        icon={produto.ativo ? faTrash : faRecycle}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <TablePagination
            pageData={pageData}
            onChangePage={(page) => filterProdutos(page)}
          />
        </>
      )}

      <ModalCadastroProduto
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={onCloseModal}
        entity={produto}
      />

      {/* Modal Confirmacao Ativar/Inativar Produto -------------------------------- */}
      <ModalConfirmacao
        showModal={showModalConfirmacao}
        setShowModal={setShowModalConfirmacao}
        onClose={(result) => {
          if (result === "yes") {
            updateStatus(produto);
          }
        }}
      >
        <p>
          Deseja mesmo <b>{produto?.ativo ? "inativar" : "reativar"}</b> o
          produto"{produto?.nome}"?
        </p>
      </ModalConfirmacao>
    </Layout>
  );
}
