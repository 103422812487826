import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCartShopping,
  faCartPlus,
  faClockRotateLeft,
  faChartLine,
  faEdit,
  faStore,
  faIndustry,
  faUser,
  faCircleQuestion,
  faWineBottle,
  faUsers,
  faFileLines,
  faDollarSign,
  faBarcode,
  faPercentage,
  faReceipt,
  faWallet,
  faTableCells,
  faListUl,
  faBullseye,
  faFileContract,
  faVial,
  faMoneyBillWave,
  faCookieBite,
  faSignOut,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { LoggedUserContext } from "../../contexts/logged-user-context";

import PedidoRascunhoService from "../../services/pedido-rascunho-service";

import lib from "../../helpers/lib";

import ImageLogo from "../../assets/imagens/logos/PRATICOVENDAS6.png";

import "./styles.scss";

export default function Sidebar({ lock }) {
  async function logout() {
    window.showLoader();

    localStorage.clear();

    await LoginService.logoutAsync();

    window.location = "/login";

    window.hideLoader();
  }

  const [userData, _] = React.useContext(LoggedUserContext);

  const navigate = useNavigate();

  async function redirectNovoPedido(pedidoTipoSigla) {
    window.showLoader();
    var result = await PedidoRascunhoService.getInicialAsync(pedidoTipoSigla);
    window.hideLoader();
    //navigate("/pedido/" + result.data.data.pedidoRascunhoId);
    navigate(
      `/pedido/${result.data.data.pedidoRascunhoId}?t=${pedidoTipoSigla}`
    );
  }

  const onClickToggler = () => {
    window.changeMenuState();
  };

  return (
    <div className="sidebar-menu">
      <button
              type="button"
              className="btn btn-navbar"
              onClick={onClickToggler}
            >
              <span className="navbar-toggler-icon">
                <FontAwesomeIcon icon={faBars} />
              </span>
            </button>
      <div id="sideMenu" className="sidenav">
        {lock ? (
          <></>
        ) : (
          <nav className="sidebar py-2 mb-4">
            {/* <button
              type="button"
              className="btn btn-navbar"
              onClick={onClickToggler}
            >
              <span className="navbar-toggler-icon">
                <FontAwesomeIcon icon={faBars} />
              </span>
            </button> */}
            <a href="/">
              <img className="logo" src={ImageLogo} alt="logo" />
            </a>
            <ul className="nav flex-column accordion" id="nav_accordion">
              <li className="nav-item">
                <Link to="/">
                  <FontAwesomeIcon icon={faHome} /> Home
                </Link>
              </li>
              {userData?.criaPedido && (
                <li className="nav-item accordion-item">
                  <h2 className="accordion-header" id="headingPedido">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#subMenuPedido"
                      aria-expanded="false"
                      aria-controls="subMenuPedido"
                    >
                      <FontAwesomeIcon icon={faCartShopping} /> Pedido
                    </button>
                  </h2>
                  <ul
                    id="subMenuPedido"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingPedido"
                    data-bs-parent="#nav_accordion"
                  >
                    <li>
                      <button
                        className="btn btn-link"
                        onClick={() =>
                          redirectNovoPedido(lib.pedidoTipos.venda.sigla)
                        }
                      >
                        <FontAwesomeIcon icon={faCartPlus} /> Nova Venda
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn btn-link"
                        onClick={() =>
                          redirectNovoPedido(lib.pedidoTipos.bonificacao.sigla)
                        }
                      >
                        <FontAwesomeIcon icon={faMoneyBillWave} /> Bonificação
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn btn-link"
                        onClick={() =>
                          redirectNovoPedido(lib.pedidoTipos.amostra.sigla)
                        }
                      >
                        <FontAwesomeIcon icon={faVial} /> Amostra
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn btn-link"
                        onClick={() =>
                          redirectNovoPedido(lib.pedidoTipos.degustacao.sigla)
                        }
                      >
                        <FontAwesomeIcon icon={faCookieBite} /> Degustação
                      </button>
                    </li>
                    <li>
                      <Link to="/pedido/historico">
                        <FontAwesomeIcon icon={faClockRotateLeft} /> Histórico
                        de Pedidos
                      </Link>
                    </li>
                    <li>
                      <Link to="/pedido/faturamento-relatorio">
                        <FontAwesomeIcon icon={faClockRotateLeft} /> Faturamento
                      </Link>
                    </li>
                    <li>
                      <Link to="/pedido/rascunhos">
                        <FontAwesomeIcon icon={faFileLines} /> Rascunhos
                      </Link>
                    </li>
                    {userData?.faturaPedidoEquipe && (
                      <>
                        <li className="item-menu-site">
                          <Link to="/pedido/faturamento">
                            <FontAwesomeIcon icon={faBarcode} />
                            Pedidos à Faturar
                          </Link>
                        </li>
                        <li className="item-menu-site">
                          <Link to="/pedido-faturamento/historico">
                            <FontAwesomeIcon icon={faClockRotateLeft} />{" "}
                            Histórico de Notas
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}
              {(userData?.cadastraRepresentada ||
                userData?.cadastraCliente ||
                userData?.cadastraClienteDistribuidor ||
                userData?.cadastraProduto ||
                userData?.cadastraTabelaPreco ||
                userData?.cadastraMeta ||
                userData?.cadastraComissao) && (
                <li className="nav-item accordion-item">
                  <h2 className="accordion-header" id="headingCadastro">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#subMenuCadastro"
                      aria-expanded="false"
                      aria-controls="subMenuCadastro"
                    >
                      <FontAwesomeIcon icon={faEdit} /> Cadastros
                    </button>
                  </h2>
                  <ul
                    id="subMenuCadastro"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingCadastro"
                    data-bs-parent="#nav_accordion"
                  >
                    {userData?.cadastraRepresentada && (
                      <li className="item-menu-site">
                        <Link to="/representada">
                          <FontAwesomeIcon icon={faIndustry} /> Representadas
                        </Link>
                      </li>
                    )}
                    {(userData?.cadastraCliente ||
                      userData?.cadastraClienteDistribuidor) && (
                      <li>
                        <Link to="/cliente">
                          <FontAwesomeIcon icon={faStore} /> Lojas
                        </Link>
                      </li>
                    )}
                    {userData?.cadastraProduto && (
                      <li className="item-menu-site">
                        <Link to="/produto">
                          <FontAwesomeIcon icon={faWineBottle} /> Produtos
                        </Link>
                      </li>
                    )}
                    {userData?.cadastraTabelaPreco && (
                      <li className="item-menu-site">
                        <Link to="/tabela-preco">
                          <FontAwesomeIcon icon={faDollarSign} /> Tabela de
                          Preços
                        </Link>
                      </li>
                    )}
                    {userData?.cadastraMeta && (
                      <li className="item-menu-site">
                        <Link to="/meta">
                          <FontAwesomeIcon icon={faChartLine} /> Meta
                        </Link>
                      </li>
                    )}
                    {userData?.cadastraComissao && (
                      <li className="item-menu-site">
                        <Link to="/comissao">
                          <FontAwesomeIcon icon={faPercentage} /> Comissão
                        </Link>
                      </li>
                    )}
                    {/*<Link to="/acordo">
                      <FontAwesomeIcon icon={faFileContract} /> Acordos
                    </Link>*/}
                  </ul>
                </li>
              )}
              {userData?.faturaPedidoEquipe && (
                <>
                  <li className="item-menu-site">
                    <Link to="/pedido-faturamento/comissao">
                      <FontAwesomeIcon icon={faReceipt} /> Notas em Aberto
                    </Link>
                  </li>
                  <li className="item-menu-site">
                    <Link to="/pedido-faturamento/comissao/historico">
                      <FontAwesomeIcon icon={faClockRotateLeft} /> Histórico de
                      Pagamentos
                    </Link>
                  </li>
                </>
              )}
              <li className="item-menu-site">
                <Link to="/equipe">
                  <FontAwesomeIcon icon={faUsers} /> Times
                </Link>
              </li>
              <li>
                <Link to="/meusdados">
                  <FontAwesomeIcon icon={faUser} /> Meus Dados
                </Link>
              </li>
              <li className="item-menu-site">
                <Link to="/relatorio">
                  <FontAwesomeIcon icon={faTableCells} /> Relatórios
                </Link>
              </li>

              {/* {(userData?.nome === "representante1" || userData?.nome === "FHORÁCIO REPRESENTAÇÕES") && (
                    <>
                      <li>
                        <Link to="/teste-home">
                          <FontAwesomeIcon icon={faReceipt} /> Teste Home
                        </Link>
                      </li>
                    </>
                  )} */}

              {/*<li>
                    <Link to="/chamados">
                      <FontAwesomeIcon icon={faCircleQuestion} /> Chamados
                    </Link> 
                </li>*/}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
}
