import React from 'react';
import ModalCadastro from '../_base/modal-cadastro';
import FormGroupHeader from '../_base/form-group-header';
import Errors from '../_base/errors';

import GeneralService from '../../services/general-service';
import EquipeService from '../../services/equipe-service';

import lib from '../../helpers/lib';

export default function ModalCadastroEquipeConvite(props) {
  const { showModal, setShowModal, onClose, equipe } = props;

  const formFields = {
    email: { key: "email", validate: () => equipeConvite.email?.length > 0 },
    textoConvite: { key: "textoConvite" },
  };

  const emptyEquipeConvite = {
    email: "",
    textoConvite: "",
  };

  const [equipeConvite, setEquipeConvite] = React.useState({ ...emptyEquipeConvite });
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    setErrors({});
    setEquipeConvite({ ...emptyEquipeConvite, equipeId: equipe?.equipeId });
  }, [equipe])

  function updateEquipeConvite(data) {
    setEquipeConvite({ ...equipeConvite, ...data });
  }

  async function saveAsync() {
    window.showLoader();

    if (!validateForm()) {
      window.hideLoader();
      return;
    }

    setErrors({});

    let response = await EquipeService.sendInviteAsync(equipeConvite)

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseEquipeConvite = response.data.data;

      if (onClose)
        onClose(responseEquipeConvite);

      setEquipeConvite({ ...emptyEquipeConvite })

      setShowModal(false);
      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  function validateForm() {
    let errors = {};

    for (let prop in formFields) {
      let field = formFields[prop];

      if (lib.isNullOrEmptyObject(formFields[field.key].validate) || formFields[field.key].validate())
        continue;

      errors[field.key] = [];
    }

    if (lib.isNullOrEmptyObject(errors))
      return true;

    setErrors(errors);

    return false;
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="CONVIDAR PARA O TIME"
    >
      <div className="modal-body">
        <div className="row justify-content-center">
          <div className="col-lg-8 pt-4">

            <div className={`row mb-4`}>
              <div className="col-lg-12 ">
                <h4 className="text-center">Para convidar um novo integrante para o seu time <b>{equipe?.nome}</b> preencha os dados abaixo para enviar o convite por email:</h4>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-lg-12">
                <input type="text" className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.email.key]) ? "" : "is-invalid"}`} placeholder="Email" value={equipeConvite.email} onChange={(e) => updateEquipeConvite({ email: e.target.value })} />
                <Errors errors={errors} keyName={formFields.email.key} />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-lg-12">
                <textarea className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.textoConvite.key]) ? "" : "is-invalid"}`} placeholder="Texto personalizado (opcional)" onChange={(e) => updateEquipeConvite({ textoConvite: e.target.value })}>
                  {equipeConvite.textoConvite}
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>ENVIAR CONVITE</button>
      </div>
    </ModalCadastro>
  )
}