import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

export default function InputImageProduto({ onChange, removeFile, file, urlImagemAtual }) {

  function onChangeEvent(e) {
    let files = e.target.files;

    let filesArr = Array.prototype.slice.call(files);

    onChange(filesArr[0]);
  }

  return (
    <div className="input-image-produto-container flex-fill d-flex flex-column justify-content-center align-items-center p-3">
      <label className="d-flex flex-column align-items-center">
        <input type="file" className="d-none" onChange={onChangeEvent} />

        {
          urlImagemAtual?.length > 0
            ? (
              <>
                <img className="img-fluid mb-2" src={urlImagemAtual} alt="imagem do produto" />
                <span>Clique para alterar a imagem do produto</span>
              </>
            )

            : (
              <>
                <FontAwesomeIcon icon={faCamera} />
                <span>Clique para adicionar uma imagem do produto</span>
              </>
            )
        }

        {file && <span><b>{file.name}</b></span>}

      </label>

    </div>
  )
}