import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import pt from "date-fns/locale/pt-BR";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedTextInput from "react-text-mask";

import "./styles.scss";

registerLocale("pt", pt);

const DatePickerCustom = ({
  name,
  disabled,
  selected,
  onChange,
  minDate,
  maxDate,
  className,
  heightAuto
}) => {
  const reactDatePickerRef = useRef(null);

  return (
    <div className={`date-picker-custom input-group ${heightAuto ? "h-auto" : ""}`}>
      <DatePicker
        locale="pt"
        name={name}
        dateFormat="dd/MM/yyyy"
        format="dd/MM/yyyy"
        disabled={disabled}
        selected={selected}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(value) => onChange(value, name)}
        className={`form-control ${className || ""}`}
        ref={reactDatePickerRef}
        customInput={
          <MaskedTextInput
            type="text"
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          />
        }
      />
      <span
        className={`input-group-text ${className || ""}`}
        onClick={() => {
          reactDatePickerRef.current.onInputClick();
        }}
      >
        <FontAwesomeIcon icon={faCalendar} />
      </span>
    </div>
  );
};

export default DatePickerCustom;
