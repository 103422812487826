import ServiceClient from "./service-client";
import { service_url } from "./service-constants";

class PedidoFaturamentoComissaoService {
  static async listAsync(filter) {
    try {
      var result = await ServiceClient.Get("/pedidofaturamentocomissao/list", filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get("/pedidofaturamentocomissao/list-paged", filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync (model) {
    try {
      var result = await ServiceClient.Post("/pedidofaturamentocomissao/update", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async removerAsync (model) {
    try {
      var result = await ServiceClient.Post("/pedidofaturamentocomissao/delete", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async deletePagamentoAsync (model) {
    try {
      var result = await ServiceClient.Post("/pedidofaturamentocomissao/delete-pagamento", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async descartarAsync (model) {
    try {
      var result = await ServiceClient.Post("/pedidofaturamentocomissao/descartar", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getFaturamentoNotaDetalhesAsync(pedidoFaturamentoId) {
    try {
      var result = await ServiceClient.Get(`/pedidofaturamentocomissao/get-faturamento-nota-detalhes/${pedidoFaturamentoId}`);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default PedidoFaturamentoComissaoService;
