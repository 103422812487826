class MesesConstants {
  static getMesString(numero) {
    let response = "";
    switch (numero) {
      case 1:
        response = "JAN";
        break;
      case 2:
        response = "FEV";
        break;
      case 3:
        response = "MAR";
        break;
      case 4:
        response = "ABR";
        break;
      case 5:
        response = "MAI";
        break;
      case 6:
        response = "JUN";
        break;
      case 7:
        response = "JUL";
        break;
      case 8:
        response = "AGO";
        break;
      case 9:
        response = "SET";
        break;
      case 10:
        response = "OUT";
        break;
      case 11:
        response = "NOV";
        break;
      case 12:
        response = "DEZ";
        break;
    }

    return response;
  }
}

export default MesesConstants;
