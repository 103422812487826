import React from 'react';
import { Modal } from 'react-bootstrap';

export default function ModalMessage(props) {
    const {
        showModal,
        setShowModal,
        onClose,
        title,
        children,
        closeText
    } = props;

    const [headerText, setHeaderText] = React.useState(title ? title : "Atenção!");
    const [btnCloseText, setBtnCloseText] = React.useState(closeText ? closeText : "Fechar");

    function close(result) {
        setShowModal(false);
        onClose(result);
    }

    return (
        <Modal className="modal" show={showModal} onHide={() => { close('no'); }} tabIndex="-1" centered>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{headerText}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { close(); }}></button>
                </div>

                <div className="modal-body">
                    {children}
                </div>

                <div className="modal-footer">
                    <button className="btn btn-primary" data-bs-dismiss="modal" onClick={() => { close(); }}>
                        {btnCloseText}
                    </button>
                </div>
            </div>
        </Modal>
    );
}