import React from 'react';
import NumberFormat from 'react-number-format';

const InputVolume = ({ onChange, initialValue, className, unidadeVolume }) => {
  const refValue = React.useRef(initialValue);

  console.log(initialValue);

  return (
    <NumberFormat
      className={`form-control ${className}`}
      displayType={"input"}
      thousandSeparator={false}
      decimalSeparator={","}
      suffix={` ${unidadeVolume}`}
      onFocus={(e) => {
        setTimeout(() => {
          e.target.select();
        }, 5);
      }}
      value={refValue.current}
      onValueChange={(values, sourceInfo) => {
        if (sourceInfo.source === "event") {
          if (
            values.floatValue &&
            values.floatValue < 0
          ) {
            values.floatValue = 0;
          }

          refValue.current = values.floatValue;
          onChange(values.floatValue);
        }
      }}
    />
  );
}

export default InputVolume;