import React from "react";
import "./styles.scss";

export default function FormGroupHeader(props) {
  let { text, className } = props;

  return (
    <div className={`form-group-header ${className}`}>
      <div className="row h-100 align-items-center">
        <div className="col-auto">
          <div className="d-inline-block">{text}</div>
          {props.children}
        </div>
        <div className="col">
          <hr></hr>
        </div>
      </div>
    </div>
  );
}
