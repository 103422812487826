import React from "react";
import NumberFormat from "react-number-format";
import lib from "../../helpers/lib";
import FormGroupHeader from "../../components/_base/form-group-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import { LoggedUserContext } from "../../contexts/logged-user-context";
import ModalCadastro from "../../components/_base/modal-cadastro";
import Errors from "../../components/_base/errors/index";
import DatePickerCustom from "../../components/_base/DatePicker";

import PedidoService from "../../services/pedido-service";
import PedidoFaturamentoService from "../../services/pedido-faturamento-service";
import UnidadeService from "../../services/unidade-service";

// import "./styles.scss";

export default function ModalPedidoFaturamento(props) {
  const { model, showModal, setShowModal, onClose } = props;

  const [userData, _] = React.useContext(LoggedUserContext);

  const [resumo, setResumo] = React.useState({});
  const [nota, setNota] = React.useState("");
  const [errors, setErrors] = React.useState("");
  const [dataNota, setDataNota] = React.useState();
  const [unidades, setUnidades] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      let unidadesResult = await UnidadeService.getSelectList();
      if (unidadesResult.data) setUnidades(unidadesResult.data.data);
    })();

  }, []);

  React.useEffect(() => {
    setErrors(null);
    setResumo({});
    setNota("");
    setDataNota("");
    getResumo();
  }, [model]);

  async function getResumo() {
    if (model !== undefined && model !== null) {
      window.showLoader();

      let response = await PedidoService.GetResumoAsync(
        model.pedidoId,
        model.representadaId
      );

      let resumo = response?.data?.data;

      //get pedidoFaturamento
      if (model?.pedidoFaturamentoId > 0) {
        let response = await PedidoFaturamentoService.getAsync(model.pedidoFaturamentoId);

        let pedidoFaturamento = response?.data?.data;
        
        setNotaDados(resumo, pedidoFaturamento)
      }

      setResumo(resumo);

      window.hideLoader();
    }
  }

  async function setNotaDados(resumo, pedidoFaturamento) {
    setNota(pedidoFaturamento.notaFaturamento);

    setDataNota(lib.getDate(pedidoFaturamento.dataFaturamento));

    //set produtos
    for (let produto of (resumo?.produtos || [])) {
      let p = pedidoFaturamento.pedidoFaturamentoProdutos.find(pfp => pfp.produtoId === produto.produtoId)
      produto.quantidadeFatura = p.quantidade
    }
  }

  function SomaTotal() {
    if (resumo === undefined || resumo.produtos === undefined) {
      return 0;
    }

    let total = 0;
    for (var i = 0; i < resumo.produtos.length; i++) {
      let item = resumo.produtos[i];
      total +=
        parseFloat(item.faturamentoQuantidade) * parseFloat(item.faturamentoValorUnidade);
    }

    return total;
  }

  async function recalcularValores(item) {
    window.showLoader();

    let model = {
      pedidoRepresentadaProdutoId: item.pedidoRepresentadaProdutoId,
      unidadeMedidaId: item.faturamentoUnidadeId,
      faturamentoQuantidade: item.quantidadeNaoFaturada,
      faturamentoValorUnitario: item.faturamentoValorUnidade
    }

    let calculoResult = await PedidoFaturamentoService.calculaValoresAsync(model);
    if (calculoResult.data) {
      item.faturamentoValorUnidade = calculoResult.data.data.faturamentoValorUnitario;
      item.quantidadeNaoFaturada = calculoResult.data.data.faturamentoQuantidade;
      setResumo({ ...resumo });
    }
    window.hideLoader();
  }

  async function saveAsync() {
    window.showLoader();
    let produtos = [];
    setErrors(null);
    for (var i = 0; i < resumo.produtos.length; i++) {
      produtos.push((({ pedidoRepresentadaProdutoId, quantidadeFatura, faturamentoQuantidade, quantidadeNaoFaturada, quantidadeFaturada, produtoNome, faturamentoUnidadeId }) => ({ pedidoRepresentadaProdutoId, quantidadeFatura, faturamentoQuantidade, quantidadeNaoFaturada, quantidadeFaturada, produtoNome, faturamentoUnidadeId }))(resumo.produtos[i]));
    }

    let pedidoFaturamentoModel = {
      pedidoFaturamentoId: model.pedidoFaturamentoId,
      notaFaturamento: nota,
      dataNota: dataNota,
      pedidoRepresentadaId: resumo.produtos[0].pedidoRepresentadaId,
      produtos: produtos
    }

    const response = await PedidoFaturamentoService.saveAsync(pedidoFaturamentoModel);

    if (response.data.data === false && response.data.errors) {
      setErrors(response.data.errors);
    }
    else {
      if (onClose)
        onClose();
    }
    
    window.hideLoader();
    
  }


  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="FATURAR PEDIDO"
      onClose={onClose}
    >
      <div className="modal-body modal-pedido-faturamento">
        <div className="row mb-2">
          <div className="col-lg-2 text-end">Pedido:</div>
          <div className="col-lg-4">
            {resumo?.codigoPedidoLoja
              ? resumo?.codigoPedidoLoja
              : resumo?.codigoPedidoRepresentada}
          </div>
          <div className="col-lg-2 text-end">Data:</div>
          <div className="col-lg-4">{lib.getStringFromDate(resumo?.data)}</div>
          {resumo?.dataMaximaEntrega && (
            <>
              <div className="col-lg-6 text-end"></div>
              <div className="col-lg-2 text-end">Entregar até:</div>
              <div className="col-lg-4">{lib.getStringFromDate(resumo.dataMaximaEntrega)}</div>
            </>
          )}
        </div>
        <FormGroupHeader text="DADOS DA LOJA" />
        <div className="row mb-2">
          <div className="col-lg-6">
            <div className="row mb-2">
              <div className="col-lg-4 text-end">Razão Social:</div>
              <div className="col-lg-8">{resumo?.loja?.razaoSocial}</div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-4 text-end">Nome Fantasia:</div>
              <div className="col-lg-8">{resumo?.loja?.nomeFantasia}</div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-4 text-end">CNPJ:</div>
              <div className="col-lg-8">{resumo?.loja?.cnpj}</div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-4 text-end">Telefone:</div>
              <div className="col-lg-8">{resumo?.loja?.contatoTelefone}</div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-4 text-end">Email:</div>
              <div className="col-lg-8">{resumo?.loja?.contatoEmail}</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row mb-2">
              <div className="col-lg-4 text-end">Endereço:</div>
              <div className="col-lg-8">
                {resumo?.loja?.logradouro}, {resumo?.loja?.numero}{" "}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-4"></div>
              <div className="col-lg-8">{resumo?.loja?.bairro}</div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-4"></div>
              <div className="col-lg-8">
                {resumo?.loja?.cidade} - {resumo?.loja?.estado}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-4"></div>
              <div className="col-lg-8">{resumo?.loja?.cep}</div>
            </div>
          </div>
        </div>

        <FormGroupHeader text="DADOS DA REPRESENTADA" />
        <div className="row mb-2">
          <div className="col-lg-9">{resumo?.representada?.nomeFantasia}</div>
          <div className="col-lg-3">CNPJ: {resumo?.representada?.cnpj}</div>
        </div>

        {/* Lista Produtos ------------------------------------------------------------ */}
        <FormGroupHeader text="PRODUTOS" />
        <div className="table-responsive">
          <table className="table table-cadastro">
            <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th className="text-center">Unid. Medida</th>
                <th className="text-center">Valor Unitário</th>
                <th className="text-center">Qtd. Pedido</th>
                <th className="text-center">Total Pedido</th>
                <th className="text-center">Qtd. Faturada</th>
                <th className="text-center">Total Faturado</th>
              </tr>
            </thead>
            <tbody>
              {(resumo?.produtos || []).map((item, index) =>
                <tr key={index}>
                  <td>{item.produtoCodigoNaRepresentada}</td>
                  <td>{item.produtoNome}</td>
                  <td className="text-center">{item.faturamentoUnidadeSigla}</td>
                  <td className="text-center">
                    <NumberFormat
                      value={item.faturamentoValorUnidade}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="text-center">
                    <NumberFormat
                      value={item.faturamentoQuantidade}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      fixedDecimalScale={false}
                      decimalScale={2}
                    />
                  </td>
                  <td className="text-center">
                    <NumberFormat
                      value={parseFloat(item.faturamentoQuantidade) *
                        parseFloat(item.faturamentoValorUnidade)}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="text-center">
                    <NumberFormat
                      value={item.quantidadeFaturada}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={4}
                      fixedDecimalScale={false}
                    />
                  </td>
                  <td className="text-center">
                    <NumberFormat
                      value={item.valorFaturado}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td align="right">Totais:</td>
                <td className="text-center">
                  <NumberFormat
                    value={SomaTotal()}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
                <td></td>
                <td className="text-center">
                  <NumberFormat
                    value={(resumo?.produtos || []).reduce((total, p) => total + p.valorFaturado, 0)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {resumo?.observacao && (
          <>
            <FormGroupHeader text="OBSERVAÇÔES" />
            <div>{resumo.observacao}</div>
          </>
        )}
        {resumo?.pedidoFaturamento && resumo?.pedidoFaturamento.length > 0 &&
          <><FormGroupHeader text="NOTAS JÁ FATURADAS" />
            <div className="table-responsive">
              <table className="table table-cadastro">
                <thead>
                  <tr>
                    <th className="text-center">Nº Nota</th>
                    <th className="text-center">Data</th>
                    <th className="text-center">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {(resumo?.pedidoFaturamento || []).map((item, index) =>
                    <tr key={index}>
                      <td className="text-center">{item.notaFaturamento}</td>
                      <td className="text-center">{lib.getStringFromDate(item.dataFaturamento)}</td>
                      <td className="text-center">
                        <NumberFormat
                          value={item.valorTotalFaturado}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        }

        {
          model?.pedidoFaturamentoId > 0
            ? <FormGroupHeader text="EDITAR NOTA" />
            : <FormGroupHeader text="NOVO FATURAMENTO" />
        }

        {errors &&
          <>
            <Errors errors={errors} keyName="" />
            <hr></hr>
          </>
        }
        <div className="row form-group">
          <div className="col-lg-2">
            <label className="col-form-label">Nº da Nota:</label>
          </div>

          <div className="col-lg-8 col-xl-5">
            <input
              type="text"
              className="form-control campo-nota"
              name="nota"
              onChange={(event) => { setNota(event.target?.value) }}
              value={nota}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-lg-2">
            <label className="col-form-label">Data da Nota:</label>
          </div>

          <div className="col-lg-8 col-xl-5">
            <DatePickerCustom
              name="dataNota"
              selected={dataNota}
              onChange={(value) => {
                setDataNota(value);
              }}
            />
          </div>
        </div>
        <div className="div-table-cadastro">
          <table className="table table-cadastro">
            <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th className="text-center">Unid. Medida</th>
                <th className="text-center">Valor Unitário</th>
                <th className="text-center">Qtd. não Faturada</th>
                <th className="text-center">Qtd. Fatura</th>
                <th className="text-center">Total Fatura</th>
              </tr>
            </thead>
            <tbody>
              {(resumo?.produtos || []).map((item, index) =>
                <tr>
                  <td>{item.produtoCodigoNaRepresentada}</td>
                  <td>{item.produtoNome}</td>
                  <td className="text-center">
                    {resumo?.pedidoFaturamento && resumo?.pedidoFaturamento.length > 0 ?
                      item.faturamentoUnidadeSigla :
                      <Select
                        options={item.produtoUnidadesMedidas.map((unidade) => ({
                          label: unidade.unidadeMedida,
                          value: unidade.unidadeMedidaId,
                        }))}
                        isClearable={false}
                        placeholder=""
                        onChange={(option) => {
                          item.faturamentoUnidadeId = option == null
                            ? null
                            : parseInt(option.value);

                          item.faturamentoUnidadeNome == unidades[0].options.filter((u) => {
                            return (
                              parseInt(u.value) ===
                              item.faturamentoUnidadeId
                            );
                          })[0].label;

                          recalcularValores(item);
                        }}
                        value={
                          unidades.length > 0 &&
                          unidades[0].options &&
                          unidades[0].options.filter((u) => {
                            return (
                              parseInt(u.value) ===
                              item.faturamentoUnidadeId
                            );
                          })[0]
                        }
                      />}
                  </td>
                  <td className="text-center">
                    <NumberFormat
                      value={item.faturamentoValorUnidade}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />/{unidades[0].options.filter((u) => {
                      return (
                        parseInt(u.value) ===
                        item.faturamentoUnidadeId
                      );
                    })[0].label}
                  </td>
                  <td className="text-center">
                    <NumberFormat
                      value={item.quantidadeNaoFaturada < 0 ? 0 : item.quantidadeNaoFaturada}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      fixedDecimalScale={false}
                      decimalScale={4}
                    />
                  </td>
                  <td className="text-center">
                    <NumberFormat
                      className="text-center"
                      displayType={"input"}
                      thousandSeparator={false}
                      decimalSeparator={","}
                      onValueChange={(values, sourceInfo) => {
                        item.quantidadeFatura = values.floatValue;
                        setResumo({ ...resumo });
                      }}
                      value={item.quantidadeFatura}
                    />
                  </td>
                  <td className="text-center">
                    <NumberFormat
                      value={(item.quantidadeFatura ?? 0) * item.faturamentoValorUnidade}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td align="right">Total:</td>
                <td className="text-center">
                  <NumberFormat
                    value={(resumo?.produtos || []).reduce((total, p) => total + ((p.quantidadeFatura ?? 0) * p.faturamentoValorUnidade), 0)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>SALVAR</button>
      </div>
    </ModalCadastro>
  );
}
