import React, { useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";

import PageTitle from "../../components/page-title/index";
import Layout from "../../components/layout/base/index";
import DatePickerCustom from "../../components/_base/DatePicker";
import ModalFaturamentoNota from "../../components/modal-faturamento-nota";
import lib from "../../helpers/lib";

import ExtratoService from "../../services/extrato-service";
import RepresentadaService from "../../services/representada-service";



export default function Extrato() {

  const [representadas, setRepresentadas] = React.useState();
  const [codigo, setCodigo] = React.useState("");
  const [nota, setNota] = React.useState("");
  const [dataInicial, setDataInicial] = React.useState(lib.getPrimeiroDiaMesAnterior());
  const [dataFinal, setDataFinal] = React.useState(lib.getUltimoDiaMesAnterior());
  const [representadaSelecionada, setRepresentadaSelecionada] = React.useState();
  const [pedidoFaturamentoNotas, setPedidoFaturamentoNotas] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [modalFaturamentoNotaData, setModalFaturamentoNotaData] = React.useState({})

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAsync();
    if (representadas.data)
      setRepresentadas(representadas.data.data);
  }

  async function getListFaturamento() {
    let filter = {
      codigo: codigo,
      nota: nota,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      representadaId: representadaSelecionada,
    }

    window.showLoader();
    let result = await ExtratoService.listAsync(filter);
    setPedidoFaturamentoNotas(result.data.data);
    window.hideLoader();
  }

  useEffect(() => {
    (async () => {
      await getSelectListRepresentada();
    })();

    (async () => {
      getListFaturamento();
    })();
  }, []);

  function onEnterKey(event) {
    if (event.key === 'Enter') {
      getListFaturamento()
    }
  }

  // ------------------------------------------------------------------------------------------
  // Renderizacao
  return (
    <Layout>
      <div className="pedido-faturamento-page">
        <PageTitle title="Extrato"></PageTitle>

        {/* Filter ------------------------------------------------------------------------------ */}
        <div className="row filter-bar2">

          {/* Nota  --------------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Nº Nota</label>
            <input type="text" className="form-control" value={nota} onChange={(ev) => { setNota(ev.target.value); }} onKeyPress={onEnterKey} />
          </div>

          {/* Pedido  --------------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Pedido</label>
            <input type="text" className="form-control" value={codigo} onChange={(ev) => { setCodigo(ev.target.value); }} onKeyPress={onEnterKey} />
          </div>

          {/* Representada ---------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3" disabled>
            <label>Representada</label>
            <Select className="select-form-control"
              isClearable
              clearValue={() => { setRepresentadaSelecionada({}) }}
              placeholder="Selecione..."
              options={representadas}
              value={representadas?.find(
                (x) => x.value === representadaSelecionada
              )}
              onChange={(selectedValue) => {
                setRepresentadaSelecionada(selectedValue?.value);
              }}
            />
          </div>
        </div>

        {/* Filter buttons -------------------------------------------------------------------------- */}
        <div className="row filter-bar2">
          {/* Data ---------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Data Inicial do Pedido</label>
            <DatePickerCustom name="dataInicial"
              selected={dataInicial}
              onChange={(value) => {
                setDataInicial(value);
              }}
            />
          </div>
          <div className="col-xl-3 mb-3">
            <label>Data Final do Pedido</label>
            <DatePickerCustom name="dataFinal"
              selected={dataFinal}
              onChange={(value) => {
                setDataFinal(value);
              }}
            />
          </div>
          <div className="col-xl-3 mb-3 d-flex">
            {/* Botão Filtrar --------------------------------------------------------------------- */}
            <button type="button" className="btn btn-secondary btn-sm btn-action me-2" onClick={getListFaturamento}>
              <FontAwesomeIcon icon={faFilter} /> Filtrar
            </button>
          </div>
        </div>

        {
          lib.isNullOrEmptyObject(errors)
            ? null
            : (
              <div className="alert alert-danger">
                <ul className="m-0">
                  {
                    Object.values(errors).map(message => <li>{message}</li>)
                  }
                </ul>
              </div>
            )
        }

        {(pedidoFaturamentoNotas || []).length === 0 &&
          <div className="row">
            <div className="col text-center">Não foi encontrado nenhum registro.</div>
          </div>}
        {(pedidoFaturamentoNotas || []).length > 0 &&
          <div className="row">
            <div className="col table-responsive">
              <table className="table text-center">
                <thead>
                  <tr className="bg-primary text-light">
                    <th>Nº NOTA</th>
                    <th>DATA NOTA</th>
                    <th>PEDIDO</th>
                    <th>DATA PEDIDO</th>
                    <th>REPRESENTADA</th>
                    <th>VALOR</th>
                    <th>COMISSÃO PREVISTA </th>
                    <th width={"250px"}>COMISSÃO RECEBIDA</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (pedidoFaturamentoNotas || []).length > 0
                      ? (pedidoFaturamentoNotas || []).map((faturamentoNota, index) =>
                      (
                        <tr key={index}>
                          <td><button type="button" className="btn btn-sm btn-link" onClick={() => {
                            setModalFaturamentoNotaData({
                              pedidoFaturamentoId: faturamentoNota.pedidoFaturamentoId,
                              showModal: true
                            })
                          }}>{faturamentoNota.nota}</button></td>
                          <td>{lib.getStringFromDate(faturamentoNota.dataNota)}</td>
                          <td>{faturamentoNota.codigoPedido}</td>
                          <td>{lib.getStringFromDate(faturamentoNota.dataPedido)}</td>
                          <td>{faturamentoNota.representada}</td>
                          <td>
                            <NumberFormat
                              value={faturamentoNota.valor}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={faturamentoNota.comissaoPrevista}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={faturamentoNota.comissaoRecebida}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </tr>
                      ))
                      : null
                  }

                  <tr className="bg-primary text-light">
                    <td className="p-2">Total:</td>
                    <td colSpan="4" className="text-end"></td>
                    <td>
                      <NumberFormat
                        value={(pedidoFaturamentoNotas || []).reduce((total, f) => total + (f.valor ?? 0), 0)}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={(pedidoFaturamentoNotas || []).reduce((total, f) => total + (f.comissaoPrevista ?? 0), 0)}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={(pedidoFaturamentoNotas || []).reduce((total, f) => total + (f.comissaoRecebida ?? 0), 0)}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        }
      </div>

      <ModalFaturamentoNota
        pedidoFaturamentoId={modalFaturamentoNotaData.pedidoFaturamentoId}
        showModal={modalFaturamentoNotaData.showModal}
        setShowModal={() => setModalFaturamentoNotaData({ ...modalFaturamentoNotaData, showModal: false })}
      />
    </Layout >
  );
};