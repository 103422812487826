import React from "react";
import Select from "react-select";

import RelatorioService from "../../services/relatorio-service";
import lib from "../../helpers/lib";
import RedeService from "../../services/rede-service";
import RepresentadaService from "../../services/representada-service";
import DatePickerCustom from "../../components/_base/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFilePdf,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import MesesConstants from "./meses-constants";

const pdfMake = require("pdfmake/build/pdfmake");
const pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

export default function RelatorioAnalitico() {
  const [redes, setRedes] = React.useState();
  const [redeSelecionada, setRedeSelecionada] = React.useState();
  const [representadas, setRepresentadas] = React.useState();
  const [representadaSelecionada, setRepresentadaSelecionada] =
    React.useState();
  const [dataInicial, setDataInicial] = React.useState();
  const [dataFinal, setDataFinal] = React.useState();

  const [resumoMensal, setResumoMensal] = React.useState([]);
  const [resumoProdutos, setResumoProdutos] = React.useState([]);
  const [totais, setTotais] = React.useState([]);
  const [cabecalhoMeses, setCabecalhoMeses] = React.useState([]);

  const [filter, setFilter] = React.useState({
    redeId: null,
    representadaId: "",
    dataInicio: "",
    dataFim: "",
  });

  React.useEffect(() => {
    const dataFinal = new Date();
    const dataInicial = new Date();
    dataInicial.setDate(dataInicial.getDate() - 90);

    setFilter({ ...filter, dataInicio: dataInicial, dataFim: dataFinal });
    setDataInicial(dataInicial);
    setDataFinal(dataFinal);
    getSelectListRede();
    (async () => {
      await getSelectListRepresentada();
    })();
  }, []);

  async function getSelectListRede() {
    const result = await RedeService.getSelectListAllAsync();
    if (result.data) setRedes(result.data.data);
  }

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAllAsync();
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  function representadaIsSet() {
    return representadaSelecionada == undefined ||
      representadaSelecionada == "" ||
      Object.keys(representadaSelecionada).length === 0
      ? false
      : true;
  }

  function onRepresentadaChange(representada) {
    const rawFilter = { ...filter, representadaId: representada?.value };
    getRelatorio(rawFilter);
  }

  function onClearRepresentada() {
    const dataFinal = new Date();
    const dataInicial = new Date();
    dataInicial.setDate(dataInicial.getDate() - 90);

    setFilter({ ...filter, dataInicio: dataInicial, dataFim: dataFinal });
    setDataInicial(dataInicial);
    setDataFinal(dataFinal);
    setRedes(0);

    setResumoMensal([]);
    setResumoProdutos([]);
    setTotais([]);
    getFaixaMeses([]);
  }

  function updateFilter() {
    const rawFilter = {
      dataInicio: dataInicial,
      dataFim: dataFinal,
      redeId:
        redeSelecionada == undefined ||
        (Object.keys(redeSelecionada).length === 0 &&
          redeSelecionada.constructor === Object)
          ? 0
          : redeSelecionada,
      representadaId: representadaSelecionada,
    };
    setFilter({
      ...filter,
      rawFilter,
    });

    getRelatorio(rawFilter);
    //enviar pesquisa com rawFilter
  }

  async function getRelatorio(filter) {
    window.showLoader();
    let response = await RelatorioService.getRelatorioAnaliticoAsync(filter);

    setResumoMensal(response?.data?.data?.listResumoMensal ?? []);
    setResumoProdutos(response?.data?.data?.listResumoProdutos ?? []);
    setTotais(response?.data?.data?.totalMeses ?? []);
    getFaixaMeses(response?.data?.data?.listResumoProdutos ?? []);
    window.hideLoader();
  }

  function getFaixaMeses(resumoProdutos) {
    if (resumoProdutos?.length > 0) {
      setCabecalhoMeses(
        resumoProdutos[0].totalMeses.map((t) => ({
          mes: t.mes,
          ano: t.ano,
          titulo: `${t.mes}/${t.ano}`,
        }))
      );

      return;
    }

    setCabecalhoMeses([]);
  }

  async function downloadExtratoExcel() {
    window.showLoader();
    const rawFilter = {
      dataInicio: dataInicial,
      dataFim: dataFinal,
      redeId: redeSelecionada,
      representadaId: representadaSelecionada,
    };
    setFilter({
      ...filter,
      rawFilter,
    });

    let result = await RelatorioService.getExcelRelatorioAnaliticoEmBytesAsync(
      rawFilter
    );

    if (result && result.data) {
      let fileName = `relatorio_analitico_${lib.getCurrentDateFilename()}.xlsx`;
      lib.downloadFile(result.data.data, fileName);
      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  async function downloadExtratoPdf() {
    window.showLoader();
    const rawFilter = {
      dataInicio: dataInicial,
      dataFim: dataFinal,
      redeId: redeSelecionada,
      representadaId: representadaSelecionada,
    };
    setFilter({
      ...filter,
      rawFilter,
    });

    let result = await RelatorioService.getRelatorioAnaliticoEmPdfAsync(
      rawFilter
    );

    if (result && result.data) {
      let fileName = `relatorio_analitico_${lib.getCurrentDateFilename()}.pdf`;
      lib.downloadFile(result.data.data, fileName);

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  return (
    <div className="container relatorio">
      <div className="row">
        {/* Representada ---------------------------------------------------------------------- */}
        <div className="col-xs-12 col-md-3" disabled>
          <label>Representada</label>
          <Select
            // className="select-form-control"
            styles={customControlStyles}
            isClearable
            clearValue={() => {
              setRepresentadaSelecionada({});
              onClearRepresentada();
            }}
            placeholder="Selecione..."
            options={representadas}
            value={representadas?.find(
              (x) => x.value === representadaSelecionada
            )}
            onChange={(selectedValue) => {
              setRepresentadaSelecionada(selectedValue?.value);
              setFilter({ ...filter, representadaId: selectedValue?.value });
              onRepresentadaChange(selectedValue);
            }}
          />
        </div>

        {/* Rede ------------------------------------------------------------------------------ */}
        {representadaIsSet() && (
          <div className="col-xs-12 col-md-3">
            <label>Rede</label>
            <Select
              styles={customControlStyles}
              isClearable
              clearValue={() => {
                setRedeSelecionada({});
              }}
              placeholder="Selecione..."
              options={redes}
              value={redes?.find((x) => x.value === redeSelecionada)}
              onChange={(selectedValue) => {
                setRedeSelecionada(selectedValue?.redeId ?? {});
                setFilter({ ...filter, redeId: selectedValue?.redeId });
              }}
            />
          </div>
        )}

        {representadaIsSet() && (
          <div className="col-xs-12 col-md-3">
            <label>Data Inicial </label>
            <DatePickerCustom
              name="dataInicial"
              selected={dataInicial}
              onChange={(value) => {
                setDataInicial(value);
              }}
            />
          </div>
        )}

        {representadaIsSet() && (
          <div className="col-xs-12 col-md-3">
            <label>Data Final</label>
            <DatePickerCustom
              name="dataFinal"
              selected={dataFinal}
              onChange={(value) => {
                setDataFinal(value);
              }}
            />
          </div>
        )}
      </div>

      {representadaIsSet() && (
        <div className="row ">
          <div className="col-12 d-flex justify-content-center ">
            {/* Botão Filtrar --------------------------------------------------------------------- */}
            <button
              type="button"
              style={{ marginTop: "20px", width: "300px" }}
              className="btn btn-secondary btn-sm btn-action"
              onClick={updateFilter}
            >
              <FontAwesomeIcon icon={faFilter} /> Filtrar
            </button>
            <button
              type="button"
              style={{ marginTop: "20px", width: "300px", marginLeft: "20px" }}
              className="btn btn-secondary btn-sm btn-action"
              onClick={downloadExtratoExcel}
            >
              <FontAwesomeIcon icon={faFileExcel} /> Exportar Excel
            </button>
            <button
              type="button"
              style={{ marginTop: "20px", width: "300px", marginLeft: "20px" }}
              className="btn btn-secondary btn-sm btn-action"
              onClick={downloadExtratoPdf}
            >
              <FontAwesomeIcon icon={faFilePdf} /> Exportar PDF
            </button>
          </div>
        </div>
      )}

      {representadaIsSet() && (
        <div id="relatorios">
          <div className="container" style={{ marginTop: "25px" }}>
            <div className="table-responsive">
              <Table striped bordered hover width="100%">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>MÊS/ANO</th>
                    <th style={{ textAlign: "center" }}>PEDIDO</th>
                    <th style={{ textAlign: "center" }}>FATURADO</th>
                    <th style={{ textAlign: "center" }}>
                      PARCIAL/AINDA NÃO FATURADO
                    </th>
                    <th style={{ textAlign: "center" }}>RUPTURA</th>
                    <th style={{ textAlign: "center" }}>TOTAL NÃO FATURADO</th>
                  </tr>
                </thead>
                <tbody>
                  {(resumoMensal || []).map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        {MesesConstants.getMesString(item.mes)}/{item.ano}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <NumberFormat
                          value={item.valor}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <NumberFormat
                          value={item.valorFaturado}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <NumberFormat
                          value={item.valorNaoFaturado}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <NumberFormat
                          value={item.valorRuptura}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <NumberFormat
                          value={item.valorTotalNaoFaturado}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <br />
          <br />

          <div className="container">
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th rowSpan={2} style={{ textAlign: "center" }}>
                      COD
                    </th>
                    <th rowSpan={2} style={{ textAlign: "center" }}>
                      UNIDADE FAT
                    </th>
                    <th rowSpan={2} style={{ textAlign: "center" }}>
                      PRODUTO
                    </th>
                    <th rowSpan={2} style={{ textAlign: "center" }}>
                      PREÇO
                    </th>
                    {(cabecalhoMeses || []).map((item, index) => (
                      <th
                        key={index}
                        colSpan={2}
                        style={{ textAlign: "center" }}
                      >
                        <span style={{ width: "160px", float: "center" }}>
                          {MesesConstants.getMesString(item.mes) +
                            "/" +
                            item.ano}
                        </span>
                      </th>
                    ))}
                  </tr>
                  <tr>
                    {(cabecalhoMeses || []).map((item, index) => (
                      <React.Fragment key={index}>
                        <th style={{ width: "80px" }}>QTD</th>
                        <th style={{ width: "80px" }}>Valor</th>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(resumoProdutos || []).map((item, index) => (
                    <tr key={index}>
                      <td className="table-meses">{item.codigo}</td>
                      <td className="table-meses">{item.unidade}</td>
                      <td className="table-meses">
                        <div style={{ minWidth: "270px" }}>{item.produto}</div>
                      </td>
                      <td className="table-meses">
                        <div style={{ minWidth: "100px" }}>
                          <NumberFormat
                            value={item.preco}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </div>
                      </td>
                      {(cabecalhoMeses || []).map((cabecalho, index) => (
                        <React.Fragment key={index}>
                          <td
                            align="center"
                            valign="middle"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRight: "1px solid #DEE2E6",
                            }}
                          >
                            {item?.totalMeses?.find(
                              (i) =>
                                i.ano == cabecalho.ano && i.mes == cabecalho.mes
                            )?.qtdeFaturada ?? 0}
                          </td>
                          <td
                            align="center"
                            valign="middle"
                            style={{
                              width: "80px",
                              height: "80px",
                            }}
                          >
                            <NumberFormat
                              value={
                                item?.totalMeses?.find(
                                  (i) =>
                                    i.ano == cabecalho.ano &&
                                    i.mes == cabecalho.mes
                                )?.valorFaturado ?? 0
                              }
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4} align="right">
                      TOTAL
                    </td>
                    {(cabecalhoMeses || []).map((cabecalho, index) => (
                      <td colSpan={2} key={index} className="table-meses">
                        <NumberFormat
                          value={
                            totais?.find(
                              (i) =>
                                i.ano == cabecalho.ano && i.mes == cabecalho.mes
                            )?.valorFaturado ?? 0
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
