import React from "react";
import TablePagination from "../../components/table-pagination";
import ModalConfirmacao from "../../components/modal-confirmacao";
import ModalCarteira from "../../components/modal-carteira";

import EquipeService from "../../services/equipe-service";

import lib from "../../helpers/lib";

export default function MemberTeams() {

  const [memberTeamsPageData, setMemberTeamsPageData] = React.useState({});

  const [refuseData, setRefuseData] = React.useState({
    equipe: {},
    showModalRefuse: false
  })

  const [leaveData, setLeaveData] = React.useState({
    equipe: {},
    showModalLeave: false
  })

  const [carteiraData, setCarteiraData] = React.useState({
    equipeId: 0,
    showModalCarteira: false
  })

  React.useEffect(() => {
    filterMemberTeams();
  }, []);

  async function filterMemberTeams(page) {
    window.showLoader();

    let response = await EquipeService.listMemberTeamsPagedAsync({ pageNumber: page });

    setMemberTeamsPageData(response.data.data);

    window.hideLoader();
  }

  async function refuseConfirm(answer) {
    if (answer === 'yes') {
      window.showLoader();

      let response = await EquipeService.refuseAsync(refuseData.equipe.equipeId);

      window.hideLoader();

      if (response.status === lib.statusCode.ok) {
        filterMemberTeams();
        return;
      }
    }
  }

  async function accept(equipeId) {
    window.showLoader();

    let response = await EquipeService.acceptAsync(equipeId);

    window.hideLoader();

    if (response.status === lib.statusCode.ok) {
      filterMemberTeams();
      return;
    }
  }

  async function leaveConfirm(answer) {
    if (answer === 'yes') {
      window.showLoader();

      let response = await EquipeService.leaveAsync(leaveData.equipe.equipeId);

      window.hideLoader();

      if (response.status === lib.statusCode.ok) {
        filterMemberTeams();
        return;
      }
    }
  }

  return (
    <>
      {memberTeamsPageData.data && (
        <div className="equipe-container">
          {(memberTeamsPageData?.data || []).map((equipe, index) => (
            <table key={index} className={`table table-cadastro ${!equipe.ativa ? "equipe-inativa" : ""}`}>
              <thead>
                <tr>
                  <th>
                    <div className="row">
                      <div className="col-6">
                        {equipe.nome}
                      </div>
                      <div className="col-6 text-end">
                        <label className="texto-light px-3">Time criado por {equipe.owner}</label>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="row justify-content-center">
                      <div className="col-11 equipe-usuario-container">
                        <div className="row">

                          <div className="col-lg-3 d-flex flex-column justify-content-center equipe-usuario-descricao-container">
                            {equipe.usuario.nome?.length > 0
                              ?
                              <>
                                <label className="d-block">{equipe.usuario.nome}</label>
                                <label className="d-block texto-light">{equipe.usuario.email}</label>
                              </>

                              :
                              <label className="texto">{equipe.usuario.email}</label>
                            }

                          </div>
                          {equipe.usuario.currentUser && (
                            <div className="col-lg-9 d-flex flex-column justify-content-center">

                              {equipe.usuario.ativo
                                ?
                                <div className="row justify-content-around">
                                  <div className="col-lg-4 px-5 py-1">
                                    <button type="button" className="btn btn-secondary w-100" onClick={() => {
                                      setCarteiraData({
                                        equipeId: equipe.equipeId,
                                        showModalCarteira: true
                                      });
                                    }}>CARTEIRA</button>
                                  </div>

                                  <div className="col-lg-4 px-5 py-1">
                                    <button type="button" className="btn btn-danger w-100" onClick={() => {
                                      setLeaveData({
                                        equipe: equipe,
                                        showModalLeave: true
                                      });
                                    }}>SAIR</button>
                                  </div>
                                </div>

                                :
                                <>
                                  <div className="row justify-content-around">
                                    <div className="col-lg-4 px-5 py-1">
                                      <button type="button" className="btn btn-success w-100" onClick={() => accept(equipe.equipeId)}>ACEITAR</button>
                                    </div>

                                    <div className="col-lg-4 px-5 py-1">
                                      <button type="button" className="btn btn-danger w-100" onClick={() => {
                                        setRefuseData({
                                          equipe: equipe,
                                          showModalRefuse: true
                                        });
                                      }}>RECUSAR</button>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12 text-center">
                                      <hr />
                                      <label className="d-block">Aguardando aceite do convite.</label>
                                      <label className="texto-light">Convite enviado em {lib.getStringFromDateTime(equipe.usuario.dataConvite)}</label>
                                    </div>
                                  </div>
                                </>}

                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          ))}

          <TablePagination
            ownerTeamsPageData={memberTeamsPageData}
            onChangePage={(page) => filterMemberTeams(page)}
          />

          <ModalConfirmacao
            showModal={refuseData.showModalRefuse}
            setShowModal={() => setRefuseData({ ...refuseData, showModalRefuse: false })}
            onClose={refuseConfirm}
            headerText=" "
            btnYesText="Recusar"
            btnNoText="Cancelar"
          >
            <p>Confirme se quer recusar o convite do time {refuseData.equipe?.nome}</p>
          </ModalConfirmacao>

          <ModalConfirmacao
            showModal={leaveData.showModalLeave}
            setShowModal={() => setLeaveData({ ...leaveData, showModalLeave: false })}
            onClose={leaveConfirm}
            headerText=" "
            btnYesText="Sair"
            btnNoText="Cancelar"
          >
            <p>Confirme se quer realmente sair do time {leaveData.equipe?.nome}</p>
          </ModalConfirmacao>

          <ModalCarteira
            showModal={carteiraData.showModalCarteira}
            setShowModal={() => setCarteiraData({ ...carteiraData, showModalCarteira: false })}
            equipeId={carteiraData.equipeId}
          />
        </div>
      )}
    </>
  );
}
