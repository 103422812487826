import React from 'react';

export default function InputCodigo({representada}) {

  const [codigo, setCodigo] = React.useState('')

  React.useEffect(() => {
    setCodigo(representada.codigo);
  }, [representada])

  return (
    <input type="text" className="form-control" value={codigo || ""} onChange={(e) => {
      representada.codigo = e.target.value;
      setCodigo(representada.codigo)
    }} />
  )
}