import React from 'react';
import CurrencyInput from "react-currency-input";

const InputValor = ({ onChange, initialValue, className }) => {
  const refValue = React.useRef(initialValue);

  return (
    <CurrencyInput
      className={`form-control ${className}`}
      decimalSeparator=","
      thousandSeparator="."
      prefix="R$ "
      selectAllOnFocus={true}
      value={refValue.current}
      onChangeEvent={(event, maskedvalue, floatvalue) => {
        refValue.current = floatvalue;
        onChange(floatvalue);
      }}
    />
  );
}

export default InputValor;