import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faEraser, faSave } from '@fortawesome/free-solid-svg-icons';

import RowRepresentada from "./_row-representada";

import "./styles.scss"
import SelectMulti from "../_base/multi-select";

export default function TableCadastroComissao({ model, save, showValorBase, fullSize }) {

  const [selectListRepresentadas, setSelectListRepresentadas] = React.useState([]);
  const [selectListProdutoCategorias, setSelectListProdutoCategorias] = React.useState([]);
  const [selectListProdutos, setSelectListProdutos] = React.useState([]);
  const [selectListRedes, setSelectListRedes] = React.useState([]);

  const [selectedRepresentadas, setSelectedRepresentadas] = React.useState([]);
  const [selectedProdutoCategorias, setSelectedProdutoCategorias] = React.useState([]);
  const [selectedProdutos, setSelectedProdutos] = React.useState([]);
  const [selectedRedes, setSelectedRedes] = React.useState([]);

  const [filterRepresentada, setFilterRepresentada] = React.useState("");
  const [filterCategoria, setFilterCategoria] = React.useState("")
  const [filterProduto, setFilterProduto] = React.useState("")
  const [filterRede, setFilterRede] = React.useState("")

  React.useEffect(() => {
    setLists();
  }, [model])

  function setLists() {
    if (!model.representadas?.length)
      return;

    let selectListRepresentadas = [];
    let selectListProdutoCategorias = [];
    let selectListProdutos = [];
    let selectListRedes = [];

    for (let r of (model.representadas || [])) {
      let optionRepresentada = {
        label: r.nomeFantasia,
        value: r.representadaId,
        representadaIds: [r.representadaId],
        produtoCategoriaIds: [],
        produtoIds: [],
        redeIds: []
      };

      selectListRepresentadas.push(optionRepresentada);

      for (let c of r.produtoCategorias) {
        optionRepresentada.produtoCategoriaIds.push(c.produtoCategoriaId);

        let optionProdutoCategoria = selectListProdutoCategorias.find(o => o.value === c.produtoCategoriaId);
        if (optionProdutoCategoria) {
          optionProdutoCategoria.representadaIds.push(r.representadaId);
        } else {
          optionProdutoCategoria = {
            label: c.nome,
            value: c.produtoCategoriaId,
            representadaIds: [r.representadaId],
            produtoCategoriaIds: [c.produtoCategoriaId],
            produtoIds: [],
            redeIds: []
          };

          selectListProdutoCategorias.push(optionProdutoCategoria);
        }

        for (let p of c.produtos) {
          optionRepresentada.produtoIds.push(p.produtoId);
          optionProdutoCategoria.produtoIds.push(p.produtoId);

          let optionProduto = {
            label: p.nome,
            value: p.produtoId,
            representadaIds: [r.representadaId],
            produtoCategoriaIds: [c.produtoCategoriaId],
            produtoIds: [p.produtoId],
            redeIds: []
          };

          selectListProdutos.push(optionProduto);

          for (let rd of p.redes) {
            optionRepresentada.redeIds.push(rd.redeId);
            optionProdutoCategoria.redeIds.push(rd.redeId);
            optionProduto.redeIds.push(rd.redeId);

            let optionRede = selectListRedes.find(o => o.value === rd.redeId);
            if (optionRede) {
              optionRede.representadaIds.push(r.representadaId);
              optionRede.produtoCategoriaIds.push(c.produtoCategoriaId);
              optionRede.produtoIds.push(p.produtoId);

              continue;
            }

            selectListRedes.push({
              label: rd.nome,
              value: rd.redeId,
              representadaIds: [r.representadaId],
              produtoCategoriaIds: [c.produtoCategoriaId],
              produtoIds: [p.produtoId],
              redeIds: [rd.redeId]
            });
          }
        }
      }
    }

    selectListRepresentadas = selectListRepresentadas.sort((a, b) => ('' + a.label).localeCompare(b.label))
    selectListProdutoCategorias = selectListProdutoCategorias.sort((a, b) => ('' + a.label).localeCompare(b.label))
    selectListProdutos = selectListProdutos.sort((a, b) => ('' + a.label).localeCompare(b.label))
    selectListRedes = selectListRedes.sort((a, b) => ('' + a.label).localeCompare(b.label))

    setSelectListRepresentadas(selectListRepresentadas);
    setSelectListProdutoCategorias(selectListProdutoCategorias);
    setSelectListProdutos(selectListProdutos);
    setSelectListRedes(selectListRedes);
  }

  //filtro hierarquico: se foi é passado o produto e o produtoCategoria, o produto que prevalece para filtrar o select de representada
  function getFilterValues(keyIds, ...filters) {
    for (let filter of filters) {
      if (filter?.length)
        return [].concat(...filter.map(x => x[keyIds]));
    }
  }

  return (
    <div className="table-comissao">
      <div className="row pb-1">
        <div className={`col-2 p-2px`}>
          <label><FontAwesomeIcon icon={faFilter} /> Representadas</label>
          <SelectMulti
            options={selectListRepresentadas}
            value={selectedRepresentadas}
            onChange={setSelectedRepresentadas}
            filterValues={getFilterValues("representadaIds", selectedProdutos, selectedProdutoCategorias)}
          />
        </div>
        <div className="col-10 p-2px pt-0">
          <div className="row">
            <div className={`col-3 p-2px`}>
              <label><FontAwesomeIcon icon={faFilter} /> Categorias</label>
              <SelectMulti
                options={selectListProdutoCategorias}
                value={selectedProdutoCategorias}
                onChange={setSelectedProdutoCategorias}
                filterValues={getFilterValues("produtoCategoriaIds", selectedProdutos, selectedRepresentadas)}
              />
            </div>
            <div className={`col-3 p-2px`}>
              <label><FontAwesomeIcon icon={faFilter} /> Produtos</label>
              <SelectMulti
                options={selectListProdutos}
                value={selectedProdutos}
                onChange={setSelectedProdutos}
                filterValues={getFilterValues("produtoIds", selectedProdutoCategorias, selectedRepresentadas)}
              />
            </div>
            <div className={`col-3 p-2px`}>
              <label><FontAwesomeIcon icon={faFilter} /> Redes</label>
              <SelectMulti
                options={selectListRedes}
                value={selectedRedes}
                onChange={setSelectedRedes}
              />
            </div>
            <div className={`col-2 p-2px d-flex align-items-end justify-content-between`}>
              <button type="button" className="btn btn-secondary" onClick={() => {
                setFilterRepresentada("");
                setFilterProduto("");
                setFilterRede("");
              }}><FontAwesomeIcon icon={faEraser} className="me-2" />Limpar Filtro</button>

              {
                fullSize && save
                  ? (
                    <button type="button" className="btn btn-primary" onClick={() => {
                      save()
                    }}><FontAwesomeIcon icon={faSave} className="me-2" />Salvar</button>
                  )
                  : null
              }
            </div>


            {
              !fullSize && save
                ? (
                  <>
                    <div className={`col-1 p-2px d-flex align-items-end justify-content-end`}>
                      <button type="button" className="btn btn-primary" onClick={() => {
                        save()
                      }}><FontAwesomeIcon icon={faSave} className="me-2" />Salvar</button>
                    </div>
                  </>
                )
                : null
            }
          </div>
        </div>
      </div>

      {
        model.representadas?.map((representada, index) => (
          <RowRepresentada key={index}
            representada={representada}
            filterRepresentada={filterRepresentada}
            filterCategoria={filterCategoria}
            filterProduto={filterProduto}
            filterRede={filterRede}
            selectedRepresentadas={selectedRepresentadas}
            selectedProdutoCategorias={selectedProdutoCategorias}
            selectedProdutos={selectedProdutos}
            selectedRedes={selectedRedes}
            showValorBase={showValorBase}
            fullSize={fullSize} />
        ))
      }

      <div className="row group-divider-bold pt-2 justify-content-end">
        <div className="col-2 p-0 text-end">
          {
            save
              ? (
                <button type="button" className="btn btn-primary" onClick={() => {
                  save()
                }}><FontAwesomeIcon icon={faSave} className="me-2" />Salvar</button>
              )
              : null
          }
        </div>
      </div>
    </div>
  )
}