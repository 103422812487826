import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import SelectMulti from "../../components/_base/multi-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faEraser } from "@fortawesome/free-solid-svg-icons";

import DasboardBarChart from "./components/bar-chart/index"
import CardPanel from "./components/big-number";
import DashboardDonutCarteira from "./components/carteira";
import DashboardDonutPositivacao from "./components/positivacao";
import DashboardBarAtivados from "./components/Ativados";
import TabelaRede from "./components/tabela-rede";

import DashboardService from "../../services/dashboard-service";
import lib from "../../helpers/lib";

import "./styles.scss";

export default function HomeVendas() {
  const data = new Date();

  const [metaContratante, setMetaContratante] = useState({});
  const [positivacao, setPositivacao] = useState({});
  const [resumoMensalRede, setResumoMensalRede] = useState({});

  const [redes, setRedes] = useState();
  const [representadas, setRepresentadas] = useState();

  const refFilter = useRef({});
  const [filter, setFilter] = useState({
    representadas: [],
    redes: [],
    ano: data.getFullYear(),
    mes: data.getMonth() + 1,
  });

  useEffect(() => {
    getSelectListRepresentada();
    getSelectListRede();

    getRelatorio(false);
  }, []);

  async function getRelatorio(exibeloader) {
    if (exibeloader) showLoader();

    //atualizar os vendedores se houve alteração do periodo
    if (refFilter.current.ano !== filter.ano || refFilter.current.mes !== filter.mes) {
      getSelectListRepresentada();
      getSelectListRede();
    }

    refFilter.current = getFilterParams();

    await Promise.all([getMetaCotratante(), getPositivacao(), getResumoMensalRede()]);

    hideLoader();
  }

  async function getSelectListRepresentada() {
    let representadas = await DashboardService.getSelectListRepresentada({ ano: filter.ano, mes: filter.mes });
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  async function getSelectListRede() {
    const result = await DashboardService.getSelectListRede({ ano: filter.ano, mes: filter.mes });
    if (result.data) setRedes(result.data.data);
  }

  async function getMetaCotratante() {
    let response = await DashboardService.getMetaContratanteAsync(refFilter.current)

    setMetaContratante(response.data.data);
  }

  async function getPositivacao() {
    let response = await DashboardService.getPositivacaoAsync(refFilter.current)

    setPositivacao(response.data.data);
  }

  async function getResumoMensalRede() {
    let response = await DashboardService.getResumoMensalRedeAsync(refFilter.current)

    setResumoMensalRede(response.data.data);
  }

  function getFilterParams() {
    return {
      representadaIds: filter.representadas.map(x => x.value),
      redeIds: filter.redes.map(x => x.value),
      mes: filter.mes,
      ano: filter.ano
    }
  }

  function limpar() {
    filter.representadas = [];
    filter.redes = [];
    filter.ano = data.getFullYear();
    filter.mes = data.getMonth() + 1;

    setFilter({ ...filter });

    getRelatorio(true);
  }

  return (

    <div className="dashboard-page">
      <div>
        <div className="row">
          {/* Representada ---------------------------------------------------------------------- */}
          <div className="col-lg-3" disabled>
            <label>Representada</label>
            <SelectMulti
              options={representadas}
              value={filter.representadas}
              onChange={(options) => setFilter({ ...filter, representadas: options })}
            //filterOptions={search}
            />
          </div>

          {/* Rede ---------------------------------------------------------------------- */}
          <div className="col-lg-3" disabled>
            <label>Rede</label>
            <SelectMulti
              options={redes}
              value={filter.redes}
              onChange={(options) => setFilter({ ...filter, redes: options })}
            //filterOptions={search}
            />
          </div>

          <div className="col-lg-3">
            <label>Mês</label>
            <Select
              className="select-form-control"
              value={lib.selectListMeses?.find((item) => item.value === filter.mes)}
              options={lib.selectListMeses}
              isClearable={false}
              onChange={(item) => { setFilter({ ...filter, mes: item.value }); }}
            />
          </div>
          <div className="col-lg-3">
            <label>Ano</label>
            <Select
              className="select-form-control"
              value={lib.selectListAnos?.find((item) => item.value === filter.ano)}
              options={lib.selectListAnos}
              isClearable={false}
              onChange={(item) => { setFilter({ ...filter, ano: item.value }); }}
            />
          </div>
        </div>
        <div className="row mt-3 justify-content-end">
          <div className="col-3 text-center">
            <button
              type="button"
              className="btn btn-secondary btn-action w-100"
              onClick={limpar}
            >
              <FontAwesomeIcon icon={faEraser} /> Limpar
            </button>
          </div>

          <div className="col-3 text-center">
            <button
              type="button"
              className="btn btn-secondary btn-action w-100"
              onClick={() => getRelatorio(true)}
            >
              <FontAwesomeIcon icon={faFilter} /> Filtrar
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div className="row m-0 mt-3">
        <div className="col-lg-12 shadow p-3 mb-5 bg-white rounded border-radius: 150px">
          <CardPanel source={metaContratante} />
        </div>
      </div>

      <div className="row m-0">
        <div className="col-lg-12 px-5 shadow p-3 mb-5 bg-white rounded">
          <DasboardBarChart source={metaContratante} />
        </div>
      </div>

      <div className="row m-0">
        <div className="col-lg-4 pt-20 text-center shadow p-3 mb-5 bg-white rounded">
          <DashboardDonutCarteira source={positivacao} />
        </div>
        <div className="col-lg-4 pt-20 text-center shadow p-3 mb-5 bg-white rounded">
          <DashboardDonutPositivacao source={positivacao} />
        </div>
        <div className="col-lg-4 pt-20 text-center shadow p-3 mb-5 bg-white rounded">
          <DashboardBarAtivados source={positivacao} />
        </div>
      </div>
      <div className="row m-0">
        <div className="col-lg-12 shadow p-3 mb-5 bg-white rounded border-radius: 150px">
          <TabelaRede source={resumoMensalRede} />
        </div>
      </div>
    </div>

  );
}
