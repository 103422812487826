import React, { useState, useEffect } from "react";
import ResumoPedido from "../resumo-pedido";
import ModalCadastro from "../_base/modal-cadastro";
import PedidoService from "../../services/pedido-service";

import Errors from "../_base/errors/index";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCancel } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";

export default function ModalCancelarPedido(props) {
  const { model, showModal, setShowModal, onClose } = props;
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors({});
  }, [showModal]);

  function close() {
    setShowModal(false);
  }

  async function cancelar() {
    window.showLoader();
    setErrors({});
    let response = await PedidoService.cancelarAsync(
      model.pedidoRepresentadaId
    );
    if (response.data.errors) {
      setErrors(response.data.errors);
    } else {
      if (onClose) await onClose();
      close();
    }
    window.hideLoader();
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="CANCELAR PEDIDO"
      headerBg="bg-danger"
    >
      <div className="text-center">
        <Errors errors={errors} keyName="" />
      </div>
      <ResumoPedido model={model}></ResumoPedido>
      <hr></hr>
      <div className="text-center modal-cancelar-pedido">
        <h3>Tem certeza que deseja cancelar este pedido?</h3>
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={close}
        >
          <FontAwesomeIcon icon={faClose} /> Fechar
        </button>
        <button type="button" className="btn btn-danger" onClick={cancelar}>
          <FontAwesomeIcon icon={faCancel} /> Cancelar
        </button>
      </div>
    </ModalCadastro>
  );
}
