import React from 'react';
import NumberFormat from 'react-number-format';

export default function InputPorcentagem({ className, value, onChange }) {
  return (
    <NumberFormat
      className={className}
      value={value}
      decimalSeparator={","}
      suffix={" %"}
      allowLeadingZeros={true}
      onValueChange={({ floatValue }) => {
        onChange(floatValue)
      }}
    />
  );
};
