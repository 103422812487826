import ServiceClient from "./service-client";
import { service_url } from "./service-constants";

class PedidoFaturamentoService {
  static async getAsync(id) {
    try {
      var result = await ServiceClient.Get("/pedidofaturamento/get", {
        id: id,
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listAsync(filter) {
    try {
      var result = await ServiceClient.Get("/pedidofaturamento/list", filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listToExcelAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        "/pedidofaturamento/get-pedido-faturamento-em-bytes",
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listToPdfAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        "/pedidofaturamento/get-pedido-faturamento-em-pdf",
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveAsync(model) {
    try {
      var result = await ServiceClient.Post("/pedidofaturamento/save", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async descartarValorAsync(id) {
    try {
      var result = await ServiceClient.Post(
        "/pedidofaturamento/descartar-valor/" + id,
        {}
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  static async calculaValoresAsync(model) {
    try {
      var result = await ServiceClient.Post(
        "/pedidofaturamento/calcula-valores",
        model
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getExtratoFaturamentoAsync(filter) {
    try {
      var result = await ServiceClient.Post(
        service_url.relatorio_faturamento,
        filter || {}
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getExtratoFaturamentoExcelAsync(filter) {
    try {
      var result = await ServiceClient.Post(
        service_url.relatorio_faturamento_excel,
        filter || {}
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getExtratoFaturamentoPdfAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        "/pedidofaturamento/get-extrato-faturamento-em-pdf",
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default PedidoFaturamentoService;
