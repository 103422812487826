import ServiceClient from './service-client';

class UnidadeService {
  static async getSelectList() {
    try {
      var result = await ServiceClient.Get("/unidademedida/select-list");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listNaoBasica() {
    try {
      var result = await ServiceClient.Get("/unidademedida/list-nao-basicas");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default UnidadeService;