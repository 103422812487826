import React, { useState, useEffect } from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from 'react-apexcharts';

import "./styles.scss";

export default function DashboardBarAtivados2({source}) {
  
  const [barChart, setBarChart] = useState(null);

  useEffect(() => {
    let clientePositivacaoAno = source?.clientePositivacaoAno;
    if (!clientePositivacaoAno)
      return;

    let prospeccoes = clientePositivacaoAno.map(x => x.prospeccoes);
    let ativos = clientePositivacaoAno.map(x => x.ativos);
    let mes = clientePositivacaoAno.map(x => `${x.mes}/${x.ano}`);


    const chartData = {
      series: [
        {
          name: 'REDES NOVAS',
          data: prospeccoes,
          align: 'center',
        },
        {
          name: 'REDES ATIVAS',
          data: ativos,
          align: 'center',
        },
      ],
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          // Não defina a altura aqui
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'center',
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '10px',
                fontWeight: 900,
              },
            },
            barWidth: '50px',
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: 'CLIENTES ATIVADOS',
          align: 'center',
          margin: 1,
        },
        xaxis: {
          categories: mes,
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val ;
            },
          },
        },
        fill: {
          opacity: 50,
        },
        legend: {
          position: 'bottom',
          offsetY: 1,
          markers: {
            width: 5,
            height: 5,
          },
          itemMargin: {
            vertical: 5,
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                height: 400,
              },
            },
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                height: '100%', // Altere a altura para 300 ou outro valor desejado
              },
            },
          },
          // Adicione mais breakpoints e opções para tamanhos de tela maiores, se necessário
        ],
        labels: [`REDES NOVAS1`, `REDES ATIVAS1`],
      },
    };
  
    setBarChart(chartData);
  }, [source]);

  if (!barChart)
    return null;
  
  return (
    <div className="dashboard-bar-chart flex-fill">
      <div id="bar-chart">
        {barChart ? (
          <ReactApexChart options={barChart.options} series={barChart.series} type="bar" />
        ) : null}
      </div>
    </div>
  );
        }