import React from "react";
import InputCnpj from "../_base/input-cnpj";
import InputDocumento from '../_base/input-documento'
import InputCep from "../_base/input-cep";
import InputTelefone from "../_base/input-telefone";
import AutoCompleteCreatable from "../_base/autocomplete-creatable";
import FormGroupHeader from "../_base/form-group-header";
import Errors from "../_base/errors";
import Representadas from "./_representadas";

import GeneralService from "../../services/general-service";
import LojaService from "../../services/loja-service";

import lib from "../../helpers/lib";

import { CadastroLojaContext } from "./_context";

export default function Representante({ lojaId, closeModal }) {
  const {
    getRedes,
    redes,
    setRedes,
    validateCnpj,
    getEndereco,
    errors,
    setErrors,
    validateForm,
  } = React.useContext(CadastroLojaContext);

  const emptyLoja = React.useMemo(
    () => ({
      cnpj: "",
      razaoSocial: "",
      nomeFantasia: "",
      ie: "",
      redeId: "",
      rede: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      contatoNome: "",
      contatoTelefone: "",
      contatoEmail: "",
    }),
    []
  );

  const [loja, setLoja] = React.useState({ ...emptyLoja });

  const formFields = React.useMemo(
    () => ({
      cnpj: { key: "cnpj", required: true, disabled: loja.lojaId?.length > 0 },
      razaoSocial: {
        key: "razaoSocial",
        required: true,
        disabled: loja.lojaId?.length > 0,
      },
      nomeFantasia: { key: "nomeFantasia", required: true },
      ie: { key: "ie", required: false },
      redeId: { key: "redeId", required: false },
      cep: { key: "cep", required: true },
      logradouro: { key: "logradouro", required: true },
      numero: { key: "numero", required: true },
      complemento: { key: "complemento", required: false },
      bairro: { key: "bairro", required: true },
      cidade: { key: "cidade", required: true },
      estado: { key: "estado", required: true },
      contatoNome: { key: "contatoNome", required: false },
      contatoTelefone: { key: "contatoTelefone", required: false },
      contatoEmail: { key: "contatoEmail", required: false },
    }),
    [loja]
  );

  React.useEffect(() => {
    setErrors({});
    getLoja();
  }, [lojaId]);

  async function getLoja() {
    if (lojaId?.length > 0) {
      window.showLoader();
      let response = await LojaService.getCadastroAsync(lojaId);
      window.hideLoader();

      setLoja(response?.data?.data || {});
    } else {
      setLoja({ ...emptyLoja });
    }
  }

  function updateLoja(data) {
    setLoja({ ...loja, ...data });
  }

  async function saveAsync() {
    window.showLoader();

    if (!validateForm(loja, formFields)) {
      window.hideLoader();
      return;
    }

    setErrors({});

    let response = lib.isNullOrEmptyObject(loja.lojaId)
      ? await LojaService.addAsync(loja)
      : await LojaService.updateAsync(loja);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseLoja = response.data.data;

      setLoja({ ...emptyLoja });

      closeModal(true);

      //reload redes
      getRedes();

      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  async function getCnpjDados() {
    let isValid = await validateCnpj(loja);
    if (!isValid) return;

    window.showLoader();

    let response = await GeneralService.getCNPJAsync(loja.cnpj);

    if (response.status === lib.statusCode.ok) {
      let data = {
        razaoSocial: response.data.data.razaoSocial ?? "",
        nomeFantasia: response.data.data.nomeFantasia ?? "",
        cep: lib.formatCep(response.data.data.cep) ?? "",
        logradouro: response.data.data.logradouro ?? "",
        numero: response.data.data.numero ?? "",
        complemento: response.data.data.complemento ?? "",
        bairro: response.data.data.bairro ?? "",
        cidade: response.data.data.cidade ?? "",
        estado: response.data.data.uf ?? "",
      };

      updateLoja(data);
    }

    window.hideLoader();
  }

  return (
    <>
      <div className="modal-body">
        <form>
          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">CNPJ/CPF</label>
              <InputDocumento
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.cnpj.key])
                    ? ""
                    : "is-invalid"
                }`}
                disabled={formFields.cnpj.disabled}
                value={loja.cnpj}
                onChange={(value) => {
                  updateLoja({ cnpj: value });

                  if (value.length === 18)
                    if (loja.cnpj !== value) {
                      loja.cnpj = value;
                      getCnpjDados(loja);
                    }
                }}
              />

              <Errors errors={errors} keyName={formFields.cnpj.key} />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">Razão Social</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.razaoSocial.key])
                    ? ""
                    : "is-invalid"
                }`}
                disabled={formFields.razaoSocial.disabled}
                value={loja.razaoSocial}
                onChange={(e) => updateLoja({ razaoSocial: e.target.value })}
              />

              <Errors errors={errors} keyName={formFields.razaoSocial.key} />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="form-label">Nome Fantasia</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.nomeFantasia.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.nomeFantasia}
                onChange={(e) => updateLoja({ nomeFantasia: e.target.value })}
              />
            </div>

            <div className="col-lg-6">
              <label className="form-label">Inscrição Estadual</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.ie.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.ie}
                onChange={(e) => updateLoja({ ie: e.target.value })}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">Rede</label>
              <AutoCompleteCreatable
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.redeId.key])
                    ? ""
                    : "is-invalid"
                }`}
                keyLabel="nome"
                keyValue="redeId"
                matchSorterKeys={["nome"]}
                onSelectedItemChange={({ selectedItem }) => {
                  updateLoja({
                    redeId: selectedItem.redeId,
                    rede: selectedItem.nome,
                  });

                  if (selectedItem.redeId === 0) {
                    let novaRede = { ...selectedItem };
                    setRedes([
                      ...redes.filter((x) => x.redeId !== novaRede.redeId),
                      { ...novaRede },
                    ]);
                  }
                }}
                selectedItem={
                  redes.find((x) => x.redeId === loja.redeId) || null
                }
                items={redes}
                itemToString={(item) => (item ? item.nome : "")}
              />
            </div>
          </div>

          <FormGroupHeader text="ENDEREÇO" />

          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">CEP</label>
              <InputCep
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.cep.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.cep}
                onChange={(value) => {
                  updateLoja({ cep: value });

                  loja.cep = value;
                  if (loja.cep.length === 9)
                    (async () => {
                      await getEndereco(loja);
                      setLoja({ ...loja });
                    })();
                }}
              />

              <Errors errors={errors} keyName={formFields.cep.key} />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">Logradouro</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.logradouro.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.logradouro}
                onChange={(e) => updateLoja({ logradouro: e.target.value })}
              />
              <Errors errors={errors} keyName={formFields.logradouro.key} />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="form-label">Número</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.numero.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.numero}
                onChange={(e) => updateLoja({ numero: e.target.value })}
              />
              <Errors errors={errors} keyName={formFields.numero.key} />
            </div>

            <div className="col-lg-6">
              <label className="form-label">Complemento</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.complemento.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.complemento}
                onChange={(e) => updateLoja({ complemento: e.target.value })}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">Bairro</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.bairro.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.bairro}
                onChange={(e) => updateLoja({ bairro: e.target.value })}
              />
              <Errors errors={errors} keyName={formFields.bairro.key} />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="form-label">Cidade</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.cidade.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.cidade}
                onChange={(e) => updateLoja({ cidade: e.target.value })}
              />
              <Errors errors={errors} keyName={formFields.cidade.key} />
            </div>

            <div className="col-lg-6">
              <label className="form-label">UF</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.estado.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.estado}
                onChange={(e) => updateLoja({ estado: e.target.value })}
              />
              <Errors errors={errors} keyName={formFields.estado.key} />
            </div>
          </div>

          <FormGroupHeader text="DADOS DE CONTATO" />

          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">Nome</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.contatoNome.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.contatoNome}
                onChange={(e) => updateLoja({ contatoNome: e.target.value })}
                required
              />
              <Errors errors={errors} keyName={formFields.contatoNome.key} />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-6">
              <label className="form-label">Telefone</label>
              <InputTelefone
                className={`form-control ${
                  lib.isNullOrEmptyObject(
                    errors[formFields.contatoTelefone.key]
                  )
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.contatoTelefone}
                onChange={(value) => updateLoja({ contatoTelefone: value })}
              />
              <Errors
                errors={errors}
                keyName={formFields.contatoTelefone.key}
              />
            </div>

            <div className="col-lg-6">
              <label className="form-label">E-mail</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.contatoEmail.key])
                    ? ""
                    : "is-invalid"
                }`}
                value={loja.contatoEmail}
                onChange={(e) => updateLoja({ contatoEmail: e.target.value })}
              />
              <Errors errors={errors} keyName={formFields.contatoEmail.key} />
            </div>
          </div>

          <Representadas loja={loja} updateLoja={updateLoja} />
        </form>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          SALVAR
        </button>
      </div>
    </>
  );
}
