import React from "react";
import Select from "react-select";

import "./styles.scss";

const formatOptionLabel = (option) => (
  <div className="format-option-representada-cadastro" style={{ display: "flex" }}>
    <div>
      <span className="fantasia">{option.fantasia}</span>
      <span className="cnpj"> - {option.cnpj}</span>
    </div>
  </div>
);

const SelectRepresentadaCadastro = (props) => {
  return (
    <>
      <Select
        {...props}
        classNamePrefix="select-cadastro"
        formatOptionLabel={formatOptionLabel}
        isClearable={true}
        placeholder="Selecione uma Representada"
        noOptionsMessage={() => 'Sem opções'}
      />
    </>
  );
};

export default SelectRepresentadaCadastro;
