import ServiceClient from './service-client';

class TableauService {
  static async getHome() {
    try {
      var result = await ServiceClient.Get("/tableau/get-url-home");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getHomeMobile() {
    try {
      var result = await ServiceClient.Get("/tableau/get-url-home-mobile");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default TableauService;