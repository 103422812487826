import React, { useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  faFileExcel,
  faFilePdf,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";

import PageTitle from "../../components/page-title/index";
import Layout from "../../components/layout/base/index";
import DatePickerCustom from "../../components/_base/DatePicker";
import lib from "../../helpers/lib";
import ModalPedidoFaturamento from "../../components/modal-pedido-faturamento";
import ModalConfirmacao from "../../components/modal-confirmacao";

import PedidoFaturamentoService from "../../services/pedido-faturamento-service";
import RepresentadaService from "../../services/representada-service";

import "./styles.scss";

const PedidoFaturamento = () => {
  const [representadas, setRepresentadas] = React.useState();
  const [codigo, setCodigo] = React.useState("");
  const [dataInicial, setDataInicial] = React.useState();
  const [dataFinal, setDataFinal] = React.useState();
  const [filter, setFilter] = React.useState({});
  const [representadaSelecionada, setRepresentadaSelecionada] =
    React.useState();
  const [pedidosRepresentadas, setPedidosRepresentadas] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [showModalFaturar, setShowModalFaturar] = React.useState(false);
  const [showModalDescartar, setShowModalDescartar] = React.useState(false);
  const [selectedDescartar, setSelectedDescartar] = React.useState(null);

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAsync();
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  async function getListFaturamento() {
    window.showLoader();
    setShowModalFaturar(false);
    let result = await PedidoFaturamentoService.listAsync(filter);
    setPedidosRepresentadas(result.data.data);

    console.log("PedidoFaturamentoService.listAsync");
    console.log(result.data.data);
    window.hideLoader();
  }

  useEffect(() => {
    (async () => {
      await getSelectListRepresentada();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      getListFaturamento();
    })();
  }, [filter]);

  async function descartarValor(result) {
    if (result === "yes") {
      window.showLoader();
      let result = await PedidoFaturamentoService.descartarValorAsync(
        selectedDescartar.pedidoRepresentadaId
      );
      getListFaturamento();
    }
    setShowModalDescartar(false);
    setSelectedDescartar(null);
  }

  async function excelClick() {
    window.showLoader();
    setShowModalFaturar(false);
    var filtroExcel = {
      codigo: codigo,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      representadaId: representadaSelecionada,
    };
    let result = await PedidoFaturamentoService.listToExcelAsync(filtroExcel);

    if (result && result.data) {
      let today = new Date();
      let date =
        today.getFullYear() +
        "_" +
        (today.getMonth() + 1) +
        "_" +
        today.getDate();
      let file = lib._base64ToByteArray(result.data?.data);
      let blob = new Blob([file], { type: "arraybuffer" });

      let fileName = "pedido_faturamento_" + date + ".xlsx";
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();
      document.body.removeChild(a);

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  async function pdfClick() {
    window.showLoader();
    setShowModalFaturar(false);
    var filtroPdf = {
      codigo: codigo,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      representadaId: representadaSelecionada,
    };

    let result = await PedidoFaturamentoService.listToPdfAsync(filtroPdf);

    if (result && result.data) {
      let today = new Date();
      let date =
        today.getFullYear() +
        "_" +
        (today.getMonth() + 1) +
        "_" +
        today.getDate();
      let file = lib._base64ToByteArray(result.data);
      let blob = new Blob([file], { type: "arraybuffer" });

      let fileName = "pedido_faturamento_" + date + ".pdf";
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();
      document.body.removeChild(a);
      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  // ------------------------------------------------------------------------------------------
  // Renderizacao
  return (
    <Layout>
      <div className="pedido-faturamento-page">
        <PageTitle title="Pedidos à Faturar"></PageTitle>

        {/* Filter ------------------------------------------------------------------------------ */}
        <div className="row filter-bar2">
          {/* Código  --------------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Pedido</label>
            <input
              type="text"
              className="form-control"
              value={codigo}
              onChange={(ev) => {
                setCodigo(ev.target.value);
              }}
            />
          </div>
          {/* Representada ---------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3" disabled>
            <label>Representada</label>
            <Select
              isClearable
              clearValue={() => {
                setRepresentadaSelecionada({});
              }}
              styles={customControlStyles}
              placeholder="Selecione..."
              options={representadas}
              value={representadas?.find(
                (x) => x.value === representadaSelecionada
              )}
              onChange={(selectedValue) => {
                setRepresentadaSelecionada(selectedValue?.value);
              }}
            />
          </div>
        </div>

        {/* Filter buttons -------------------------------------------------------------------------- */}
        <div className="row filter-bar2">
          {/* Data ---------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Data Inicial </label>
            <DatePickerCustom
              name="dataInicial"
              selected={dataInicial}
              onChange={(value) => {
                setDataInicial(value);
              }}
            />
          </div>
          <div className="col-xl-3 mb-3">
            <label>Data Final</label>
            <DatePickerCustom
              name="dataFinal"
              selected={dataFinal}
              onChange={(value) => {
                setDataFinal(value);
              }}
            />
          </div>
        </div>

        {/* Filter buttons -------------------------------------------------------------------------- */}
        <div className="row filter-bar2">
          <div className="col-xl-3 mb-3 d-flex">
            {/* Botão Filtrar --------------------------------------------------------------------- */}
            <button
              type="button"
              className="btn btn-secondary btn-action me-2"
              onClick={() => {
                setFilter({
                  ...filter,
                  codigo: codigo,
                  dataInicio: dataInicial,
                  dataFim: dataFinal,
                  representadaId: representadaSelecionada,
                });
              }}
            >
              <FontAwesomeIcon icon={faFilter} /> Filtrar
            </button>
          </div>
          <div className="col-xl-3 mb-3 d-flex">
            <button
              type="button"
              className="btn btn-secondary btn-action me-2"
              onClick={() => {
                excelClick();
              }}
            >
              <FontAwesomeIcon icon={faFileExcel} /> Exportar Excel
            </button>
          </div>
          <div className="col-xl-3 mb-3 d-flex">
            <button
              type="button"
              className="btn btn-secondary btn-action me-2"
              onClick={() => {
                pdfClick();
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} />
              Exportar PDF
            </button>
          </div>
        </div>

        {(pedidosRepresentadas || []).length === 0 && (
          <div className="row">
            <div className="col text-center">
              Não foi encontrado nenhum valor não faturado!
            </div>
          </div>
        )}
        {(pedidosRepresentadas || []).length > 0 && (
          <div className="row">
            <div className="col table-responsive">
              <table className="table text-center">
                <thead>
                  <tr className="bg-primary text-light">
                    <th>REPRESENTADA</th>
                    <th>PEDIDO</th>
                    <th>DT PEDIDO</th>
                    <th>DT ENTREGA</th>
                    <th>DIAS EM ABERTO</th>
                    <th>REDE</th>
                    <th>LOJA</th>
                    <th>NÃO FATURADO</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {(pedidosRepresentadas || []).map((pedidos, index) => (
                    <>
                      {(pedidos || []).map((item, index) => (
                        <tr>
                          <td>{item.representada}</td>
                          <td>{item.codigo}</td>
                          <td>{lib.getStringFromDate(item.dataPedido)}</td>
                          <td>{lib.getStringFromDate(item.dataEntrega)}</td>
                          <td>{item.diasEmAberto}</td>
                          <td>{item.rede}</td>
                          <td>{item.loja}</td>
                          <td>
                            <NumberFormat
                              value={item.valorNaoFaturado}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-table btn-secondary"
                              onClick={() => {
                                setSelected(item);
                                setShowModalFaturar(true);
                              }}
                            >
                              Faturar
                            </button>
                            <button
                              className="btn btn-table btn-danger"
                              onClick={() => {
                                setSelectedDescartar(item);
                                setShowModalDescartar(true);
                              }}
                              disabled={item.status == "Não Faturado"}
                            >
                              Descartar
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-gray-300">
                        <td colSpan="7" className="text-end">
                          Total:
                        </td>
                        <td>
                          <NumberFormat
                            value={pedidos.reduce(
                              (total, p) => total + p.valorNaoFaturado,
                              0
                            )}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$ "}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td></td>
                      </tr>
                    </>
                  ))}

                  <tr className="bg-primary text-light">
                    <td colSpan="7" className="text-end">
                      Total não Faturado:
                    </td>
                    <td>
                      <NumberFormat
                        value={(pedidosRepresentadas || []).reduce(
                          (total, p) =>
                            total +
                            p.reduce(
                              (total, f) => total + f.valorNaoFaturado,
                              0
                            ),
                          0
                        )}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {/*<hr></hr>
      <Link to="/pedido-faturamento/historico" type="button" className="btn btn-secondary">
        <FontAwesomeIcon icon={faClockRotateLeft} />  Histórico de Notas
      </Link>*/}

      {/* Modal Resumo Pedido ----------------------------------------------------------------- */}
      <ModalPedidoFaturamento
        model={selected}
        showModal={showModalFaturar}
        setShowModal={setShowModalFaturar}
        onClose={getListFaturamento}
      />

      {/* Modal Resumo Pedido ----------------------------------------------------------------- */}
      <ModalConfirmacao
        showModal={showModalDescartar}
        setShowModal={setShowModalDescartar}
        onClose={descartarValor}
      >
        {selectedDescartar && (
          <>
            Tem certeza que deseja descartar o valor{" "}
            {
              <NumberFormat
                value={selectedDescartar.valorNaoFaturado}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"R$ "}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            }{" "}
            da representada {selectedDescartar.representada}?
          </>
        )}
      </ModalConfirmacao>
    </Layout>
  );
};

export default PedidoFaturamento;
