import React, { useState, useEffect } from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from 'react-apexcharts';

import "./styles.scss";

export default function DashboardDonutPositivacao({source}) {
  const [donutChart, setDonutChart] = useState(null);

  useEffect(() => {
    if (!source?.clientePositivacao)
      return;

    const chartData = {
      series: [source.clientePositivacao.ativos, source.clientePositivacao.prospeccoes, source.clientePositivacao.inativosAntigos, source.clientePositivacao.inativosRecentes],
      options: {
        chart: {
          width: '100%', // Define a largura da pizza para ocupar o espaço disponível
          type: 'donut',
        },

        title: {
          text: 'POSITIVAÇÃO',
          align: 'center',
          margin: 1,
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(1) + '%';
          },
        },

 

        legend: {
          position: 'bottom',
          offsetY: 1,
          markers: {
            width: 5,
            height: 5,
          },
          itemMargin: {
            vertical: 5,
          },
        },
        labels: [`${source.clientePositivacao.ativos} ATIVOS`, `${source.clientePositivacao.prospeccoes} PROSPECÇÕES`, `${source.clientePositivacao.inativosRecentes} INATIVOS RECENTES`, `${source.clientePositivacao.inativosAntigos} INATIVOS ANTIGOS`],
      },
    };

    setDonutChart(chartData);
  }, [source]);

  if (!donutChart)
    return null;

  const windowWidth = window.innerWidth;
  const chartHeight = windowWidth < 768 ? 250 : 350; // Define a altura com base na largura da tela

  return (
    <div className="dashboard-donut-chart chart-container">
      <div id="donut-chart">
        {donutChart ? (
          <ReactApexChart options={donutChart.options} series={donutChart.series} type="donut" height={350} />
        ) : null}
      </div>
    </div>
  );
}