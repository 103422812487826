import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import lib from "../../helpers/lib";
import ChamadoService from "../../services/chamado-service";
import "./styles.scss";

export default function ListChamado(props) {
  const [selectedChamado, setSelectedChamado] = React.useState({});

  function chamadoClick(chamado) {
    props.onChangeChamado(chamado);
    setSelectedChamado(chamado);
  }

  return (
    <div className="content-scroll">
      <div className="vh-100  list-chamados">
        <div className="row mx-0 px -0 caixa-pesquisa">
          <div className="col-1 d-flex align-items-center">
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: "white", height: "30px" }}
            />
          </div>
          <div className="col-11 d-flex align-items-center">
            <input type="text"></input>
          </div>
        </div>
        {props.chamados.map((chamado, index) => (
          <div
            key={chamado.chamadoId}
            className="row mx-0 px -0 box-chamado"
            style={{
              borderLeft:
                chamado.chamadoId == selectedChamado.chamadoId
                  ? "8px solid green"
                  : "",
            }}
            onClick={(e) => chamadoClick(chamado)}
          >
            <span className="col-12 text-dentro data">
              {lib.getStringFromDateMinute(chamado.dataCriacao)}
            </span>
            <span className="col-12 text-dentro titulo">
              {chamado.tipo.toUpperCase()}
            </span>
            <span className="col-12 text-dentro  descricao">
              {chamado.mensagem}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
