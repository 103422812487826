import React, { useEffect } from "react";
import Select from "react-select";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import RelatorioService from "../../services/relatorio-service";
import RepresentadaService from "../../services/representada-service";
import lib from "../../helpers/lib";
import { Tab, Table, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faFileExcel,
  faFilePdf,
  faFilter,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import ExtratoService from "../../services/extrato-service";
import ModalFaturamentoNota from "../../components/modal-faturamento-nota";
import NumberFormat from "react-number-format";
import Layout from "../../components/layout/base";
import PageTitle from "../../components/page-title";
import DatePickerCustom from "../../components/_base/DatePicker";

const pdfMake = require("pdfmake/build/pdfmake");
const pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

export default function ExtratoComissao() {
  const [representadas, setRepresentadas] = React.useState();
  const [codigo, setCodigo] = React.useState("");
  const [nota, setNota] = React.useState("");
  const [dataInicial, setDataInicial] = React.useState(
    lib.getPrimeiroDiaMesAnterior()
  );
  const [dataFinal, setDataFinal] = React.useState(
    lib.getUltimoDiaMesAnterior()
  );
  const [representadaSelecionada, setRepresentadaSelecionada] =
    React.useState();
  const [pedidoFaturamentoNotas, setPedidoFaturamentoNotas] = React.useState(
    []
  );
  const [errors, setErrors] = React.useState({});
  const [modalFaturamentoNotaData, setModalFaturamentoNotaData] =
    React.useState({});

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAllAsync();
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  async function getListFaturamento() {
    let filter = {
      codigo: codigo,
      nota: nota,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      representadaId: representadaSelecionada,
    };

    window.showLoader();
    let result = await ExtratoService.listAsync(filter);
    if (result.data.data && result.data.data != null) {
      setPedidoFaturamentoNotas(result.data.data);
    } else {
      setPedidoFaturamentoNotas([]);
    }

    window.hideLoader();
  }

  useEffect(() => {
    (async () => {
      await getSelectListRepresentada();
    })();

    (async () => {
      getListFaturamento();
    })();
  }, []);

  function onEnterKey(event) {
    if (event.key === "Enter") {
      getListFaturamento();
    }
  }

  async function downloadExtratoPdf() {
    window.showLoader();
    const rawFilter = {
      codigo: codigo,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      nota: nota,
      representadaId: representadaSelecionada,
    };

    let result = await RelatorioService.getRelatorioComissaoEmPdfAsync(
      rawFilter
    );

    if (result && result.data) {
      let fileName = `relatorio_comissao_${lib.getCurrentDateFilename()}.pdf`;
      lib.downloadFile(result.data.data, fileName)

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  function exportarPdfClick() {
    const content = document.getElementById("extrato-comissao-pdf");
    const html = content.innerHTML;

    const rawContent =
      "<div style='text-align: center;'><h1>Extrato Comissão</h1></div><br/><br/>" +
      html;
    const val = htmlToPdfmake(rawContent);
    const dd = { content: val, pageOrientation: "landscape" };
    pdfMake.createPdf(dd).download("extrato-comissao.pdf");
  }

  function exportarExcelClick() {
    downloadExtratoExcel();
  }

  async function downloadExtratoExcel() {
    window.showLoader();
    let filter = {
      codigo: codigo,
      nota: nota,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      representadaId: representadaSelecionada,
    };

    let result = await RelatorioService.getExtratoComissaoExcelAsync(filter);

    if (result && result.data) {
      let today = new Date();
      let date =
        today.getFullYear() +
        "_" +
        (today.getMonth() + 1) +
        "_" +
        today.getDate();
      let file = lib._base64ToByteArray(result.data?.data);
      let blob = new Blob([file], { type: "arraybuffer" });

      let fileName = "extrato_comissao_" + date + ".xlsx";
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  // ------------------------------------------------------------------------------------------
  // Renderizacao
  return (
    <div className="container">
      <div className="pedido-faturamento-page">
        {/* Filter ------------------------------------------------------------------------------ */}
        <div className="row filter-bar2">
          {/* Nota  --------------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Nº Nota</label>
            <input
              type="text"
              className="form-control"
              value={nota}
              onChange={(ev) => {
                setNota(ev.target.value);
              }}
              onKeyPress={onEnterKey}
            />
          </div>

          {/* Pedido  --------------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Pedido</label>
            <input
              type="text"
              className="form-control"
              value={codigo}
              onChange={(ev) => {
                setCodigo(ev.target.value);
              }}
              onKeyPress={onEnterKey}
            />
          </div>

          {/* Representada ---------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3" disabled>
            <label>Representada</label>
            <Select
              className="select-form-control"
              isClearable
              clearValue={() => {
                setRepresentadaSelecionada({});
              }}
              placeholder="Selecione..."
              options={representadas}
              value={representadas?.find(
                (x) => x.value === representadaSelecionada
              )}
              onChange={(selectedValue) => {
                setRepresentadaSelecionada(selectedValue?.value);
              }}
            />
          </div>
        </div>

        {/* Filter buttons -------------------------------------------------------------------------- */}
        <div className="row filter-bar2">
          {/* Data ---------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Data Inicial do Pedido</label>
            <DatePickerCustom
              name="dataInicial"
              selected={dataInicial}
              onChange={(value) => {
                setDataInicial(value);
              }}
            />
          </div>
          <div className="col-xl-3 mb-3">
            <label>Data Final do Pedido</label>
            <DatePickerCustom
              name="dataFinal"
              selected={dataFinal}
              onChange={(value) => {
                setDataFinal(value);
              }}
            />
          </div>
          {/* <div className="col-xl-3 mb-3 d-flex">            
            <button
              type="button"
              className="btn btn-secondary btn-sm btn-action me-2"
              onClick={getListFaturamento}
            >
              <FontAwesomeIcon icon={faFilter} /> Filtrar
            </button>
          </div> */}
          <div className="row" style={{ marginBottom: "15px" }}>
            <div className="col-12 d-flex justify-content-center ">
              {/* Botão Filtrar --------------------------------------------------------------------- */}
              <button
                type="button"
                style={{ marginTop: "20px", width: "300px" }}
                className="btn btn-secondary btn-sm btn-action"
                onClick={getListFaturamento}
              >
                <FontAwesomeIcon icon={faFilter} /> Filtrar
              </button>
              <button
                type="button"
                style={{
                  marginTop: "20px",
                  width: "300px",
                  marginLeft: "20px",
                }}
                className="btn btn-secondary btn-sm btn-action"
                onClick={exportarExcelClick}
              >
                <FontAwesomeIcon icon={faFileExcel} /> Exportar Excel
              </button>
              <button
                type="button"
                style={{
                  marginTop: "20px",
                  width: "300px",
                  marginLeft: "20px",
                }}
                className="btn btn-secondary btn-sm btn-action"
                onClick={downloadExtratoPdf}
              >
                <FontAwesomeIcon icon={faFilePdf} /> Exportar PDF
              </button>
            </div>
          </div>
        </div>

        {lib.isNullOrEmptyObject(errors) ? null : (
          <div className="alert alert-danger">
            <ul className="m-0">
              {Object.values(errors).map((message) => (
                <li>{message}</li>
              ))}
            </ul>
          </div>
        )}

        {(pedidoFaturamentoNotas || []).length === 0 && (
          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col text-center">
              Não foi encontrado nenhum registro.
            </div>
          </div>
        )}
        {(pedidoFaturamentoNotas || []).length > 0 && (
          <div className="row">
            <div className="col table-responsive">
              <table className="table text-center">
                <thead>
                  <tr className="bg-primary text-light">
                    <th>Nº NOTA</th>
                    <th>DATA NOTA</th>
                    <th>PEDIDO</th>
                    <th>DATA PEDIDO</th>
                    <th>REPRESENTADA</th>
                    <th>VALOR</th>
                    <th>COMISSÃO PREVISTA </th>
                    <th width={"250px"}>COMISSÃO RECEBIDA</th>
                  </tr>
                </thead>
                <tbody>
                  {(pedidoFaturamentoNotas || []).length > 0
                    ? (pedidoFaturamentoNotas || []).map(
                        (faturamentoNota, index) => (
                          <tr key={index}>
                            <td>
                              <button
                                type="button"
                                className="btn btn-sm btn-link"
                                onClick={() => {
                                  setModalFaturamentoNotaData({
                                    pedidoFaturamentoId:
                                      faturamentoNota.pedidoFaturamentoId,
                                    showModal: true,
                                  });
                                }}
                              >
                                {faturamentoNota.nota}
                              </button>
                            </td>
                            <td>
                              {lib.getStringFromDate(faturamentoNota.dataNota)}
                            </td>
                            <td>{faturamentoNota.codigoPedido}</td>
                            <td>
                              {lib.getStringFromDate(
                                faturamentoNota.dataPedido
                              )}
                            </td>
                            <td>{faturamentoNota.representada}</td>
                            <td>
                              <NumberFormat
                                value={faturamentoNota.valor}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"R$ "}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={faturamentoNota.comissaoPrevista}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"R$ "}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={faturamentoNota.comissaoRecebida}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"R$ "}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </td>
                          </tr>
                        )
                      )
                    : null}

                  <tr className="bg-primary text-light">
                    <td className="p-2">Total:</td>
                    <td colSpan="4" className="text-end"></td>
                    <td>
                      <NumberFormat
                        value={(pedidoFaturamentoNotas || []).reduce(
                          (total, f) => total + (f.valor ?? 0),
                          0
                        )}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={(pedidoFaturamentoNotas || []).reduce(
                          (total, f) => total + (f.comissaoPrevista ?? 0),
                          0
                        )}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={(pedidoFaturamentoNotas || []).reduce(
                          (total, f) => total + (f.comissaoRecebida ?? 0),
                          0
                        )}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <ModalFaturamentoNota
        pedidoFaturamentoId={modalFaturamentoNotaData.pedidoFaturamentoId}
        showModal={modalFaturamentoNotaData.showModal}
        setShowModal={() =>
          setModalFaturamentoNotaData({
            ...modalFaturamentoNotaData,
            showModal: false,
          })
        }
      />

      <div id="extrato-comissao-pdf" style={{ display: "none" }}>
        <div className="row">
          <div className="col table-responsive">
            <table className="table text-center">
              <thead>
                <tr className="bg-primary text-light">
                  <th>Nº NOTA</th>
                  <th>DATA NOTA</th>
                  <th>PEDIDO</th>
                  <th>DATA PEDIDO</th>
                  <th>REPRESENTADA</th>
                  <th>VALOR</th>
                  <th>COMISSÃO PREVISTA </th>
                  <th width={"250px"}>COMISSÃO RECEBIDA</th>
                </tr>
              </thead>
              <tbody>
                {(pedidoFaturamentoNotas || []).length > 0
                  ? (pedidoFaturamentoNotas || []).map(
                      (faturamentoNota, index) => (
                        <tr key={index + "rel"}>
                          <td>{faturamentoNota.nota}</td>
                          <td>
                            {lib.getStringFromDate(faturamentoNota.dataNota)}
                          </td>
                          <td>{faturamentoNota.codigoPedido}</td>
                          <td>
                            {lib.getStringFromDate(faturamentoNota.dataPedido)}
                          </td>
                          <td>{faturamentoNota.representada}</td>
                          <td>
                            <NumberFormat
                              value={faturamentoNota.valor}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={faturamentoNota.comissaoPrevista}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={faturamentoNota.comissaoRecebida}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </tr>
                      )
                    )
                  : null}

                <tr className="bg-primary text-light">
                  <td className="p-2">Total:</td>
                  <td colSpan="4" className="text-end"></td>
                  <td>
                    <NumberFormat
                      value={(pedidoFaturamentoNotas || []).reduce(
                        (total, f) => total + (f.valor ?? 0),
                        0
                      )}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={(pedidoFaturamentoNotas || []).reduce(
                        (total, f) => total + (f.comissaoPrevista ?? 0),
                        0
                      )}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={(pedidoFaturamentoNotas || []).reduce(
                        (total, f) => total + (f.comissaoRecebida ?? 0),
                        0
                      )}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
