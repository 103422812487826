import React from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faEdit, faFilter, faRecycle, faTrash } from '@fortawesome/free-solid-svg-icons';
import PageTitle from "../../components/page-title/index";
import ModalCadastroRepresentada from "../../components/modal-cadastro-representada";
import TablePagination from "../../components/table-pagination";
import Layout from "../../components/layout/base/index";
import ModalConfirmacao from '../../components/modal-confirmacao';

import AdminService from "../../services/admin-service";

import lib from "../../helpers/lib";

export default function Admin() {
    const [usuarios, setUsuarios] = React.useState({});
    const [usuarioTipos, setUsuarioTipos] = React.useState();
    const [pageData, setPageData] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [filter, setFilter] = React.useState({
        nome: "",
        email: "",
        usuarioTipoId: "",
        situacao: "A"
    });

    React.useEffect(() => {
        getUsuarioTipoSelectList();
        filterUsuarios();
    }, []);

    async function filterUsuarios(page) {
        window.showLoader();

        let response = await AdminService.getUsuarioListPagedAsync({ ...filter, pageNumber: page });

        setPageData(response.data.data);

        window.hideLoader();
    }

    async function getUsuarioTipoSelectList() {
        let usuarioTipos = await AdminService.getUsuarioTipoSelectListAsync();
        if (usuarioTipos.data) setUsuarioTipos(usuarioTipos.data.data);
    }

    async function loginAs(usuarioId) {
        window.showLoader();

        localStorage.clear();

        setErrors({});

        const params = new URLSearchParams(window.location.search);
        const redirectTo = params.get('redirectTo');

        let result = await AdminService.loginAsAsync(usuarioId);

        if (result.status === lib.statusCode.ok) {
            window.location = result.data.data.urlRedirect;
        } else {
            setErrors(result.data.errors)
        }

        window.hideLoader();
    }

    return (
        <Layout>
            {/* Titulo -------------------------------------------------------------------------------- */}
            <PageTitle title="Lista de Usuários Para Login" />

            <div className="row mb-4 filter-bar3">
                {/* Nome ------------------------------------------------------------------------------ */}
                <div className="col-lg-4">
                    <label>Nome</label>
                    <input type="text" className="form-control" value={filter.nome} onChange={(e) => setFilter({ ...filter, nome: e.target.value })} />
                </div>

                {/* Email ----------------------------------------------------------------------------- */}
                <div className="col-lg-3">
                    <label>Email</label>
                    <input type="text" className="form-control" value={filter.email} onChange={(e) => setFilter({ ...filter, email: e.target.value })} />
                </div>

                {/* Tipo ------------------------------------------------------------------------------ */}
                <div className="col-lg-3">
                    <label>Tipo</label>
                    <Select
                        styles={customControlStyles}
                        isClearable
                        placeholder="Selecione..."
                        options={usuarioTipos}
                        value={usuarioTipos?.find(x => x.value === filter.usuarioTipoId)}
                        onChange={(selectedValue) => {
                            setFilter({ ...filter, usuarioTipoId: selectedValue?.value});
                        }}
                    />
                </div>

                {/* Botao Filtrar --------------------------------------------------------------------- */}
                <div className="col-lg-2 d-flex align-items-center">
                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => { filterUsuarios() }}><FontAwesomeIcon icon={faFilter} /> Filtrar</button>
                </div>
            </div>
            {pageData.data && (
                <>
                    <table className="table table-cadastro">
                        {/* Cabecalho da Tabela ------------------------------------------------------- */}
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Tipo</th>
                                <th></th>
                            </tr>
                        </thead>

                        {/* Corpo da Tabela ----------------------------------------------------------- */}
                        <tbody>
                            {(pageData?.data || []).map((representada, index) => (
                                <tr key={index}>
                                    <td>{representada.usuarioId}</td>
                                    <td>{representada.nome}</td>
                                    <td>{representada.email}</td>
                                    <td>{representada.usuarioTipo}</td>
                                    <td>
                                        <button type="button" className="btn btn-secondary btn-sm me-1" title='Logar como' onClick={() => { loginAs(representada.usuarioId) }}>
                                            <FontAwesomeIcon icon={faSignInAlt} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Paginaco ---------------------------------------------------------------------- */}
                    <TablePagination
                        pageData={pageData}
                        onChangePage={(page) => filterUsuarios(page)}
                    />
                </>
            )}

        </Layout>
    );
}