import React from "react";
import NumberFormat from "react-number-format";

export default function InputComissao({ entity, inheritedValue, onChange }) {
  const regexDecimal = /^[+-]?\d*(?:[.,]\d*)?$/;
  const [isEditing, setIsEditing] = React.useState(false);
  const [valor, setValor] = React.useState("");

  React.useEffect(() => {
    setValor(entity.valor);
  }, [entity, inheritedValue])

  return (

    <div className="input-group">
      <input type="text"
        className="form-control form-control-comissao"
        maxLength={9}
        pattern="[+-]?\d+(?:[.,]\d+)?"
        value={valor || (isEditing ? "" : inheritedValue) || ""}
        onFocus={() => setIsEditing(true)}
        onBlur={() => setIsEditing(false)}
        onChange={(e) => {
          if (!regexDecimal.test(e.target.value))
            return;

          entity.valor = e.target.value;
          setValor(entity.valor);

          if (onChange)
            onChange();
        }} />
        <span className="input-group-text" id="basic-addon1">%</span>
    </div>


  )
}