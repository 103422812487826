import axios from "axios";
import lib from "./lib"

var url = process.env.REACT_APP_API_ROOT;

// ----------------------------------------------------------------------------------------------
// Cliente Json

const AxiosJsonClient = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});

AxiosJsonClient.interceptors.request.use(function (config) {
  const bearerToken = localStorage.getItem('token')
    ? `Bearer ${localStorage.getItem('token')}`
    : '';

  config.headers.Authorization = bearerToken;
  config.headers["equipeId"] = localStorage.getItem("equipeId");

  return config;
});

AxiosJsonClient.interceptors.response.use(
  function (response) {
    return response;
  },
  (error)=>processError(error)
);

// ----------------------------------------------------------------------------------------------
// Cliente para usar Post com FormData

const AxiosFormClient = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

AxiosFormClient.interceptors.request.use(function (config) {
  const bearerToken = localStorage.getItem('token')
    ? `Bearer ${localStorage.getItem('token')}`
    : '';

  config.headers.Authorization = bearerToken;
  config.headers["equipeId"] = localStorage.getItem("equipeId");

  return config;
});

AxiosFormClient.interceptors.response.use(
  function (response) {
    return response;
  },
  (error)=>processError(error)
);

// ----------------------------------------------------------------------------------------------
// Tratamento de erros

function processError(error){
  if (401 === error.response?.status) {
    window.location = '/login';
  } 
  
  // else if (403 === error.response?.status) {
  //   // mostrar uma tela de voce nao tem permissao pra finalizar essa operação
  //   window.location = '/acesso-restrito';
  // } 
  
  //else 
  if (error.response?.status === lib.statusCode.badRequest && error.response?.data) {
    return error.response;
  }

  // else {
  //   // mostrar tela de um erro inesperado na tela
  //   window.location = '/erro';
  // }  

}

export {AxiosJsonClient, AxiosFormClient};