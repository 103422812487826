import ServiceClient from "./service-client";
import { service_url } from "./service-constants";

class AdminService {
    static async getUsuarioListPagedAsync(filter) {
        try {
            var result = await ServiceClient.Get(service_url.admin_usuariolistpaged, filter);

            return result;
        } catch (error) {
            console.log(error);
            return undefined;
        }
    }

    static async getUsuarioTipoSelectListAsync() {
        try {
            var result = await ServiceClient.Get(service_url.admin_usuarioTipoSelectList);
            console.log(service_url.admin_usuarioTipoSelectList);
            return result;
        } catch (error) {
            console.log(error);
            return undefined;
        }
    }

    static async loginAsAsync(usuarioId){
        try {
            var result = await ServiceClient.Get(service_url.admin_loginAs + "/" + usuarioId);

            return result;
        } catch (error) {
            console.log(error);
            return undefined;
        }
    }
}

export default AdminService;