import ServiceClient from "./service-client";
import { service_url } from "./service-constants";

class PedidoRascunhoService {
  static async getAsync(id) {
    try {
      var result = await ServiceClient.Get("/pedidorascunho/get", {
        pedidoRascunhoId: id,
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async GetPagedAsync(filter) {
    try {
      const result = await ServiceClient.Get(
        service_url.pedidorascunho_getpaged,
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getInicialAsync(pedidoTipoSigla) {
    try {
      var result = await ServiceClient.Get("/pedidorascunho/get-inicial",{pedidoTipoSigla});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getPadraoLojaAsync(id, lojaId) {
    try {
      var result = await ServiceClient.Get("/pedidorascunho/get-padrao-loja", {
        pedidoRascunhoId: id,
        lojaId: lojaId,
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async removePedidoPadraoLojaAsync(lojaId) {
    try {
      var result = await ServiceClient.Post(
        "/pedidorascunho/remove-padrao-loja/" + lojaId
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync(rascunho) {
    try {
      var result = await ServiceClient.Post("/pedidorascunho/update", rascunho);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateRevisaoAsync(rascunho) {
    try {
      var result = await ServiceClient.Post("/pedidorascunho/update-revisao", rascunho);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async inactivateAsync(id) {
    try {
      const result = await ServiceClient.Post(
        service_url.pedidorascunho_inactivate + "/" + id,
        {}
      );

      return result;
    } catch (error) {
      console.log(error);

      return undefined;
    }
  }

  static async removeAsync(id) {
    try {
      const result = await ServiceClient.Post(
        service_url.pedidorascunho_remove + "/" + id,
        {}
      );

      return result;
    } catch (error) {
      console.log(error);

      return undefined;
    }
  }
}

export default PedidoRascunhoService;
