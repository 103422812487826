import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

export default function InputImagePreview(props) {
  const { selectedFile, setSelectedFile, urlImage, text } = props;

  const [preview, setPreview] = React.useState()

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile, urlImage]);

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
  }

  return (
    <div className="input-image-preview-container h-auto flex-grow-1 d-flex flex-column justify-content-center">
      <label className="text-center">
      {selectedFile && <img src={preview} />}
        {!selectedFile && <img src={urlImage} />}
        <input type='file' onChange={onSelectFile} />
        {(!selectedFile && !urlImage) && <FontAwesomeIcon icon={faCamera} />}
        <div>{text? text: "Clique para adicionar uma Imagem"}</div>
      </label>
    </div>
  );
}