import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faFilter,
  faPlus,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ModalCadastroEquipe from "../../components/modal-cadastro-equipe";
import ModalCadastroEquipeConvite from "../../components/modal-cadastro-equipe-convite";
import ModalConfirmacao from "../../components/modal-confirmacao";
import ModalCadastroEquipeCarteira from "../../components/modal-cadastro-equipe-carteira";
import ModalCadastroEquipeUsuarioPermissoes from "../../components/modal-cadastro-equipe-permissoes";
import ModalCadastroEquipeComissao from "../../components/modal-cadastro-equipe-comissao";
import ModalCadastroEquipeMeta from "../../components/modal-cadastro-equipe-meta";
import TablePagination from "../../components/table-pagination";

import { LoggedUserContext } from "../../contexts/logged-user-context";

import EquipeService from "../../services/equipe-service";

import lib from "../../helpers/lib";
import ModalCadastroDefaultEquipePermissoes from "../../components/modal-cadastro-default-permissoes";
import RepresentadaService from "../../services/representada-service";
import ModalMessage from "../../components/modal-message";

export default function OwnerTeams() {
  const [userData, _] = React.useContext(LoggedUserContext);

  const [opcoesQuantidade, setOpcoesQuantidade] = React.useState([]);

  const [equipe, setEquipe] = React.useState({});

  const [ownerTeamsPageData, setOwnerTeamsPageData] = React.useState({});

  const [showModalCadastro, setShowModalCadastro] = React.useState(false);

  const [showModalInvite, setShowModalInvite] = React.useState(false);

  const [showModalInviteSuccess, setShowModalInviteSuccess] =
    React.useState(false);

  const [modalRemoveEquipeData, setModalRemoveEquipeData] = React.useState({
    equipe: {},
    showModal: false,
  });

  const [removeData, setRemoveData] = React.useState({
    equipe: {},
    usuario: {},
    showModalRemover: false,
  });

  const [carteiraData, setCarteiraData] = React.useState({
    equipe: {},
    usuario: {},
    showModalCarteira: false,
  });

  const [equipePermissoesData, setEquipePermissoesData] = React.useState({
    equipe: {},
    showModalEquipePermissoes: false,
  });

  const [permissoesData, setPermissoesData] = React.useState({
    equipe: {},
    usuario: {},
    showModalPermissoes: false,
  });

  const [comissaoData, setComissaoData] = React.useState({
    equipe: {},
    usuario: {},
    showModalComissao: false,
  });

  const [metaData, setMetaData] = React.useState({
    equipe: {},
    usuario: {},
    showModalMeta: false,
  });

  const [modalResendInvite, setModalResendInvite] = React.useState({
    equipe: {},
    usuario: {},
    showModal: false,
  });

  React.useEffect(() => {
    filterOwnerTeams();
    getOpcoesQuantidadeAsync();
  }, []);

  async function filterOwnerTeams(page) {
    window.showLoader();

    let response = await EquipeService.listOwnerTeamsPagedAsync({
      pageNumber: page,
    });

    setOwnerTeamsPageData(response.data.data);

    window.hideLoader();
  }

  async function getOpcoesQuantidadeAsync() {
    let response = await RepresentadaService.getOrderQuantConfigOptionsAsync();

    if (response && response.data && response.data.data)
      setOpcoesQuantidade(response.data.data);
  }

  function add() {
    setEquipe({});
    setShowModalCadastro(true);
  }

  async function resendInvite() {
    window.showLoader();

    var request = {
      equipeId: modalResendInvite.equipe.equipeId,
      usuarioId: modalResendInvite.usuario.usuarioId,
    };

    let response = await EquipeService.resendInviteAsync(request);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();
      setShowModalInviteSuccess(true);
      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  function update(equipe) {
    setEquipe(equipe);
    setShowModalCadastro(true);
  }

  function onCloseModalCadastro() {
    setEquipe({});
    filterOwnerTeams();
  }

  function invite(equipe) {
    setEquipe(equipe);
    setShowModalInvite(true);
  }

  function onCloseModalInvite() {
    setEquipe({});
    filterOwnerTeams();
  }

  async function removeConfirm(answer) {
    if (answer === "yes") {
      window.showLoader();

      let requestData = {
        equipeId: removeData.equipe.equipeId,
        email: removeData.usuario.email,
      };

      let response = await EquipeService.removeUser(requestData);

      window.hideLoader();

      if (response.status === lib.statusCode.ok) {
        filterOwnerTeams();
        return;
      }
    }
  }

  async function removeEquipe(equipe) {
    window.showLoader();

    let response = await EquipeService.inativateAsync(equipe);

    window.hideLoader();

    if (response.status === lib.statusCode.ok) {
      filterOwnerTeams();
      return;
    }
  }

  function onCloseModalPermissao() {
    setEquipePermissoesData({
      ...equipePermissoesData,
      showModalEquipePermissoes: false,
    });
    filterOwnerTeams();
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col-lg-12">
          <button type="button" className="btn btn-secondary" onClick={add}>
            + Novo Time
          </button>
        </div>
      </div>

      {ownerTeamsPageData.data && (
        <div className="equipe-container">
          {(ownerTeamsPageData?.data || [])
            ?.filter((equipe) => equipe.ativo)
            .map((equipe, index) => (
              <table key={index} className="table table-cadastro">
                <thead>
                  <tr>
                    <th>
                      <div className="row">
                        <div className="col-6">{equipe.nome}</div>
                        <div className="col-6 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-link text-white btn-sm me-1"
                            title="Convidar"
                            onClick={() => invite(equipe)}
                          >
                            CONVIDAR PESSOAS
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm ms-4"
                            title="Editar"
                            onClick={() => update(equipe)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>

                          <button
                            type="button"
                            className="btn btn-secondary btn-sm ms-4"
                            style={{ marginRight: "15px" }}
                            title="PERMISSÕES"
                            onClick={() => {
                              setEquipePermissoesData({
                                equipe: equipe,
                                showModalEquipePermissoes: true,
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </button>

                          <button
                            type="button"
                            className="btn btn-danger btn-sm ms-2"
                            title="Remover"
                            onClick={() =>
                              setModalRemoveEquipeData({
                                equipe: equipe,
                                showModal: true,
                              })
                            }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {equipe.usuarios?.map((usuario, index) => (
                        <div key={index} className="row justify-content-center">
                          <div className="col-11 equipe-usuario-container">
                            <div className="row">
                              <div className="col-lg-3 d-flex flex-column justify-content-center equipe-usuario-descricao-container">
                                {usuario.nome?.length > 0 ? (
                                  <>
                                    <label className="d-block">
                                      {usuario.nome}
                                    </label>
                                    <label className="d-block texto-light">
                                      {usuario.email}
                                    </label>
                                  </>
                                ) : (
                                  <label className="texto">
                                    {usuario.email}
                                  </label>
                                )}
                              </div>

                              <div className="col-lg-9 d-flex flex-column justify-content-center">
                                <div className="row">
                                  <div className="col px-5 py-1">
                                    <button
                                      type="button"
                                      className="btn btn-secondary w-100"
                                      onClick={() => {
                                        setPermissoesData({
                                          equipe: equipe,
                                          usuario: usuario,
                                          showModalPermissoes: true,
                                        });
                                      }}
                                    >
                                      PERMISSÕES
                                    </button>
                                  </div>

                                  <div className="col px-8 py-1">
                                    <button
                                      type="button"
                                      className="btn btn-secondary w-100"
                                      onClick={() => {
                                        setCarteiraData({
                                          equipe: equipe,
                                          usuario: usuario,
                                          showModalCarteira: true,
                                        });
                                      }}
                                    >
                                      CARTEIRA
                                    </button>
                                  </div>

                                  {userData?.cadastraEquipe ||
                                  userData?.gerenciaEquipeParticipante ? (
                                    <div className="col px-5 py-1">
                                      <button
                                        type="button"
                                        className="btn btn-secondary w-100"
                                        onClick={() => {
                                          setComissaoData({
                                            equipe: equipe,
                                            usuario: usuario,
                                            showModalComissao: true,
                                          });
                                        }}
                                      >
                                        COMISSÃO
                                      </button>
                                    </div>
                                  ) : null}

                                  {userData?.cadastraEquipe ||
                                  userData?.gerenciaEquipeParticipante ? (
                                    <div className="col px-5 py-1">
                                      <button
                                        type="button"
                                        className="btn btn-secondary w-100"
                                        onClick={() => {
                                          setMetaData({
                                            equipe: equipe,
                                            usuario: usuario,
                                            showModalMeta: true,
                                          });
                                        }}
                                      >
                                        META
                                      </button>
                                    </div>
                                  ) : null}
                                  <div className="col px-5 py-1">
                                    <button
                                      type="button"
                                      className="btn btn-danger w-100"
                                      onClick={() => {
                                        setRemoveData({
                                          equipe: equipe,
                                          usuario: usuario,
                                          showModalRemover: true,
                                        });
                                      }}
                                    >
                                      REMOVER
                                    </button>
                                  </div>
                                </div>

                                {!usuario.ativo && (
                                  <div className="row">
                                    <div className="col-lg-12 text-center">
                                      <hr />
                                      <label className="d-block">
                                        Aguardando aceite do convite.
                                      </label>
                                      <label className="texto-light">
                                        Convite enviado em{" "}
                                        {lib.getStringFromDateTime(
                                          usuario.dataConvite
                                        )}
                                      </label>
                                      <br />
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setModalResendInvite({
                                            equipe: equipe,
                                            usuario: usuario,
                                            showModal: true,
                                          });
                                        }}
                                      >
                                        Reenviar Convite
                                      </a>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}

          <TablePagination
            ownerTeamsPageData={ownerTeamsPageData}
            onChangePage={(page) => filterOwnerTeams(page)}
          />
        </div>
      )}

      <ModalCadastroEquipe
        showModal={showModalCadastro}
        setShowModal={setShowModalCadastro}
        onClose={onCloseModalCadastro}
        entity={equipe}
      />

      <ModalCadastroEquipeConvite
        showModal={showModalInvite}
        setShowModal={setShowModalInvite}
        onClose={onCloseModalInvite}
        equipe={equipe}
      />

      {/* Modal Remover Equipe ---------------------------------------------------------------- */}
      <ModalConfirmacao
        showModal={modalRemoveEquipeData.showModal}
        setShowModal={() =>
          setModalRemoveEquipeData({
            ...modalRemoveEquipeData,
            showModal: false,
          })
        }
        onClose={(result) => {
          if (result === "yes") {
            removeEquipe(modalRemoveEquipeData.equipe);
          }
        }}
        headerText=" "
        btnYesText="Remover"
        btnNoText="Cancelar"
      >
        <p>
          Por favor, confirme se quer remover o time{" "}
          <b>"{modalRemoveEquipeData.equipe?.nome}"</b>
        </p>
      </ModalConfirmacao>

      {/* Modal Remover Usuario --------------------------------------------------------------- */}
      <ModalConfirmacao
        showModal={removeData.showModalRemover}
        setShowModal={() =>
          setRemoveData({ ...removeData, showModalRemover: false })
        }
        onClose={removeConfirm}
        headerText=" "
        btnYesText="Remover"
        btnNoText="Cancelar"
      >
        <p>
          Confirme se quer remover{" "}
          {removeData.usuario?.nome ?? removeData.usuario.email} do time{" "}
          {removeData.equipe?.nome}
        </p>
      </ModalConfirmacao>

      <ModalCadastroEquipeCarteira
        showModal={carteiraData.showModalCarteira}
        setShowModal={() =>
          setCarteiraData({ ...carteiraData, showModalCarteira: false })
        }
        carteira={carteiraData}
      />

      <ModalCadastroEquipeUsuarioPermissoes
        showModal={permissoesData.showModalPermissoes}
        setShowModal={() =>
          setPermissoesData({ ...permissoesData, showModalPermissoes: false })
        }
        usuarioPermissoes={permissoesData}
        opcoesQuantPedido={opcoesQuantidade}
      />

      <ModalCadastroDefaultEquipePermissoes
        showModal={equipePermissoesData.showModalEquipePermissoes}
        setShowModal={() =>
          setEquipePermissoesData({
            ...equipePermissoesData,
            showModalEquipePermissoes: false,
          })
        }
        equipePermissoes={equipePermissoesData}
        onClose={onCloseModalPermissao}
      />

      <ModalCadastroEquipeComissao
        showModal={comissaoData.showModalComissao}
        setShowModal={() =>
          setComissaoData({ ...comissaoData, showModalComissao: false })
        }
        comissao={comissaoData}
      />

      <ModalCadastroEquipeMeta
        showModal={metaData.showModalMeta}
        setShowModal={() => setMetaData({ ...metaData, showModalMeta: false })}
        meta={metaData}
      />

      {/* Modal reenviar convite ---------------------------------------------------------------- */}
      <ModalConfirmacao
        showModal={modalResendInvite.showModal}
        setShowModal={() =>
          setModalResendInvite({ ...modalResendInvite, showModal: false })
        }
        onClose={(result) => {
          if (result === "yes") {
            resendInvite();
          }
        }}
        headerText=" "
        btnYesText="Reenviar"
        btnNoText="Cancelar"
      >
        <p>
          Por favor, confirme se quer reenviar o convite para
          <b>{" " + modalResendInvite?.usuario?.email}</b>
        </p>
      </ModalConfirmacao>

      <ModalMessage
        title="Convite Enviado!"
        showModal={showModalInviteSuccess}
        setShowModal={setShowModalInviteSuccess}
        onClose={() => {}}
      >
        <p>O convite foi reenviado!</p>
      </ModalMessage>
    </>
  );
}
