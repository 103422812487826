import React from "react";
import ModalCadastro from "../_base/modal-cadastro";

import PedidoFaturamentoComissaoService from "../../services/pedido-faturamento-comissao-service";

// import "./styles.scss";

import lib from "../../helpers/lib";

export default function ModalFaturamentoNota({ pedidoFaturamentoId, showModal, setShowModal, onClose }) {
  const [model, setModel] = React.useState({});

  React.useEffect(() => {
    if (!pedidoFaturamentoId)
      return;

    (async () => {
      window.showLoader();
      let response = await PedidoFaturamentoComissaoService.getFaturamentoNotaDetalhesAsync(pedidoFaturamentoId);

      setModel(response.data.data);

      window.hideLoader()
    })();
  }, [pedidoFaturamentoId])

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="NOTA DETALHES"
      onClose={onClose}
    >
      <div className="modal-body">
        {
          !model?.pedidoFaturamentoId
            ? null
            : (
              <>
                <div className="row form-group">
                  <div className="col-lg-2">
                    <label className="col-form-label">Nº da Nota:</label>
                  </div>

                  <div className="col-lg-8 col-xl-5">
                    <label className="col-form-label">{model.nota}</label>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-lg-2">
                    <label className="col-form-label">Data da Nota:</label>
                  </div>

                  <div className="col-lg-8 col-xl-5">
                    <label className="col-form-label">{lib.getStringFromDate(model.dataNota)}</label>
                  </div>
                </div>
                <div className="div-table-cadastro">
                  <table className="table table-cadastro table-xs">
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>Descrição</th>
                        <th className="text-center">Unid. Medida</th>
                        <th className="text-center">Valor Unitário</th>
                        <th className="text-center">Qtd. Faturada</th>
                        <th className="text-center">Total Fatura</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(model?.produtos || []).map((item, index) =>
                        <tr>
                          <td>{item.codigo}</td>
                          <td>{item.descricao}</td>
                          <td className="text-center">{item.unidade}</td>
                          <td className="text-center">{item.valorUnitarioBrl}</td>
                          <td className="text-center">{item.quantidadeFaturada}</td>
                          <td className="text-center">{item.valorTotalBrl}</td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan={5} align="right">Total:</td>
                        <td className=" text-center">{model?.valorTotalBrl}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )
        }

      </div>


    </ModalCadastro>
  );
}
