import React from "react";
import CurrencyInput from "react-currency-input";

export default function InputPreco({ tabelaPreco, keyName, label }) {
  const [valor, setValor] = React.useState("");

  React.useEffect(() => {
    setValor(tabelaPreco[keyName]);
  }, [tabelaPreco, keyName]);

  return (
    <CurrencyInput
      className={`form-control`}
      decimalSeparator=","
      thousandSeparator="."
      prefix="R$ "
      selectAllOnFocus={true}
      value={parseFloat(valor || 0)}
      onChangeEvent={(event, maskedvalue, floatvalue) => {
        tabelaPreco[keyName] = floatvalue;
        setValor(tabelaPreco[keyName]);
      }}
    />
  );
}
