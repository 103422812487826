import React, { useEffect } from "react";
import PageTitle from "../../components/page-title/index";
import Layout from "../../components/layout/base/index";

import { initMercadoPago, CardPayment } from "@mercadopago/sdk-react";

import "./styles.scss";

export default function MeusDados() {
  initMercadoPago("TEST-5e8e5915-14f5-4506-8b02-e985d38e089f");

  const [errors, setErrors] = React.useState({});

  return (
    <Layout>
      <PageTitle title="Pagamento"></PageTitle>

      <div className="mercado-pago-page">
        <CardPayment
          initialization={{
            amount: 5,
            payer: {
              email: "fmeirel@gmail.com",
              identification: { type: "CPF", number: "12345678909" },
            },
          }}
          onSubmit={async (param) => {}}
        />
      </div>
    </Layout>
  );
}
