import React from "react";
import { matchSorter } from "match-sorter";
import ModalCadastro from "../_base/modal-cadastro";
import FormGroupHeader from "../_base/form-group-header";
import Errors from "../_base/errors";

import EquipeService from "../../services/equipe-service";

import lib from "../../helpers/lib";

import "./styles.scss";

export default function ModalCadastroEquipeCarteira(props) {
  const { showModal, setShowModal, onClose, carteira: ref_carteira } = props;

  const emptyCarteira = {
    usuario: {},
    equipe: {},
  };

  const [carteira, setCarteira] = React.useState({ ...emptyCarteira });
  const [carteiraClientes, setCarteiraClientes] = React.useState([]);
  const [carteiraRepresentadas, setCarteiraRepresentadas] = React.useState([]);
  const [clientes, setClientes] = React.useState([]);
  const [representadas, setRepresentadas] = React.useState([]);
  const [filteredClientes, setFilteredClientes] = React.useState([]);
  const [filteredRepresentadas, setFilteredRepresentadas] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    getClientes();
    getRepresentadas();
  }, []);

  React.useEffect(() => {
    reset();

    getCarteira();
  }, [ref_carteira]);

  function reset() {
    setErrors({});
    setCarteira({ ...emptyCarteira });
    setCarteiraClientes([]);
    setCarteiraRepresentadas([]);
    setFilteredClientes(clientes);
    setFilteredRepresentadas(representadas);
  }

  async function getCarteira() {
    if (
      ref_carteira?.usuario?.usuarioId?.length &&
      ref_carteira?.equipe?.equipeId > 0
    ) {
      window.showLoader();

      let response = await EquipeService.getCarteiraEquipeUsuarioAsync(
        ref_carteira
      );
      let carteira = response.data.data;
      setCarteira(carteira);
      setCarteiraClientes(carteira.clientes);
      setCarteiraRepresentadas(carteira.representadas);
      window.hideLoader();
    }
  }

  async function getClientes() {
    let response = await EquipeService.listClientesAsync();
    let clientes = response.data.data;
    setClientes(clientes);
    setFilteredClientes(clientes);
  }

  async function getRepresentadas() {
    let response = await EquipeService.listRepresentadasAsync();
    let representadas = response.data.data;
    setRepresentadas(representadas);
    setFilteredRepresentadas(representadas);
  }

  async function saveAsync() {
    window.showLoader();

    setErrors({});

    let requestModel = {
      usuario: carteira.usuario,
      equipe: carteira.equipe,
      clientes: carteiraClientes,
      representadas: carteiraRepresentadas,
    };

    let response = await EquipeService.saveCarteiraAsync(requestModel);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      setShowModal(false);
      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText={`${carteira?.usuario?.nome || ""} - ${
        carteira?.equipe?.nome || ""
      } - CARTEIRA`}
    >
      <div className="modal-body">
        <div className="row carteira">
          <div className="col-lg-6">
            <FormGroupHeader text="CLIENTES" />

            <input
              type="search"
              className="form-control mb-3"
              placeholder="Filtrar"
              onChange={(e) => {
                setFilteredClientes(
                  matchSorter(clientes || [], e.target.value, {
                    keys: ["nomeFantasia", "cnpj"],
                  })
                );
              }}
            />

            <label className="list-item  mb-3">
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    let lista = carteiraClientes || [];
                    for (let cliente of filteredClientes) {
                      if (
                        lista.findIndex((x) => x.lojaId === cliente.lojaId) ===
                        -1
                      )
                        lista.push(cliente);
                    }

                    setCarteiraClientes([...lista]);
                  } else {
                    let lista = carteiraClientes || [];
                    for (let cliente of filteredClientes) {
                      let index = lista.findIndex(
                        (x) => x.lojaId === cliente.lojaId
                      );
                      if (index !== -1) lista.splice(index, 1);
                    }

                    setCarteiraClientes([...lista]);
                  }
                }}
              />{" "}
              Selecionar todos
            </label>

            {(filteredClientes || []).map((cliente, index) => (
              <div key={index} className="d-block">
                <label className="list-item  mb-3 d-flex align-items-start">
                  <input
                    type="checkbox"
                    checked={
                      (carteiraClientes || []).findIndex(
                        (c) => c.lojaId === cliente.lojaId
                      ) !== -1
                    }
                    onChange={(e) => {
                      let lista = carteiraClientes || [];

                      if (e.target.checked) {
                        lista.push(cliente);
                        setCarteiraClientes([...lista]);
                      } else {
                        let index = lista.findIndex(
                          (x) => x.lojaId === cliente.lojaId
                        );

                        if (index !== -1) {
                          lista.splice(index, 1);
                          setCarteiraClientes([...lista]);
                        }
                      }
                    }}
                  />
                  <div className="ps-1">
                    <div className="list-item  mb-3">
                      <span className="">
                        {cliente.nomeFantasia}{" "}
                        {cliente.inadimplente ? " - Bloqueado" : ""}
                      </span>
                      <br />
                      <span className="fantasia">
                        {cliente.razaoSocial} - {cliente.cnpj}
                      </span>
                      <br />
                      <span className="cnpj">
                        {cliente.logradouro} - {cliente.bairro} -{" "}
                        {cliente.cidade}
                      </span>
                    </div>
                  </div>
                </label>
              </div>
            ))}
          </div>

          <div className="col-lg-6">
            <FormGroupHeader text="REPRESENTADAS" />

            <input
              type="search"
              className="form-control mb-3"
              placeholder="Filtrar"
              onChange={(e) => {
                setFilteredRepresentadas(
                  matchSorter(representadas || [], e.target.value, {
                    keys: ["nomeFantasia"],
                  })
                );
              }}
            />

            <label className="list-item  mb-3">
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    let lista = carteiraRepresentadas || [];
                    for (let representada of filteredRepresentadas) {
                      if (
                        lista.findIndex(
                          (x) =>
                            x.representadaId === representada.representadaId
                        ) === -1
                      )
                        lista.push(representada);
                    }

                    setCarteiraRepresentadas([...lista]);
                  } else {
                    let lista = carteiraRepresentadas || [];
                    for (let representada of filteredRepresentadas) {
                      let index = lista.findIndex(
                        (x) => x.representadaId === representada.representadaId
                      );
                      if (index !== -1) lista.splice(index, 1);
                    }

                    setCarteiraRepresentadas([...lista]);
                  }
                }}
              />{" "}
              Selecionar todos
            </label>

            {(filteredRepresentadas || []).map((representada, index) => (
              <div key={index} className="d-block">
                <label className="list-item mb-3">
                  <input
                    type="checkbox"
                    checked={
                      (carteiraRepresentadas || []).findIndex(
                        (c) => c.representadaId === representada.representadaId
                      ) !== -1
                    }
                    onChange={(e) => {
                      let lista = carteiraRepresentadas || [];

                      if (e.target.checked) {
                        lista.push(representada);
                        setCarteiraRepresentadas([...lista]);
                      } else {
                        let index = lista.findIndex(
                          (x) =>
                            x.representadaId === representada.representadaId
                        );

                        if (index !== -1) {
                          lista.splice(index, 1);
                          setCarteiraRepresentadas([...lista]);
                        }
                      }
                    }}
                  />{" "}
                  <div className="list-item  mb-3">
                    <span className="">{representada.nomeFantasia} </span>
                    <br />
                    <span className="fantasia">
                      {representada.razaoSocial} - {representada.cnpj}
                    </span>
                  </div>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          SALVAR
        </button>
      </div>
    </ModalCadastro>
  );
}
