import ServiceClient from "./service-client";
import { service_url } from "./service-constants";

class ExtratoService {
  static async listAsync(filter) {
    try {
      var result = await ServiceClient.Get("/extrato/list", filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default ExtratoService;
