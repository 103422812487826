import React from "react";
import ModalCadastro from "../_base/modal-cadastro";
import { LoggedUserContext } from "../../contexts/logged-user-context";
import { MuiChipsInput } from "mui-chips-input";
import PedidoService from "../../services/pedido-service";

export default function ModalEmailPedido(props) {
  const { showModal, setShowModal, emailEnviadoMessage, params } = props;
  const [chips, setChips] = React.useState([]);
  const [unicoEmail, setUnicoEmail] = React.useState("");

  const handleChange = (newChips) => {
    setChips(newChips);
  };

  const changeInputValue = (value) => {
    setUnicoEmail(value);
  };

  React.useEffect(() => {
    setChips([]);
    if (showModal == false) return;
    (async () => {
      window.showLoader();
      let response = await PedidoService.getUltimosEmailsDoUsuarioAsync(
        params.representadaId
      );
      if (
        response &&
        response.data &&
        (response.data != null) & (response.data != "")
      ) {
        if (response.data.includes(";")) {
          const arrayEmails = response.split(";");
          setChips(arrayEmails);
        } else {
          const arrayEmails = [response.data];
          setChips(arrayEmails);
        }
      } else {
        setChips([]);
      }
      window.hideLoader();
    })();
  }, [params]);

  async function sendEmail() {
    if (!chips || chips.length == 0) {
      if (unicoEmail == "") {
        alert("Insira ao menos um e-mail para poder enviar!");
        return;
      }
    }
    window.showLoader();
    let emails = "";
    if (chips.length > 0) {
      emails = chips.join(";");
    } else {
      emails = unicoEmail;
    }
    var emailValidos = true;

    emails.split(";").forEach(function myFunction(item) {
      if (!validEmail(item)) {
        emailValidos = false;
        return;
      }
    });

    if (!emailValidos) {
      alert("Existe e-mail com endereço inválido!");
      window.hideLoader();
      return;
    }

    const result = await PedidoService.enviarPedidoParaEmailsAsync(
      params.pedidoRepresentadaId,
      params.representadaId,
      emails
    );
    window.hideLoader();
    setChips([]);
    setShowModal(false);
    if (result.data) {
      emailEnviadoMessage();
    }
  }

  function validEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText={`Insira os e-mails abaixo`}
      size="md"
    >
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            {/* <p>Digite os emails</p> */}

            <MuiChipsInput
              placeholder="Digite o e-mail e aprte enter para digitar o proximo"
              about="Digite o e-mail e aprte enter para digitar o proximo"
              style={{ minWidth: "95%", maxWidt: "95%" }}
              value={chips}
              onChange={handleChange}
              onInputChange={changeInputValue}
            />
            <span>
              *Para digitar mais de um e-mail, precione enter após digitar cada
              e-mail
            </span>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => sendEmail()}
        >
          Enviar
        </button>
      </div>
    </ModalCadastro>
  );
}
