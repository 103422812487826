import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import CurrencyInput from 'react-currency-input';
import NumberFormat from 'react-number-format';

import ModalConfirmacao from '../../components/modal-confirmacao';
import PedidoFaturamentoComissaoService from '../../services/pedido-faturamento-comissao-service';
import lib from '../../helpers/lib';

export default function InputComissaoRecebida({ entityArray, entity, refresh, setErrors, label, className }) {

  const [showModalSalvar, setShowModalSalvar] = React.useState(false);
  const [showModalDescartar, setShowModalDescartar] = React.useState(false);
  const [showModalRemover, setShowModalRemover] = React.useState(false);
  const [valorInicial, setValorInicial] = React.useState(0);
  const [valor, setValor] = React.useState(0);

  React.useEffect(() => {
    if (valor === 0)
      setValor(entity.comissaoRecebida || 0);

    setValorInicial(entity.comissaoRecebida || 0);
  }, [entity])

  async function updateComissaoRecebida() {

    entity.comissaoRecebida = valor;

    window.showLoader();

    let response = await PedidoFaturamentoComissaoService.updateAsync(entity);

    window.hideLoader();

    if (response.status === lib.statusCode.ok) {
      setValorInicial(entity.comissaoRecebida);

      let faturamentoNota = response.data.data;

      //se a nota foi finalizada vai ser removida do array
      if (faturamentoNota.finalizada) {
        let entityIndex = entityArray.indexOf(entity);
        entityArray.splice(entityIndex, 1);
      }
      //--

      refresh();
    } else {
      window.scrollTo(0, 0);
      setErrors(response.data.errors);
    }
  }

  async function descartar() {
    window.showLoader();

    let response = await PedidoFaturamentoComissaoService.descartarAsync(entity);

    window.hideLoader();

    if (response.status === lib.statusCode.ok) {
      let faturamentoNota = response.data.data;

      //se a nota foi finalizada vai ser removida do array
      if (faturamentoNota.finalizada) {
        let entityIndex = entityArray.indexOf(entity);
        entityArray.splice(entityIndex, 1);
      }
      //--

      refresh();
    } else {
      window.scrollTo(0, 0);
      setErrors(response.data.errors);
    }
  }

  async function remover() {
    window.showLoader();

    let response = await PedidoFaturamentoComissaoService.removerAsync(entity);

    window.hideLoader();

    if (response.status === lib.statusCode.ok) {
      //remover do array
      let entityIndex = entityArray.indexOf(entity);
      entityArray.splice(entityIndex, 1);
      //--

      refresh();
    } else {
      window.scrollTo(0, 0);
      setErrors(response.data.errors);
    }
  }

  function onEnterKey(event) {
    if (event.key === 'Enter') {
      //updateComissaoRecebida();
      setShowModalSalvar(true);
    }
  }

  let valorDescartar = (entity.comissaoPrevista || 0) - (entity.comissaoRecebida || 0)

  return (
    <>
      <td>
        <div className="d-flex justify-content-center w-100">
          <CurrencyInput
            className={`form-control ${className}`}
            decimalSeparator=","
            thousandSeparator="."
            prefix="R$ "
            selectAllOnFocus={true}
            value={valor || 0}
            onChangeEvent={(event, maskedvalue, floatvalue) => {
              entity.novoValorComissaoRecebida = floatvalue;
              setValor(floatvalue);

              if (floatvalue === valorInicial)
                delete entity.novoValorComissaoRecebida
            }}
            onKeyPress={onEnterKey}
          />

          {
            valor !== valorInicial
              ? (
                <button type="button" className="btn btn-sm btn-primary ms-2" onClick={() => setShowModalSalvar(true)} title="Salvar"><FontAwesomeIcon icon={faSave} /></button>
              )
              : null
          }
        </div>

        {/* Modal Salvar */}
        <ModalConfirmacao
          headerText={" "}
          showModal={showModalSalvar}
          setShowModal={setShowModalSalvar}
          onConfirm={updateComissaoRecebida}
          onCancel={() => {
            delete entity.novoValorComissaoRecebida;
            setValor(valorInicial);
          }}
        >
          {
            <>
              Tem certeza que deseja confirmar o valor da comissão de <span className="text-success">{
                <NumberFormat
                  value={valor}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"R$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              }</span> para a nota <i className="text-primary">{entity.nota}</i>?
            </>
          }
        </ModalConfirmacao>
      </td>
      <td>
        {

          (
            valorDescartar > 0
              ? (
                <>
                  {
                    <NumberFormat
                      value={valorDescartar}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  }
                  <button type="button" className="btn btn-sm btn-xs btn-danger ms-2 text-nowrap" title="Descartar" onClick={() => setShowModalDescartar(true)}>
                    <FontAwesomeIcon icon={faTrash} className="me-1" />Descartar
                  </button>
                </>
              )
              : null
          )
        }

        {/* Modal Descartar */}
        <ModalConfirmacao
          headerText={" "}
          showModal={showModalDescartar}
          setShowModal={setShowModalDescartar}
          onConfirm={descartar}
        >
          {
            <>
              Tem certeza que deseja descartar o valor <span className="text-danger">{
                <NumberFormat
                  value={valorDescartar}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"R$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              }</span> para a nota <i className="text-primary">{entity.nota}</i>?
            </>
          }
        </ModalConfirmacao>

      </td>

      <td>

        <button type="button" className="btn btn-sm btn-xs btn-danger ms-2 text-nowrap" title="Descartar" onClick={() => setShowModalRemover(true)}>
          <FontAwesomeIcon icon={faTimes} className="me-1" />Excluir Nota
        </button>


        {/* Modal Descartar */}
        <ModalConfirmacao
          headerText={" "}
          showModal={showModalRemover}
          setShowModal={setShowModalRemover}
          onConfirm={remover}
        >

          Tem certeza que deseja remover a nota <i className="text-primary">{entity.nota}</i>?

        </ModalConfirmacao>

      </td>
    </>
  )
}